import React, { useEffect, useState, useRef } from "react";
import "../../../App.css";
import PageTitle from "../../../Component/pageTitle";
import { useDispatch } from "react-redux";
import {
  getCallToExpertSupportList,
  addExpertSupportRemark,
  getCallToExpertSupportRemarkList,
} from "../../../Redux/Actions/counsellorPanelAction";
import LabelField from "../../../Component/labelField";
import moment from "moment";
import { DownloadTableExcel } from "react-export-table-to-excel";
import { getTableDataSlots } from "../../../DATA/dataConstant";
import { showErrorSnackbar } from "../../../Redux/Actions/snackbarAction";
import DatePicker from "../../../Component/datePicker";
import Pagination from "@mui/material/Pagination";
import usePagination from "../../../Component/usePagination";
import PaginationTable from "../../../Component/paginationTable";
import PaginationView from "../../../Component/paginationView";
import {
  containsStartsWithSpecialCharsOrStartsWithNumberOrWhiteSpace,
  length_OneThousandFiveHundred,
  length_Ten,
  validateNameWithoutSpace,
} from "../../../DATA/validators";
import {
  containsStartsWithSpecialCharsOrStartsWithNumberOrWhiteSpaceErrMsg,
  validateFullNameErrMsg,
} from "../../../DATA/errorMsg";
import DateTime from "../../../Component/dateTime";
import SubmitButton from "../../../Component/submitButton";
import InputField from "../../../Component/inputField";

function ExpertSupport() {
  const dispatch = useDispatch();
  const tableRef = useRef(null);
  const [startDate, setStartDate] = useState<any>(
    moment.utc(new Date()).format("YYYY-MM-DD")
  );
  const [endDate, setEndDate] = useState<any>(
    moment.utc(new Date()).format("YYYY-MM-DD")
  );
  const [callId, setCallId] = useState<any>("");
  const [fullName, setFullName] = useState<any>("");
  const [contactNo, setContactNo] = useState<any>("");
  const [contactDateTime, setContactDateTime] = useState<any>("");
  const [noOfTimesContacted, setNoOfTimesContacted] = useState<any>("");
  const [remark, setRemark] = useState<any>("");
  const [expertSupportList, setExpertSupportList] = useState<any>([]);
  const [remarkList, setRemarkList] = useState<any>([]);
  const [isViewMode, setIsViewMode] = useState<any>(false);

  // pagination
  const [count, setCount] = useState<any>(0);
  const [page, setPage] = useState<any>(0);
  const [rowsPerPage, setRowsPerPage] = useState<any>(10);
  const [currentPage, setCurrentPage] = useState<any>(1);
  const totalPages = Math.ceil(count / rowsPerPage);
  const _DATA = usePagination(count, rowsPerPage);

  const handleChange = (e: any, page: any) => {
    onPageChange(page, rowsPerPage, 1);
    setPage(page - 1);
    setCurrentPage(page);
    _DATA.jump(page);
  };

  const onPageChange = (page: any, rowsPerPage: any, flag: any) => {
    /************ page change****************** */
    let postData: any = "";
    if (flag === 1) {
      postData = {
        skip: (page - 1) * rowsPerPage,
        limit: rowsPerPage,
        startDate,
        endDate,
      };
    } else if (flag === 2) {
      postData = {
        skip: 0,
        limit: rowsPerPage,
        startDate,
        endDate,
      };
    } else {
      postData = {
        skip: 0,
        limit: count,
        startDate,
        endDate,
      };
    }
    getData(postData);
  };

  const handleChangePage = (event: any, newPage: any) => {
    setPage(newPage);
    setCurrentPage(newPage + 1);
  };

  const handleChangeRowsPerPage = (event: any) => {
    const value = event.target.value;
    if (value === "All") {
      setRowsPerPage(-1);
      setPage(0);
      setCurrentPage(1);
      return;
    }
    setRowsPerPage(parseInt(value, 10));
    setPage(0);
    setCurrentPage(1);
    onPageChange(page, value, 2);
  };

  useEffect(() => {
    let postData = {
      startDate: startDate,
      endDate: endDate,
      skip: 0,
      limit: rowsPerPage,
    };
    getData(postData);
  }, []);

  const resetData = () => {
    setCount(0);
    setExpertSupportList([]);
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    const postData = {
      skip: 0,
      limit: rowsPerPage,
      startDate,
      endDate,
    };
    getData(postData);
  };

  const getData = (postData: any) => {
    dispatch(
      getCallToExpertSupportList(postData, (res: any) => {
        setCount(res?.count);
        setExpertSupportList(res?.data);
      })
    );
  };

  const validations = () => {
    return startDate === "" || endDate === "";
  };

  const changeEndDate = (value: any) => {
    setEndDate(value);
    resetData();

    if (value < startDate) {
      dispatch(
        showErrorSnackbar("End date must be greater than start date") as any
      );
      return;
    }
  };

  const renderListMode = () => {
    return (
      <div className="col-12">
        <form onSubmit={handleSubmit}>
          <div className="col-auto">
            <div className="d-flex align-items-center justify-content-between">
              <div className="d-flex mb-2 align-items-center">
                <LabelField lableName={"Start Date"} />
                <DatePicker
                  setInputText={(value: any) => {
                    setStartDate(value);
                    resetData();
                  }}
                  value={startDate}
                  Isrequired={true}
                />
              </div>
              <div className="d-flex mb-2 align-items-center">
                <LabelField lableName={"End Date"} />
                <DatePicker
                  setInputText={(value: any) => changeEndDate(value)}
                  value={endDate}
                  Isrequired={true}
                />
              </div>
              <SubmitButton name="Search" validation={validations()} />
              {expertSupportList?.length > 0 ? (
                <div className="d-flex align-items-center">
                  <DownloadTableExcel
                    filename="Call To Expert Support List"
                    sheet="Expert Support"
                    currentTableRef={tableRef.current}
                  >
                    <button className="btn btn-outline-primary submitbtn py-1">
                      Export Details
                    </button>
                  </DownloadTableExcel>
                </div>
              ) : null}
            </div>
          </div>
        </form>

        {expertSupportList?.length !== 0 ? (
          <div className="d-flex paginationdiv justify-content-end align-items-center">
            <Pagination
              count={totalPages}
              size="large"
              page={currentPage}
              variant="outlined"
              shape="rounded"
              color={"primary"}
              onChange={handleChange}
            />
          </div>
        ) : null}
        {count > 0 ? (
          <PaginationView
            count={count}
            tableData={expertSupportList ? expertSupportList : []}
            page={page}
            rowsPerPage={rowsPerPage}
            handleChangePage={handleChangePage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
            rowsPerPageOptions={getTableDataSlots(count)}
          />
        ) : null}

        <div ref={tableRef}>
          <PaginationTable
            tableData={expertSupportList ? expertSupportList : []}
            pagename={"callExpertSupport"}
            rowsPerPage={rowsPerPage}
            page={page}
            tableHead={[
              "S.N",
              "Full Name",
              "Contact Date",
              "Contact Time",
              "No Of Times Contacted",
              "Remark",
              "Remark Date",
              "Edit Remark",
            ]}
            edit={true}
            handleEdit={(value: any) => handleEdit(value)}
          />
        </div>
      </div>
    );
  };

  const handleEdit = (value: any) => {
    setIsViewMode(true);
    setCallId(value?._id);
    dispatch(
      getCallToExpertSupportRemarkList(
        {
          expertSalesLeadId: value?._id,
        },
        (res: any) => {
          setRemarkList(res?.data);
        }
      )
    );
    setFullName(value?.fullName);
    setContactNo(value?.mobileNumber);
    const originalDateTimeString = value?.updatedAt;
    if (originalDateTimeString) {
      const originalDateTime = new Date(originalDateTimeString);
      originalDateTime.setTime(
        originalDateTime.getTime() + 5.5 * 60 * 60 * 1000
      );
      const formattedDateTime = originalDateTime.toISOString();
      setContactDateTime(formattedDateTime);
    } else {
      setContactDateTime("");
    }
    setNoOfTimesContacted(value?.count);
  };

  const changeRemark = (value: any) => {
    if (value.length >= length_OneThousandFiveHundred) {
      dispatch(
        showErrorSnackbar(
          `Max Length ${length_OneThousandFiveHundred} reached for this field`
        ) as any
      );
    } else if (
      containsStartsWithSpecialCharsOrStartsWithNumberOrWhiteSpace(value)
    ) {
      dispatch(
        showErrorSnackbar(
          containsStartsWithSpecialCharsOrStartsWithNumberOrWhiteSpaceErrMsg
        ) as any
      );
      return;
    } else if (!validateNameWithoutSpace(value)) {
      dispatch(showErrorSnackbar(validateFullNameErrMsg) as any);
      return;
    } else {
      setRemark(value);
    }
  };

  const handleUpdate = (e: any) => {
    e.preventDefault();
    const postData = {
      expertSalesLeadId: callId,
      Remark: remark,
      count: noOfTimesContacted,
    };

    dispatch(
      addExpertSupportRemark(postData, () => {
        setIsViewMode(false);
        setRemark("");
      })
    );
  };

  const RemarkValidations = () => {
    return remark === "";
  };

  const renderEditMode = () => {
    return (
      <>
        <form className="col-6 pb-2" onSubmit={handleUpdate}>
          <div className="row d-flex justify-content-between pt-2">
            <div className="col-md-4 mt-2">
              <LabelField lableName="Full Name" isRequired={true} />
            </div>
            <div className="col-md-7">
              <InputField
                placeholder="Enter Full Name"
                value={fullName}
                onChangeInput={(value: any) => setFullName(value)}
                disabled={isViewMode}
              />
            </div>
          </div>
          <div className="row d-flex justify-content-between pt-2">
            <div className="col-md-4 mt-2">
              <LabelField lableName="Mobile Number" isRequired={true} />
            </div>
            <div className="col-md-7">
              <InputField
                placeholder="Enter Mobile Number"
                value={contactNo}
                onChangeInput={(value: any) => setContactNo(value)}
                disabled={isViewMode}
              />
            </div>
          </div>
          <div className="row d-flex justify-content-between pt-2">
            <div className="col-md-4 mt-2">
              <LabelField lableName="Contact Date & Time" isRequired={true} />
            </div>
            <div className="col-md-7">
              <DateTime
                setInputText={(value: any) => {
                  setContactDateTime(value);
                }}
                value={contactDateTime}
                defaultValue={contactDateTime}
                disabled={true}
              />
            </div>
          </div>
          <div className="row d-flex justify-content-between pt-2">
            <div className="col-md-4 mt-2">
              <LabelField lableName="No Of Time Contacted" isRequired={true} />
            </div>
            <div className="col-md-7">
              <InputField
                placeholder="Enter No Of Time Contacted"
                value={noOfTimesContacted}
                onChangeInput={(value: any) => setNoOfTimesContacted(value)}
                disabled={isViewMode}
              />
            </div>
          </div>
          <div className="row d-flex justify-content-between pt-2">
            <div className="col-md-4 mt-2">
              <LabelField lableName="COMMENT" isRequired={true} />
            </div>
            <div className="col-md-7">
              <textarea
                className="form-control"
                placeholder={"Enter Comment"}
                onChange={(e) => changeRemark(e.target.value)}
                maxLength={length_OneThousandFiveHundred}
                minLength={length_Ten}
                value={remark}
                defaultValue={remark}
              ></textarea>
            </div>
          </div>
          <div className="row d-flex justify-content-between pt-2">
            <div className="col-md-4 mt-2">
              <LabelField lableName="" />
            </div>
            <div className="col-md-7">
              <SubmitButton
                name="Save and Update"
                validation={RemarkValidations()}
              />
            </div>
          </div>
        </form>
        {renderRemarkList()}
      </>
    );
  };

  const renderRemarkList = () => {
    return (
      <table className="mb-2 w-100">
        <thead>
          <tr>
            <th>Sr.No.</th>
            <th>Count</th>
            <th>Remark</th>
            <th>Remark Date</th>
          </tr>
        </thead>
        <tbody>
          {remarkList?.map((item: any, index: any) => {
            return (
              <tr>
                <td>{index + 1}</td>
                <td>{item?.count}</td>
                <td>{item?.Remark}</td>
                <td>{moment(item?.createdAt).format("DD/MM/YYYY LTS")}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    );
  };

  const handleCancleEditMode = () => {
    setIsViewMode(false);
  };

  return (
    <div className="contentBox">
      <PageTitle
        name={"EXPERT SUPPORT"}
        isGoBack={isViewMode}
        onClick={handleCancleEditMode}
      />
      <div className="formDiv ">
        <div className="col-auto formWidth">
          {!isViewMode ? renderListMode() : renderEditMode()}
        </div>
      </div>
    </div>
  );
}

export default ExpertSupport;
