import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import PageTitle from "../../Component/pageTitle";
import PaginationTable from "../../Component/paginationTable";
import usePagination from "../../Component/usePagination";
import { useSelector } from "react-redux";
import moment from "moment";
import { orderStatusList } from "../../DATA/dataConstant";
import { updateCommonValues } from "../../Redux/Actions/CommonAction";
import { useAppDispatch } from "../../hooks";
import LabelField from "../../Component/labelField";
import DatePicker from "../../Component/datePicker";
import CustomDropdown from "../../Component/customDropdown";
import SubmitButton from "../../Component/submitButton";
import { getCounsellorOrderDetails } from "../../Redux/Actions/counsellorPanelAction";

export default function ReferencesLeadsList() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const { selectedStartDate, selectedEndDate, selectedStatus } = useSelector(
    (state: any) => ({
      selectedStartDate: state.Auth.startDate,
      selectedEndDate: state.Auth.endDate,
      selectedStatus: state.Auth.selectedStatus,
    })
  );

  const [startDate, SetStartDate] = useState<any>(
    moment.utc(new Date()).format("YYYY-MM-DD")
  );
  const [endDate, SetEndDate] = useState<any>(
    moment.utc(new Date()).format("YYYY-MM-DD")
  );
  const [orderStatus, setOrderStatus] = useState<any>("");

  const pageName = location.state?.pageName;

  // pagination
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const totalPages = Math.ceil(count / rowsPerPage);
  const _DATA = usePagination(count, rowsPerPage);

  const [orderLeadsList, setOrderLeadsList] = useState([]);

  useEffect(() => {
    if (pageName !== "leadDetail") {
      dispatch(updateCommonValues("selectedLeadId", ""));
      dispatch(updateCommonValues("selectedPage", ""));
      dispatch(updateCommonValues("selectedRowsPerPage", ""));
      dispatch(updateCommonValues("startDate", ""));
      dispatch(updateCommonValues("endDate", ""));
      dispatch(updateCommonValues("selectedStatus", ""));
    }
    dispatch(updateCommonValues("startDate", startDate));
    dispatch(updateCommonValues("endDate", endDate));
    fetchData();
  }, []);

  const fetchData = () => {
    setCount(0);
    setOrderLeadsList([]);
    const postData: any = {
      startDate: startDate,
      endDate: endDate,
      status: orderStatus?._id,
    };
    if (pageName === "leadDetail") {
      SetStartDate(selectedStartDate);
      SetEndDate(selectedEndDate);
      let tempStatus = orderStatusList?.find(
        (item: any) => item?._id === selectedStatus
      );
      setOrderStatus(tempStatus);
      postData["startDate"] = selectedStartDate;
      postData["endDate"] = selectedEndDate;
      postData["status"] = selectedStatus;
    }
    dispatch(
      getCounsellorOrderDetails(
        postData,
        (res: any) => {
          if (res?.length > 0) {
            setCount(res?.length);
            setOrderLeadsList(res);
          } else {
            setCount(0);
            setOrderLeadsList([]);
          }
        },
        () => {}
      )
    );
  };

  const handleChange = (e: any, page: number) => {
    onPageChange(page, rowsPerPage, 1);
    setPage(page - 1);
    setCurrentPage(page);
    _DATA.jump(page);
  };
  const onPageChange = (page: number, rowsPerPage: number, flag: number) => {
    /************ page change****************** */
    let data: any = {};
    if (flag === 1) {
      data = {
        skip: (page - 1) * rowsPerPage,
        limit: rowsPerPage,
      };
    } else if (flag === 2) {
      data = {
        skip: 0,
        limit: rowsPerPage,
      };
    } else {
      data = {
        skip: 0,
        limit: count,
      };
    }
  };
  const handleChangePage = (event: any, newPage: number) => {
    setPage(newPage);
    setCurrentPage(newPage + 1);
  };

  const handleChangeRowsPerPage = (event: { target: { value: any } }) => {
    const value = event.target.value;
    if (value === "All") {
      setRowsPerPage(-1);
      setPage(0);
      setCurrentPage(1);
      return;
    }
    setRowsPerPage(parseInt(value, 10));
    setPage(0);
    setCurrentPage(1);
    onPageChange(page, value, 2);
  };

  const onClickLead = (value: any) => {
    dispatch(
      updateCommonValues("selectedLeadId", value?.userDetails?.studentLeadId)
    );
    dispatch(updateCommonValues("selectedPage", page));
    dispatch(updateCommonValues("selectedRowsPerPage", rowsPerPage));
    dispatch(updateCommonValues("selectedStatus", orderStatus?._id));
    navigate("/leadDetail", {
      state: {
        pageName: "ordersList",
      },
    });
  };

  const renderListMode = () => {
    return (
      <>
        <div>
          {/* <div className="d-flex paginationdiv justify-content-end align-items-center">
            <Pagination
              count={totalPages}
              size="large"
              page={currentPage}
              variant="outlined"
              shape="rounded"
              color={"primary"}
              onChange={handleChange}
            />
          </div>
          <PaginationView
            count={count}
            tableData={orderLeadsList ? orderLeadsList : []}
            page={page}
            rowsPerPage={rowsPerPage}
            handleChangePage={handleChangePage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
            rowsPerPageOptions={getTableDataSlots(count)}
          /> */}
          <div>
            <PaginationTable
              tableData={orderLeadsList ? orderLeadsList : []}
              pagename={"orderLeadsList"}
              rowsPerPage={rowsPerPage}
              page={page}
              tableHead={[
                "S.N",
                "Name",
                "Course Name",
                "Student Pay Amount",
                "Coupon Name",
                "Order Date",
              ]}
              edit={false}
              handleClick={(value: any) => onClickLead(value)}
            />
          </div>
        </div>
      </>
    );
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    fetchData();
  };

  const onChangeStartDate = (value: any) => {
    SetStartDate(value);
    dispatch(updateCommonValues("startDate", value));
  };

  const onChangeEndDate = (value: any) => {
    SetEndDate(value);
    dispatch(updateCommonValues("endDate", value));
  };

  const onChangeStatus = (val: any) => {
    setOrderStatus(val);
    dispatch(updateCommonValues("selectedStatus", val?._id));
  };

  const searchValidation = () => {
    return startDate === "" || endDate === "" || orderStatus === "";
  };

  return (
    <div className="contentBox">
      <PageTitle name={"ORDERS LIST"} />
      <div className="row my-1">
        <div className="col-md-12 formDiv formWidth">
          <form onSubmit={handleSubmit}>
            <div className="d-flex">
              <div className="col-md-3 d-flex pb-3 pe-2 ">
                <div className="col-md-3 mt-2">
                  <LabelField lableName="Start Date" />
                </div>
                <div className="col-md-9">
                  <DatePicker
                    value={startDate}
                    setInputText={(value: string) => onChangeStartDate(value)}
                  />
                </div>
              </div>
              <div className="col-md-3 d-flex pb-3 pe-2">
                <div className="col-md-3 mt-2">
                  <LabelField lableName="End Date" />
                </div>
                <div className="col-md-9">
                  <DatePicker
                    value={endDate}
                    setInputText={(value: string) => onChangeEndDate(value)}
                  />
                </div>
              </div>
              <div className="col-md-3 d-flex mb-3">
                <div className="col-md-3 mt-2">
                  <LabelField lableName="Status" />
                </div>
                <div className="col-md-8 ms-2 pe-2">
                  <CustomDropdown
                    lableName="Select Status"
                    setInputText={(value: any) => onChangeStatus(value)}
                    value={orderStatus}
                    options={orderStatusList}
                    defaultValue={
                      orderStatusList?.length > 0 ? orderStatus?._id : ""
                    }
                  />
                </div>
              </div>
              <div className="justify-content-end mb-2">
                <SubmitButton name={"Search"} validation={searchValidation()} />
              </div>
            </div>
          </form>
          {renderListMode()}
        </div>
      </div>
    </div>
  );
}
