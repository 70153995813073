import { useEffect, useState, useRef } from 'react';
import ImageIcon from "@mui/icons-material/Image";
import DescriptionIcon from "@mui/icons-material/Description";
import MessageIcon from '@mui/icons-material/Message';
import {
    getChatBotLeadDetails,
    updateChatBotStatus,
    addcounsellorChatBotMessages,
    getcounsellorChatBotMessages,
    updatecounselloChatBotReadmessages,
    getExamList,
    getExamStreamList,
} from "../../Redux/Actions/counsellorPanelAction";
import { useDispatch, useSelector } from 'react-redux';
import moment from "moment";
import messageToneUrl from "../../Assets/audio/notification-beep.mp3"
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { updateCommonValues } from "../../Redux/Actions/CommonAction";
import { useNavigate } from 'react-router-dom';
import { useAppDispatch } from "../../hooks";

const CourseSupport = (props: any) => {
    const dispatch = useDispatch();
    const Appdispatch = useAppDispatch();
    const navigate = useNavigate();
    const newMessagesRef = useRef<any>(null);
    const messageTune = useRef<any>(new Audio(messageToneUrl));
    const { loggedInUserId } =
        useSelector((state: any) => ({
            loggedInUserId: state.Auth.loggedInUserId,
            loggedInUserManagerId: state.Auth.loggedInUserManagerId,
            loggedInUserRole: state.Auth.loggedInUserRole,
        }));
    const [selectedStatus, setSelectedStatus] = useState<number>(0);
    const [newIssue, setNewIssue] = useState<any>([]);
    const [inProgress, setInProgress] = useState<any>([]);
    const [closedIssue, setClosedIssues] = useState<any>([]);
    const [userMessage, setUserMessage] = useState<any>([]);
    const [userTicket, setUserTicket] = useState<any>();
    const [counsellorMessage, setCounsellorMessage] = useState<any>();
    const [activeIndex, setActiveIndex] = useState<any>(null);
    const [showPopUp, setShowPopUp] = useState<boolean>(false);
    const [exam, setExam] = useState<any>([]);
    const [examName, setExamName] = useState<any>();
    const [examStreamName, setExamStreamName] = useState<any>();
    useEffect(() => {
        dispatch(getExamList((res: any) => {
            if (res?.data) {
                setExam(res?.data);
            }
        }
        ));
    }, [])
    useEffect(() => {
        const interval = setInterval(() => {
            dispatch(
                getChatBotLeadDetails(
                    loggedInUserId,
                    (res: any) => {
                        if (res) {
                            const newIssues: any = [];
                            res?.data?.forEach((statusWise: any) => {
                                if (statusWise?.status === 0) {
                                    newIssues.push(statusWise);
                                }
                            });
                            setNewIssue(newIssues);
                            const inProgressIssues: any = [];
                            res?.data?.forEach((statusWise: any) => {
                                if (statusWise?.status === 1) {
                                    inProgressIssues.push(statusWise);
                                }
                            });
                            setInProgress(inProgressIssues);
                            const closedIssues: any = [];
                            res?.data?.forEach((statusWise: any) => {
                                if (statusWise?.status === 2) {
                                    closedIssues.push(statusWise);
                                }
                            });
                            setClosedIssues(closedIssues);
                        }
                    },
                    () => { }
                )
            );
        }, 3000);
        return () => clearInterval(interval);
    }, [userMessage, loggedInUserId]);
    useEffect(() => {
        if (props?.onSendIssueCount) {
            props?.onSendIssueCount(newIssue?.length || 0);
        }
    }, [newIssue]);

    useEffect(() => {
        const interval = setInterval(() => {
            if (userTicket) {
                const ticket = {
                    ticketNumber: userTicket,
                };
                dispatch(getcounsellorChatBotMessages(ticket, (res: any) => {
                    const unReadMsg = res?.unreadMessages?.map((item: any) => item?._id)
                    if (res?.data) {
                        setUserMessage(res?.data);
                        const updateData = {
                            ticketNumber: userTicket,
                            messageIds: unReadMsg,
                        };
                        dispatch(updatecounselloChatBotReadmessages(updateData, () => { }, () => { }));
                    }
                },
                    () => { }
                ));
            }
        }, 5000);
        return () => clearInterval(interval);
    }, [userMessage, loggedInUserId]);

    const showData = (val: number) => {
        setSelectedStatus(val);
    };

    const updateChatStatus = (data: any) => {
        setUserTicket(data?.ticketNumber);
        const payload = {
            status: data?.status,
            updateStatus: 1,
            ticketNumber: data?.ticketNumber,
            type: "counsellor"
        };
        dispatch(updateChatBotStatus(loggedInUserId, payload, (res: any) => {
            if (res?.acknowledged === true) {
                const ticket = {
                    ticketNumber: data?.ticketNumber,
                };
                dispatch(getcounsellorChatBotMessages(ticket, (res: any) => {
                    if (res?.data) {
                        setUserMessage(res?.data);
                        showData(1);
                        dispatch(updatecounselloChatBotReadmessages(ticket, () => { }, () => { }));
                    }
                },
                    () => { }
                ));
            }
        },
            () => { }));
    };
    const getInProgressMessages = (data: any) => {
        setActiveIndex(0);
        setUserTicket(data?.ticketNumber);
        if (data) {
            const ticket = {
                ticketNumber: data?.ticketNumber,
            };
            dispatch(getcounsellorChatBotMessages(ticket, (res: any) => {
                if (res?.data) {
                    setUserMessage(res?.data);
                    showData(1);
                    const unReadMsg = res?.unreadMessages?.map((item: any) => item?._id)
                    const updateData = {
                        ticketNumber: userMessage?.ticketNumber,
                        messageIds: unReadMsg,
                    };
                    dispatch(updatecounselloChatBotReadmessages(updateData, (unread: any) => { }, () => { }));
                }
            },
                () => { }
            ));
            dispatch(getExamList((res: any) => {
                if (res?.data) {
                    const exam = res.data.find((item: any) => item?._id === data?.examTypeId);
                    const streamName = exam.baseCourseId.find((examStream: any) => examStream?._id === data?.baseCourseId);
                    setExamName(exam?.name);
                    setExamStreamName(streamName?.name);
                }
            }
            ));
        }
    };

    const sendMessage = () => {
        if (!counsellorMessage.trim()) return;
        const payload = {
            counsellorId: loggedInUserId,
            senderType: "counsellor",
            message: counsellorMessage,
            messageRead: false,
            ticketNumber: userTicket,
        };
        dispatch(addcounsellorChatBotMessages(payload, (res: any) => {
            if (res?.acknowledged === true) {
                const ticket = {
                    ticketNumber: userTicket,
                };
                dispatch(getcounsellorChatBotMessages(ticket, (res: any) => {
                    setUserMessage(res?.data);
                    showData(1);
                    setCounsellorMessage('');
                },
                    () => { }
                ));
            }
        },
            () => { }
        ));
    };
    const closeTicket = () => {
        console.log("userMessage?.ticketNumber", userMessage?.ticketNumber);        
        const payload = {
            status: userMessage?.status,
            updateStatus: 2,
            ticketNumber: userMessage?.ticketNumber,
            type: "counsellor"
        };
        dispatch(updateChatBotStatus(loggedInUserId, payload, (res: any) => {
            if (res?.acknowledged === true) {
                setUserMessage('');
                showData(2);
                setShowPopUp(false);
                setActiveIndex(0);
            }
        }, () => { }));
    };
    const getClosedMessages = (data: any) => {
        setUserTicket(data?.ticketNumber);
        if (data) {
            const ticket = {
                ticketNumber: data?.ticketNumber,
            };
            dispatch(getcounsellorChatBotMessages(ticket, (res: any) => {
                if (res?.data) {
                    setUserMessage(res?.data);
                }
            },
                () => { }
            ));
            dispatch(getExamList((res: any) => {
                if (res?.data) {
                    const exam = res.data.find((item: any) => item?._id === data?.examTypeId);
                    const streamName = exam.baseCourseId.find((examStream: any) => examStream?._id === data?.baseCourseId);
                    setExamName(exam?.name);
                    setExamStreamName(streamName?.name);
                }
            }
            ));
        }
    };

    const handleKeyDown = (event: any) => {
        if (event.key === 'Enter') {
            sendMessage();
        }
    };
    const previousMessageCount = useRef(0);

    useEffect(() => {
        if (userMessage?.messages?.length > previousMessageCount.current) {
            const latestMessage = userMessage.messages[userMessage.messages.length - 1];
            if (latestMessage?.senderType === "user") {
                messageTune.current.play();
            }
        }
        previousMessageCount.current = userMessage?.messages?.length || 0;
    }, [userMessage]);

    useEffect(() => {
        if (newMessagesRef.current && userMessage) {
            newMessagesRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    }, [userMessage]);

    const gotoLeadDetails = (data: any) => {
        Appdispatch(updateCommonValues("selectedLeadId", data));
        const url = '/leadDetail';
        navigate(url, {
            state: {
                pageName: "coursesupport",
            },
        });
        // window.open(url, "_blank");
    };


    return (<>
        <div className="contentBox">
            <div className="row mx-3 ">
                <div className="col-sm-9 p-0 mb-2">
                    <div className="p-lg-3 pb-lg-0">
                        <div className="shadow-none rounded">
                            {/* <PageTitle name="Chat" /> */}
                        </div>
                        <div className="userInfo d-flex msgheader bg-blue border-0 Title shadow-none">
                            <h5 className="text-white">{selectedStatus != 0 ? (
                                <>
                                    {userMessage?.firstName} {userMessage?.lastName}
                                    <span className="border-0 ms-5">
                                        <small>{examName} {examStreamName}</small>
                                    </span>
                                </>
                            ) : (
                                ""
                            )}
                            </h5>
                            <div className='ms-auto'>
                                <button type="button" className="add_Question-btn btn btn btn-success btn-md me-2" onClick={() => setShowPopUp(true)}>Close Issue</button>
                                <button type="button" className="btn btn-primary submitbtn rounded-pill me-2" onClick={() => gotoLeadDetails(userMessage?.studentLeadId)}>User Details</button>
                            </div>
                        </div>
                        <div className="messegeBox p-2 border" id="messegeBox">
                            {selectedStatus != 0 ? (
                                userMessage?.messages?.map((item: any) => {
                                    const {
                                        message,
                                        messageRead,
                                        senderType,
                                        timestamp,
                                    } = item;
                                    if (senderType === "counsellor") {
                                        return (
                                            <div className="requestMsg">
                                                <div className="text-dark font-16">
                                                    {message}
                                                </div>
                                                <div className="font-11-req-msg">
                                                    {" "}
                                                    {moment(timestamp).format(
                                                        "DD-MMM-YYYY, hh:mm A"
                                                    )}{" "}
                                                </div>
                                            </div>
                                        );
                                    } else if (senderType === "user") {
                                        return (
                                            <div className="responseMsg">
                                                <div className="text-dark font-16">
                                                    {message}
                                                </div>
                                                <div className="font-11">
                                                    {" "}
                                                    {moment(timestamp).format(
                                                        "DD-MMM-YYYY, hh:mm A"
                                                    )}{" "}
                                                </div>
                                            </div>
                                        )
                                    }
                                })
                            ) : ("")}
                            {/* <div ref={newMessagesRef} /> */}
                        </div>
                        <div className="chatBottom border px-1">
                            {selectedStatus != 2 && (
                                <div className="removelabel w-100">
                                    <textarea
                                        className="form-control border border-0"
                                        placeholder={"Enter Messege Here"}
                                        onChange={(e) => { setCounsellorMessage(e.target?.value) }}
                                        value={counsellorMessage}
                                        onKeyDown={handleKeyDown}
                                    />
                                </div>
                            )}
                            {/* <span className="ms-2 mt-2 d-flex align-items-center">
                                <span className="p-3 position-absolute rounded d-flex justify-content-between">
                                    <span className="text-center">
                                        <p className="mediaicon">
                                            <span className="mx-2 cursor">
                                                <label htmlFor="upload-button" className="cursor">
                                                    <ImageIcon />
                                                </label>
                                                <input
                                                    type="file"
                                                    id="upload-button"
                                                    style={{ display: "none" }}
                                                />
                                            </span>
                                        </p>
                                    </span>
                                    <span className="text-center ms-3">
                                        <p className="mediaicon">
                                            <span className="mx-2 cursor">
                                                <label htmlFor="upload-button1" className="cursor">
                                                    <DescriptionIcon />
                                                </label>
                                                <input
                                                    type="file"
                                                    id="upload-button1"
                                                    style={{ display: "none" }}
                                                    // onChange={(e) => handleChange(e, 2)}
                                                    accept=".pdf"
                                                />
                                            </span>
                                        </p>
                                    </span>
                                </span>
                                <div className="text-end me-2">
                                    <button
                                        type="submit"
                                        className="btn btn-primary submitbtn rounded-pill"
                                        onClick={sendMessage}
                                    // disabled={validations()}
                                    >
                                        Send
                                    </button>
                                </div>
                            </span> */}
                        </div>
                    </div>
                </div>
                <div className="col-sm-3 p-0 border border-1 rounded mb-2 mt-3">
                    <div className="">
                        <div className="d-flex justify-content-center align-item-center py-1">
                            <h3>Chats</h3>
                        </div>
                        <div className="px-2">
                            <ul className="nav d-flex mb-3 w-100 py-2">
                                <li className="flex-fill" onClick={() => showData(0)}>
                                    <button className={`border-0 w-100 rounded-start p-2 d-flex user-list-button ${selectedStatus === 0 ? "nav-btn-bgcolor" : ""}`}>
                                        <span>New Issue</span>
                                        {newIssue?.length > 0 && (
                                            <p className={`${selectedStatus === 0 ? 'active' : ''} total-count`}> {newIssue?.length}</p>
                                        )}
                                    </button>
                                </li>
                                <li className="flex-fill" onClick={() => showData(1)}>
                                    <button className={`border-0 w-100 p-2 d-flex user-list-button ${selectedStatus === 1 ? "nav-btn-bgcolor" : ""}`}>
                                        <span>In progress</span>
                                        {inProgress?.length > 0 && (
                                            <p className={`${selectedStatus === 1 ? 'active' : ''} total-count`}> {inProgress?.length}</p>
                                        )}
                                    </button>
                                </li>
                                <li className="flex-fill" onClick={() => showData(2)}>
                                    <button className={`border-0 w-100 rounded-end p-2 d-flex user-list-button ${selectedStatus === 2 ? "nav-btn-bgcolor" : ""}`}>
                                        <span>Resolved</span>
                                        {closedIssue?.length > 0 && (
                                            <p className={`${selectedStatus === 2 ? 'active' : ''} total-count`}> {closedIssue?.length}</p>
                                        )}
                                    </button>
                                </li>
                            </ul>
                        </div>
                        <div className="p-2 user-list-box">
                            {selectedStatus === 0 && (
                                newIssue?.length > 0 ? (
                                    newIssue?.slice()?.reverse()?.map((issueData: any, index: any) => {
                                        const examName = exam.find((item: any) => item?._id === issueData?.examTypeId);
                                        const baseCourseName = examName?.baseCourseId?.find((item: any) => item?._id === issueData?.baseCourseId);
                                        return (
                                            <div className={`user-chat-list ${activeIndex === index ? 'active' : ''}`} key={index} onClick={() => { updateChatStatus(issueData); setActiveIndex(index); }}>
                                                <div className="user-name d-flex px-2">
                                                    <div>
                                                        <p className='mb-0'>{issueData?.firstName} {issueData?.lastName}</p>
                                                        <p className='mb-0'><small className='text-dark d-block my-1 fw-bold'>{examName?.name} {baseCourseName?.name}</small></p>
                                                        <p className='mb-0'><small className='text-dark d-block my-1'>{issueData?.message}</small></p>
                                                        <p className='mb-0'><small> {issueData?.ticketNumber} | {moment(issueData?.createdAt).format("DD-MMM-YYYY, hh:mm A")}</small></p>
                                                        <MessageIcon fontSize="small" /><small className='text-dark fw-normal'> {moment(issueData?.updatedAt).format("DD-MMM-YYYY, hh:mm A")}</small>
                                                    </div>
                                                </div>
                                            </div>
                                        );
                                    })
                                ) : (
                                    <div className="">No Issue Found</div>
                                )
                            )}
                            {selectedStatus === 1 && (
                                inProgress?.length > 0 ? (
                                    inProgress?.slice()?.reverse()?.map((progressData: any, index: any) => {
                                        const examName = exam.find((item: any) => item?._id === progressData?.examTypeId);
                                        const baseCourseName = examName?.baseCourseId?.find((item: any) => item?._id === progressData?.baseCourseId);
                                        return (
                                            <div className={`user-chat-list ${activeIndex === index ? 'active' : ''}`} key={index} onClick={() => { getInProgressMessages(progressData); setActiveIndex(index); }}>
                                                <div className="user-name d-flex px-2 justify-content-between align-item-center">
                                                    <div>
                                                        <p className='mb-0'>{progressData.firstName} {progressData.lastName}</p>
                                                        <p className='mb-0'><small className='text-dark d-block my-1'>{examName?.name} {baseCourseName?.name}</small></p>
                                                        <p className='mb-0'><small className='text-dark fw-normal'> {progressData.ticketNumber} | {moment(progressData.createdAt).format("DD-MMM-YYYY, hh:mm A")}</small></p>
                                                        <MessageIcon fontSize="small" /><small className='text-dark fw-normal'> {moment(progressData.updatedAt).format("DD-MMM-YYYY, hh:mm A")}</small>
                                                    </div>
                                                    {progressData?.unreadCount > 0 && (
                                                        <p className="msg-count">{progressData?.unreadCount}</p>
                                                    )}
                                                </div>
                                            </div>
                                        )
                                    })
                                ) : (
                                    <div className="">No Issue Found</div>
                                )
                            )}
                            {selectedStatus === 2 && (
                                closedIssue?.length > 0 ? (
                                    closedIssue?.slice()?.reverse()?.map((closedData: any, index: any) => {
                                        const examName = exam.find((item: any) => item?._id === closedData?.examTypeId);
                                        const baseCourseName = examName?.baseCourseId?.find((item: any) => item?._id === closedData?.baseCourseId);
                                        return (
                                            <div className={`user-chat-list ${activeIndex === index ? 'active' : ''}`} key={index} onClick={() => { getClosedMessages(closedData); setActiveIndex(index); }}>
                                                <div className="user-name d-flex px-2">
                                                    <div>
                                                        <p className='mb-0'>{closedData.firstName} {closedData.lastName}</p>
                                                        <p className='mb-0'><small className='text-dark d-block my-1 fw-bold'>{examName?.name} {baseCourseName?.name}</small></p>
                                                        <p className='mb-0'> <small> {closedData.ticketNumber} | {moment(closedData.createdAt).format("DD-MMM-YYYY, hh:mm A")}</small></p>
                                                        <MessageIcon fontSize="small" /><small className='text-dark fw-normal'> {moment(closedData.updatedAt).format("DD-MMM-YYYY, hh:mm A")}</small>
                                                    </div>
                                                    {closedData?.status === 2 && (
                                                        <span className='ms-auto text-dark'>Ticket Closed</span>
                                                    )}
                                                </div>
                                            </div>
                                        )
                                    })
                                ) : (
                                    <div className="">No Issue Found</div>
                                )
                            )}

                        </div>
                    </div>
                </div>
            </div>
        </div>
        {showPopUp && (
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className="modelChat"
                open={showPopUp}
                closeAfterTransition
                BackdropComponent={Backdrop}
            >
                <Fade in={showPopUp}>
                    <div className="modeldivChat text-center p-md-4 py-md-4 ">
                        <div className=" d-flex justify-content-between">
                            <div>
                                <h3 className="text-primary fw-bold">Close Ticket</h3>{" "}
                            </div>
                            <div>
                                <HighlightOffIcon
                                    color="primary"
                                    className="cursor"
                                    onClick={() => setShowPopUp(false)}
                                />{" "}
                            </div>
                        </div>
                        <div className='my-5'>
                            <h3 className='mb-3'>Are You Sure!! Do you want Close This Ticket?</h3>
                            <div className='d-flex justify-content-center my-4'>
                                <button type="button" className='btn btn-primary w-25 me-4' onClick={closeTicket} >Yes</button>
                                <button type="button" className='btn btn-secondary w-25' onClick={() => setShowPopUp(false)}>No</button>
                            </div>
                        </div>
                    </div>
                </Fade>
            </Modal>
        )}
    </>);
}

export default CourseSupport;