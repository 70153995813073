import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import PageTitle from "../../Component/pageTitle";
import DatePicker from "../../Component/datePicker";
import LabelField from "../../Component/labelField";
import SubmitButton from "../../Component/submitButton";
import moment from "moment";
import { useSelector } from "react-redux";
import {
  getEmployeetByManagerId,
  getTeamLeadAssignedOverview,
} from "../../Redux/Actions/managerPanelAction";
import CustomDropdown from "../../Component/customDropdown";
import { updateCommonValues } from "../../Redux/Actions/CommonAction";
import { useAppDispatch } from "../../hooks";

export default function CounsellorLeadOverview() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [startDate, SetStartDate] = useState<any>(
    moment.utc(new Date()).format("YYYY-MM-DD")
  );
  const [endDate, SetEndDate] = useState<any>(
    moment.utc(new Date()).format("YYYY-MM-DD")
  );
  const [manager, setManager] = useState<any>("");
  const [managerList, setManagerList] = useState([]);
  const [leadsDataList, setLeadsDataList] = useState<any>([]);

  const { loggedInUserRole, loggedInUserId } = useSelector((state: any) => ({
    loggedInUserRole: state.Auth.loggedInUserRole,
    loggedInUserId: state.Auth.loggedInUserId,
  }));

  useEffect(() => {
    const data = {
      startDate,
      endDate,
    };
    if (loggedInUserRole === 1) {
      dispatch(
        getTeamLeadAssignedOverview(
          loggedInUserId,
          data,
          (res: any) => {
            setLeadsDataList(res);
          },
          () => {}
        )
      );
    }
  }, []);

  const onClickLead = (e: any, data: any, assignedDate: any) => {
    console.log("dataaaaaaaa", data);
    dispatch(updateCommonValues("selectedClickedDate", assignedDate));
    navigate("../counsellorLeadOverview", { state: { counsellorData: data } });
  };
  const handleSubmit = (e: any) => {
    e.preventDefault();

    const data = {
      startDate,
      endDate,
    };
    if (loggedInUserRole === 1) {
      dispatch(
        getTeamLeadAssignedOverview(
          loggedInUserId,
          data,
          (res: any) => {
            setLeadsDataList(res);
          },
          () => {}
        )
      );
    } else if (loggedInUserRole === 2) {
      dispatch(
        getTeamLeadAssignedOverview(
          loggedInUserId,
          data,
          (res: any) => {
            setLeadsDataList(res);
          },
          () => {}
        )
      );
    }
  };

  const renderSearchMode = () => {
    return (
      <form className="row d-flex  " onSubmit={handleSubmit}>
        <div className="col-md-3 d-flex mb-3 ">
          <div className="col-md-3 mt-2">
            <LabelField lableName="Start Date" />
          </div>
          <div className="col-md-9">
            <DatePicker
              value={startDate}
              setInputText={(value: string) => SetStartDate(value)}
            />
          </div>
        </div>
        <div className="col-md-3 d-flex mb-3">
          <div className="col-md-3 mt-2">
            <LabelField lableName="End Date" />
          </div>
          <div className="col-md-9">
            <DatePicker
              value={endDate}
              setInputText={(value: string) => SetEndDate(value)}
            />
          </div>
        </div>
        <div className="col-md-3 d-flex justify-content-between mb-3">
          <div className="col-md-6 me-2">
            <SubmitButton name={"Search"} />
          </div>
        </div>
      </form>
    );
  };

  const renderListMode = () => {
    return (
      <>
        <table className="w-100 ">
          <tr className="">
            <th>S.N</th>
            <th>Date</th>
            <th>Source</th>
          </tr>
          <tbody className=" ">
            {leadsDataList?.map((item: any, index: number) => {
              return (
                <tr>
                  <td>{index + 1}</td>
                  <td>{moment(item?.assignedDate).format("DD-MM-YYYY")}</td>
                  <td>
                    <table className="w-100">
                      <tr>
                        <th>Total</th>
                        <th>{item?.totalLeadCount}</th>
                      </tr>
                      {item?.data?.map((subItem: any) => {
                        return (
                          <tr>
                            <td>
                              {subItem?.firstName} {subItem?.lastName}
                            </td>
                            <td
                              onClick={(e) =>
                                onClickLead(e, subItem, item?.assignedDate)
                              }
                            >
                              <span className="leaddetailsOverView cursor">
                                {subItem?.count}
                              </span>
                            </td>
                          </tr>
                        );
                      })}
                    </table>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </>
    );
  };

  return (
    <div className="contentBox">
      <PageTitle name={"Total Assigned Leads"} />
      <div className="col-md-12 row">
        <div className=" formDiv formWidth p-5">
          {renderSearchMode()}
          {renderListMode()}
        </div>
      </div>
    </div>
  );
}
