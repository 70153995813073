import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import InputField from "../../Component/inputField";
import LabelField from "../../Component/labelField";
import CallIcon from "@mui/icons-material/Call";
import CustomDropdown from "../../Component/customDropdown";
import LeadDetailHeader from "../../Component/LeadDetailheader";
import MessagePopUp from "../../Component/messagePopUp";
import EmailPopUp from "../../Component/emailPopUp";
import {
  getCountryList,
  getStatesList,
  updateLeadDetails,
} from "../../Redux/Actions/counsellorPanelAction";
import {
  candidateCallHistory,
  candidateSearch,
  getHrCandidateDetailsById,
  getHrEmployeeCompanyList,
  getHrEmployeeDepartmentList,
  getHrEmployeeDesignationList,
  getHrStatusList,
  getHrSubDepartment,
  getHrSubStatusList,
  makeCalltoCandidate,
  updateHrCandidateDetails,
  uploadCVPDF,
  uploadPDF,
} from "../../Redux/Actions/hrPanelAction";
import DateTime from "../../Component/dateTime";
import SubmitButton from "../../Component/submitButton";
import { useSelector } from "react-redux";
import moment from "moment";
import {
  showErrorSnackbar,
  showSuccessSnackbar,
} from "../../Redux/Actions/snackbarAction";
import {
  containsNumber,
  length_Ten,
  pdf_size_1MB,
  validateIsFilePDFType,
  validateValueWithoutSpace,
} from "../../DATA/validators";
import {
  validateIsFilePDFTypeErrMsg,
  validateIsNumberOnlyErrMsg,
} from "../../DATA/errorMsg";
import { HrGenderList, HrQualificationList } from "../../DATA/dataConstant";
import { updateCommonValues } from "../../Redux/Actions/CommonAction";
import { useAppDispatch } from "../../hooks";
import ImagePickerAndViewer from "../../Component/imagePickerAndViewer";
import Button from "../../Component/button";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import FilePresentIcon from "@mui/icons-material/FilePresent";
import VisibilityIcon from "@mui/icons-material/Visibility";

const initialQualification: {
  qualification: string;
  educationYear: string;
}[] = [
  {
    qualification: "",
    educationYear: "",
  },
];

export default function LeadDetail() {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const { loggedInUserId, selectedLeadId } = useSelector((state: any) => ({
    loggedInUserId: state.Auth.loggedInUserId,
    selectedLeadId: state.Auth.selectedLeadId,
  }));

  const pageName = location.state?.pageName;
  const candidateMobileNo = location.state?.candidateMobileNo;
  const success = location.state?.success;
  const leadStatus = location.state?.status;
  const leadState = location.state?.state;
  const leadStartDate = location.state?.startDate;
  const leadEndDate = location.state?.endDate;
  const leadType = location.state?.type;
  const leadMobileNo = location.state?.leadMobileNo;

  const [isSave, setIsSave] = useState(false);

  const [firstName, setFirstName] = useState<any>("");
  const [lastName, setLastName] = useState<any>("");
  const [gender, setGender] = useState<any>("");
  const [contact1, setContact1] = useState<any>("");
  const [contact2, setContact2] = useState<any>("");
  const [email, setEmail] = useState<any>("");
  const [state, setState] = useState<any>("");
  const [city, setCity] = useState<any>("");
  const [address, setAddress] = useState<any>("");

  const [type, setType] = useState(1);
  const [educationYear, setEducationYear] = useState<any>("");
  const [qualification, setQualification] = useState<any>("");
  const [currentCompany, setCurrentCompany] = useState<any>("");
  const [currentCTC, setCurrentCTC] = useState<any>("");
  const [experience, setExperience] = useState<any>("");
  const [selectedCV, setSelectedCV] = useState<any>("");
  const [pdfCV, setPdfCV] = useState<any>("");
  const [selectedAadharCard, setSelectedAadharCard] = useState<any>("");
  const [pdfAadharCard, setPdfAadharCard] = useState<any>("");
  const [selectedPanCard, setSelectedPanCard] = useState<any>("");
  const [pdfPanCard, setPdfPanCard] = useState<any>("");
  const [company, setCompany] = useState<any>("");
  const [department, setDepartment] = useState<any>("");
  const [subDepartment, setSubDepartment] = useState<any>("");
  const [designation, setDesignation] = useState<any>("");
  const [status, setStatus] = useState<any>("");
  const [subStatus, setSubStatus] = useState<any>("");
  const [remark, setRemark] = useState<any>("");
  const [callBackDate, setCallBackDate] = useState<any>("");
  const [firstInterviewDate, setFirstInterviewDate] = useState<any>("");
  const [secondInterviewDate, setSecondInterviewDate] = useState<any>("");
  const [hrRoundDate, setHrRoundDate] = useState<any>("");
  const [joiningDate, setJoiningDate] = useState<any>("");

  const [openPopup, setOpenPopup] = useState(false);
  const [openEmailPopup, setOpenEmailPopup] = useState(false);

  const [stateList, setStateList] = useState([]);
  const [countryList, setCountryList] = useState([]);
  const [qualificationArr, setQualificationArr] =
    useState<any>(initialQualification);
  const [companyList, setCompanyList] = useState([]);
  const [departmentList, setDepartmentList] = useState([]);
  const [subDepartmentList, setSubDepartmentList] = useState([]);
  const [designationList, setDesignationList] = useState([]);
  const [statusList, setStatusList] = useState([]);
  const [subStatusList, setSubStatusList] = useState([]);

  const [history, setHistory] = useState<any>(1);
  const [resetStatus, setResetStatus] = useState(false);
  const [resetSubStatus, setResetSubStatus] = useState(false);

  const [callHistoryData, setCallHistoryData] = useState([]);

  useEffect(() => {
    dispatch(
      getHrCandidateDetailsById(
        selectedLeadId,
        (res: any) => {
          console?.log("hrLeadDetail", res);
          setFirstName(res?.data?.firstName?.toUpperCase());
          setLastName(res?.data?.lastName?.toUpperCase());
          let tempGender: any = HrGenderList?.find?.(
            (item: any) => item?.name === res?.data?.gender
          );
          setGender(tempGender);
          setContact1(res?.data?.mobileNo);
          setContact2(res?.data?.altMobileNo);
          if (res?.data?.altMobileNo?.length === 10) {
            setIsSave(true);
          }
          setEmail(res?.data?.email);
          setState(res?.data?.stateId);
          dispatch(
            getStatesList((res: any) => {
              if (res?.data?.length > 0) {
                setStateList(res?.data);
              } else {
                setStateList([]);
              }
            })
          );
          setCity(res?.data?.cityId);
          setAddress(res?.data?.address);
          // setQualificationArr(res?.data?.qualification);
          if (res?.data?.qualification?.length > 0) {
            let qualificationNewArr = res?.data?.qualification?.map(
              (item: any) => {
                let tempQual: any = HrQualificationList?.find?.(
                  (subItem: any) => subItem?.name === item?.qualification
                );
                console?.log("tempQual", tempQual);
                return {
                  qualification: tempQual,
                  educationYear: item?.educationYear,
                  _id: item?._id,
                };
              }
            );
            setQualificationArr(qualificationNewArr);
          }
          setType(res?.data?.currentCompany ? 2 : 1);
          setCurrentCompany(res?.data?.currentCompany);
          setCurrentCTC(res?.data?.currentCTC);
          setExperience(res?.data?.experience);
          setPdfCV(res?.data?.resume);
          setPdfAadharCard(res?.data?.aadharCard);
          setPdfPanCard(res?.data?.panCard);
          // setQualification(res?.data?.qualification);
          dispatch(
            getHrEmployeeCompanyList(
              (companyRes: any) => {
                setCompanyList(companyRes);
                let tempCompanyId: any = companyRes?.find?.(
                  (item: any) => item?._id === res?.data?.companyId
                );
                setCompany(tempCompanyId);
              },
              () => {}
            )
          );
          dispatch(
            getHrEmployeeDepartmentList(
              (deptRes: any) => {
                setDepartmentList(deptRes);
                let tempDeptId: any = deptRes?.find?.(
                  (item: any) => item?._id === res?.data?.departmentId
                );
                setDepartment(tempDeptId);
                dispatch(
                  getHrSubDepartment(
                    res?.data?.departmentId,
                    (subDeptRes: any) => {
                      setSubDepartmentList(subDeptRes);
                      let tempSubDeptId: any = subDeptRes?.find?.(
                        (item: any) => item?._id === res?.data?.subDepartmentId
                      );
                      setSubDepartment(tempSubDeptId);
                    },
                    () => {}
                  )
                );
              },
              () => {}
            )
          );
          dispatch(
            getHrEmployeeDesignationList(
              (desgRes: any) => {
                setDesignationList(desgRes);
                let tempDesgId: any = desgRes?.find?.(
                  (item: any) => item?._id === res?.data?.designationId
                );
                setDesignation(tempDesgId);
              },
              () => {}
            )
          );
          dispatch(
            getHrStatusList(
              "",
              (statusRes: any) => {
                setStatusList(statusRes);
                let tempStatus: any = statusRes?.find?.(
                  (item: any) => item?._id === res?.data?.status
                );
                setStatus(tempStatus);
              },
              () => {}
            )
          );
          dispatch(
            getHrSubStatusList(
              res?.data?.status,
              (subStatusRes: any) => {
                setSubStatusList(subStatusRes);
                let tempSubStatus: any = subStatusRes?.find?.(
                  (item: any) => item?._id === res?.data?.subStatus
                );
                setSubStatus(tempSubStatus);
              },
              () => {}
            )
          );
          setRemark(res?.data?.comment);
          setCallBackDate(res?.data?.callBackDate);
          setFirstInterviewDate(res?.data?.firstInterviewDate);
          setSecondInterviewDate(res?.data?.secondInterviewDate);
          setHrRoundDate(res?.data?.hrRoundDate);
          setJoiningDate(res?.data?.joiningDate);
          dispatch(
            candidateCallHistory(
              { candidateId: selectedLeadId },
              (res: any) => {
                console.log("resdfdsdf", res);
                setCallHistoryData(res);
              },
              () => {}
            )
          );
        },
        (errorRes: any) => {
          console.log("errorRes", errorRes);
          if (errorRes) {
            dispatch(
              candidateSearch(
                candidateMobileNo,
                (res: any) => {
                  console.log("resss", res);
                  if (res?.length > 0) {
                    dispatch(showErrorSnackbar("Candidate Already Present"));
                  } else {
                    setContact1(candidateMobileNo);
                  }
                },
                () => {}
              )
            );
          }
        }
      )
    );
  }, []);

  const onChangeState = (val: any) => {
    setState(val);
    dispatch(
      getCountryList(
        val._id,
        (res: any) => {
          setCountryList(res?.data);
        },
        () => {}
      )
    );
  };

  const onPressAddQualification = () => {
    setQualificationArr([...qualificationArr, initialQualification[0]]);
  };

  const onChangeQualification = (index: number, value: any, key: string) => {
    let newArrr = JSON.parse(JSON.stringify(qualificationArr));
    if (key === "qualification") {
      newArrr[index].qualification = value;
      setQualificationArr(newArrr);
    } else if (key === "educationYear") {
      let year = new Date().getFullYear();
      if (value <= year) {
        if (value !== "") {
          if (!containsNumber(value)) {
            dispatch(showErrorSnackbar("Please enter number only") as any);
            return;
          } else if (!validateValueWithoutSpace(value)) {
            dispatch(showErrorSnackbar(validateIsNumberOnlyErrMsg) as any);
            return;
          } else {
            newArrr[index].educationYear = value;
          }
        } else {
          newArrr[index].educationYear = value;
        }
        setQualificationArr(newArrr);
      } else {
        dispatch(
          showErrorSnackbar(
            `Please Enter Year in between 1900 to ${year}` as any
          )
        );
      }
    }
  };

  const onPressCloseQualification = (index: number) => {
    const newArrr = [...qualificationArr];
    if (index > -1) {
      newArrr.splice(index, 1);
    }
    setQualificationArr(newArrr);
  };

  const onChangeType = (value: any) => {
    setType(value);
    setCurrentCompany("");
    setCurrentCTC("");
    setExperience("");
  };

  const onChangePDFCV = (e: any) => {
    e.preventDefault();
    if (!validateIsFilePDFType(e)) {
      dispatch(showErrorSnackbar(validateIsFilePDFTypeErrMsg));
      return;
    }
    if (e.target.files[0].size > pdf_size_1MB) {
      dispatch(
        showErrorSnackbar("File size should be less than 10 MB!!!") as any
      );
    } else {
      const reader = new FileReader();
      const file = e.target.files[0];
      reader.onloadend = () => {
        setPdfCV(reader.result);
        setSelectedCV(file);
      };
      reader.readAsDataURL(file);
    }
  };

  const onChangePDFAadharCard = (e: any) => {
    e.preventDefault();
    if (!validateIsFilePDFType(e)) {
      dispatch(showErrorSnackbar(validateIsFilePDFTypeErrMsg));
      return;
    }
    if (e.target.files[0].size > pdf_size_1MB) {
      dispatch(
        showErrorSnackbar("File size should be less than 10 MB!!!") as any
      );
    } else {
      const reader = new FileReader();
      const file = e.target.files[0];
      reader.onloadend = () => {
        // setPdfAadharCard(reader.result);
        setSelectedAadharCard(file);
      };
      reader.readAsDataURL(file);
    }
  };

  const onChangePDFPanCard = (e: any) => {
    e.preventDefault();
    if (!validateIsFilePDFType(e)) {
      dispatch(showErrorSnackbar(validateIsFilePDFTypeErrMsg));
      return;
    }
    if (e.target.files[0].size > pdf_size_1MB) {
      dispatch(
        showErrorSnackbar("File size should be less than 10 MB!!!") as any
      );
    } else {
      const reader = new FileReader();
      const file = e.target.files[0];
      reader.onloadend = () => {
        // setPdfPanCard(reader.result);
        setSelectedPanCard(file);
      };
      reader.readAsDataURL(file);
    }
  };

  const onChangeDepartment = (val: any) => {
    setSubDepartment(val);
    dispatch(
      getHrSubDepartment(
        val?._id,
        (res: any) => {
          setSubDepartmentList(res);
        },
        () => {}
      )
    );
  };

  const onChangeStatus = (val: any) => {
    setSubStatus("");
    setStatus(val);
    setResetStatus(false);
    setResetSubStatus(true);
    setSubStatusList([]);
    dispatch(
      getHrSubStatusList(
        val?._id,
        (res: any) => {
          setSubStatusList(res);
        },
        () => {}
      )
    );
  };

  const onChangeSubStatus = (val: any) => {
    setSubStatus(val);
    setResetSubStatus(false);
  };
  const changeRemark = (val: any) => {
    setRemark(val);
  };
  const handleClose = (value: any) => {
    setOpenEmailPopup(false);
    setOpenPopup(false);
  };
  const handleOpenMessagePopup = () => {
    // setOpenPopup(true);
    const whatsappURL = `whatsapp://send?phone=+91${contact1}`;
    window.location.href = whatsappURL;
  };
  const handleOpenEmailPopup = () => {
    setOpenEmailPopup(true);
  };

  console?.log("qualificationArr", qualificationArr);

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    console?.log("qualificationArr asdfds", qualificationArr);
    let tempQualificationList = qualificationArr?.map((item: any) => {
      return {
        qualification: item?.qualification?.name,
        educationYear: item?.educationYear,
      };
    });
    console?.log("tempQualificationList", tempQualificationList);
    let postData: any = {
      altMobileNo: contact2,
      address: address,
      qualification: tempQualificationList,
      currentCTC: currentCTC,
      currentCompany: currentCompany,
      experience: experience,
      companyId: company?._id,
      companyName: company?.name,
      departmentId: department?._id,
      departmentName: department?.name,
      subDepartmentId: subDepartment?._id,
      subDepartmentName: subDepartment?.name,
      designationId: designation?._id,
      designation: designation?.name,
      status: status?._id,
      subStatus: subStatus?._id,
      comment: remark,
      callBackDate: callBackDate,
      firstInterviewDate: firstInterviewDate,
      secondInterviewDate: secondInterviewDate,
      hrRoundDate: hrRoundDate,
      joiningDate: joiningDate,
    };

    const fileUploadPromises: Promise<any>[] = [];

    if (selectedCV) {
      const sanitizedFirstName = firstName?.toUpperCase().replace(/\s+/g, "_");
      const sanitizedLastName = lastName?.toUpperCase().replace(/\s+/g, "_");
      const sanitizedDesignation = designation?._id.replace(/\s+/g, "_");
      let fileName =
        sanitizedFirstName +
        "_" +
        sanitizedLastName +
        "_" +
        sanitizedDesignation +
        "_";
      fileUploadPromises.push(
        new Promise((resolve, reject) => {
          dispatch(
            uploadCVPDF(
              selectedCV,
              fileName,
              (resPdfCV: any) => {
                if (resPdfCV) {
                  postData["resume"] = resPdfCV?.file;
                  resolve(undefined);
                }
              },
              reject
            )
          );
        })
      );
    }
    if (selectedAadharCard) {
      const sanitizedFirstName = firstName?.toUpperCase().replace(/\s+/g, "_");
      const sanitizedLastName = lastName?.toUpperCase().replace(/\s+/g, "_");
      const sanitizedDesignation = designation?._id.replace(/\s+/g, "_");
      let fileName =
        sanitizedFirstName +
        "_" +
        sanitizedLastName +
        "_AadharCard_" +
        sanitizedDesignation +
        "_";
      fileUploadPromises.push(
        new Promise((resolve, reject) => {
          dispatch(
            uploadCVPDF(
              selectedAadharCard,
              fileName,
              (resPdfAadhar: any) => {
                if (resPdfAadhar) {
                  postData["aadharCard"] = resPdfAadhar?.file;
                  resolve(undefined);
                }
              },
              reject
            )
          );
        })
      );
    }
    if (selectedPanCard) {
      const sanitizedFirstName = firstName?.toUpperCase().replace(/\s+/g, "_");
      const sanitizedLastName = lastName?.toUpperCase().replace(/\s+/g, "_");
      const sanitizedDesignation = designation?._id.replace(/\s+/g, "_");
      let fileName =
        sanitizedFirstName +
        "_" +
        sanitizedLastName +
        "_PanCard_" +
        sanitizedDesignation +
        "_";
      fileUploadPromises.push(
        new Promise((resolve, reject) => {
          dispatch(
            uploadCVPDF(
              selectedPanCard,
              fileName,
              (resPdfPan: any) => {
                if (resPdfPan) {
                  postData["panCard"] = resPdfPan?.file;
                  resolve(undefined);
                }
              },
              reject
            )
          );
        })
      );
    }

    await Promise.all(fileUploadPromises);

    if (
      postData["altMobileNo"] === undefined ||
      postData["altMobileNo"] === "" ||
      postData["altMobileNo"] === null
    ) {
      delete postData["altMobileNo"];
    }
    if (
      postData["address"] === undefined ||
      postData["address"] === "" ||
      postData["address"] === null
    ) {
      delete postData["address"];
    }
    if (type === 1) {
      delete postData["currentCTC"];
      delete postData["currentCompany"];
      delete postData["experience"];
    }
    if (postData["qualification"]?.length > 0) {
      if (
        (postData["qualification"][0]["qualification"] === "" ||
          postData["qualification"][0]["qualification"] === null ||
          postData["qualification"][0]["qualification"] === undefined) &&
        (postData["qualification"][0]["educationYear"] === "" ||
          postData["qualification"][0]["educationYear"] === null ||
          postData["qualification"][0]["educationYear"] === undefined)
      ) {
        delete postData["qualification"];
      }
    }

    if (
      postData["status"] > 1 ||
      postData["callBackDate"] === undefined ||
      postData["callBackDate"] === "" ||
      postData["callBackDate"] === null
    ) {
      delete postData["callBackDate"];
    }

    if (
      postData["status"] < 2 ||
      postData["status"] > 2 ||
      postData["firstInterviewDate"] === undefined ||
      postData["firstInterviewDate"] === "" ||
      postData["firstInterviewDate"] === null
    ) {
      delete postData["firstInterviewDate"];
    }

    if (
      postData["status"] < 3 ||
      postData["status"] > 3 ||
      postData["secondInterviewDate"] === undefined ||
      postData["secondInterviewDate"] === "" ||
      postData["secondInterviewDate"] === null
    ) {
      delete postData["secondInterviewDate"];
    }
    if (
      postData["status"] < 4 ||
      postData["status"] > 4 ||
      postData["hrRoundDate"] === undefined ||
      postData["hrRoundDate"] === "" ||
      postData["hrRoundDate"] === null
    ) {
      delete postData["hrRoundDate"];
    }
    if (
      postData["status"] < 9 ||
      postData["joiningDate"] === undefined ||
      postData["joiningDate"] === "" ||
      postData["joiningDate"] === null
    ) {
      delete postData["joiningDate"];
    }

    console?.log("postData", postData);

    dispatch(
      updateHrCandidateDetails(
        selectedLeadId,
        postData,
        (res: any) => {
          console?.log("reset");
          resetData();
        },
        () => {}
      )
    );
  };

  const resetData = () => {
    setFirstName("");
    setLastName("");
    setContact1("");
    setContact2("");
    setEmail("");
    setState("");
    setCity("");
    setAddress("");
    setEducationYear("");
    setQualification("");
    setCurrentCTC("");
    setCurrentCompany("");
    setExperience("");
    setSelectedCV("");
    setPdfCV("");
    setSelectedAadharCard("");
    setPdfAadharCard("");
    setSelectedPanCard("");
    setPdfPanCard("");
    setDepartment("");
    setDesignation("");
    setStatus("");
    setSubStatus("");
    setRemark("");
    setCallBackDate("");
    setFirstInterviewDate("");
    setSecondInterviewDate("");
    setHrRoundDate("");
    setJoiningDate("");
    // navigate("../hrDashbord");
    console?.log("resetData");
    if (pageName === "resumeShared") {
      navigate("../HrPanel/resumeShared", {
        state: {
          pageName: "hrLeadDetail",
        },
      });
    } else if (pageName === "firstRoundInterview") {
      navigate("../HrPanel/firstRoundInterview", {
        state: {
          pageName: "hrLeadDetail",
        },
      });
    } else if (pageName === "secondRoundInterview") {
      navigate("../HrPanel/secondRoundInterview", {
        state: {
          pageName: "hrLeadDetail",
        },
      });
    } else if (pageName === "hrRoundInterview") {
      navigate("../HrPanel/hrRoundInterview", {
        state: {
          pageName: "hrLeadDetail",
        },
      });
    } else if (pageName === "rejected") {
      navigate("../HrPanel/rejected", {
        state: {
          pageName: "hrLeadDetail",
        },
      });
    } else if (pageName === "joiningToday") {
      navigate("../HrPanel/joiningToday", {
        state: {
          pageName: "hrLeadDetail",
        },
      });
    } else if (pageName === "joiningInWeek") {
      navigate("../HrPanel/joiningInWeek", {
        state: {
          pageName: "hrLeadDetail",
        },
      });
    } else if (pageName === "callingDone") {
      navigate("../HrPanel/callingDone", {
        state: {
          pageName: "hrLeadDetail",
        },
      });
    } else if (pageName === "onBoarding") {
      navigate("../HrPanel/onBoarding", {
        state: {
          pageName: "hrLeadDetail",
        },
      });
    } else if (pageName === "candidateSearchByMobileNumber") {
      navigate("../HrPanel/candidateSearchByMobileNumber", {
        state: {
          pageName: "hrLeadDetail",
        },
      });
    } else if (pageName === "candidateStatusRecords") {
      navigate("../HrPanel/candidateStatusRecords", {
        state: {
          pageName: "hrLeadDetail",
        },
      });
    }
  };

  const onClickCall = () => {
    dispatch(
      makeCalltoCandidate(
        {
          employeeId: loggedInUserId,
          candidateNumber: contact1,
          // agentId: ozonetelAgentId,
        },
        (res: any) => {
          dispatch(updateCommonValues("ozonetelIframe", true));
          dispatch(showSuccessSnackbar(res?.data) as any);
        },
        () => {}
      )
    );
  };

  const onChangeContact2 = (val: string) => {
    setIsSave(false);
    if (val !== "") {
      if (!containsNumber(val)) {
        dispatch(showErrorSnackbar("Please enter number only") as any);
        return;
      } else if (!validateValueWithoutSpace(val)) {
        dispatch(showErrorSnackbar(validateIsNumberOnlyErrMsg) as any);
        return;
      } else {
        setContact2(val);
      }
    } else {
      setContact2(val);
    }
  };

  const onHandleContact2 = () => {
    setIsSave(true);

    let postData = {
      studentLeadId: selectedLeadId,
      altMobileNo: contact2,
    };
    if (
      postData?.altMobileNo === "" ||
      postData?.altMobileNo === undefined ||
      postData?.altMobileNo === null
    ) {
      delete postData?.altMobileNo;
    }

    dispatch(
      updateLeadDetails(
        postData,
        (res: any) => {},
        () => {}
      )
    );
  };

  const onClickCall2 = () => {
    dispatch(
      makeCalltoCandidate(
        {
          employeeId: loggedInUserId,
          candidateNumber: contact1,
          // agentId: ozonetelAgentId,
        },
        (res: any) => {
          dispatch(updateCommonValues("ozonetelIframe", true));
          dispatch(showSuccessSnackbar(res?.data) as any);
        },
        () => {}
      )
    );
  };

  const onClickHistory = (val: any) => {
    setHistory(val);
  };

  const viewPDF = (val: any) => {
    sessionStorage.setItem("pdfLink", val);
    window.open(
      "/pdfViewer",
      "_blank",
      "toolbar=no,scrollbars=yes,resizable=yes"
    );
  };

  console?.log("subStatus", subStatus);

  return (
    <div className="col-md-12">
      <LeadDetailHeader
        handleCall={onClickCall}
        handleWhatsapp={handleOpenMessagePopup}
        page="hrLeadDetail"
        leadStatus={leadStatus}
        leadState={leadState}
        leadType={leadType}
        leadStartDate={leadStartDate}
        leadEndDate={leadEndDate}
        pageName={pageName}
        leadMobileNo={leadMobileNo}
      />

      <div className="row m-0">
        <div className="col-md-12 leaddetails">
          <div className="boxshadow mx-5 my-1">
            <div className="p-1 d-flex justify-content-center  ">
              <form
                onSubmit={handleSubmit}
                className="detailsdiv col-md-9 row d-flex justify-content-center px-2 "
              >
                <div className="col-md-6 col-sm-12 p-2">
                  <div className="tabheader p-2">Personal & Contact </div>
                  <div className="row d-flex justify-content-between pt-2 ">
                    <div className="col-md-4 mt-2">
                      <LabelField lableName="First Name" isRequired={true} />
                    </div>
                    <div className="col-md-7">
                      <InputField
                        placeholder="Enter First Name"
                        value={firstName}
                        onChangeInput={(value: any) =>
                          setFirstName(value?.toUpperCase())
                        }
                      />
                    </div>
                  </div>
                  <div className="row d-flex justify-content-between ">
                    <div className="col-md-4 mt-2">
                      <LabelField lableName="Last Name" isRequired={true} />
                    </div>
                    <div className="col-md-7">
                      <InputField
                        placeholder="Enter Last Name"
                        value={lastName}
                        onChangeInput={(value: any) =>
                          setLastName(value?.toUpperCase())
                        }
                      />
                    </div>
                  </div>
                  <div className="row d-flex justify-content-between mt-1">
                    <div className="col-md-4 mt-2">
                      <LabelField lableName="Gender" isRequired={true} />
                    </div>
                    <div className="col-md-7 ps-3">
                      <CustomDropdown
                        lableName="Select Gender"
                        setInputText={(value: any) => setGender(value)}
                        value={gender}
                        options={HrGenderList}
                        defaultValue={
                          HrGenderList?.length > 0 ? gender?._id : ""
                        }
                      />
                    </div>
                  </div>
                  <div className="row d-flex justify-content-between  ">
                    <div className="col-md-4 mt-2">
                      <LabelField lableName="Contact 1" isRequired={true} />
                    </div>
                    <div className="col-md-7">
                      <InputField
                        placeholder="Enter Contact 1"
                        value={contact1}
                        onChangeInput={(value: any) => setContact1(value)}
                        disabled={true}
                      />
                    </div>
                  </div>
                  <div className="row d-flex justify-content-between  ">
                    <div className="col-md-4 mt-2">
                      <LabelField lableName="Contact 2" />
                    </div>
                    <div className="d-flex justify-content-between col-7 me-0">
                      <InputField
                        placeholder="Enter Contact 2"
                        value={contact2}
                        onChangeInput={(value: any) => onChangeContact2(value)}
                        disabled={isSave ? true : false}
                        minlength={length_Ten}
                        maxlength={length_Ten}
                      />
                      {!isSave ? (
                        <button
                          type="button"
                          className={
                            contact2?.length === 10
                              ? "btn btn-success ms-2 me-2 font12 mb-2 "
                              : "btn btn-secondary me-2 font12 mb-2 "
                          }
                          onClick={onHandleContact2}
                        >
                          {" "}
                          <span>Save</span>
                        </button>
                      ) : null}

                      {contact2?.length === 10 && isSave ? (
                        <button
                          type="button"
                          className={
                            contact2?.length === 10
                              ? "btn btn-success me-2 font12 mb-2 "
                              : "btn btn-secondary me-2 font12 mb-2 "
                          }
                          onClick={onClickCall2}
                        >
                          {" "}
                          <span>
                            <CallIcon className="font12" />
                          </span>
                        </button>
                      ) : null}
                    </div>
                  </div>
                  <div className="row d-flex justify-content-between  ">
                    <div className="col-md-4 mt-2">
                      <LabelField lableName="Email" />
                    </div>
                    <div className="col-md-7">
                      <InputField
                        placeholder="Enter Email"
                        value={email}
                        onChangeInput={(value: any) => setEmail(value)}
                      />
                    </div>
                  </div>
                  <div className="row d-flex justify-content-between mt-1">
                    <div className="col-md-4 mt-2">
                      <LabelField lableName="State" isRequired={true} />
                    </div>
                    <div className="col-md-7 ps-3">
                      <CustomDropdown
                        lableName="Select State"
                        setInputText={(value: any) => onChangeState(value)}
                        value={state}
                        options={stateList}
                        defaultValue={stateList?.length > 0 ? state : ""}
                      />
                    </div>
                  </div>
                  <div className="row d-flex justify-content-between mt-1">
                    <div className="col-md-4 mt-2">
                      <LabelField lableName="City" isRequired={true} />
                    </div>
                    <div className="col-md-7  ps-3">
                      <CustomDropdown
                        lableName="Select City"
                        setInputText={(value: any) => setCity(value)}
                        value={city}
                        options={countryList}
                        defaultValue={countryList?.length > 0 ? city : ""}
                      />
                    </div>
                  </div>
                  <div className="row d-flex justify-content-between mt-1">
                    <div className="col-md-4 mt-2">
                      <LabelField lableName="Address" />
                    </div>
                    <div className="col-md-7  ps-3">
                      <textarea
                        className="form-control"
                        placeholder={"Address"}
                        value={address}
                        onChange={(e) => setAddress(e.target.value)}
                      ></textarea>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 p-2">
                  <div className="tabheader p-2">Employment Details</div>
                  <div className="row d-flex justify-content-between  pt-3">
                    <div className="col-md-4 mt-2">
                      <LabelField lableName="Company" isRequired={true} />
                    </div>
                    <div className="col-md-7">
                      <CustomDropdown
                        lableName="Select Company"
                        setInputText={(value: any) => setCompany(value)}
                        value={company}
                        options={companyList}
                        defaultValue={
                          companyList?.length > 0 ? company?._id : ""
                        }
                      />
                    </div>
                  </div>
                  <div className="row d-flex justify-content-between  pt-3">
                    <div className="col-md-4 mt-2">
                      <LabelField lableName="Department" isRequired={true} />
                    </div>
                    <div className="col-md-7">
                      <CustomDropdown
                        lableName="Select Department"
                        setInputText={(value: any) => onChangeDepartment(value)}
                        value={department}
                        options={departmentList}
                        defaultValue={
                          departmentList?.length > 0 ? department?._id : ""
                        }
                      />
                    </div>
                  </div>
                  <div className="row d-flex justify-content-between  pt-3">
                    <div className="col-md-4 mt-2">
                      <LabelField
                        lableName="Sub Department"
                        isRequired={true}
                      />
                    </div>
                    <div className="col-md-7">
                      <CustomDropdown
                        lableName="Select Sub Department"
                        setInputText={(value: any) => setSubDepartment(value)}
                        value={subDepartment}
                        options={subDepartmentList}
                        defaultValue={
                          subDepartmentList?.length > 0
                            ? subDepartment?._id
                            : ""
                        }
                      />
                    </div>
                  </div>
                  <div className="row d-flex justify-content-between  pt-3">
                    <div className="col-md-4 mt-2">
                      <LabelField lableName="Designation" isRequired={true} />
                    </div>
                    <div className="col-md-7">
                      <CustomDropdown
                        lableName="Select Designation"
                        setInputText={(value: any) => setDesignation(value)}
                        value={designation}
                        options={designationList}
                        defaultValue={
                          designationList?.length > 0 ? designation?._id : ""
                        }
                      />
                    </div>
                  </div>
                </div>

                <div className="col-md-6 p-2">
                  <div className="tabheader p-2">Education & Work</div>
                  <div className="row d-flex justify-content-between mt-1">
                    {qualificationArr?.map((item: any, index: number) => {
                      console?.log("itemss sfds", item);
                      const { qualification, educationYear } = item;
                      return (
                        <div className="position-relative" key={index}>
                          <div className="position-relative">
                            <div className="row d-flex justify-content-between  pt-3">
                              <div className="col-md-4 mt-2">
                                <LabelField lableName="Qualification" />
                              </div>
                              <div className="col-md-7 d-flex">
                                <CustomDropdown
                                  lableName="Select Qualification"
                                  setInputText={(value: any) =>
                                    onChangeQualification(
                                      index,
                                      value,
                                      "qualification"
                                    )
                                  }
                                  value={qualification}
                                  options={HrQualificationList}
                                  defaultValue={
                                    HrQualificationList?.length > 0
                                      ? qualification?._id
                                      : ""
                                  }
                                />
                                <span className="d-flex justify-content-end ms-2 removeButton">
                                  {qualificationArr.length === 1 ? null : (
                                    <HighlightOffIcon
                                      className="cursor"
                                      color="primary"
                                      fontSize="small"
                                      onClick={() =>
                                        onPressCloseQualification(index)
                                      }
                                    />
                                  )}
                                </span>
                              </div>
                            </div>
                            {qualification ? (
                              <div className="row d-flex justify-content-between  ">
                                <div className="col-md-4 mt-2">
                                  <LabelField
                                    lableName="Education Year"
                                    isRequired={true}
                                  />
                                </div>
                                <div className="col-md-7 mx-3 px-4">
                                  <InputField
                                    placeholder="Enter Education Year"
                                    value={educationYear}
                                    onChangeInput={(value: any) =>
                                      onChangeQualification(
                                        index,
                                        value,
                                        "educationYear"
                                      )
                                    }
                                  />
                                </div>
                              </div>
                            ) : null}
                          </div>
                        </div>
                      );
                    })}
                    <div className="d-flex justify-content-end">
                      <Button
                        name={"Add Qualification"}
                        handleClick={onPressAddQualification}
                      />
                    </div>
                  </div>
                  <div className="row d-flex justify-content-between  ">
                    <div className="col-md-4 mt-2">
                      <LabelField
                        lableName="Candidate Type"
                        isRequired={true}
                      />
                    </div>
                    <div className="col-md-7 mt-2">
                      <span className="me-3 mt-2 ms-3">
                        <input
                          type="radio"
                          value={1}
                          checked={type === 1}
                          onChange={() => onChangeType(1)}
                        />{" "}
                        Fresher
                      </span>
                      <span className="me-3 mt-2">
                        <input
                          type="radio"
                          value={2}
                          checked={type === 2}
                          onChange={() => onChangeType(2)}
                        />{" "}
                        Experienced
                      </span>
                    </div>
                  </div>
                  {type === 2 ? (
                    <>
                      <div className="row d-flex justify-content-between ">
                        <div className="col-md-4 mt-2">
                          <LabelField
                            lableName="Current Company"
                            isRequired={true}
                          />
                        </div>
                        <div className="col-md-7">
                          <InputField
                            placeholder="Enter Current Company"
                            value={currentCompany}
                            onChangeInput={(value: any) =>
                              setCurrentCompany(value)
                            }
                          />
                        </div>
                      </div>
                      <div className="row d-flex justify-content-between ">
                        <div className="col-md-4 mt-2">
                          <LabelField
                            lableName="Current CTC"
                            isRequired={true}
                          />
                        </div>
                        <div className="col-md-7">
                          <InputField
                            placeholder="Enter Current CTC in Lakhs"
                            value={currentCTC}
                            onChangeInput={(value: any) => setCurrentCTC(value)}
                          />
                        </div>
                      </div>
                      <div className="row d-flex justify-content-between ">
                        <div className="col-md-4 mt-2">
                          <LabelField lableName="Experience" />
                        </div>
                        <div className="col-md-7">
                          <InputField
                            placeholder="Enter Experience in Months"
                            value={experience}
                            onChangeInput={(value: any) => setExperience(value)}
                          />
                        </div>
                      </div>
                    </>
                  ) : null}
                  <div className="d-flex justify-content-between">
                    <div className="row d-flex justify-content-between ">
                      <div className="mt-2">
                        <LabelField lableName="Upload CV" />
                        <div className="d-flex">
                          <ImagePickerAndViewer
                            lableName={"Upload PDF"}
                            value={pdfCV}
                            doctype={"pdf"}
                            removelabel={true}
                            htmlFor={`pdf-upload`}
                            handleChange={(e: any) => onChangePDFCV(e)}
                          />
                          {pdfCV ? (
                            <div
                              className="d-flex teacherexam cursor justify-content-between align-items-center"
                              onClick={() => viewPDF(pdfCV)}
                            >
                              <span>
                                <FilePresentIcon style={{ color: "green" }} />{" "}
                                View PDF
                              </span>
                            </div>
                          ) : null}
                        </div>
                      </div>
                    </div>
                    <div className="row d-flex justify-content-between ">
                      <div className="mt-2">
                        <LabelField lableName="Upload Aadhar Card" />
                        <div className="d-flex">
                          <ImagePickerAndViewer
                            lableName={"Upload PDF"}
                            value={
                              selectedAadharCard
                                ? selectedAadharCard
                                : pdfAadharCard
                            }
                            doctype={"pdf"}
                            removelabel={true}
                            htmlFor={`pdf-upload-aadhar`}
                            handleChange={(e: any) => onChangePDFAadharCard(e)}
                          />
                          {pdfAadharCard ? (
                            <div
                              className="d-flex teacherexam cursor justify-content-between align-items-center"
                              onClick={() => viewPDF(pdfAadharCard)}
                            >
                              <span>
                                <FilePresentIcon style={{ color: "green" }} />{" "}
                                View PDF
                              </span>
                            </div>
                          ) : null}
                        </div>
                      </div>
                    </div>
                    <div className="row d-flex justify-content-between ">
                      <div className="mt-2">
                        <LabelField lableName="Upload Pan Card" />
                        <div className="d-flex">
                          <ImagePickerAndViewer
                            lableName={"Upload PDF"}
                            value={
                              selectedPanCard ? selectedPanCard : pdfPanCard
                            }
                            doctype={"pdf"}
                            removelabel={true}
                            htmlFor={`pdf-upload-pan`}
                            handleChange={(e: any) => onChangePDFPanCard(e)}
                          />
                          {pdfPanCard ? (
                            <div
                              className="d-flex teacherexam cursor justify-content-between align-items-center"
                              onClick={() => viewPDF(pdfPanCard)}
                            >
                              <span>
                                <FilePresentIcon style={{ color: "green" }} />{" "}
                                View PDF
                              </span>
                            </div>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 p-2">
                  <div className="tabheader p-2">Action & Disposition</div>
                  <div className="row d-flex justify-content-between pt-2 ">
                    <div className="col-md-4 mt-2">
                      <LabelField lableName="Status" isRequired={true} />
                    </div>

                    <div className="col-md-7">
                      <CustomDropdown
                        lableName="Select Status"
                        setInputText={(value: any) => onChangeStatus(value)}
                        value={status}
                        options={statusList}
                        defaultValue={statusList?.length > 0 ? status?._id : ""}
                        reset={resetStatus}
                      />
                    </div>
                  </div>
                  <div className="row d-flex justify-content-between mt-1">
                    <div className="col-md-4 mt-2">
                      <LabelField
                        lableName="Status Details"
                        isRequired={true}
                      />
                    </div>
                    <div className="col-md-7">
                      <CustomDropdown
                        lableName="Select Status Details"
                        setInputText={(value: any) => onChangeSubStatus(value)}
                        value={subStatus}
                        options={subStatusList}
                        defaultValue={
                          subStatusList?.length > 0 ? subStatus?._id : ""
                        }
                        reset={resetSubStatus}
                      />
                    </div>
                  </div>

                  <div className="row d-flex justify-content-between mt-1 pb-2">
                    <div className="col-md-4 mt-0">
                      <LabelField lableName="Remark" isRequired={true} />
                    </div>
                    <div className="d-flex">
                      <textarea
                        className="form-control"
                        placeholder={"Remark"}
                        value={remark}
                        onChange={(e) => changeRemark(e.target.value)}
                      ></textarea>
                    </div>
                  </div>

                  {status?._id === 1 ? (
                    <div className="row d-flex justify-content-between  ">
                      <div className="col-md-4 mt-2">
                        <LabelField lableName="Call Back Date" />
                      </div>
                      <div className="col-md-7">
                        <DateTime
                          value={callBackDate}
                          setInputText={(value: any) => setCallBackDate(value)}
                          defaultValue={callBackDate}
                          mindate={moment
                            .utc(new Date())
                            .format("YYYY-MM-DD HH:MM:SS")}
                        />
                      </div>
                    </div>
                  ) : null}

                  {status?._id === 2 ? (
                    <div className="row d-flex justify-content-between mt-2">
                      <div className="col-md-4 mt-2">
                        <LabelField lableName="1st Interview Date" />
                      </div>
                      <div className="col-md-7">
                        <DateTime
                          value={firstInterviewDate}
                          setInputText={(value: any) =>
                            setFirstInterviewDate(value)
                          }
                          defaultValue={firstInterviewDate}
                          mindate={moment
                            .utc(new Date())
                            .format("YYYY-MM-DD HH:MM:SS")}
                        />
                      </div>
                    </div>
                  ) : null}

                  {status?._id === 3 ? (
                    <div className="row d-flex justify-content-between mt-2">
                      <div className="col-md-4 mt-2">
                        <LabelField lableName="2nd Interview Date" />
                      </div>
                      <div className="col-md-7">
                        <DateTime
                          value={secondInterviewDate}
                          setInputText={(value: any) =>
                            setSecondInterviewDate(value)
                          }
                          defaultValue={secondInterviewDate}
                          mindate={moment
                            .utc(new Date())
                            .format("YYYY-MM-DD HH:MM:SS")}
                        />
                      </div>
                    </div>
                  ) : null}

                  {status?._id === 4 ? (
                    <div className="row d-flex justify-content-between mt-2">
                      <div className="col-md-4 mt-2">
                        <LabelField lableName="Hr Interview Date" />
                      </div>
                      <div className="col-md-7">
                        <DateTime
                          value={hrRoundDate}
                          setInputText={(value: any) => setHrRoundDate(value)}
                          defaultValue={hrRoundDate}
                          mindate={moment
                            .utc(new Date())
                            .format("YYYY-MM-DD HH:MM:SS")}
                        />
                      </div>
                    </div>
                  ) : null}

                  {status?._id === 9 ? (
                    <div className="row d-flex justify-content-between mt-2">
                      <div className="col-md-4 mt-2">
                        <LabelField lableName="Joining Date" />
                      </div>
                      <div className="col-md-7">
                        <DateTime
                          value={joiningDate}
                          setInputText={(value: any) => setJoiningDate(value)}
                          defaultValue={joiningDate}
                          mindate={moment
                            .utc(new Date())
                            .format("YYYY-MM-DD HH:MM:SS")}
                        />
                      </div>
                    </div>
                  ) : null}

                  <div className=" d-flex justify-content-end  mt-2">
                    <SubmitButton name={"Save"} />
                  </div>
                </div>
              </form>
              <div className="col-md-3 p-3 pt-2">
                <div className="d-flex justify-content-between tabheader p-1">
                  <button
                    className={`btn btn-sm me-1 w-100 ${
                      history === 1 ? "btn-success" : "btn-secondary"
                    }`}
                    onClick={() => onClickHistory(1)}
                  >
                    Call History
                  </button>
                  {/* <button
                    className={`btn btn-sm me-1 ${
                      history === 2 ? "btn-success" : "btn-secondary"
                    }`}
                    onClick={() => onClickHistory(2)}
                  >
                    Payment History
                  </button>
                  <button
                    className={`btn btn-sm me-1 ${
                      history === 3 ? " btn-success" : " btn-secondary"
                    }`}
                    onClick={() => onClickHistory(3)}
                  >
                    Reference History
                  </button> */}
                </div>
                <div className="historydetails">
                  {history === 1
                    ? callHistoryData?.map((value: any) => {
                        return (
                          <div className="my-2">
                            {value?.callBackDate ? (
                              <p className=" ps-4 fw-bold    mb-0 text-primary">
                                Call Back Date :{" "}
                                {moment(value?.callBackDate)
                                  .utc()
                                  .format("DD-MM-YYYY hh:mm:ss a")}
                              </p>
                            ) : null}
                            {value?.call_date ? (
                              <div className="d-flex justify-content-between">
                                <span className="ps-4 fw-bold mb-0 text-secondary">
                                  Called Date & Time :{" "}
                                  {moment(value?.call_date).format(
                                    "DD-MM-YYYY"
                                  )}{" "}
                                  {value?.call_time}
                                </span>
                              </div>
                            ) : null}
                            {value?.call_date ? (
                              <div className="d-flex justify-content-between">
                                <span className="ps-4 fw-bold mb-0 text-secondary">
                                  Call Type : {value?.calltype}
                                </span>
                              </div>
                            ) : null}
                            {value?.call_duration ? (
                              <div className="d-flex justify-content-between">
                                <span className="ps-4 fw-bold mb-0 text-secondary">
                                  Call Duration : {value?.call_duration}
                                </span>
                              </div>
                            ) : null}
                            {value?.call_transfer_status ? (
                              <div className="d-flex justify-content-between">
                                <span className="ps-4 fw-bold mb-0 text-secondary">
                                  Call Status : {value?.call_transfer_status}
                                </span>
                              </div>
                            ) : null}

                            <p className=" ps-4  mb-0 text-success">
                              {value?.departmentName}
                            </p>
                            <p className=" ps-4 mb-0 text-danger">
                              {value?.designation}
                            </p>
                            <p className=" ps-4  mb-0 text-danger">-----</p>
                            <p className=" ps-4 mb-0 text-primary ">
                              Status : {value?.leadStatus?.status}
                            </p>
                            <p className=" ps-4 mb-0 text-success">
                              Status Details : {value?.leadSubStatus?.status}
                            </p>
                            {value?.couponName ? (
                              <p className=" ps-4 mb-0  ">
                                Coupon Code :{" "}
                                <span className=" mb-0  text-secondary">
                                  {value?.couponName}
                                </span>
                              </p>
                            ) : null}
                            <div className="d-flex justify-content-center">
                              {value?.callRecords ? (
                                value?.callRecords?.map((item: any) => {
                                  return (
                                    <audio
                                      className="ps-4 mb-0"
                                      src={item?.rURL}
                                      controls
                                    ></audio>
                                  );
                                })
                              ) : value?.call_recording &&
                                value?.call_recording !== "None" ? (
                                <audio
                                  className="ps-4 mb-0"
                                  src={value?.call_recording}
                                  controls
                                ></audio>
                              ) : null}
                            </div>
                            {value?.comment ? (
                              <p className=" ps-4 mb-0  ">
                                Comment :{" "}
                                <span className=" mb-0  text-secondary">
                                  {value?.comment}
                                </span>
                              </p>
                            ) : null}
                            <hr />
                          </div>
                        );
                      })
                    : ""}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {openPopup ? (
        <MessagePopUp open={openPopup} handleClose={handleClose} />
      ) : (
        ""
      )}
      {openEmailPopup ? (
        <EmailPopUp open={openEmailPopup} handleClose={handleClose} />
      ) : (
        ""
      )}
    </div>
  );
}
