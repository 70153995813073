export const HrManagerPanelSidebarData = [
  {
    title: "ALL CALLS DETAILS",
    path: "hrCallDetails",
  },
  {
    title: "TALKTIME ANALYSIS",
    path: "hrTalktime",
  },
  {
    title: "LEAD ANALYSIS",
    path: "hrLeadAnalysis",
  },
  {
    title: "SEARCH CANDIDATE",
    path: "candidateSearchByMobileNumber",
  },
];
