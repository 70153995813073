export const CrmCounsellorPanelSidebarData = [
  {
    title: "All CALL DETAILS",
    path: "crmCounsellorCallDetails",
  },
  {
    title: "DASHBOARD",
    path: "counsellorDashbord",
  },
  {
    title: "DASHBOARD ANALYSIS",
    path: "counsellorDashbordAnalysis",
  },
  {
    title: "TALKTIME ANALYSIS",
    path: "counsellorTalktime",
  },
  {
    title: "LEAD OVERVIEW",
    path: "counsellorLeadOverview",
  },
  {
    title: "LEAD ANALYSIS",
    subNav: [
      {
        title: "New Opportunity",
        path: "newOpportunity",
        cName: "sub-nav",
      },
      {
        title: "Re-Enquired",
        path: "reEnquired",
        cName: "sub-nav",
      },
      {
        title: "Enrolled",
        path: "enrolledLead",
        cName: "sub-nav",
      },
      {
        title: "Not Interested",
        path: "notIntrestedLead",
        cName: "sub-nav",
      },
      {
        title: "Expired",
        path: "expired",
        cName: "sub-nav",
      },
      {
        title: "Lead Invalid",
        path: "leadInvalid",
        cName: "sub-nav",
      },
      {
        title: "CNR Today",
        path: "cnrLead",
        cName: "sub-nav",
      },
      {
        title: "Prospect Today",
        path: "prospectLead",
        cName: "sub-nav",
      },
      {
        title: "Interested Today",
        path: "interested",
        cName: "sub-nav",
      },
      {
        title: "Call Back Today",
        path: "callBackLead",
        cName: "sub-nav",
      },
      {
        title: "Call Reject Today",
        path: "callRejectToday",
        cName: "sub-nav",
      },
      {
        title: "Overdue Prospect",
        path: "overdueProspect",
        cName: "sub-nav",
      },
      {
        title: "Overdue Interested",
        path: "overdueInterested",
        cName: "sub-nav",
      },
      {
        title: "Overdue Callback",
        path: "overdueCallback",
        cName: "sub-nav",
      },
      {
        title: "Overdue CNR",
        path: "overdueCNR",
        cName: "sub-nav",
      },
      {
        title: "Overdue Call Reject",
        path: "overdueCallReject",
        cName: "sub-nav",
      },
      {
        title: "Already Admission Done",
        path: "alreadyAdmissionDone",
        cName: "sub-nav",
      },
      {
        title: "Final CNR",
        path: "finalCNR",
        cName: "sub-nav",
      },
      {
        title: "Summary",
        path: "summary",
        cName: "sub-nav",
      },
    ],
  },
  {
    title: "REVENUE ANALYSIS",
    subNav: [
      // {
      //   title: "Leads Allotted Date",
      //   path: "leadAssignedDate",
      //   cName: "sub-nav",
      // },
      {
        title: "Admission Date",
        path: "admissionDate",
        cName: "sub-nav",
      },
      {
        title: "Exam Revenue Based On Admission Date",
        path: "examRevenue",
        cName: "sub-nav",
      },
      {
        title: "Course Subscription Months",
        path: "CourseSubscriptionMonths",
        cName: "sub-nav",
      },
    ],
  },
  {
    title: "TARGET DASHBOARD",
    subNav: [
      {
        title: "Target Status",
        path: "targetStatus",
        cName: "sub-nav",
      },
      {
        title: "Direct Target Status",
        path: "incentiveTargetStatus",
        cName: "sub-nav",
      },
    ],
  },
  {
    title: "CALL NOTIFICATION",
    subNav: [
      {
        title: "ALL CALL HISTORY",
        path: "allCallHistory",
        cName: "sub-nav",
      },
      {
        title: "CALL NOTIFICATION",
        path: "callList",
        cName: "sub-nav",
      },
      {
        title: "INCOMING CALL",
        path: "incomingCall",
        cName: "sub-nav",
      },
    ],
  },
  {
    title: "COUPON CODE",
    path: "couponCode",
  },
  {
    title: "LEAD SEARCH",
    path: "leadSearchByMobileNumber",
  },
  {
    title: "REFERENCES",
    subNav: [
      {
        title: "REFER NEW LEAD",
        path: "referNewLead",
        cName: "sub-nav",
      },
      {
        title: "REFERENCES GENERATED LIST",
        path: "referenceLeadsList",
        cName: "sub-nav",
      },
    ],
  },
  {
    title: "MANAGER REMARK",
    path: "counsellorRemark",
  },
  {
    title: "RESULT COMPILATION",
    path: "resultCompilation",
  },
  {
    title: "STUDENT RESULT",
    path: "studentResult",
  },
  {
    title: "USER ACTIVITY TRACKER",
    path: "userActivityTracker",
  },
  {
    title: "REMARK ANALYSIS",
    path: "remarkAnalysis",
  },
  {
    title: "STUDENT ANALYSIS",
    path: "studentAnalysis",
  },
  {
    title: "COURSE SUPPORT",
    path: "coursesupport",
  },
  {
    title: "ORDERS",
    path: "ordersList",
  },
];

export const CrmServiceCounsellorPanelSidebarData = [
  {
    title: "All CALL DETAILS",
    path: "crmCounsellorCallDetails",
  },
  {
    title: "DASHBOARD",
    path: "counsellorDashbord",
  },
  {
    title: "DASHBOARD ANALYSIS",
    path: "counsellorDashbordAnalysis",
  },
  {
    title: "TALKTIME ANALYSIS",
    path: "counsellorTalktime",
  },
  {
    title: "LEAD OVERVIEW",
    path: "counsellorLeadOverview",
  },
  {
    title: "LEAD ANALYSIS",
    subNav: [
      {
        title: "New Opportunity",
        path: "newOpportunity",
        cName: "sub-nav",
      },
      {
        title: "Re-Enquired",
        path: "reEnquired",
        cName: "sub-nav",
      },
      {
        title: "Enrolled",
        path: "enrolledLead",
        cName: "sub-nav",
      },
      {
        title: "Not Interested",
        path: "notIntrestedLead",
        cName: "sub-nav",
      },
      {
        title: "Expired",
        path: "expired",
        cName: "sub-nav",
      },
      {
        title: "Lead Invalid",
        path: "leadInvalid",
        cName: "sub-nav",
      },
      {
        title: "CNR Today",
        path: "cnrLead",
        cName: "sub-nav",
      },
      {
        title: "Prospect Today",
        path: "prospectLead",
        cName: "sub-nav",
      },
      {
        title: "Interested Today",
        path: "interested",
        cName: "sub-nav",
      },
      {
        title: "Call Back Today",
        path: "callBackLead",
        cName: "sub-nav",
      },
      {
        title: "Call Reject Today",
        path: "callRejectToday",
        cName: "sub-nav",
      },
      {
        title: "Overdue Prospect",
        path: "overdueProspect",
        cName: "sub-nav",
      },
      {
        title: "Overdue Interested",
        path: "overdueInterested",
        cName: "sub-nav",
      },
      {
        title: "Overdue Callback",
        path: "overdueCallback",
        cName: "sub-nav",
      },
      {
        title: "Overdue CNR",
        path: "overdueCNR",
        cName: "sub-nav",
      },
      {
        title: "Overdue Call Reject",
        path: "overdueCallReject",
        cName: "sub-nav",
      },
      {
        title: "Already Admission Done",
        path: "alreadyAdmissionDone",
        cName: "sub-nav",
      },
      {
        title: "Welcome Call Done",
        path: "welcomeCallDone",
        cName: "sub-nav",
      },
      {
        title: "Welcome Call & Reference",
        path: "welcomeCallReference",
        cName: "sub-nav",
      },
      {
        title: "Follow - Up For Upselling",
        path: "followUp",
        cName: "sub-nav",
      },
      {
        title: "Final CNR",
        path: "finalCNR",
        cName: "sub-nav",
      },
      {
        title: "Summary",
        path: "summary",
        cName: "sub-nav",
      },
    ],
  },
  {
    title: "REVENUE ANALYSIS",
    subNav: [
      // {
      //   title: "Leads Allotted Date",
      //   path: "leadAssignedDate",
      //   cName: "sub-nav",
      // },
      {
        title: "Admission Date",
        path: "admissionDate",
        cName: "sub-nav",
      },
      {
        title: "Exam Revenue Based On Admission Date",
        path: "examRevenue",
        cName: "sub-nav",
      },
      {
        title: "Course Subscription Months",
        path: "CourseSubscriptionMonths",
        cName: "sub-nav",
      },
    ],
  },
  {
    title: "TARGET DASHBOARD",
    subNav: [
      {
        title: "Target Status",
        path: "targetStatus",
        cName: "sub-nav",
      },
      {
        title: "Direct Target Status",
        path: "incentiveTargetStatus",
        cName: "sub-nav",
      },
    ],
  },
  {
    title: "CALL NOTIFICATION",
    subNav: [
      {
        title: "ALL CALL HISTORY",
        path: "allCallHistory",
        cName: "sub-nav",
      },
      {
        title: "CALL NOTIFICATION",
        path: "callList",
        cName: "sub-nav",
      },
      {
        title: "INCOMING CALL",
        path: "incomingCall",
        cName: "sub-nav",
      },
    ],
  },
  {
    title: "COUPON CODE",
    path: "couponCode",
  },
  {
    title: "LEAD SEARCH",
    path: "leadSearchByMobileNumber",
  },
  {
    title: "REFERENCES",
    subNav: [
      {
        title: "REFER NEW LEAD",
        path: "referNewLead",
        cName: "sub-nav",
      },
      {
        title: "REFERENCES GENERATED LIST",
        path: "referenceLeadsList",
        cName: "sub-nav",
      },
    ],
  },
  {
    title: "MANAGER REMARK",
    path: "counsellorRemark",
  },
  {
    title: "RESULT COMPILATION",
    path: "resultCompilation",
  },
  {
    title: "STUDENT RESULT",
    path: "studentResult",
  },
  {
    title: "USER ACTIVITY TRACKER",
    path: "userActivityTracker",
  },
  {
    title: "REMARK ANALYSIS",
    path: "remarkAnalysis",
  },
  {
    title: "COURSE SUPPORT",
    path: "coursesupport",
  },
  {
    title: "TECHNICAL SUPPORT",
    path: "technicalsupport",
  },
  {
    title: "REGISTRATION DETAILS",
    path: "registrationdetails",
  },
  {
    title: "DOUBT SOLVE REVIEW",
    path: "doubtSolveReview",
  },
  {
    title: "MULTI-DEVICE TOKEN CLEAR",
    path: "MultiDeviceTokenClear",
  },
  {
    title: "LIVE CLASS SCHEDULE",
    path: "liveclassschedule",
  },
  {
    title: "USER DETAILS",
    path: "userDetails",
  },
  {
    title: "CALL TO SUPPORT REQUEST LEADS",
    path: "expertSupport",
  },
  {
    title: "TRANSACTION DETAILS",
    subNav: [
      {
        title: "TRANSACTION EDUTECH",
        path: "transactionedutech",
        cName: "sub-nav",
      },
      {
        title: "TRANSACTION EDUTECH STUDY MATERIAL",
        path: "transactionEdutechStudyMaterial",
        cName: "sub-nav",
      },
    ],
  },
];

export const CrmUpsellingCounsellorPanelSidebarData = [
  {
    title: "All CALL DETAILS",
    path: "crmCounsellorCallDetails",
  },
  {
    title: "DASHBOARD",
    path: "counsellorDashbord",
  },
  {
    title: "DASHBOARD ANALYSIS",
    path: "counsellorDashbordAnalysis",
  },
  {
    title: "TALKTIME ANALYSIS",
    path: "counsellorTalktime",
  },
  {
    title: "LEAD OVERVIEW",
    path: "counsellorLeadOverview",
  },
  {
    title: "LEAD ANALYSIS",
    subNav: [
      {
        title: "New Opportunity",
        path: "newOpportunity",
        cName: "sub-nav",
      },
      {
        title: "Re-Enquired",
        path: "reEnquired",
        cName: "sub-nav",
      },
      {
        title: "Enrolled",
        path: "enrolledLead",
        cName: "sub-nav",
      },
      {
        title: "Not Interested",
        path: "notIntrestedLead",
        cName: "sub-nav",
      },
      {
        title: "Expired",
        path: "expired",
        cName: "sub-nav",
      },
      {
        title: "Lead Invalid",
        path: "leadInvalid",
        cName: "sub-nav",
      },
      {
        title: "CNR Today",
        path: "cnrLead",
        cName: "sub-nav",
      },
      {
        title: "Prospect Today",
        path: "prospectLead",
        cName: "sub-nav",
      },
      {
        title: "Interested Today",
        path: "interested",
        cName: "sub-nav",
      },
      {
        title: "Call Back Today",
        path: "callBackLead",
        cName: "sub-nav",
      },
      {
        title: "Call Reject Today",
        path: "callRejectToday",
        cName: "sub-nav",
      },
      {
        title: "Overdue Prospect",
        path: "overdueProspect",
        cName: "sub-nav",
      },
      {
        title: "Overdue Interested",
        path: "overdueInterested",
        cName: "sub-nav",
      },
      {
        title: "Overdue Callback",
        path: "overdueCallback",
        cName: "sub-nav",
      },
      {
        title: "Overdue CNR",
        path: "overdueCNR",
        cName: "sub-nav",
      },
      {
        title: "Overdue Call Reject",
        path: "overdueCallReject",
        cName: "sub-nav",
      },
      {
        title: "Already Admission Done",
        path: "alreadyAdmissionDone",
        cName: "sub-nav",
      },
      {
        title: "Welcome Call Done",
        path: "welcomeCallDone",
        cName: "sub-nav",
      },
      {
        title: "Welcome Call & Reference",
        path: "welcomeCallReference",
        cName: "sub-nav",
      },
      {
        title: "Follow - Up For Upselling",
        path: "followUp",
        cName: "sub-nav",
      },
      {
        title: "Interested For Upselling",
        path: "interestedForUpselling",
        cName: "sub-nav",
      },
      {
        title: "Prospect For Upselling",
        path: "prospectForUpselling",
        cName: "sub-nav",
      },
      {
        title: "Technical Issue",
        path: "technicalIssue",
        cName: "sub-nav",
      },
      {
        title: "Final CNR",
        path: "finalCNR",
        cName: "sub-nav",
      },
      {
        title: "Summary",
        path: "summary",
        cName: "sub-nav",
      },
    ],
  },
  {
    title: "REVENUE ANALYSIS",
    subNav: [
      // {
      //   title: "Leads Allotted Date",
      //   path: "leadAssignedDate",
      //   cName: "sub-nav",
      // },
      {
        title: "Admission Date",
        path: "admissionDate",
        cName: "sub-nav",
      },
      {
        title: "Exam Revenue Based On Admission Date",
        path: "examRevenue",
        cName: "sub-nav",
      },
      {
        title: "Course Subscription Months",
        path: "CourseSubscriptionMonths",
        cName: "sub-nav",
      },
    ],
  },
  {
    title: "TARGET DASHBOARD",
    subNav: [
      {
        title: "Target Status",
        path: "targetStatus",
        cName: "sub-nav",
      },
      {
        title: "Direct Target Status",
        path: "incentiveTargetStatus",
        cName: "sub-nav",
      },
    ],
  },
  {
    title: "CALL NOTIFICATION",
    subNav: [
      {
        title: "ALL CALL HISTORY",
        path: "allCallHistory",
        cName: "sub-nav",
      },
      {
        title: "CALL NOTIFICATION",
        path: "callList",
        cName: "sub-nav",
      },
      {
        title: "INCOMING CALL",
        path: "incomingCall",
        cName: "sub-nav",
      },
    ],
  },
  {
    title: "COUPON CODE",
    path: "couponCode",
  },
  {
    title: "LEAD SEARCH",
    path: "leadSearchByMobileNumber",
  },
  {
    title: "REFERENCES",
    subNav: [
      {
        title: "REFER NEW LEAD",
        path: "referNewLead",
        cName: "sub-nav",
      },
      {
        title: "REFERENCES GENERATED LIST",
        path: "referenceLeadsList",
        cName: "sub-nav",
      },
    ],
  },
  {
    title: "MANAGER REMARK",
    path: "counsellorRemark",
  },
  {
    title: "RESULT COMPILATION",
    path: "resultCompilation",
  },
  {
    title: "STUDENT RESULT",
    path: "studentResult",
  },
  {
    title: "USER ACTIVITY TRACKER",
    path: "userActivityTracker",
  },
  {
    title: "REMARK ANALYSIS",
    path: "remarkAnalysis",
  },
  {
    title: "COURSE SUPPORT",
    path: "coursesupport",
  },
];
