import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import moment from "moment";
import LabelField from "../../Component/labelField";
import GenaralTable from "../../Component/genaralTable";
import { updateCommonValues } from "../../Redux/Actions/CommonAction";
import { useAppDispatch } from "../../hooks";
import DatePicker from "../../Component/datePicker";
import SubmitButton from "../../Component/submitButton";
import { getHrTalktime } from "../../Redux/Actions/hrPanelAction";

export default function ManagerDashbord() {
  const dispatch = useAppDispatch();
  const [startDate, SetStartDate] = useState<any>(
    moment.utc(new Date()).format("YYYY-MM-DD")
  );
  const [endDate, SetEndDate] = useState<any>(
    moment.utc(new Date()).format("YYYY-MM-DD")
  );
  const [talkTimeList, setTalkTimeList] = useState([]);

  const { loggedInUserId, loggedInUserName } = useSelector((state: any) => ({
    loggedInUserId: state.Auth.loggedInUserId,
    loggedInUserName: state.Auth.loggedInUserName,
  }));

  useEffect(() => {
    dispatch(updateCommonValues("startDate", startDate));
    dispatch(updateCommonValues("endDate", endDate));
    displayTalktime();
  }, []);

  const displayTalktime = () => {
    dispatch(
      getHrTalktime(
        {
          startDate,
          endDate,
          managerId: loggedInUserId,
        },
        (res: any) => {
          setTalkTimeList(res);
        },
        () => {}
      )
    );
  };
  const onChangeStartDate = (val: any) => {
    SetStartDate(val);
    dispatch(updateCommonValues("startDate", val));
  };

  const onChangeEndDate = (val: any) => {
    SetEndDate(val);
    dispatch(updateCommonValues("endDate", val));
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    displayTalktime();
  };

  const renderListMode = () => {
    return (
      <div className="mx-2 mt-3 col-12 formWidth shadow">
        <h4 className="fw-bold">Team Talk Time</h4>
        <form className="d-flex" onSubmit={handleSubmit}>
          <div className="col-md-3 d-flex me-2 mt-3">
            <div className="col-md-4 d-flex flex-row-reverse">
              <LabelField lableName="Start Date :" />
            </div>
            <div className="col-md-8">
              <DatePicker
                value={startDate}
                setInputText={(value: string) => onChangeStartDate(value)}
                maxdate={moment.utc(new Date()).format("YYYY-MM-DD")}
              />
            </div>
          </div>
          <div className="col-md-3 d-flex me-2 mt-3">
            <div className="col-md-4 d-flex flex-row-reverse">
              <LabelField lableName="End Date :" />
            </div>
            <div className="col-md-8">
              <DatePicker
                value={endDate}
                setInputText={(value: string) => onChangeEndDate(value)}
                maxdate={moment.utc(new Date()).format("YYYY-MM-DD")}
              />
            </div>
          </div>
          {/* {loggedInUserRole === 1 ? (
            <div className="col-md-3 d-flex mb-3 me-2 mt-3">
              <div className="col-md-4 mt-2">
                <LabelField lableName="Manager" />
              </div>
              <div className="col-md-8 pe-2">
                <CustomDropdown
                  lableName="Select Manager"
                  setInputText={(value: any) => onChangeManager(value)}
                  value={manager}
                  options={managerList}
                />
              </div>
            </div>
          ) : null} */}
          <div className="col-md-3 text-center mt-3">
            <SubmitButton name={"Submit"} />
          </div>
        </form>

        <GenaralTable
          tableData={talkTimeList ? talkTimeList : []}
          pagename={"managerDashboard"}
          tableHead={[
            "Name",
            "Total",
            // "12AM-1AM",
            // "1AM-2AM",
            // "2AM-3AM",
            // "3AM-4AM",
            // "4AM-5AM",
            // "5AM-6AM",
            // "6AM-7AM",
            "7AM-8AM",
            "8AM-9AM",
            "9AM-10AM",
            "10AM-11AM",
            "11AM-12PM",
            "12PM-1PM",
            "1PM-2PM",
            "2PM-3PM",
            "3PM-4PM",
            "4PM-5PM",
            "5PM-6PM",
            "6PM-7PM",
            "7PM-8PM",
            "8PM-9PM",
            "9PM-10PM",
            "10PM-11PM",
            "11PM-12AM",
          ]}
        />
      </div>
    );
  };
  return (
    <div className="contentBox">
      <div className="col-md-12 row">
        <div className="formWidth p-4">
          <h3 className="fw-bold text-primary">Hi,{loggedInUserName}</h3>
          <h5 className="fw-bold text-primary">Today's Dashboard</h5>
          <h6>Date: {moment.utc(startDate).format("DD-MM-YYYY")}</h6>
          {renderListMode()}
        </div>
      </div>
    </div>
  );
}
