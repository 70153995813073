import "../../App.css";
import { Suspense } from "react";
import Sidebar from "../../Module/sidebar";
import { Route, Routes } from "react-router-dom";

import Loading from "../../Component/loading";
import { HrManagerPanelSidebarData } from "../../DATA/HrManagerPanelSidebarData";

import HrCallDetails from "./hrCallDetails";
import HrTalktime from "./hrTalktime";
import HrLeadAnalysis from "./hrLeadAnalysis";
import HrLeadAnalysisDetails from "./hrLeadAnalysisDetails";
import CandidateSearchByMobileNumber from "./candidateSearchByMobileNumber";

function HrManagerPanelDashbord() {
  return (
    <div className="AppBox">
      <Sidebar data={HrManagerPanelSidebarData} />
      <div className="dashboard">
        {
          <Suspense
            fallback={
              <div className="border p-1">
                <Loading />
              </div>
            }
          >
            <Routes>
              <Route path="/" element={<HrCallDetails />} />
              <Route path="hrCallDetails" element={<HrCallDetails />} />
              <Route path="hrTalktime" element={<HrTalktime />} />
              <Route path="hrLeadAnalysis" element={<HrLeadAnalysis />} />
              <Route
                path="hrLeadAnalysisDetails"
                element={<HrLeadAnalysisDetails />}
              />
              <Route
                path="candidateSearchByMobileNumber"
                element={<CandidateSearchByMobileNumber />}
              />
            </Routes>
          </Suspense>
        }
      </div>
    </div>
  );
}

export default HrManagerPanelDashbord;
