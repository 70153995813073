import { Pagination } from "@mui/material";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import PageTitle from "../../Component/pageTitle";
import PaginationTable from "../../Component/paginationTable";
import PaginationView from "../../Component/paginationView";
import usePagination from "../../Component/usePagination";
import { useSelector } from "react-redux";
import {
  getLeadAnalysisSummary,
  getStatesList,
} from "../../Redux/Actions/counsellorPanelAction";
import CustomDropdown from "../../Component/customDropdown";
import LabelField from "../../Component/labelField";
import { getTableDataSlots, sortLeadScoreList } from "../../DATA/dataConstant";
import { getStatusList } from "../../Redux/Actions/leadManagementAction";
import DatePicker from "../../Component/datePicker";
import { updateCommonValues } from "../../Redux/Actions/CommonAction";
import { useAppDispatch } from "../../hooks";
import SubmitButton from "../../Component/submitButton";
import moment from "moment";
export default function Summery() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const {
    loggedInUserId,
    loggedInUserRole,
    selectedPage,
    selectedRowsPerPage,
  } = useSelector((state: any) => ({
    loggedInUserId: state.Auth.loggedInUserId,
    loggedInUserRole: state.Auth.loggedInUserRole,
    selectedPage: state.Auth.selectedPage,
    selectedRowsPerPage: state.Auth.selectedRowsPerPage,
  }));

  const pageName = location.state?.pageName;
  const leadStatus = location.state?.status;
  const leadState = location.state?.state;
  const leadStartDate = location.state?.startDate;
  const leadEndDate = location.state?.endDate;

  const [sortLeadScore, setSortLeadScore] = useState<any>(sortLeadScoreList[0]);
  const [state, setState] = useState<any>("");
  const [status, setStatus] = useState<any>("");
  const [startDate, SetStartDate] = useState<any>(
    moment.utc(new Date()).format("YYYY-MM-DD")
  );
  const [endDate, SetEndDate] = useState<any>(
    moment.utc(new Date()).format("YYYY-MM-DD")
  );

  const [stateList, setStateList] = useState<any>([]);
  const [leadsList, setLeadsList] = useState<any>([]);
  const [statusList, setStatusList] = useState<any>([]);

  const [resetStatus, setResetStatus] = useState(false);
  const [resetState, setResetState] = useState<any>("");
  const [resetSortLeadScore, setResetSortLeadScore] = useState<any>(false);

  // pagination
  const [count, setCount] = useState<any>(0);
  const [page, setPage] = useState<any>(0);
  const [rowsPerPage, setRowsPerPage] = useState<any>(10);
  const [currentPage, setCurrentPage] = useState<any>(1);
  const totalPages = Math.ceil(count / rowsPerPage);
  const _DATA = usePagination(count, rowsPerPage);

  useEffect(() => {
    if (pageName !== "leadDetail") {
      dispatch(updateCommonValues("selectedLeadId", ""));
      dispatch(updateCommonValues("selectedPage", ""));
      dispatch(updateCommonValues("selectedRowsPerPage", ""));
    }
    dispatch(
      getStatusList(
        "",
        (res: any) => {
          if (loggedInUserRole !== 8 && loggedInUserRole !== 4) {
            // let arr = res?.filter?.(
            //   (item: any) =>
            //     item?._id === 4 ||
            //     item?._id === 6 ||
            //     item?._id === 7 ||
            //     item?._id === 11 ||
            //     item?._id === 12
            // );
            let arr = res?.filter?.(
              (item: any) =>
                item?._id !== 15 && item?._id !== 16 && item?._id !== 17
            );
            setStatusList(arr);
            let tempStatus = arr?.filter?.(
              (item: any) => item?._id === leadStatus
            );
            setStatus(tempStatus[0]);
          } else if (loggedInUserRole === 8) {
            // let arr = res?.filter?.(
            //   (item: any) =>
            //     item?._id === 4 ||
            //     item?._id === 6 ||
            //     item?._id === 7 ||
            //     item?._id === 11 ||
            //     item?._id === 12 ||
            //     item?._id === 15 ||
            //     item?._id === 16 ||
            //     item?._id === 17
            // );
            setStatusList(res);
            let tempStatus = res?.filter?.(
              (item: any) => item?._id === leadStatus
            );
            setStatus(tempStatus[0]);
          } else if (loggedInUserRole === 4) {
            let tempStatus = res?.filter(
              (item: any) =>
                item?._id === 4 ||
                item?._id === 5 ||
                item?._id === 6 ||
                item?._id === 7 ||
                item?._id === 8 ||
                item?._id === 9 ||
                item?._id === 10 ||
                item?._id === 11 ||
                item?._id === 12 ||
                item?._id === 15 ||
                item?._id === 16 ||
                item?._id === 17 ||
                item?._id === 18 ||
                item?._id === 19 ||
                item?._id === 20
            );
            setStatusList(tempStatus);
          }
        },
        () => {}
      )
    );
    dispatch(
      getStatesList((res: any) => {
        if (res?.data?.length > 0) {
          setStateList(res?.data);
        } else {
          setStateList([]);
        }
      })
    );
    let PostData: any = {
      skip: 0,
      limit: rowsPerPage,
      counsellorId: loggedInUserId,
      sort: sortLeadScore?.value,
    };
    if (pageName === "leadDetail") {
      setStatus(leadStatus);
      setState(leadState);
      SetStartDate(leadStartDate);
      SetEndDate(leadEndDate);
      setRowsPerPage(selectedRowsPerPage);
      setPage(selectedPage);
      setCurrentPage(selectedPage + 1);
      PostData["status"] = leadStatus?._id;
      PostData["stateId"] = leadState?._id;
      PostData["startDate"] = leadStartDate;
      PostData["endDate"] = leadEndDate;
      PostData["skip"] = selectedPage * selectedRowsPerPage;
      PostData["limit"] = selectedRowsPerPage;
      if (leadStartDate === "") {
        delete PostData["startDate"];
      }
      if (leadEndDate === "") {
        delete PostData["endDate"];
      }
      setCount(0);
      setLeadsList([]);
      dispatch(
        getLeadAnalysisSummary(
          PostData,
          (res: any) => {
            setCount(res?.data?.count);
            setLeadsList(res?.data?.data);
          },
          () => {}
        )
      );
    }
  }, []);

  const onChangeLeadScore = (val: any) => {
    setSortLeadScore(val);
    setResetSortLeadScore(false);
  };

  const onChangeStatus = (val: any) => {
    setStatus(val);
    setResetStatus(false);
  };

  const onChangeState = (val: any) => {
    setState(val);
    setResetState(false);
  };

  const onEndDateChange = (val: any) => {
    SetEndDate(val);
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    let postData: any = {
      skip: 0,
      limit: rowsPerPage,
      counsellorId: loggedInUserId,
      sort: sortLeadScore?.value,
    };
    if (status) {
      postData["status"] = status?._id;
    }
    if (state) {
      postData["stateId"] = state?._id;
    }
    if (startDate) {
      postData["startDate"] = startDate;
    }
    if (endDate) {
      postData["endDate"] = endDate;
    }
    dispatch(
      getLeadAnalysisSummary(
        postData,
        (res: any) => {
          setCount(res?.data?.count);
          setLeadsList(res?.data?.data);
        },
        () => {}
      )
    );
  };

  const handleChange = (e: any, page: number) => {
    onPageChange(page, rowsPerPage, 1);
    setPage(page - 1);
    setCurrentPage(page);
    _DATA.jump(page);
  };
  const onPageChange = (page: number, rowsPerPage: number, flag: number) => {
    /************ page change****************** */
    let data: any = {};
    if (flag === 1) {
      data = {
        skip: (page - 1) * rowsPerPage,
        limit: rowsPerPage,
        counsellorId: loggedInUserId,
        sort: sortLeadScore?.value,
      };
    } else if (flag === 2) {
      data = {
        skip: 0,
        limit: rowsPerPage,
        counsellorId: loggedInUserId,
        sort: sortLeadScore?.value,
      };
    } else {
      data = {
        skip: 0,
        limit: count,
        counsellorId: loggedInUserId,
        sort: sortLeadScore?.value,
      };
    }

    if (status) {
      data["status"] = status?._id;
    }
    if (state) {
      data["stateId"] = state?._id;
    }
    if (startDate) {
      data["startDate"] = startDate;
    }
    if (endDate) {
      data["endDate"] = endDate;
    }

    dispatch(
      getLeadAnalysisSummary(
        data,
        (res: any) => {
          setCount(res?.data?.count);
          setLeadsList(res?.data?.data);
        },
        () => {}
      )
    );
  };

  const handleChangePage = (event: any, newPage: number) => {
    setPage(newPage);
    setCurrentPage(newPage + 1);
  };

  const handleChangeRowsPerPage = (event: { target: { value: any } }) => {
    const value = event.target.value;
    if (value === "All") {
      setRowsPerPage(-1);
      setPage(0);
      setCurrentPage(1);
      return;
    }
    setRowsPerPage(parseInt(value, 10));
    setPage(0);
    setCurrentPage(1);
    onPageChange(page, value, 2);
  };

  const onClickLead = (data: any) => {
    dispatch(updateCommonValues("selectedLeadId", data?._id));
    dispatch(updateCommonValues("selectedPage", page));
    dispatch(updateCommonValues("selectedRowsPerPage", rowsPerPage));
    navigate("/leadDetail", {
      state: {
        counsellorId: loggedInUserId,
        pageName: "summary",
        status,
        state,
        startDate,
        endDate,
      },
    });
  };

  const renderListMode = () => {
    return (
      <>
        <div>
          <div className="d-flex paginationdiv justify-content-end align-items-center">
            <Pagination
              count={totalPages}
              size="large"
              page={currentPage}
              variant="outlined"
              shape="rounded"
              color={"primary"}
              onChange={handleChange}
            />
          </div>
          <PaginationView
            count={count}
            tableData={leadsList ? leadsList : []}
            page={page}
            rowsPerPage={rowsPerPage}
            handleChangePage={handleChangePage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
            rowsPerPageOptions={getTableDataSlots(count)}
          />
          <div>
            <PaginationTable
              tableData={leadsList ? leadsList : []}
              pagename={"summary"}
              rowsPerPage={rowsPerPage}
              page={page}
              tableHead={[
                "S.N",
                "First Name",
                "Last Name",
                "Class Rank",
                "Lead Score",
                "State",
                "Exam",
                "Exam Stream",
                "Source",
                "Status",
                "Remark",
                status?._id === 11 && status !== ""
                  ? "Expired Date"
                  : "Call Back Date",
                "Allotted Date",
              ]}
              edit={false}
              handleClick={(value: any) => {
                onClickLead(value);
              }}
            />
          </div>
        </div>
      </>
    );
  };

  return (
    <div className="contentBox">
      <PageTitle name={"Summary"} />
      <div className="row m-2">
        <form className="col-md-12 formDiv formWidth" onSubmit={handleSubmit}>
          <div className="d-flex">
            <div className="col-md-4 d-flex mb-3 ms-0">
              <div className="col-md-3 mt-2">
                <LabelField lableName="Start Date" />
              </div>
              <div className="col-md-8 ms-2 pe-2">
                <DatePicker
                  value={startDate}
                  setInputText={(value: string) => SetStartDate(value)}
                />
              </div>
            </div>
            <div className="col-md-4 d-flex mb-3 ms-0">
              <div className="col-md-3 mt-2">
                <LabelField lableName="End Date" />
              </div>
              <div className="col-md-8 ms-2 pe-2">
                <DatePicker
                  value={endDate}
                  setInputText={(value: string) => onEndDateChange(value)}
                />
              </div>
            </div>
            <div className="col-md-4 d-flex mb-3 me-5">
              <div className="col-md-3 mt-2">
                <LabelField lableName="Lead Score" />
              </div>
              <div className="col-md-8 ms-2 pe-2">
                <CustomDropdown
                  lableName="Select Lead Score"
                  setInputText={(value: any) => onChangeLeadScore(value)}
                  value={sortLeadScore}
                  options={sortLeadScoreList}
                  reset={resetSortLeadScore}
                  defaultValue={
                    sortLeadScoreList?.length > 0 ? sortLeadScore?._id : ""
                  }
                />
              </div>
            </div>
          </div>
          <div className="d-flex">
            <div className="col-md-4 d-flex mb-3">
              <div className="col-md-3 mt-2">
                <LabelField lableName="Status" />
              </div>
              <div className="col-md-8 ms-2 pe-2">
                <CustomDropdown
                  lableName="Select Status"
                  setInputText={(value: any) => onChangeStatus(value)}
                  value={status}
                  options={statusList}
                  reset={resetStatus}
                  defaultValue={statusList?.length > 0 ? status?._id : ""}
                />
              </div>
            </div>
            <div className="col-md-4 d-flex mb-3 ms-0">
              <div className="col-md-3 mt-2">
                <LabelField lableName="State" />
              </div>
              <div className="col-md-8 ms-2 pe-2">
                <CustomDropdown
                  lableName="Select State"
                  setInputText={(value: any) => onChangeState(value)}
                  value={state}
                  options={stateList}
                  defaultValue={stateList?.length > 0 ? state?._id : ""}
                  reset={resetState}
                />
              </div>
            </div>
            <div className="col-md-4 d-flex mb-3 ms-0">
              <div className="col-md-3">
                <SubmitButton name={"Search"} />
              </div>
            </div>
          </div>
          {renderListMode()}
        </form>
      </div>
    </div>
  );
}
