import { Pagination } from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import PageTitle from "../../Component/pageTitle";
import EditCollege from "../../Component/editCollege";
import {
  getCollgeDetails,
  getDepartmentList,
} from "../../Redux/Actions/bdExecutivePanelAction";
import moment from "moment";
export default function CollegeDetails() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const leadData = location.state?.leadData;
  const isView = location.state?.isViewMode;
  const pageName = location.state?.pageName;

  const [isViewMode, setIsViewMode] = useState<boolean>(isView);

  const [editCollege, setEditCollege] = useState(false);
  const [collegeDetails, setCollegeDetails] = useState<any>("");
  const [departmentDetails, setDepartmentDeatils] = useState<any>([]);

  useEffect(() => {
    dispatch(
      getCollgeDetails(
        leadData,
        (res: any) => {
          setCollegeDetails(res?.data[0]);
        },
        () => {}
      )
    );

    dispatch(
      getDepartmentList(
        leadData,
        (res: any) => {
          setDepartmentDeatils(res?.departmentData);
        },
        () => {}
      )
    );
  }, []);

  const handleEdit = (val: any) => {
    setEditCollege(true);
  };
  const handleClose = () => {
    setEditCollege(false);
    dispatch(
      getCollgeDetails(
        leadData,
        (res: any) => {
          setCollegeDetails(res?.data[0]);
        },
        () => {}
      )
    );
  };
  const addDetails = () => {
    navigate("/departmentdetails", {
      state: {
        college: collegeDetails,
        IsCheck: "false",
      },
    });
  };
  const handleEditDepartment = (val: any) => {
    navigate("/departmentdetails", {
      state: {
        departmentId: val?._id,
        college: collegeDetails,
        IsEdit: "true",
        IsCheck: "true",
      },
    });
  };

  const handleGoback = () => {
    setIsViewMode(false);
    if (pageName === "collegeList") {
      navigate("../collegeList", {
        state: {
          pageName: "collegeDetails",
        },
      });
    }
  };

  return (
    <div className="contentBox">
      <PageTitle
        name={"Details"}
        isGoBack={isViewMode}
        onClick={handleGoback}
      />
      <div className="row p-2 px-5 formDiv">
        <div className="col-md-12   formWidth">
          <div className="border border-2 rounded">
            <div className="border-bottom p-2 d-flex justify-content-between">
              <h3 className="fw-bold">College Details</h3>
              <button
                type="button"
                className="btn btn-outline-primary"
                onClick={addDetails}
              >
                Add New Department
              </button>
            </div>
            <div className="d-flex justify-content-between p-2">
              <div className="col-md-6 font17">
                <div className="row d-flex justify-content-between ">
                  <div className="col-md-3  ">Name:</div>
                  <div className="col-md-9 text-uppercase">
                    {collegeDetails?.collegeName}
                  </div>
                </div>
                <div className="row d-flex justify-content-between ">
                  <div className="col-md-3  ">State:</div>

                  <div className="col-md-9">{collegeDetails?.state?.name}</div>
                </div>
                <div className="row d-flex justify-content-between ">
                  <div className="col-md-3  ">City:</div>

                  <div className="col-md-9">
                    {collegeDetails?.district?.name}
                  </div>
                </div>
                <div className="row d-flex justify-content-between ">
                  <div className="col-md-3 ">Mobile No:</div>
                  <div className="col-md-9">{collegeDetails?.contactNo}</div>
                </div>
                <div className="row d-flex justify-content-between ">
                  <div className="col-md-3 ">Email:</div>
                  <div className="col-md-9">{collegeDetails?.email}</div>
                </div>
              </div>
              <div className=" d-flex Justify-content-end">
                <div className="me-5">
                  <button
                    type="button"
                    className="btn btn-outline-primary py-1 px-4"
                    onClick={(val: any) => handleEdit(val)}
                  >
                    Edit
                  </button>
                </div>
              </div>
            </div>
          </div>

          {departmentDetails?.map((item: any) => {
            return (
              <div className="mt-5">
                <h5>
                  {" "}
                  <span className="fw-bold">Department :- </span>
                  {item?.departmentName}
                </h5>
                <div className="border border-2 rounded collegeDetailsbg  px-5 py-2">
                  <div className="border border-2 rounded bg-light row d-flex jystify-content-between mx-5">
                    <div className="col-md-6 border border-dark px-0">
                      <div className="border-bottom p-2 px-3 border-dark">
                        <h5 className="fw-bold">Main Contact</h5>
                      </div>
                      <div className="p-3 font17">
                        <div className="row d-flex justify-content-between ">
                          <div className="col-md-4  ">Name:</div>

                          <div className="col-md-7">
                            {item?.primaryContact?.firstName}{" "}
                            {item?.primaryContact?.lastName}
                          </div>
                        </div>
                        <div className="row d-flex justify-content-between ">
                          <div className="col-md-4  ">Designation:</div>

                          <div className="col-md-7">
                            {item?.primaryContact?.designation}
                          </div>
                        </div>

                        <div className="row d-flex justify-content-between ">
                          <div className="col-md-4  ">Mobile No:</div>

                          <div className="col-md-7">
                            {item?.primaryContact?.contact1}
                          </div>
                        </div>
                        <div className="row d-flex justify-content-between ">
                          <div className="col-md-4  ">Email:</div>

                          <div className="col-md-7 text-break">
                            {item?.primaryContact?.email}
                          </div>
                        </div>
                        <div className="row d-flex justify-content-between ">
                          <div className="col-md-4  ">IFASian</div>

                          <div className="col-md-7">
                            {item?.primaryContact?.IFASian}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6 border border-dark px-0">
                      <div className="d-flex justify-content-between border-bottom p-2 px-3 border-dark">
                        <h5 className="fw-bold">Alternate Contact</h5>
                        <div className=" d-flex Justify-content-end">
                          <button
                            type="button"
                            className="btn btn-sm btn-outline-primary py-1 px-4"
                            onClick={(val: any) => handleEditDepartment(item)}
                          >
                            Edit{" "}
                          </button>
                        </div>
                      </div>
                      <div className="p-2 font17">
                        <div className="row d-flex justify-content-between ">
                          <div className="col-md-4  ">Name:</div>

                          <div className="col-md-7">
                            {item?.secondaryContact?.firstName}{" "}
                            {item?.secondaryContact?.lastName}
                          </div>
                        </div>
                        <div className="row d-flex justify-content-between ">
                          <div className="col-md-4  ">Designation:</div>

                          <div className="col-md-7">
                            {item?.secondaryContact?.designation}
                          </div>
                        </div>

                        <div className="row d-flex justify-content-between ">
                          <div className="col-md-4  ">Mobile No:</div>

                          <div className="col-md-7">
                            {item?.secondaryContact?.contact1}
                          </div>
                        </div>
                        <div className="row d-flex justify-content-between ">
                          <div className="col-md-4  ">Email:</div>

                          <div className="col-md-7 text-break">
                            {item?.secondaryContact?.email}
                          </div>
                        </div>
                        <div className="row d-flex justify-content-between ">
                          <div className="col-md-4  ">IFASian</div>

                          <div className="col-md-7">
                            {item?.secondaryContact?.IFASian}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="mx-5 mt-4">
                    <h5 className="text-center fw-bold">Webinar History</h5>
                    {/* <div className="d-flex "> */}

                    <div className="row d-flex jystify-content-between px-3">
                      {item?.webinarsHistory?.map((val: any) => {
                        return (
                          <div className="col-md-6 p-3  font16">
                            <div className="row d-flex justify-content-between ">
                              <div className="col-md-4  ">Webinar Date :</div>
                              <div className="col-md-7">
                                {val?.webinarAt
                                  ? moment(val?.webinarAt).format(
                                      "DD/MM/YYYY, h:mm:ss a"
                                    )
                                  : "-"}
                              </div>
                            </div>
                            <div className="row d-flex justify-content-between ">
                              <div className="col-md-4  ">
                                Webinar Conducted By :
                              </div>
                              <div className="col-md-7">{val?.conductedBy}</div>
                            </div>
                            <div className="row d-flex justify-content-between ">
                              <div className="col-md-4  ">Webinar Leads :</div>
                              <div className="col-md-7">
                                {val?.leadCount ? val?.leadCount : 0}
                              </div>
                            </div>
                            <div className="row d-flex justify-content-between ">
                              <div className="col-md-4  ">Admission :</div>
                              <div className="col-md-7">
                                {val?.admissionCount ? val?.admissionCount : 0}
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </div>

                    {/* </div> */}
                  </div>
                </div>
              </div>
            );
          })}
        </div>

        {editCollege ? (
          <EditCollege
            open={editCollege}
            handleClose={handleClose}
            data={collegeDetails}
          />
        ) : (
          ""
        )}
      </div>
    </div>
  );
}
