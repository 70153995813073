import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import DatePicker from "../../Component/datePicker";
import LabelField from "../../Component/labelField";
import { useLocation } from "react-router-dom";
import CustomDropdown from "../../Component/customDropdown";
import { Pagination } from "@mui/material";
import PaginationTable from "../../Component/paginationTable";
import PaginationView from "../../Component/paginationView";
import usePagination from "../../Component/usePagination";
import { getTableDataSlots } from "../../DATA/dataConstant";
import { updateCommonValues } from "../../Redux/Actions/CommonAction";
import { useAppDispatch } from "../../hooks";
import { useSelector } from "react-redux";
import {
  getHrCandidateStatusList,
  getHrStatusList,
  getHrSubStatusList,
} from "../../Redux/Actions/hrPanelAction";

export default function CounsellorCallDetails() {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const { loggedInUserName } = useSelector((state: any) => ({
    loggedInUserName: state.Auth.loggedInUserName,
  }));

  const [analysis, setAnalysis] = useState<any>("");
  const [startDate, SetStartDate] = useState<any>("");
  const [endDate, SetEndDate] = useState<any>("");
  const [status, setStatus] = useState<any>("");
  const [subStatus, setSubStatus] = useState<any>("");

  const [statusList, setStatusList] = useState([]);
  const [subStatusList, setSubStatusList] = useState<any>([]);
  const [studentList, setstudentList] = useState<any>([]);

  const [resetStatus, setResetStatus] = useState<any>(false);
  const [resetSubStatus, setResetSubStatus] = useState<any>(false);

  const candidateData = location.state?.candidateData;
  const executiveName = location.state?.executiveName;
  const pageName = location.state?.pageName;
  const leadAnalysis = location.state?.analysis;
  const leadStatus = location.state?.status;
  const leadPage = location.state?.page;
  const leadRowsPerPage = location.state?.rowsPerPage;

  // pagination
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const totalPages = Math.ceil(count / rowsPerPage);
  const _DATA = usePagination(count, rowsPerPage);

  const handleChange = (e: any, page: number) => {
    onPageChange(page, rowsPerPage, 1);
    setPage(page - 1);
    setCurrentPage(page);
    _DATA.jump(page);
  };
  const onPageChange = (page: number, rowsPerPage: number, flag: number) => {
    /************ page change****************** */
    let data: any = {};
    setCount(0);
    setstudentList([]);
    if (flag === 1) {
      data = {
        skip: (page - 1) * rowsPerPage,
        limit: rowsPerPage,
        status: status?._id,
        executiveId: candidateData?.executiveId,
      };
    } else if (flag === 2) {
      data = {
        skip: 0,
        limit: rowsPerPage,
        status: status?._id,
        executiveId: candidateData?.executiveId,
      };
    } else {
      data = {
        skip: 0,
        limit: count,
        status: status?._id,
        executiveId: candidateData?.executiveId,
      };
    }
    if (startDate) {
      data["startDate"] = startDate;
    }
    if (endDate) {
      data["endDate"] = endDate?._id;
    }
    if (analysis === "ALL") {
      dispatch(
        getHrCandidateStatusList(
          data,
          (res: any) => {
            setCount(res?.count);
            setstudentList(res?.data);
          },
          () => {}
        )
      );
    }
  };

  const handleChangePage = (event: any, newPage: number) => {
    setPage(newPage);
    setCurrentPage(newPage + 1);
  };

  const handleChangeRowsPerPage = (event: { target: { value: any } }) => {
    const value = event.target.value;
    if (value === "All") {
      setRowsPerPage(-1);
      setPage(0);
      setCurrentPage(1);
      return;
    }
    setRowsPerPage(parseInt(value, 10));
    setPage(0);
    setCurrentPage(1);
    onPageChange(page, value, 2);
  };

  useEffect(() => {
    dispatch(
      getHrStatusList(
        "",
        (res: any) => {
          setStatusList(res);
          let tempStatus = res?.filter?.(
            (item: any) => item?._id === leadStatus
          );
          setStatus(tempStatus[0]);
          setAnalysis(leadAnalysis);
          let PostData: any = {
            skip: 0,
            limit: rowsPerPage,
            status: leadStatus,
          };
          if (candidateData?.executiveId) {
            PostData["executiveId"] = candidateData?.executiveId;
          }
          if (pageName === "hrLeadAnalysis") {
            dispatch(
              getHrSubStatusList(
                leadStatus,
                (res: any) => {
                  setSubStatusList(res);
                },
                () => {}
              )
            );
          }
          if (leadAnalysis === "ALL") {
            dispatch(
              getHrCandidateStatusList(
                PostData,
                (res: any) => {
                  console.log("res", res);
                  setCount(res?.count);
                  setstudentList(res?.data);
                },
                () => {}
              )
            );
          }
        },
        () => {}
      )
    );
  }, []);

  const onChangeStatus = (val: any) => {
    setStatus(val);
    setResetStatus(false);
    setCount(0);
    setstudentList([]);
    dispatch(
      getHrSubStatusList(
        val?._id,
        (res: any) => {
          setSubStatusList(res);
        },
        () => {}
      )
    );
    const PostData: any = {
      skip: 0,
      limit: rowsPerPage,
      status: val?._id,
      executiveId: candidateData?.executiveId,
    };
    if (startDate) {
      PostData["startDate"] = startDate;
    }
    if (endDate) {
      PostData["endDate"] = endDate;
    }
    if (analysis === "ALL") {
      dispatch(
        getHrCandidateStatusList(
          PostData,
          (res: any) => {
            setCount(res?.count);
            setstudentList(res?.data);
          },
          () => {}
        )
      );
    }
  };

  const onClickLead = (data: any) => {
    dispatch(updateCommonValues("selectedLeadId", data?._id));
    dispatch(updateCommonValues("startDate", startDate));
    dispatch(updateCommonValues("endDate", endDate));
    navigate("../completeLeadDetail", {
      state: {
        // data: data ,
        pageName: "ManagerLeadAnalysis",
        status,
        // startDate,
        // endDate,
        analysis,
        page,
        rowsPerPage,
      },
    });
  };

  const renderSearchMode = () => {
    return (
      <div className="col-md-12 row d-flex pt-4">
        <div className="col-md-6 d-flex mb-3">
          <div className="col-md-3 mt-2">
            <LabelField lableName="Status" isRequired={true} />
          </div>
          <div className="col-md-9 ps-2">
            <CustomDropdown
              lableName="Select Status"
              setInputText={(value: any) => onChangeStatus(value)}
              value={status}
              options={statusList}
              reset={resetStatus}
              defaultValue={status?._id}
              disabled={true}
            />
          </div>
        </div>
      </div>
    );
  };

  const renderListMode = () => {
    return (
      <>
        {studentList?.length > 0 ? (
          <div className="d-flex paginationdiv justify-content-end align-items-center">
            <Pagination
              count={totalPages}
              size="large"
              page={currentPage}
              variant="outlined"
              shape="rounded"
              color={"primary"}
              onChange={handleChange}
            />
          </div>
        ) : null}

        {count > 0 ? (
          <PaginationView
            pagename={"Counsellor_call_Details"}
            count={count}
            tableData={studentList ? studentList : []}
            page={page}
            rowsPerPage={rowsPerPage}
            handleChangePage={handleChangePage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
            rowsPerPageOptions={getTableDataSlots(count)}
          />
        ) : null}

        <PaginationTable
          tableData={studentList ? studentList : []}
          pagename={"hrLeadDetailsAnalysis"}
          rowsPerPage={rowsPerPage}
          page={page}
          tableHead={[
            "S. No.",
            "First Name",
            "Last Name",
            // "Phone No ",
            "Candidate Registered Date",
            "",
          ]}
          handleClick={onClickLead}
        />
      </>
    );
  };

  return (
    <div className="contentBox">
      <div className="col-md-12 row">
        <div className=" formDiv formWidth p-5">
          <h3 className="fw-bold fontcolor">
            {executiveName ? executiveName : loggedInUserName}
            {}
          </h3>
          {renderSearchMode()}
          {renderListMode()}
        </div>
      </div>
    </div>
  );
}
