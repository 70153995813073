import { useEffect, useState } from "react";
import SalesPanelGraph from "../../Component/salesPanelGraph";
import { useSelector } from "react-redux";
import moment from "moment";
import RoundChart from "../../Component/roundChart";
import {
  getManagerRevenueList, // API userd for manager
  getManagerTalkTimeList, // API userd for manager
  getManagerTargetRevenueUnachieved, // API used for manager
  // getIncomingTalkTimeList,  // API not in Used
  getManagerCallRecord, // API userd for teamlead
  getSellableExamList, // API userd for teamlead
  getSellableExamListByExamID, // API userd for teamlead
  getTalkTimeList, // API userd for teamlead
  getTargetRevenueUnachieved, // API userd for teamlead
  getTeamLeadList, // API userd for teamlead
} from "../../Redux/Actions/managerPanelAction";
import LabelField from "../../Component/labelField";
import SalesPanelSellableGraph from "../../Component/salesPanelSellableGraph";
import GenaralTable from "../../Component/genaralTable";
import SalesPanelSummeryGraph from "../../Component/salesPanelSummeryGraph";
import CallRecordedGraph from "../../Component/callRecordedGraph";
import { updateCommonValues } from "../../Redux/Actions/CommonAction";
import { useAppDispatch } from "../../hooks";
import DatePicker from "../../Component/datePicker";
import SubmitButton from "../../Component/submitButton";
import SalesPanelSatckGraph from "../../Component/salesPanelStackGraph";
import { useNavigate } from "react-router-dom";

export default function ManagerDashbord() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [startDate, SetStartDate] = useState<any>(
    moment.utc(new Date()).format("YYYY-MM-DD")
  );
  const [endDate, SetEndDate] = useState<any>(
    moment.utc(new Date()).format("YYYY-MM-DD")
  );
  const [totalRevenue, SetTotalRevenue] = useState<any>("");
  const [targetStartDate, SetTargetStartDate] = useState<any>("");
  const [targetEndDate, SetTargetEndDate] = useState<any>("");
  const [xLabel, setXLabel] = useState<any>([]);
  const [Graphdata, setGraphdata1] = useState<any>([]);
  const [xLabel2, setXLabel2] = useState<any>([]);
  const [Graphdata3, setGraphdata3] = useState<any>([]);
  const [piedata, setPiedata] = useState<any>([]);
  const [xLabel3, setXLabel3] = useState<any>([]);
  const [Graphdata4, setGraphdata4] = useState<any>([]);
  const [xLabel4, setXLabel4] = useState<any>([]);
  const [Graphdata5, setGraphdata5] = useState<any>([]);
  const [show, setShow] = useState(false);
  const [ExamDataList, setExamDataList] = useState<any>([]);
  const [sellableExamListByExamID, setSellableExamListByExamID] = useState<any>(
    []
  );
  const [examId, setExamId] = useState("");
  const [talkTimeList, setTalkTimeList] = useState([]);
  const [manager, setManager] = useState<any>("");
  const [managerList, setManagerList] = useState([]);
  const [graphSellable, setGraphSellable] = useState(false);
  const [examStreamId, setExamStreamId] = useState("");

  const [xLabelCallTotal, setXLabelCallTotal] = useState<any>([]);
  const [GraphdataCallTotal, setGraphdataCallTotal] = useState<any>([]);
  const [xLabelCallAll, setXLabelCallAll] = useState<any>([]);
  const [GraphdataCallAll, setGraphdataCallAll] = useState<any>([]);
  const [xLabelCallUnique, setXLabelCallUnique] = useState<any>([]);
  const [GraphdataCallUnique, setGraphdataCallUnique] = useState<any>([]);
  const [linkList, setLinkList] = useState<any>([]);
  const [teamLeadList, setTeamLeadList] = useState<any>([]);

  const { loggedInUserId, loggedInUserRole, loggedInUserName } = useSelector(
    (state: any) => ({
      loggedInUserId: state.Auth.loggedInUserId,
      loggedInUserRole: state.Auth.loggedInUserRole,
      loggedInUserName: state.Auth.loggedInUserName,
    })
  );
  useEffect(() => {
    if (loggedInUserRole === 2) {
      setGraphSellable(true);
    }
  }, [loggedInUserRole]);

  useEffect(() => {
    dispatch(updateCommonValues("startDate", startDate));
    dispatch(updateCommonValues("endDate", endDate));
    if (loggedInUserRole === 1) {
      // API userd for manager
      // dispatch(
      //   getManagerRevenueList(
      //     {
      //       startDate,
      //       endDate,
      //     },
      //     (res: any) => {
      //       showGraph(res?.data);
      //       setTeamLeadList(res?.data);
      //     },
      //     () => {}
      //   )
      // );
      // API userd for manager
      // dispatch(
      //   getManagerTargetRevenueUnachieved(
      //     {
      //       startDate,
      //       endDate,
      //     },
      //     (res: any) => {
      //       piechartData(res?.data);
      //     },
      //     () => {}
      //   )
      // );
      // API userd for manager
      dispatch(
        getManagerTalkTimeList(
          loggedInUserId,
          {
            startDate,
            endDate,
          },
          (res: any) => {
            setTalkTimeList(res);
          },
          () => {}
        )
      );
    } else if (loggedInUserRole === 2) {
      // dispatch(
      //   getTeamLeadList(
      //     {
      //       startDate,
      //       endDate,
      //       managerId: loggedInUserId,
      //     },
      //     (res: any) => {
      //       showGraph(res?.data);
      //       let tempLinkList: any = [];
      //       for (let i = 0; i <= res?.data?.length; i++) {
      //         tempLinkList?.push("../summeryOverview");
      //       }
      //       setLinkList(tempLinkList);
      //     },
      //     () => {}
      //   )
      // );
      // dispatch(
      //   getTargetRevenueUnachieved(
      //     {
      //       managerId: loggedInUserId,
      //       startDate,
      //       endDate,
      //     },
      //     (res: any) => {
      //       piechartData(res?.data);
      //     },
      //     () => {}
      //   )
      // );
      // if (loggedInUserId === "656d99b3ffd64a92fd69a973") {
      //   dispatch(
      //     getIncomingTalkTimeList(
      //       loggedInUserId,
      //       {
      //         startDate,
      //         endDate,
      //       },
      //       (res: any) => {
      //         setTalkTimeList(res);
      //       },
      //       () => {}
      //     )
      //   );
      // }
      dispatch(
        getTalkTimeList(
          loggedInUserId,
          {
            startDate,
            endDate,
          },
          (res: any) => {
            setTalkTimeList(res);
          },
          () => {}
        )
      );

      const data = {
        startTime: startDate,
        endTime: endDate,
      };

      dispatch(
        getManagerCallRecord(
          loggedInUserId,
          data,
          (res: any) => {
            showGraphCallTotalConnected(res?.data);
            showGraphCallAllConnected(res?.data);
            showGraphCallUniqueConnected(res?.data);
            let tempLinkList: any = [];
            for (let i = 0; i <= res?.data?.length; i++) {
              tempLinkList?.push("../summeryOverview");
            }
            setLinkList(tempLinkList);
          },
          () => {}
        )
      );
      dispatch(
        getSellableExamList(
          {
            startDate,
            endDate,
          },
          (res: any) => {
            setExamDataList(res?.data);
            showGraph2(res?.data);
            // piechartData(res);
          },
          () => {}
        )
      );
    }
  }, []);

  const link2 = ["../teamShrinkageAnalysis", "../teamShrinkageAnalysis"];
  const Piechartlabels = ["Unachieved Revenue", "Achieved Revenue"];
  const link3 = ["../topSellableExams"];

  const Piechartdata = {
    labels: ["Achieved Revenue", "Unachieved Revenue"],
    datasets: [
      {
        data: piedata,
        backgroundColor: ["#1F77B4", "#F23E36"],
        percentageInnerCutout: 80,
      },
    ],
  };

  const piechartData = (list: any) => {
    let arr = [];
    let unachieved = 0;
    let achieved = 0;
    list?.map((item: any) => {
      achieved = achieved + item?.totalRevenue;
      unachieved = unachieved + item?.UnachievedRevenue;
      SetTargetStartDate(item?.data?.startDate);
      SetTargetEndDate(item?.data?.endDate);
    });
    arr?.push(achieved);
    arr?.push(unachieved);
    setPiedata(arr);
  };

  const getExamData = (val: any) => {
    if (loggedInUserRole === 1) {
      const data = {
        startDate,
        endDate,
        examTypeId: ExamDataList[val?.index]?.examTypeId,
        managerId: manager?._id,
      };
      setExamId(ExamDataList[val?.index]?.examTypeId);
      // dispatch(
      //   getSellableExamListByExamID(
      //     data,
      //     (res: any) => {
      //       setSellableExamListByExamID(res?.data);
      //       showGraph3(res?.data);
      //     },
      //     () => {}
      //   )
      // );
    } else if (loggedInUserRole === 2) {
      const data = {
        startDate,
        endDate,
        examTypeId: ExamDataList[val?.index]?.examTypeId,
      };
      setExamId(ExamDataList[val?.index]?.examTypeId);
      dispatch(
        getSellableExamListByExamID(
          data,
          (res: any) => {
            setSellableExamListByExamID(res?.data);
            showGraph3(res?.data);
          },
          () => {}
        )
      );
    }
  };

  const getExamStreamData = (val: any) => {
    setExamStreamId(sellableExamListByExamID[val?.index]?.baseCourseId);
    setExamId(ExamDataList[val?.index]?.examTypeId);
    sessionStorage.setItem(
      "ExamStreamId",
      sellableExamListByExamID[val?.index]?.baseCourseId
    );
    sessionStorage.setItem("examId", ExamDataList[val?.index]?.examTypeId);
  };

  const showGraphCallTotalConnected = (list: any) => {
    // list?.map((item: any) => {
    //   xLabelCallTotal?.push(item?.name);
    //   GraphdataCallTotal?.push(item?.totalConnectedCall?.achieved);
    // });
    setXLabelCallTotal([]);
    setGraphdataCallTotal([]);
    let labelList: any = [];
    let graphDataList: any = [];
    list?.map((item: any) => {
      labelList?.push(item?.name);
      graphDataList?.push(item?.totalConnectedCall?.achieved);
    });
    setXLabelCallTotal(labelList);
    setGraphdataCallTotal(graphDataList);
  };
  const showGraphCallAllConnected = (list: any) => {
    // list?.map((item: any) => {
    //   xLabelCallAll?.push(item?.name);
    //   GraphdataCallAll?.push(item?.oldConnectedCall?.achieved);
    // });
    setXLabelCallAll([]);
    setGraphdataCallAll([]);
    let labelList: any = [];
    let graphDataList: any = [];
    list?.map((item: any) => {
      labelList?.push(item?.name);
      graphDataList?.push(item?.oldConnectedCall?.achieved);
    });
    setXLabelCallAll(labelList);
    setGraphdataCallAll(graphDataList);
  };
  const showGraphCallUniqueConnected = (list: any) => {
    // list?.map((item: any) => {
    //   xLabelCallUnique?.push(item?.name);
    //   GraphdataCallUnique?.push(item?.uniqueConnectedCall?.achieved);
    // });
    setXLabelCallUnique([]);
    setGraphdataCallUnique([]);
    let labelList: any = [];
    let graphDataList: any = [];
    list?.map((item: any) => {
      labelList?.push(item?.name);
      graphDataList?.push(item?.uniqueConnectedCall?.achieved);
    });
    setXLabelCallUnique(labelList);
    setGraphdataCallUnique(graphDataList);
  };

  const showGraph = (list: any) => {
    let total = 0;
    while (xLabel.length) {
      xLabel.pop();
      Graphdata?.pop();
      // xLabel2.pop();
      // Graphdata3?.pop();
    }
    if (loggedInUserRole === 1) {
      list?.map((item: any) => {
        total = total + item?.Revenue;
        xLabel?.push(item?.name);
        Graphdata?.push(item?.Revenue);
        // xLabel2?.push(item?.name);
        // Graphdata3?.push(item?.status);
      });
    } else if (loggedInUserRole === 2) {
      list?.map((item: any) => {
        total = total + item?.revenue;
        xLabel?.push(item?._id?.name);
        Graphdata?.push(item?.revenue);
        // xLabel2?.push(item?._id?.name);
        // Graphdata3?.push(item?._id?.status);
      });
    }

    SetTotalRevenue(total?.toFixed(2));
  };

  const showGraph2 = (data: any) => {
    while (xLabel3.length) {
      xLabel3.pop();
      Graphdata4?.pop();
    }
    data?.map((val: any) => {
      xLabel3?.push(val?.exam);
      Graphdata4?.push(val?.revenue);
    });
  };

  const showGraph3 = (val: any) => {
    while (xLabel4.length) {
      xLabel4.pop();
      Graphdata5?.pop();
    }
    val?.map((data: any) => {
      xLabel4?.push(data?.examStream);
      Graphdata5?.push(data?.revenue);
    });
  };

  // const onChangeManager = (value: any) => {
  //   setManager(value);
  // };

  const onChangeStartDate = (val: any) => {
    SetStartDate(val);
    dispatch(updateCommonValues("startDate", val));
  };

  const onChangeEndDate = (val: any) => {
    SetEndDate(val);
    dispatch(updateCommonValues("endDate", val));
  };

  const getManagerData = (val: any) => {
    if (loggedInUserRole === 1) {
      // dispatch(updateCommonValues("selectedLeadId", tempData?._id));
      navigate("../summeryOverview", {
        state: {
          pageName: "summaryOverview",
          counsellorId: teamLeadList[val?.index]?._id,
        },
      });
    }
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    let data = {
      startDate,
      endDate,
    };
    if (loggedInUserRole === 1) {
      dispatch(
        getManagerTalkTimeList(
          loggedInUserId,
          {
            startDate,
            endDate,
          },
          (res: any) => {
            setTalkTimeList(res);
          },
          () => {}
        )
      );
      // if (manager?._id === "656d99b3ffd64a92fd69a973") {
      //   dispatch(
      //     getIncomingTalkTimeList(
      //       manager?._id,
      //       data,
      //       (res: any) => {
      //         setTalkTimeList(res);
      //       },
      //       () => {}
      //     )
      //   );
      // }
      // setGraphSellable(true);
      // dispatch(
      //   getSellableExamList(
      //     {
      //       startDate,
      //       endDate,
      //       managerId: manager?._id,
      //     },
      //     (res: any) => {
      //       setExamDataList(res?.data);
      //       showGraph2(res?.data);
      //       // piechartData(res);
      //     },
      //     () => {}
      //   )
      // );
    } else if (loggedInUserRole === 2) {
      // if (loggedInUserId === "656d99b3ffd64a92fd69a973") {
      //   dispatch(
      //     getIncomingTalkTimeList(
      //       loggedInUserId,
      //       {
      //         startDate,
      //         endDate,
      //       },
      //       (res: any) => {
      //         setTalkTimeList(res);
      //       },
      //       () => {}
      //     )
      //   );
      // }
      dispatch(
        getTalkTimeList(
          loggedInUserId,
          data,
          (res: any) => {
            setTalkTimeList(res);
          },
          () => {}
        )
      );
    }
  };

  const renderListMode = () => {
    return (
      <div className="mx-2 mt-3 col-12 formWidth shadow">
        <h4 className="fw-bold">Team Talk Time</h4>
        <form className="d-flex" onSubmit={handleSubmit}>
          <div className="col-md-3 d-flex me-2 mt-3">
            <div className="col-md-4 d-flex flex-row-reverse">
              <LabelField lableName="Start Date :" />
            </div>
            <div className="col-md-8">
              <DatePicker
                value={startDate}
                setInputText={(value: string) => onChangeStartDate(value)}
                maxdate={moment.utc(new Date()).format("YYYY-MM-DD")}
              />
            </div>
          </div>
          <div className="col-md-3 d-flex me-2 mt-3">
            <div className="col-md-4 d-flex flex-row-reverse">
              <LabelField lableName="End Date :" />
            </div>
            <div className="col-md-8">
              <DatePicker
                value={endDate}
                setInputText={(value: string) => onChangeEndDate(value)}
                maxdate={moment.utc(new Date()).format("YYYY-MM-DD")}
              />
            </div>
          </div>
          {/* {loggedInUserRole === 1 ? (
            <div className="col-md-3 d-flex mb-3 me-2 mt-3">
              <div className="col-md-4 mt-2">
                <LabelField lableName="Manager" />
              </div>
              <div className="col-md-8 pe-2">
                <CustomDropdown
                  lableName="Select Manager"
                  setInputText={(value: any) => onChangeManager(value)}
                  value={manager}
                  options={managerList}
                />
              </div>
            </div>
          ) : null} */}
          <div className="col-md-3 text-center mt-3">
            <SubmitButton name={"Submit"} />
          </div>
        </form>

        <GenaralTable
          tableData={talkTimeList ? talkTimeList : []}
          pagename={"managerDashboard"}
          tableHead={[
            "Name",
            "Total",
            // "12AM-1AM",
            // "1AM-2AM",
            // "2AM-3AM",
            // "3AM-4AM",
            // "4AM-5AM",
            // "5AM-6AM",
            // "6AM-7AM",
            "7AM-8AM",
            "8AM-9AM",
            "9AM-10AM",
            "10AM-11AM",
            "11AM-12PM",
            "12PM-1PM",
            "1PM-2PM",
            "2PM-3PM",
            "3PM-4PM",
            "4PM-5PM",
            "5PM-6PM",
            "6PM-7PM",
            "7PM-8PM",
            "8PM-9PM",
            "9PM-10PM",
            "10PM-11PM",
            "11PM-12AM",
          ]}
        />
      </div>
    );
  };
  return (
    <div className="contentBox">
      <div className="col-md-12 row">
        <div className="formWidth p-4">
          <h3 className="fw-bold text-primary">Hi,{loggedInUserName}</h3>
          <h5 className="fw-bold text-primary">Today's Dashboard</h5>
          <h6>Date: {moment.utc(startDate).format("DD-MM-YYYY")}</h6>

          {/* <div className="d-flex justify-content-between mx-2 mt-0 shadow">
            <div className="col-md-8 p-3">
              <h4 className="fw-bold">Summary Overview </h4>
              <span className="fw-bold">Total Revenue : {totalRevenue}</span>
              {loggedInUserRole === 1 ? (
                <SalesPanelSatckGraph
                  label={xLabel}
                  value={Graphdata}
                  getManagerData={(val: any) => getManagerData(val)}
                />
              ) : (
                <SalesPanelSummeryGraph
                  label={xLabel}
                  value={Graphdata}
                  link={linkList}
                />
              )}
            </div>
            <div className="col-md-4 p-3 divBackground  ">
              <h4 className="fw-bold">Revenue Achieved</h4>
              {targetStartDate && targetEndDate ? (
                <div className="d-flex">
                  <span className="me-2">
                    Start Date -{" "}
                    {moment(targetStartDate).utc().format("DD-MM-YYYY")}
                  </span>
                  <span>
                    End Date -{" "}
                    {moment(targetEndDate).utc().format("DD-MM-YYYY")}
                  </span>
                </div>
              ) : null}

              <p>Out Of</p>

              <div className=" d-flex justify-content-center">
                <RoundChart data={Piechartdata} />
              </div>
            </div>
          </div> */}

          {/* {loggedInUserRole === 1 ? (
            <div className="col-md-4 d-flex mb-3 pe-3 mt-3">
              <div className="col-md-4 mt-2">
                <LabelField lableName="Manager" />
              </div>
              <div className="col-md-8 pe-2">
                <CustomDropdown
                  lableName="Select Manager"
                  setInputText={(value: any) => onChangeManager(value)}
                  value={manager}
                  options={managerList}
                />
              </div>
            </div>
          ) : null} */}

          {loggedInUserRole === 2 ? (
            <>
              <div className="d-flex justify-content-between mx-5 mt-3 boxshadow">
                <div className="col-md-12 p-3">
                  <h4 className="fw-bold">Total Connected calls</h4>
                  {/* <span className="fw-bold">Total Revenue : {totalRevenue}</span> */}
                  <CallRecordedGraph
                    label={xLabelCallTotal}
                    value={GraphdataCallTotal}
                  />
                </div>
              </div>

              <div className="d-flex justify-content-between mx-5 mt-3 boxshadow">
                <div className="col-md-12 p-3">
                  <h4 className="fw-bold">Old Connected calls</h4>
                  {/* <span className="fw-bold">Total Revenue : {totalRevenue}</span> */}
                  <CallRecordedGraph
                    label={xLabelCallAll}
                    value={GraphdataCallAll}
                  />
                </div>
              </div>

              <div className="d-flex justify-content-between mx-5 mt-3 boxshadow">
                <div className="col-md-12 p-3">
                  <h4 className="fw-bold">Unique Connected calls</h4>
                  {/* <span className="fw-bold">Total Revenue : {totalRevenue}</span> */}
                  <CallRecordedGraph
                    label={xLabelCallUnique}
                    value={GraphdataCallUnique}
                    link={linkList}
                  />
                </div>
              </div>
            </>
          ) : null}

          {renderListMode()}

          {graphSellable ? (
            <div className="d-flex justify-content-between mx-5 mt-3 boxshadow">
              <div className="col-md-6 p-3">
                <h4 className="fw-bold">Top Sellable Exam</h4>
                <SalesPanelSellableGraph
                  label={xLabel3}
                  value={Graphdata4}
                  getExamData={(val: any) => [getExamData(val), setShow(true)]}
                />
              </div>
              {show ? (
                <div className="col-md-6 p-3">
                  <h4 className="fw-bold"> Top Sellable Exam Stream</h4>
                  <div className=" d-flex justify-content-center">
                    <SalesPanelGraph
                      label={xLabel4}
                      value={Graphdata5}
                      link={link3}
                      sDate={startDate}
                      eDate={endDate}
                      examId={examId}
                      managerId={manager?._id}
                      examStreamId={examStreamId}
                      getExamStreamData={(val: any) => getExamStreamData(val)}
                    />
                  </div>
                </div>
              ) : null}
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
}
