import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import moment from "moment";
import DatePicker from "../../Component/datePicker";
import LabelField from "../../Component/labelField";
import PageTitle from "../../Component/pageTitle";
import SubmitButton from "../../Component/submitButton";
import {
  getDailyIVRCallsDetails,
  getDailyIVROutCallsDetails,
  updateNotConnectedCounsellorCalls,
} from "../../Redux/Actions/managerPanelAction";
import { updateCommonValues } from "../../Redux/Actions/CommonAction";
import { showSuccessSnackbar } from "../../Redux/Actions/snackbarAction";
import { useAppDispatch } from "../../hooks";
import { useLocation, useNavigate } from "react-router-dom";

const initialVal: any = {
  newCourseEnquiry: [],
  serviceEnquiry: [],
  technicalSupportEnquiry: [],
};

const initialCallStatus: any = {
  answered: 0,
  notAnswered: 0,
};

const initialOutgoingVal: any = {
  incomingTeam: [],
  technicalServiceTeam: [],
};

export default function IVRCalls() {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const [startDate, setStartDate] = useState<any>(
    moment.utc(new Date()).format("YYYY-MM-DD")
  );
  const [endDate, setEndDate] = useState<any>(
    moment.utc(new Date()).format("YYYY-MM-DD")
  );

  const [ivrDetails, setIVRDetails] = useState<any>([]);
  const [ivrOutgoingDetails, setIVROutgoingDetails] = useState<any>([]);

  const [totalCallStatus, setTotalCallStatus] =
    useState<any>(initialCallStatus);
  const [callStatusList, setCallStatusList] = useState<any>([]);

  const [totalOutgoingCallStatus, setTotalOutgoingCallStatus] =
    useState<any>(initialCallStatus);
  const [outgoingCallStatusList, setOutgoingCallStatusList] = useState<any>([]);

  const [dataArr, setDataArr] = useState<any>(initialVal);
  const [outgoingDataArr, setOutgoingDataArr] =
    useState<any>(initialOutgoingVal);

  const [IVRtype, setIVRType] = useState<any>("");
  const [IVROutgoingtype, setIVROutgoingType] = useState<any>("");
  const [counsellorList, setCounsellorList] = useState<any>([]);
  const [isViewMode, setIsViewMode] = useState<any>(false);

  useEffect(() => {
    displayIVRDetails();
  }, []);

  const displayIVRDetails = () => {
    dispatch(
      getDailyIVRCallsDetails(
        {
          startDate,
          endDate,
        },
        (res: any) => {
          let tempData = {
            newCourseEnquiry: 0,
            technicalSupportEnquiry: 0,
            serviceEnquiry: 0,
          };

          tempData.newCourseEnquiry = res?.uui_1?.length;
          tempData.serviceEnquiry = res?.uui_2?.length;
          tempData.technicalSupportEnquiry = res?.uui_3?.length;
          setIVRDetails(tempData);

          const newCourseEnquiry: any = res?.uui_1;
          const serviceEnquiry: any = res?.uui_2;
          const technicalSupportEnquiry: any = res?.uui_3;
          setDataArr({
            newCourseEnquiry,
            serviceEnquiry,
            technicalSupportEnquiry,
          });
        },
        () => {}
      )
    );
    dispatch(
      getDailyIVROutCallsDetails(
        {
          startDate,
          endDate,
        },
        (res: any) => {
          let tempData = {
            incomingTeam: 0,
            technicalServiceTeam: 0,
          };

          tempData.incomingTeam = res?.role_5?.length;
          tempData.technicalServiceTeam = res?.role_8?.length;
          setIVROutgoingDetails(tempData);

          const incomingTeam: any = res?.role_5;
          const technicalServiceTeam: any = res?.role_8;
          setOutgoingDataArr({
            incomingTeam,
            technicalServiceTeam,
          });
        },
        () => {}
      )
    );
  };

  const onClickLead = (data: any) => {
    setIVRType(data);
    if (data === "newCourseEnquiry") {
      const newCourseEnquiry = calculateTotals(dataArr?.newCourseEnquiry);
      setTotalCallStatus(newCourseEnquiry);
      let result = calculateByCounsellor(dataArr?.newCourseEnquiry);
      setCallStatusList(result);
    } else if (data === "serviceEnquiry") {
      const serviceEnquiry = calculateTotals(dataArr?.serviceEnquiry);
      setTotalCallStatus(serviceEnquiry);
      let result = calculateByCounsellor(dataArr?.serviceEnquiry);
      setCallStatusList(result);
    } else if (data === "technicalSupportEnquiry") {
      const technicalSupportEnquiry = calculateTotals(
        dataArr?.technicalSupportEnquiry
      );
      setTotalCallStatus(technicalSupportEnquiry);
      let result = calculateByCounsellor(dataArr?.technicalSupportEnquiry);
      setCallStatusList(result);
    }
  };

  const onClickOutLead = (data: any) => {
    setIVROutgoingType(data);
    if (data === "incomingTeam") {
      const incomingTeam = calculateTotals(outgoingDataArr?.incomingTeam);
      setTotalOutgoingCallStatus(incomingTeam);
      let result = calculateByCounsellor(outgoingDataArr?.incomingTeam);
      setOutgoingCallStatusList(result);
    } else if (data === "technicalServiceTeam") {
      const technicalServiceTeam = calculateTotals(
        outgoingDataArr?.technicalServiceTeam
      );
      setTotalOutgoingCallStatus(technicalServiceTeam);
      let result = calculateByCounsellor(outgoingDataArr?.technicalServiceTeam);
      setOutgoingCallStatusList(result);
    }
  };

  const calculateTotals = (items: any) => {
    return items.reduce(
      (acc: any, item: any) => {
        if (item?.call_status === "Answered") {
          acc.answered += 1 || 0;
        } else if (item?.call_status === "NotAnswered") {
          acc.notAnswered += 1 || 0;
        }
        return acc;
      },
      {
        answered: 0,
        notAnswered: 0,
      }
    );
  };

  const calculateByCounsellor = (data: any) => {
    const groupedData: any = {};
    data.forEach((item: any) => {
      const counsellorId = item.CounsellorId || 0;
      if (!groupedData[counsellorId]) {
        groupedData[counsellorId] = {
          CounsellorName: item.CounsellorName || "Not Connected to Counsellor",
          CounsellorId: counsellorId,
          Answered: 0,
          NotAnswered: 0,
        };
      }

      if (item.call_status === "Answered") {
        groupedData[counsellorId].Answered += 1;
      } else if (item.call_status === "NotAnswered") {
        groupedData[counsellorId].NotAnswered += 1;
      }
    });

    const result = Object.values(groupedData);
    return result;
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    displayIVRDetails();
  };

  const onClickIncoming = (data: any, callStatus: any) => {
    setIsViewMode(true);

    if (IVRtype === "newCourseEnquiry") {
      if (data?.CounsellorId) {
        let tempData = dataArr?.newCourseEnquiry?.filter((item: any) => {
          if (
            item?.CounsellorId === data?.CounsellorId &&
            item?.call_status === callStatus
          ) {
            return item;
          }
        });
        setCounsellorList(tempData);
      } else {
        let tempData = dataArr?.newCourseEnquiry?.filter((item: any) => {
          if (!item?.CounsellorId && item?.call_status === callStatus) {
            return item;
          }
        });
        setCounsellorList(tempData);
      }
    } else if (IVRtype === "serviceEnquiry") {
      if (data?.CounsellorId) {
        let tempData = dataArr?.serviceEnquiry?.filter((item: any) => {
          if (
            item?.CounsellorId === data?.CounsellorId &&
            item?.call_status === callStatus
          ) {
            return item;
          }
        });
        setCounsellorList(tempData);
      } else {
        let tempData = dataArr?.serviceEnquiry?.filter((item: any) => {
          if (!item?.CounsellorId && item?.call_status === callStatus) {
            return item;
          }
        });
        setCounsellorList(tempData);
      }
    } else if (IVRtype === "technicalSupportEnquiry") {
      if (data?.CounsellorId) {
        let tempData = dataArr?.technicalSupportEnquiry?.filter((item: any) => {
          if (
            item?.CounsellorId === data?.CounsellorId &&
            item?.call_status === callStatus
          ) {
            return item;
          }
        });
        setCounsellorList(tempData);
      } else {
        let tempData = dataArr?.technicalSupportEnquiry?.filter((item: any) => {
          if (!item?.CounsellorId && item?.call_status === callStatus) {
            return item;
          }
        });
        setCounsellorList(tempData);
      }
    }
  };

  const onClickOutgoing = (data: any, callStatus: any) => {
    setIsViewMode(true);

    if (IVROutgoingtype === "incomingTeam") {
      if (data?.CounsellorId) {
        let tempData = outgoingDataArr?.incomingTeam?.filter((item: any) => {
          if (
            item?.CounsellorId === data?.CounsellorId &&
            item?.call_status === callStatus
          ) {
            return item;
          }
        });
        setCounsellorList(tempData);
      } else {
        let tempData = outgoingDataArr?.incomingTeam?.filter((item: any) => {
          if (!item?.CounsellorId && item?.call_status === callStatus) {
            return item;
          }
        });
        setCounsellorList(tempData);
      }
    } else if (IVROutgoingtype === "technicalServiceTeam") {
      if (data?.CounsellorId) {
        let tempData = outgoingDataArr?.technicalServiceTeam?.filter(
          (item: any) => {
            if (
              item?.CounsellorId === data?.CounsellorId &&
              item?.call_status === callStatus
            ) {
              return item;
            }
          }
        );
        setCounsellorList(tempData);
      } else {
        let tempData = outgoingDataArr?.technicalServiceTeam?.filter(
          (item: any) => {
            if (!item?.CounsellorId && item?.call_status === callStatus) {
              return item;
            }
          }
        );
        setCounsellorList(tempData);
      }
    }
  };

  const updateNotConnectedCalls = (IVRtype: any) => {
    let postData: any = {};
    if (IVRtype === "newCourseEnquiry") {
      postData = {
        agentId: "SWATIB",
        UUI: "1",
      };
    } else if (IVRtype === "serviceEnquiry") {
      postData = {
        agentId: "RAVINAW",
        UUI: "2",
      };
    } else if (IVRtype === "technicalSupportEnquiry") {
      postData = {
        agentId: "ASHISHJ",
        UUI: "3",
      };
    }
    dispatch(
      updateNotConnectedCounsellorCalls(
        postData,
        () => {},
        () => {}
      )
    );
  };

  const renderIVRListMode = () => {
    return (
      <>
        <hr className="hr"></hr>
        <div className="shadow rounded pb-5">
          <div>
            <div className="mt-2">
              <h4 className="p-2 text-center fw-bold">Incoming Calls</h4>
              <div className="d-flex justify-content-between">
                <div className="col-4">
                  <div
                    className={`d-flex justify-content-center p-2 rounded m-2 border shadow cursor ${
                      IVRtype === "newCourseEnquiry" ? "border-success" : ""
                    }`}
                  >
                    <div
                      className="text-center px-5"
                      onClick={() => {
                        onClickLead("newCourseEnquiry");
                      }}
                    >
                      <p className="fw-bold font24">New Course Enquiry</p>
                      <p className="fw-bold text-success font24">
                        {ivrDetails?.newCourseEnquiry
                          ? ivrDetails?.newCourseEnquiry
                          : 0}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-4">
                  <div
                    className={`d-flex justify-content-center p-2 rounded m-2 border shadow cursor ${
                      IVRtype === "serviceEnquiry" ? "border-success" : ""
                    }`}
                  >
                    <div
                      className="text-center px-5"
                      onClick={() => {
                        onClickLead("serviceEnquiry");
                      }}
                    >
                      <p className="fw-bold font24">Service Enquiry</p>
                      <p className="fw-bold text-success font24">
                        {ivrDetails?.serviceEnquiry
                          ? ivrDetails?.serviceEnquiry
                          : 0}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-4">
                  <div
                    className={`d-flex justify-content-center p-2 rounded m-2 border shadow cursor ${
                      IVRtype === "technicalSupportEnquiry"
                        ? "border-success"
                        : ""
                    }`}
                  >
                    <div
                      className="text-center px-5"
                      onClick={() => {
                        onClickLead("technicalSupportEnquiry");
                      }}
                    >
                      <p className="fw-bold font24">Technical Enquiry</p>
                      <p className="fw-bold text-success font24">
                        {ivrDetails?.technicalSupportEnquiry
                          ? ivrDetails?.technicalSupportEnquiry
                          : 0}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {IVRtype ? (
              <div className="d-flex justify-content-end mx-2">
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={() => updateNotConnectedCalls(IVRtype)}
                >
                  Update Not Connected
                </button>
              </div>
            ) : null}

            <div className="mx-0">
              <div className="mt-2 ">
                <div className="px-2">
                  <table className="w-100">
                    <thead>
                      <tr className="border-dark border text-dark">
                        <th
                          className="border-dark border"
                          rowSpan={2}
                          style={{ width: "3rem" }}
                        >
                          S.R.
                        </th>
                        <th
                          className="border-dark border"
                          rowSpan={2}
                          style={{ width: "10rem" }}
                        >
                          COUNSELLOR
                        </th>
                        <th
                          className="border-dark border"
                          style={{ width: "5rem" }}
                        >
                          ANSWERED
                        </th>
                        <th
                          className="border-dark border"
                          style={{ width: "5rem" }}
                        >
                          NOT ANSWERED
                        </th>
                      </tr>
                      <tr className="text-dark">
                        <th className="border-dark border bg-white text-dark">
                          {totalCallStatus?.answered}
                        </th>
                        <th className="border-dark border bg-white text-dark">
                          {totalCallStatus?.notAnswered}
                        </th>
                      </tr>
                    </thead>
                    <tbody className="border-dark border text-dark">
                      {callStatusList?.map((item: any, index: number) => {
                        return (
                          <>
                            <tr>
                              <td className="border-dark border bg-white text-dark">
                                {index + 1}
                              </td>
                              <td className="border-dark border bg-white text-dark">
                                {item?.CounsellorName}
                              </td>
                              <td
                                className="border-dark border bg-white text-dark cursor"
                                onClick={() => {
                                  onClickIncoming(item, "Answered");
                                }}
                              >
                                {item?.Answered}
                              </td>
                              <td
                                className="border-dark border bg-white text-dark cursor"
                                onClick={() => {
                                  onClickIncoming(item, "NotAnswered");
                                }}
                              >
                                {item?.NotAnswered}
                              </td>
                            </tr>
                          </>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <hr />
          <div>
            <div className="mt-2">
              <h4 className="p-2 text-center fw-bold">Outgoing Calls</h4>
              <div className="d-flex justify-content-between">
                <div className="col-6">
                  <div className="d-flex justify-content-center p-2 rounded m-2 border shadow cursor">
                    <div
                      className="text-center px-5"
                      onClick={() => {
                        onClickOutLead("incomingTeam");
                      }}
                    >
                      <p className="fw-bold font24">Incoming Team</p>
                      <p className="fw-bold text-success font24">
                        {ivrOutgoingDetails?.incomingTeam
                          ? ivrOutgoingDetails?.incomingTeam
                          : 0}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-6">
                  <div className="d-flex justify-content-center p-2 rounded m-2 border shadow cursor">
                    <div
                      className="text-center px-5"
                      onClick={() => {
                        onClickOutLead("technicalServiceTeam");
                      }}
                    >
                      <p className="fw-bold font24">Technical / Service Team</p>
                      <p className="fw-bold text-success font24">
                        {ivrOutgoingDetails?.technicalServiceTeam
                          ? ivrOutgoingDetails?.technicalServiceTeam
                          : 0}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="mx-0">
              <div className="mt-2 ">
                <div className="px-2">
                  <table className="w-100">
                    <thead>
                      <tr className="border-dark border text-dark">
                        <th
                          className="border-dark border"
                          rowSpan={2}
                          style={{ width: "3rem" }}
                        >
                          S.R.
                        </th>
                        <th
                          className="border-dark border"
                          rowSpan={2}
                          style={{ width: "10rem" }}
                        >
                          COUNSELLOR
                        </th>
                        <th
                          className="border-dark border"
                          style={{ width: "5rem" }}
                        >
                          ANSWERED
                        </th>
                        <th
                          className="border-dark border"
                          style={{ width: "5rem" }}
                        >
                          NOT ANSWERED
                        </th>
                      </tr>
                      <tr className="text-dark">
                        <th className="border-dark border bg-white text-dark">
                          {totalOutgoingCallStatus?.answered}
                        </th>
                        <th className="border-dark border bg-white text-dark">
                          {totalOutgoingCallStatus?.notAnswered}
                        </th>
                      </tr>
                    </thead>
                    <tbody className="border-dark border text-dark">
                      {outgoingCallStatusList?.map(
                        (item: any, index: number) => {
                          return (
                            <>
                              <tr>
                                <td className="border-dark border bg-white text-dark">
                                  {index + 1}
                                </td>
                                <td className="border-dark border bg-white text-dark">
                                  {item?.CounsellorName}
                                </td>
                                <td
                                  className="border-dark border bg-white text-dark cursor"
                                  onClick={() => {
                                    onClickOutgoing(item, "Answered");
                                  }}
                                >
                                  {item?.Answered}
                                </td>
                                <td
                                  className="border-dark border bg-white text-dark cursor"
                                  onClick={() => {
                                    onClickOutgoing(item, "NotAnswered");
                                  }}
                                >
                                  {item?.NotAnswered}
                                </td>
                              </tr>
                            </>
                          );
                        }
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };

  const renderSearchMode = () => {
    return (
      <form className="row d-flex" onSubmit={handleSubmit}>
        <div className="col-md-3 d-flex mb-3 ">
          <div className="col-md-3 mt-2">
            <LabelField lableName="Start Date" />
          </div>
          <div className="col-md-9">
            <DatePicker
              value={startDate}
              setInputText={(value: string) => setStartDate(value)}
              disabled={isViewMode}
            />
          </div>
        </div>
        <div className="col-md-3 d-flex mb-3">
          <div className="col-md-3 mt-2">
            <LabelField lableName="End Date" />
          </div>
          <div className="col-md-9">
            <DatePicker
              value={endDate}
              setInputText={(value: string) => setEndDate(value)}
              disabled={isViewMode}
            />
          </div>
        </div>
        <div className="col-md-3 d-flex justify-content-between mb-3">
          <div className="col-md-6 me-2">
            <SubmitButton name={"Search"} validation={isViewMode} />
          </div>
        </div>
      </form>
    );
  };

  const handleOnClick = (value: any, type: string) => {
    console?.log("valuesss", value);
    // dispatch(updateCommonValues("selectedLeadId", value?.studentLeadId));
    // if (type === "view") {
    //   navigate("../completeLeadDetail", {
    //     state: {
    //       pageName: "IVRCalls",
    //     },
    //   });
    // }
  };

  const renderListMode = () => {
    return (
      <div className="mt-2">
        <table className="w-100">
          <thead>
            <tr className="border-dark border text-dark">
              <th className="border-dark border" style={{ width: "3rem" }}>
                S.R.
              </th>
              <th className="border-dark border" style={{ width: "10rem" }}>
                COUNSELLOR NAME
              </th>
              <th className="border-dark border" style={{ width: "5rem" }}>
                CALLER NAME
              </th>
              <th className="border-dark border" style={{ width: "5rem" }}>
                CALL TYPE
              </th>
              <th className="border-dark border" style={{ width: "10rem" }}>
                CALL DURATION
              </th>
              <th className="border-dark border" style={{ width: "5rem" }}>
                CALL STATUS
              </th>
              <th className="border-dark border" style={{ width: "10rem" }}>
                Call Recording
              </th>
              {/* <th className="border-dark border" style={{ width: "10rem" }}>
                Action
              </th> */}
            </tr>
          </thead>
          <tbody className="border-dark border text-dark">
            {counsellorList?.map((item: any, index: number) => {
              return (
                <>
                  <tr className="cursor">
                    <td className="border-dark border bg-white text-dark">
                      {index + 1}
                    </td>
                    <td className="border-dark border bg-white text-dark">
                      {item?.CounsellorName}
                    </td>
                    <td className="border-dark border bg-white text-dark">
                      {item?.CallerName}
                    </td>
                    <td className="border-dark border bg-white text-dark">
                      {item?.Type}
                    </td>
                    <td className="border-dark border bg-white text-dark">
                      {item?.Duration}
                    </td>
                    <td className="border-dark border bg-white text-danger">
                      {item?.call_status}
                    </td>
                    <td className="border-dark border bg-white text-dark">
                      <audio
                        className="ps-4 mb-0"
                        src={item?.AudioFile}
                        controls
                      ></audio>
                    </td>
                    {/* <td className="border-dark border bg-white text-dark">
                      <button
                        className={`btn btn-primary`}
                        onClick={() => handleOnClick(item, "view")}
                      >
                        View
                      </button>
                    </td> */}
                  </tr>
                </>
              );
            })}
          </tbody>
        </table>
      </div>
    );
  };

  const handleGoback = () => {
    setIsViewMode(false);
  };

  return (
    <div className="contentBox">
      <PageTitle
        name={"IVR Calls"}
        isGoBack={isViewMode}
        onClick={handleGoback}
      />
      <div className="row">
        <div className="formWidth">
          {renderSearchMode()}
          {!isViewMode ? renderIVRListMode() : renderListMode()}
        </div>
      </div>
    </div>
  );
}
