import "../App.css";
import { useDispatch } from "react-redux";
import {
  ReactElement,
  JSXElementConstructor,
  ReactNode,
  ReactPortal,
  Key,
  useState,
} from "react";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import DeleteIcon from "@mui/icons-material/Delete";
import moment from "moment";
import { useSelector } from "react-redux";
import VisibilityIcon from "@mui/icons-material/Visibility";

interface props {
  tableData: any;
  tableHead: any;
  handleEdit?: any;
  handleEditBatch?: any;
  handleDelete?: any;
  pagename?: any;
  edit?: any;
  type?: any;
  className?: any;
  addOnButton?: any;
  handleAddOnButton?: any;
  addOnButtonLabel?: any;
  handleCheck?: any;
  handleAllCheck?: any;
  isSelectedCheckbox?: any;
  adddeleteButton?: any;
  page?: any;
  rowsPerPage?: any;
  handleCancelButton?: any;
  handleEndLiveButton?: any;
  handleRescheduleButton?: any;
  addOnButtonClass?: any;
  handleToggle?: any;
  handleNavigate?: any;
  selectedIndex?: any;
  handleClick?: any;
  handleApprove?: any;
  handleCancel?: any;
}

function PaginationTable(props: props) {
  const [toggle, setToggle] = useState(false);
  const [attendanceCheck, setAttendanceCheck] = useState(0);

  const {
    tableData,
    tableHead,
    page,
    rowsPerPage,
    pagename,
    handleCancelButton,
    handleEndLiveButton,
    handleRescheduleButton,
    edit,
    handleEdit,
    handleDelete,
    handleCheck,
    handleAllCheck,
    isSelectedCheckbox,
    addOnButton,
    handleAddOnButton,
    addOnButtonLabel,
    addOnButtonClass,
    handleToggle,
    handleNavigate,
    handleClick,
    handleApprove,
    handleCancel,
  } = props;
  const dispatch = useDispatch();

  const { loggedInUserRole, crmEmployeeId } = useSelector((state: any) => ({
    loggedInUserRole: state.Auth.loggedInUserRole,
    crmEmployeeId: state.Auth.counsellorId,
  }));

  let temp = 0;
  temp = page * rowsPerPage + 1;

  console.log("page", page);
  console.log("temp", temp);

  const OnClickHandle = (data: any) => {
    if (
      pagename === "newOpportunity" ||
      pagename === "reEnquired" ||
      pagename === "enrolledLead" ||
      pagename === "overdueProspect" ||
      pagename === "notInterested" ||
      pagename === "expired" ||
      pagename === "cnrLead" ||
      pagename === "leadInvalid" ||
      pagename === "alreadyAdmissionDone" ||
      pagename === "prospectLead" ||
      pagename === "interested" ||
      pagename === "callBackLead" ||
      pagename === "overdueCallback" ||
      pagename === "overdueCNR" ||
      pagename === "overdueInterested" ||
      pagename === "welcomeCallDone" ||
      pagename === "welcomeCallReference" ||
      pagename === "followUp" ||
      pagename === "interestedForUpselling" ||
      pagename === "prospectForUpselling" ||
      pagename === "technicalIssue" ||
      pagename === "finalCNR" ||
      pagename === "summary" ||
      pagename === "Counsellor_call_Details" ||
      pagename === "hrLeadDetailsAnalysis" ||
      pagename === "LeadGenerationOverview" ||
      pagename === "EnrolledAnalysis" ||
      pagename === "courseAnalysis" ||
      pagename === "ManagerRemarkTeamLead" ||
      pagename === "referenceLeadsList" ||
      pagename === "ManagerRemarkExecutive" ||
      pagename === "salesDirectorManager" ||
      pagename === "salesDirectorTeamLead" ||
      pagename === "resumeShared" ||
      pagename === "firstRoundInterview" ||
      pagename === "secondRoundInterview" ||
      pagename === "hrRoundInterview" ||
      pagename === "rejected" ||
      pagename === "joiningToday" ||
      pagename === "joiningInWeek" ||
      pagename === "callingDone" ||
      pagename === "orderLeadsList" ||
      pagename === "candidateStatusRecords"
    ) {
      handleClick(data);
    }
  };

  const OnClickTableData = (data: any) => {
    if (
      pagename === "counsellorRemark" ||
      pagename === "teamLeadRemark" ||
      pagename === "ManagerRemark"
    ) {
      handleClick(data);
    }
  };

  const getCourseArrayList = (array: any) => {
    var tempArray = array.map((item: any) => {
      return <li>{item?.name}</li>;
    });
    return <td className="col-2 cursor">{tempArray}</td>;
  };

  const renderStatusButton = (item: any) => {
    if (item.status === 1) {
      return (
        <div className="position-relative statusbtn">
          <button
            className="add_Question-btn btn btn-outline-success btn-sm me-4"
            onClick={() =>
              handleAddOnButton(
                item._id,
                pagename === "raiseTeacherIssue"
                  ? item?.adminUser
                  : item?.userId || item.teacherId,
                item.status,
                pagename === "raiseIssue" ||
                  pagename === "raiseTeacherIssue" ||
                  pagename === "InfraIssue"
                  ? item?.queryNo
                  : ""
              )
            }
          >
            New
          </button>
          {item?.unreadMsgCountAdmin > 0 && (
            <div className="unreadcount bg-success">
              {item?.unreadMsgCountAdmin}
            </div>
          )}
        </div>
      );
    }

    if (item.status === 2) {
      const isDifferentDay =
        moment.utc(item.createdAt).format("DD-MM-YYYY") !==
        moment.utc(new Date()).format("DD-MM-YYYY");

      return (
        <div className="position-relative statusbtn">
          <button
            className="add_Question-btn btn btn-outline-primary btn-sm me-4"
            onClick={() =>
              handleAddOnButton(
                item._id,
                item.userId || item.teacherId,
                item.status,
                pagename === "raiseIssue" ||
                  pagename === "raiseTeacherIssue" ||
                  pagename === "InfraIssue"
                  ? item?.queryNo
                  : ""
              )
            }
          >
            {isDifferentDay ? "InProgress" : "Open"}
          </button>
          {item?.unreadMsgCountAdmin > 0 && (
            <div className="unreadcount bg-success">
              {item?.unreadMsgCountAdmin}
            </div>
          )}
        </div>
      );
    }

    if (item.status === 3) {
      return (
        <div className="position-relative statusbtn">
          <button
            className="add_Question-btn btn btn-outline-success btn-sm me-4"
            onClick={() =>
              handleAddOnButton(
                item._id,
                item.userId || item.teacherId,
                item.status,
                pagename === "raiseIssue" || pagename === "raiseTeacherIssue"
                  ? item?.queryNo
                  : ""
              )
            }
          >
            View
          </button>
        </div>
      );
    }

    return null;
  };

  const renderTableRows = () => {
    return tableData?.map((item: any, index: any) => {
      temp++;
      return (
        <tr
          key={item._id}
          className={
            pagename === "newOpportunity" ||
            pagename === "reEnquired" ||
            pagename === "enrolledLead" ||
            pagename === "overdueProspect" ||
            pagename === "notInterested" ||
            pagename === "expired" ||
            pagename === "cnrLead" ||
            pagename === "leadInvalid" ||
            pagename === "alreadyAdmissionDone" ||
            pagename === "prospectLead" ||
            pagename === "interested" ||
            pagename === "callBackLead" ||
            pagename === "overdueCallback" ||
            pagename === "overdueCNR" ||
            pagename === "welcomeCallDone" ||
            pagename === "welcomeCallReference" ||
            pagename === "followUp" ||
            pagename === "interestedForUpselling" ||
            pagename === "prospectForUpselling" ||
            pagename === "technicalIssue" ||
            pagename === "finalCNR" ||
            pagename === "Counsellor_call_Details" ||
            pagename === "hrLeadDetailsAnalysis" ||
            pagename === "EnrolledAnalysis" ||
            pagename === "courseAnalysis" ||
            pagename === "ManagerRemarkTeamLead" ||
            pagename === "ManagerRemarkExecutive" ||
            pagename === "salesDirectorManager" ||
            pagename === "salesDirectorTeamLead" ||
            pagename === "resumeShared" ||
            pagename === "firstRoundInterview" ||
            pagename === "secondRoundInterview" ||
            pagename === "hrRoundInterview" ||
            pagename === "rejected" ||
            pagename === "joiningToday" ||
            pagename === "joiningInWeek" ||
            pagename === "callingDone" ||
            pagename === "candidateStatusRecords"
              ? "cursor"
              : ""
          }
          onClick={() => OnClickHandle(item)}
        >
          {pagename === "LeadTransfer" ? (
            item?._id ? (
              <td>
                <span>
                  <input
                    id={item?._id}
                    className="check"
                    type="checkbox"
                    checked={item.isSelected}
                    onChange={(item) => {
                      handleCheck(item, index);
                    }}
                  />
                </span>
              </td>
            ) : (
              <td>-</td>
            )
          ) : null}

          {pagename === "createImportLeads" ||
          pagename === "Employee" ||
          pagename === "HrEmployee" ||
          pagename === "LeadAssignment" ||
          pagename === "SkipDetails" ||
          pagename === "LeadTransfer" ||
          pagename === "IncentiveAllocation" ||
          pagename === "IncentiveTargetAllocation" ||
          pagename === "overdueProspect" ||
          pagename === "newOpportunity" ||
          pagename === "reEnquired" ||
          pagename === "enrolledLead" ||
          pagename === "notInterested" ||
          pagename === "expired" ||
          pagename === "cnrLead" ||
          pagename === "leadInvalid" ||
          pagename === "alreadyAdmissionDone" ||
          pagename === "prospectLead" ||
          pagename === "interested" ||
          pagename === "callBackLead" ||
          pagename === "overdueCallback" ||
          pagename === "overdueCNR" ||
          pagename === "overdueInterested" ||
          pagename === "welcomeCallDone" ||
          pagename === "welcomeCallReference" ||
          pagename === "followUp" ||
          pagename === "interestedForUpselling" ||
          pagename === "prospectForUpselling" ||
          pagename === "technicalIssue" ||
          pagename === "finalCNR" ||
          pagename === "AllCallList" ||
          pagename === "CallList" ||
          pagename === "IncomingCallList" ||
          pagename === "SkipDetails" ||
          pagename === "CouponCode" ||
          pagename === "leadRecord" ||
          pagename === "LeadGenerationOverview" ||
          pagename === "courseAnalysis" ||
          pagename === "EnrolledAnalysis" ||
          pagename === "attendance" ||
          pagename === "attendanceList" ||
          pagename === "Counsellor_call_Details" ||
          pagename === "hrLeadDetailsAnalysis" ||
          pagename === "ManageEmployee" ||
          pagename === "BDEmployee" ||
          pagename === "MonthlyReport" ||
          pagename === "summary" ||
          pagename === "collegeList" ||
          pagename === "UpcomingWebinar" ||
          pagename === "BDEUpcomingWebinar" ||
          pagename === "counsellorRemark" ||
          pagename === "teamLeadRemark" ||
          pagename === "ManagerRemark" ||
          pagename === "BdeIncentiveAllocation" ||
          pagename === "completeWebinar" ||
          pagename === "completeWebinarTL" ||
          pagename === "cancelledWebinar" ||
          pagename === "cancelledWebinarTL" ||
          pagename === "PendingWebinar" ||
          pagename === "PendingWebinarTL" ||
          pagename === "notIntrestedBDELead" ||
          pagename === "notIntrestedBDTeamLead" ||
          pagename === "IntrestedBDELead" ||
          pagename === "collegeDepartmentList" ||
          pagename === "IntrestedBDELead" ||
          pagename === "CallBackBDELead" ||
          pagename === "overdueCallBackBDELead" ||
          pagename === "OverdueInterestedBDELead" ||
          pagename === "UpcomingTLWebinar" ||
          pagename === "ManageEmployee2" ||
          pagename === "TLcollegeList" ||
          pagename === "notInterestedDepartment" ||
          pagename === "interestedDepartment" ||
          pagename === "callBackDepartment" ||
          pagename === "overdueInterestedDepartment" ||
          pagename === "ManagerRemarkTeamLead" ||
          pagename === "ManagerRemarkExecutive" ||
          pagename === "showResult" ||
          pagename === "showResultExport" ||
          pagename === "userActivityTracker" ||
          pagename === "userActivityTrackerTeamLead" ||
          pagename === "leadSearch" ||
          pagename === "referenceLeadsList" ||
          pagename === "resumeShared" ||
          pagename === "firstRoundInterview" ||
          pagename === "secondRoundInterview" ||
          pagename === "hrRoundInterview" ||
          pagename === "rejected" ||
          pagename === "joiningToday" ||
          pagename === "joiningInWeek" ||
          pagename === "callingDone" ||
          pagename === "orderLeadsList" ||
          pagename === "callExpertSupport" ||
          pagename === "liveClassSchedule" ||
          pagename === "candidateRecord" ||
          pagename === "candidateStatusRecords" ||
          pagename === "courseSupport" ? (
            <td>{temp - 1}.</td>
          ) : (
            <td>{index + 1}</td>
          )}

          {pagename === "userdata" ||
          pagename === "question" ||
          pagename === "zoneList" ? (
            <td>{item.name ? item.name : "-"}</td>
          ) : (
            ""
          )}

          {/* ********************createImportLeads*************** */}

          {pagename === "createImportLeads" ||
          pagename === "counsellorRemark" ||
          pagename === "teamLeadRemark" ||
          pagename === "ManagerRemark" ? (
            item?.firstName ? (
              <td
                className="text-break cursor"
                onClick={() => OnClickTableData(item)}
              >
                {item?.firstName}
              </td>
            ) : (
              <td>-</td>
            )
          ) : null}

          {pagename === "createImportLeads" ||
          pagename === "counsellorRemark" ||
          pagename === "teamLeadRemark" ||
          pagename === "ManagerRemark" ? (
            item?.lastName ? (
              <td
                className="text-break cursor"
                onClick={() => OnClickTableData(item)}
              >
                {item?.lastName}
              </td>
            ) : (
              <td>-</td>
            )
          ) : null}

          {pagename === "counsellorRemark" ||
          pagename === "teamLeadRemark" ||
          pagename === "ManagerRemark" ? (
            item?.counsellorName ? (
              <td
                className="text-break cursor"
                onClick={() => OnClickTableData(item)}
              >
                {item?.counsellorName}
              </td>
            ) : (
              <td>-</td>
            )
          ) : null}

          {pagename === "counsellorRemark" ||
          pagename === "teamLeadRemark" ||
          pagename === "ManagerRemark" ? (
            item?.statusName ? (
              <td
                className="text-break cursor"
                onClick={() => OnClickTableData(item)}
              >
                {item?.statusName}
              </td>
            ) : (
              <td>-</td>
            )
          ) : null}

          {pagename === "counsellorRemark" ||
          pagename === "teamLeadRemark" ||
          pagename === "ManagerRemark" ? (
            item?.call_duration ? (
              <td
                className="text-break cursor"
                onClick={() => OnClickTableData(item)}
              >
                {item?.call_duration}
              </td>
            ) : (
              <td>-</td>
            )
          ) : null}

          {pagename === "counsellorRemark" ||
          pagename === "teamLeadRemark" ||
          pagename === "ManagerRemark" ? (
            item?.teamLeadAuditRemark?.remarkData?.length > 0 ? (
              <td className="text-break">
                <span
                  className="text-Dark fw-bold me-2 ms-2 cursor"
                  onClick={() => handleEdit(item, "teamLeadRemark")}
                >
                  <span className="text-primary">
                    <VisibilityIcon /> View
                  </span>
                </span>
              </td>
            ) : (
              <td>-</td>
            )
          ) : null}

          {pagename === "counsellorRemark" ||
          pagename === "teamLeadRemark" ||
          pagename === "ManagerRemark" ? (
            item?.managerLeadAuditRemark?.remarkData?.length > 0 ? (
              <td className="text-break">
                <span
                  className="text-Dark fw-bold me-2 ms-2 cursor"
                  onClick={() => handleEdit(item, "managerRemark")}
                >
                  {" "}
                  <span className="text-success">
                    <VisibilityIcon />
                    View
                  </span>
                </span>
              </td>
            ) : (
              <td>-</td>
            )
          ) : null}

          {pagename === "createImportLeads" ? (
            item?.email ? (
              <td className="text-break">{item?.email}</td>
            ) : (
              <td>-</td>
            )
          ) : null}

          {pagename === "createImportLeads" ? (
            item?.stateName ? (
              <td className="text-break">{item?.stateName}</td>
            ) : (
              <td>-</td>
            )
          ) : null}

          {pagename === "createImportLeads" ? (
            item?.cityName ? (
              <td className="text-break">{item?.cityName}</td>
            ) : (
              <td>-</td>
            )
          ) : null}

          {pagename === "createImportLeads" ? (
            item?.basecourseName ? (
              <td className="text-break">{item?.basecourseName}</td>
            ) : (
              <td>-</td>
            )
          ) : null}

          {pagename === "createImportLeads" ? (
            item?.examTypeName ? (
              <td className="text-break">{item?.examTypeName}</td>
            ) : (
              <td>-</td>
            )
          ) : null}

          {pagename === "createImportLeads" ? (
            item?.educationYear ? (
              <td className="text-break">{item?.educationYear}</td>
            ) : (
              <td>-</td>
            )
          ) : null}

          {pagename === "createImportLeads" ? (
            item?.source ? (
              <td className="text-break">{item?.source}</td>
            ) : (
              <td>-</td>
            )
          ) : null}

          {pagename === "createImportLeads" ? (
            item?.type ? (
              <td className="text-break">
                {item?.type === 1 ? item?.reference : item?.counsellor}
              </td>
            ) : (
              <td>-</td>
            )
          ) : null}

          {/* ********************Manage Emplyee*************** */}
          {pagename === "Employee" || pagename === "HrEmployee" ? (
            item?.uniqueId ? (
              <td className="text-break">{item?.uniqueId}</td>
            ) : (
              <td>-</td>
            )
          ) : null}
          {pagename === "Employee" ||
          pagename === "HrEmployee" ||
          pagename === "courseAnalysis" ||
          pagename === "BDEmployee" ||
          pagename === "ManageEmployee" ||
          pagename === "ManageEmployee2" ? (
            item?.firstName ? (
              <td className="text-break">{item?.firstName}</td>
            ) : (
              <td>-</td>
            )
          ) : null}

          {pagename === "Employee" ||
          pagename === "HrEmployee" ||
          pagename === "courseAnalysis" ||
          pagename === "BDEmployee" ||
          pagename === "ManageEmployee" ||
          pagename === "ManageEmployee2" ? (
            item?.lastName ? (
              <td className="text-break">{item?.lastName}</td>
            ) : (
              <td>-</td>
            )
          ) : null}
          {pagename === "Employee" ||
          pagename === "HrEmployee" ||
          pagename === "BDEmployee" ||
          pagename === "ManageEmployee" ||
          pagename === "ManageEmployee2" ? (
            item?.contactNo ? (
              <td className="text-break">{item?.contactNo}</td>
            ) : (
              <td>-</td>
            )
          ) : null}

          {pagename === "Employee" ||
          pagename === "HrEmployee" ||
          pagename === "BDEmployee" ||
          pagename === "ManageEmployee" ||
          pagename === "ManageEmployee2" ? (
            item?.role ? (
              <td className="text-break">{item?.role?.name}</td>
            ) : (
              <td>-</td>
            )
          ) : null}

          {pagename === "BDEmployee" ||
          pagename === "ManageEmployee" ||
          pagename === "ManageEmployee2" ? (
            item?.states?.length > 0 ? (
              <td>
                {item?.states.map((c: any) => {
                  return <li>{c?.name}</li>;
                })}
              </td>
            ) : (
              <td>-</td>
            )
          ) : null}

          {pagename === "Employee" || pagename === "HrEmployee" ? (
            item?.managerId ? (
              <td className="text-break">{item?.managerId?.name}</td>
            ) : (
              <td>-</td>
            )
          ) : null}

          {pagename === "Employee" ? (
            item?.managerId ? (
              <td className="text-break">{item?.managerId?.managerName}</td>
            ) : (
              <td>-</td>
            )
          ) : null}

          {pagename === "HrEmployee" ? (
            item?.months || item?.months === 0 ? (
              <td className="text-break">{item?.months}</td>
            ) : (
              <td>-</td>
            )
          ) : null}

          {pagename === "HrEmployee" ||
          pagename === "BDEmployee" ||
          pagename === "ManageEmployee" ||
          pagename === "ManageEmployee2" ? (
            item?.status || item?.status === 0 ? (
              <td className="text-break">
                {item?.status === 1
                  ? "Active "
                  : item?.status === 0
                  ? "Not Active"
                  : item?.status === 9
                  ? "Deleted"
                  : "NA"}
              </td>
            ) : (
              <td>-</td>
            )
          ) : null}

          {/* ************************************Lead Assignment********************************************* */}
          {pagename === "LeadAssignment" ||
          pagename === "LeadTransfer" ||
          pagename === "SkipDetails" ? (
            item?.firstName ? (
              <td className="text-break">{item?.firstName}</td>
            ) : (
              <td>-</td>
            )
          ) : null}
          {pagename === "LeadAssignment" ||
          pagename === "LeadTransfer" ||
          pagename === "SkipDetails" ? (
            item?.lastName ? (
              <td className="text-break">{item?.lastName}</td>
            ) : (
              <td>-</td>
            )
          ) : null}

          {pagename === "LeadAssignment" ||
          pagename === "LeadTransfer" ||
          pagename === "SkipDetails" ? (
            item?.stateName ? (
              <td className="text-break">{item?.stateName}</td>
            ) : (
              <td>-</td>
            )
          ) : null}
          {pagename === "LeadAssignment" ||
          pagename === "LeadTransfer" ||
          pagename === "SkipDetails" ? (
            item?.cityName ? (
              <td className="text-break">{item?.cityName}</td>
            ) : (
              <td>-</td>
            )
          ) : null}
          {pagename === "LeadAssignment" ||
          pagename === "LeadTransfer" ||
          pagename === "SkipDetails" ||
          pagename === "courseAnalysis" ? (
            item?.examTypeName ? (
              <td className="text-break">{item?.examTypeName}</td>
            ) : (
              <td>-</td>
            )
          ) : null}
          {pagename === "LeadAssignment" ||
          pagename === "LeadTransfer" ||
          pagename === "SkipDetails" ||
          pagename === "courseAnalysis" ? (
            item?.basecourseName ? (
              <td className="text-break">{item?.basecourseName}</td>
            ) : (
              <td>-</td>
            )
          ) : null}
          {pagename === "LeadAssignment" ||
          pagename === "LeadTransfer" ||
          pagename === "SkipDetails" ? (
            item?.educationYear ? (
              <td className="text-break">{item?.educationYear}</td>
            ) : (
              <td>-</td>
            )
          ) : null}
          {pagename === "courseAnalysis" ? (
            item?.courseType ? (
              <td className="text-break">{item?.courseType}</td>
            ) : (
              <td>-</td>
            )
          ) : null}

          {pagename === "courseAnalysis" ? (
            item?.studyMaterial ? (
              <td className="text-break">{item?.studyMaterial}</td>
            ) : (
              <td>-</td>
            )
          ) : null}

          {pagename === "LeadAssignment" ||
          pagename === "LeadTransfer" ||
          pagename === "SkipDetails" ? (
            item?.source ? (
              <td className="text-break">{item?.source}</td>
            ) : (
              <td>-</td>
            )
          ) : null}
          {pagename === "LeadAssignment" ||
          pagename === "LeadTransfer" ||
          pagename === "SkipDetails" ? (
            item?.reference ? (
              <td className="text-break">{item?.reference}</td>
            ) : (
              <td>-</td>
            )
          ) : null}

          {pagename === "LeadAssignment" ? (
            <td>
              {item?.status === 1 || item?.status === 0
                ? item.createdAt
                  ? moment(item.createdAt).utc().format("DD-MM-YYYY hh:mm:ss a")
                  : ""
                : item.expireAt
                ? moment(item.expireAt).utc().format("DD-MM-YYYY hh:mm:ss a")
                : item?.status === 5 && item.admissionDate
                ? moment(item.admissionDate)
                    .utc()
                    .format("DD-MM-YYYY hh:mm:ss a")
                : "-"}
            </td>
          ) : null}

          {/* ******************************IncentiveAllocation******************* */}
          {pagename === "IncentiveAllocation" ? (
            item?.source ? (
              <td className="text-break">{item?.source}</td>
            ) : (
              <td>-</td>
            )
          ) : null}

          {pagename === "IncentiveTargetAllocation" ? (
            item?.sourcetype ? (
              <td className="text-break">{item?.sourcetype?.name}</td>
            ) : (
              <td>-</td>
            )
          ) : null}

          {pagename === "IncentiveAllocation" ? (
            item?.examTypeId ? (
              <td className="text-break">{item?.examTypeId?.name}</td>
            ) : (
              <td>-</td>
            )
          ) : null}

          {pagename === "IncentiveAllocation" ? (
            item?.baseCourseId ? (
              <td className="text-break">{item?.baseCourseId?.name}</td>
            ) : (
              <td>-</td>
            )
          ) : null}

          {pagename === "IncentiveAllocation" ||
          pagename === "BdeIncentiveAllocation" ? (
            item?.experience ? (
              <td className="text-break">{item?.experience}</td>
            ) : (
              <td>-</td>
            )
          ) : null}

          {pagename === "IncentiveAllocation" ||
          pagename === "IncentiveTargetAllocation" ? (
            item?.counsellorType ? (
              <td className="text-break">{item?.counsellorType?.name}</td>
            ) : (
              <td>-</td>
            )
          ) : null}

          {pagename === "IncentiveTargetAllocation" ? (
            item?.managerId ? (
              <td className="text-break">{item?.managerId?.name}</td>
            ) : (
              <td>-</td>
            )
          ) : null}

          {pagename === "IncentiveTargetAllocation" ? (
            item?.counsellorId ? (
              <td className="text-break">{item?.counsellorId?.name}</td>
            ) : (
              <td>-</td>
            )
          ) : null}

          {pagename === "IncentiveAllocation" ||
          pagename === "IncentiveTargetAllocation" ? (
            item?.target?.bronze_level ? (
              <td className="text-break">
                {item?.target?.bronze_level?.incentivetarget}
              </td>
            ) : (
              <td>-</td>
            )
          ) : null}

          {pagename === "IncentiveAllocation" ? (
            item?.target?.silver_level ? (
              <td className="text-break">
                {item?.target?.silver_level?.incentivetarget}
              </td>
            ) : (
              <td>-</td>
            )
          ) : null}

          {pagename === "IncentiveAllocation" ? (
            item?.target?.gold_level ? (
              <td className="text-break">
                {item?.target?.gold_level?.incentivetarget}
              </td>
            ) : (
              <td>-</td>
            )
          ) : null}

          {pagename === "IncentiveAllocation" ? (
            item?.target?.platinum_level ? (
              <td className="text-break">
                {item?.target?.platinum_level?.incentivetarget}
              </td>
            ) : (
              <td>-</td>
            )
          ) : null}

          {pagename === "BdeIncentiveAllocation" ? (
            item?.target?.bronze_level ? (
              <td className="text-break">
                {item?.target?.bronze_level?.incentiveAmount}
              </td>
            ) : (
              <td>-</td>
            )
          ) : null}
          {pagename === "BdeIncentiveAllocation" ? (
            item?.target?.silver_level ? (
              <td className="text-break">
                {item?.target?.silver_level?.incentiveAmount}
              </td>
            ) : (
              <td>-</td>
            )
          ) : null}

          {pagename === "BdeIncentiveAllocation" ? (
            item?.target?.gold_level ? (
              <td className="text-break">
                {item?.target?.gold_level?.incentiveAmount}
              </td>
            ) : (
              <td>-</td>
            )
          ) : null}

          {pagename === "BdeIncentiveAllocation" ? (
            item?.target?.platinum_level ? (
              <td className="text-break">
                {item?.target?.platinum_level?.incentiveAmount}
              </td>
            ) : (
              <td>-</td>
            )
          ) : null}

          {/* ***********************************New Opportunity***************************************** */}
          {pagename === "newOpportunity" ||
          pagename === "reEnquired" ||
          pagename === "enrolledLead" ||
          pagename === "overdueProspect" ||
          pagename === "notInterested" ||
          pagename === "expired" ||
          pagename === "cnrLead" ||
          pagename === "leadInvalid" ||
          pagename === "alreadyAdmissionDone" ||
          pagename === "prospectLead" ||
          pagename === "interested" ||
          pagename === "callBackLead" ||
          pagename === "overdueCallback" ||
          pagename === "overdueCNR" ||
          pagename === "overdueInterested" ||
          pagename === "EnrolledAnalysis" ||
          pagename === "welcomeCallDone" ||
          pagename === "welcomeCallReference" ||
          pagename === "followUp" ||
          pagename === "interestedForUpselling" ||
          pagename === "prospectForUpselling" ||
          pagename === "technicalIssue" ||
          pagename === "finalCNR" ||
          pagename === "Counsellor_call_Details" ||
          pagename === "hrLeadDetailsAnalysis" ||
          pagename === "summary" ? (
            item?.firstName ? (
              <td className="text-break">{item?.firstName}</td>
            ) : (
              <td>-</td>
            )
          ) : null}
          {pagename === "newOpportunity" ||
          pagename === "reEnquired" ||
          pagename === "overdueProspect" ||
          pagename === "enrolledLead" ||
          pagename === "notInterested" ||
          pagename === "expired" ||
          pagename === "cnrLead" ||
          pagename === "leadInvalid" ||
          pagename === "alreadyAdmissionDone" ||
          pagename === "prospectLead" ||
          pagename === "interested" ||
          pagename === "callBackLead" ||
          pagename === "overdueCallback" ||
          pagename === "overdueCNR" ||
          pagename === "overdueInterested" ||
          pagename === "EnrolledAnalysis" ||
          pagename === "welcomeCallDone" ||
          pagename === "welcomeCallReference" ||
          pagename === "followUp" ||
          pagename === "interestedForUpselling" ||
          pagename === "prospectForUpselling" ||
          pagename === "technicalIssue" ||
          pagename === "finalCNR" ||
          pagename === "Counsellor_call_Details" ||
          pagename === "hrLeadDetailsAnalysis" ||
          pagename === "summary" ? (
            item?.lastName ? (
              <td className="text-break">{item?.lastName}</td>
            ) : (
              <td>-</td>
            )
          ) : null}

          {pagename === "newOpportunity" ||
          pagename === "reEnquired" ||
          pagename === "enrolledLead" ||
          pagename === "notInterested" ||
          pagename === "expired" ||
          pagename === "cnrLead" ||
          pagename === "prospectLead" ||
          pagename === "interested" ||
          pagename === "callBackLead" ||
          pagename === "overdueProspect" ||
          pagename === "overdueInterested" ||
          pagename === "overdueCallback" ||
          pagename === "overdueCNR" ||
          pagename === "leadInvalid" ||
          pagename === "welcomeCallDone" ||
          pagename === "welcomeCallReference" ||
          pagename === "followUp" ||
          pagename === "interestedForUpselling" ||
          pagename === "prospectForUpselling" ||
          pagename === "technicalIssue" ||
          pagename === "finalCNR" ||
          pagename === "alreadyAdmissionDone" ||
          // pagename === "Counsellor_call_Details" ||
          // pagename === "EnrolledAnalysis" ||
          pagename === "summary" ? (
            item?.webinarRank ? (
              <td className="text-break">{item?.webinarRank}</td>
            ) : (
              <td>-</td>
            )
          ) : null}

          {pagename === "newOpportunity" ||
          pagename === "reEnquired" ||
          pagename === "overdueProspect" ||
          pagename === "enrolledLead" ||
          pagename === "notInterested" ||
          pagename === "expired" ||
          pagename === "cnrLead" ||
          pagename === "leadInvalid" ||
          pagename === "alreadyAdmissionDone" ||
          pagename === "prospectLead" ||
          pagename === "interested" ||
          pagename === "callBackLead" ||
          pagename === "overdueCallback" ||
          pagename === "overdueCNR" ||
          pagename === "overdueInterested" ||
          pagename === "welcomeCallDone" ||
          pagename === "welcomeCallReference" ||
          pagename === "followUp" ||
          pagename === "interestedForUpselling" ||
          pagename === "prospectForUpselling" ||
          pagename === "technicalIssue" ||
          pagename === "finalCNR" ||
          pagename === "summary" ? (
            item?.leadScore || item?.leadScore === 0 ? (
              <td className="text-break">{item?.leadScore}</td>
            ) : (
              <td>-</td>
            )
          ) : null}

          {pagename === "newOpportunity" ||
          pagename === "reEnquired" ||
          pagename === "overdueProspect" ||
          pagename === "enrolledLead" ||
          pagename === "notInterested" ||
          pagename === "expired" ||
          pagename === "cnrLead" ||
          pagename === "leadInvalid" ||
          pagename === "alreadyAdmissionDone" ||
          pagename === "prospectLead" ||
          pagename === "interested" ||
          pagename === "callBackLead" ||
          pagename === "overdueCallback" ||
          pagename === "overdueCNR" ||
          pagename === "overdueInterested" ||
          pagename === "welcomeCallDone" ||
          pagename === "welcomeCallReference" ||
          pagename === "followUp" ||
          pagename === "interestedForUpselling" ||
          pagename === "prospectForUpselling" ||
          pagename === "technicalIssue" ||
          pagename === "finalCNR" ||
          pagename === "summary" ? (
            item?.stateName ? (
              <td className="text-break">{item?.stateName}</td>
            ) : (
              <td>-</td>
            )
          ) : null}

          {pagename === "newOpportunity" ||
          pagename === "reEnquired" ||
          pagename === "enrolledLead" ||
          pagename === "notInterested" ||
          pagename === "expired" ||
          pagename === "cnrLead" ||
          pagename === "leadInvalid" ||
          pagename === "alreadyAdmissionDone" ||
          pagename === "prospectLead" ||
          pagename === "interested" ||
          pagename === "callBackLead" ||
          pagename === "overdueProspect" ||
          pagename === "overdueCallback" ||
          pagename === "overdueCNR" ||
          pagename === "overdueInterested" ||
          pagename === "welcomeCallDone" ||
          pagename === "welcomeCallReference" ||
          pagename === "followUp" ||
          pagename === "interestedForUpselling" ||
          pagename === "prospectForUpselling" ||
          pagename === "technicalIssue" ||
          pagename === "finalCNR" ||
          pagename === "summary" ? (
            item?.examTypeName ? (
              <td className="text-break">{item?.examTypeName}</td>
            ) : (
              <td>-</td>
            )
          ) : null}
          {pagename === "newOpportunity" ||
          pagename === "reEnquired" ||
          pagename === "enrolledLead" ||
          pagename === "notInterested" ||
          pagename === "expired" ||
          pagename === "cnrLead" ||
          pagename === "leadInvalid" ||
          pagename === "alreadyAdmissionDone" ||
          pagename === "prospectLead" ||
          pagename === "interested" ||
          pagename === "callBackLead" ||
          // pagename === "overdueProspect" ||
          pagename === "overdueCallback" ||
          pagename === "overdueCNR" ||
          pagename === "overdueInterested" ||
          pagename === "welcomeCallDone" ||
          pagename === "welcomeCallReference" ||
          pagename === "followUp" ||
          pagename === "interestedForUpselling" ||
          pagename === "prospectForUpselling" ||
          pagename === "technicalIssue" ||
          pagename === "finalCNR" ||
          pagename === "summary" ? (
            item?.basecourseName ? (
              <td className="text-break">{item?.basecourseName}</td>
            ) : (
              <td>-</td>
            )
          ) : null}
          {pagename === "newOpportunity" ||
          pagename === "reEnquired" ||
          pagename === "enrolledLead" ||
          pagename === "notInterested" ||
          pagename === "expired" ||
          pagename === "cnrLead" ||
          pagename === "leadInvalid" ||
          pagename === "alreadyAdmissionDone" ||
          pagename === "prospectLead" ||
          pagename === "interested" ||
          pagename === "callBackLead" ||
          // pagename === "overdueProspect" ||
          pagename === "overdueCallback" ||
          pagename === "overdueCNR" ||
          pagename === "overdueInterested" ||
          pagename === "welcomeCallDone" ||
          pagename === "welcomeCallReference" ||
          pagename === "followUp" ||
          pagename === "interestedForUpselling" ||
          pagename === "prospectForUpselling" ||
          pagename === "technicalIssue" ||
          pagename === "finalCNR" ||
          pagename === "summary" ? (
            item?.source ? (
              <td className="text-break">{item?.source}</td>
            ) : (
              <td>-</td>
            )
          ) : null}

          {pagename === "summary" ? (
            item?.statusName ? (
              <td className="text-break">{item?.statusName}</td>
            ) : (
              <td>-</td>
            )
          ) : null}
          {pagename === "reEnquired" ||
          pagename === "notInterested" ||
          pagename === "leadInvalid" ||
          pagename === "welcomeCallDone" ||
          pagename === "welcomeCallReference" ||
          pagename === "followUp" ||
          pagename === "interestedForUpselling" ||
          pagename === "prospectForUpselling" ||
          pagename === "technicalIssue" ||
          pagename === "finalCNR" ||
          pagename === "alreadyAdmissionDone" ? (
            item?.assignedDate ? (
              <td className="text-break">
                {item.assignedDate
                  ? moment(item.assignedDate).utc().format("DD-MM-YYYY  ")
                  : ""}
              </td>
            ) : (
              <td>-</td>
            )
          ) : null}
          {pagename === "newOpportunity" ? (
            item?.updatedAssignedDate ? (
              <td className="text-break">
                {" "}
                {item.updatedAssignedDate
                  ? moment(item.updatedAssignedDate).utc().format("DD-MM-YYYY ")
                  : ""}
              </td>
            ) : (
              <td>-</td>
            )
          ) : null}
          {pagename === "enrolledLead" ? (
            item?.admissionDate ? (
              <td className="text-break">
                {" "}
                {item.admissionDate
                  ? moment(item.admissionDate).utc().format("DD-MM-YYYY ")
                  : ""}
              </td>
            ) : (
              <td>-</td>
            )
          ) : null}

          {pagename === "Counsellor_call_Details" ? (
            <td> {item.state ? item.state : "-"}</td>
          ) : null}
          {pagename === "Counsellor_call_Details" ? (
            <td> {item.course ? item.course : "-"}</td>
          ) : null}

          {pagename === "courseAnalysis" ||
          pagename === "Counsellor_call_Details" ? (
            item?.status ? (
              <td className="text-break">{item?.status}</td>
            ) : (
              <td>-</td>
            )
          ) : null}

          {pagename === "courseAnalysis" ||
          pagename === "Counsellor_call_Details" ? (
            item?.source ? (
              <td className="text-break">{item?.source}</td>
            ) : (
              <td>-</td>
            )
          ) : null}

          {pagename === "Counsellor_call_Details" ? (
            item?.remark ? (
              <td className="text-break">{item?.remark}</td>
            ) : (
              <td>-</td>
            )
          ) : null}
          {pagename === "Counsellor_call_Details" ? (
            <td>
              {item?.statusId === 2 || item?.statusId === 3
                ? item.allotedDate
                  ? moment(item.allotedDate)
                      .utc()
                      .format("DD-MM-YYYY hh:mm:ss a")
                  : ""
                : item?.statusId !== 2 || item?.statusId !== 3
                ? moment(item.callBackDate)
                    .utc()
                    .format("DD-MM-YYYY hh:mm:ss a")
                : "-"}
            </td>
          ) : null}
          {/* {pagename === "Counsellor_call_Details" ? (
            <td>
              {item.registerDate
                ? moment(item.registerDate).format(
                  "DD-MM-YYYY hh:mm:ss a"
                )
                : ""}
            </td>
          ) : null} */}

          {pagename === "hrLeadDetailsAnalysis" ? (
            <td>
              {item.createdAt
                ? moment(item.createdAt).format("DD-MM-YYYY hh:mm:ss a")
                : ""}
            </td>
          ) : null}

          {pagename === "cnrLead" ||
          pagename === "prospectLead" ||
          pagename === "interested" ||
          pagename === "callBackLead" ||
          pagename === "overdueProspect" ||
          pagename === "overdueCallback" ||
          pagename === "overdueCNR" ||
          pagename === "overdueInterested" ||
          pagename === "LeadTransfer" ? (
            item?.callBackDate ? (
              <td className="text-break">
                {moment(item?.callBackDate)
                  .utc()
                  .format("DD-MM-YYYY hh:mm:ss a ")}
              </td>
            ) : (
              <td>-</td>
            )
          ) : null}

          {pagename === "overdueCNR" ? (
            item?.updatedAt ? (
              <td className="text-break">
                {moment(item?.updatedAt).utc().format("DD-MM-YYYY hh:mm:ss a ")}
              </td>
            ) : (
              <td>-</td>
            )
          ) : null}

          {pagename === "summary" ? (
            item?.comment ? (
              <td className="text-break">{item?.comment}</td>
            ) : (
              <td>-</td>
            )
          ) : null}

          {pagename === "summary" || pagename === "expired" ? (
            <td>
              {item?.status !== 11
                ? item.callBackDate
                  ? moment(item.callBackDate)
                      .utc()
                      .format("DD-MM-YYYY hh:mm:ss a")
                  : ""
                : item.expireAt
                ? moment(item.expireAt).format("DD-MM-YYYY hh:mm:ss a")
                : "-"}
            </td>
          ) : null}

          {pagename === "summary" ? (
            <td>
              {item.assignedDate
                ? moment(item.assignedDate)
                    .utc()
                    .format("DD-MM-YYYY hh:mm:ss a")
                : "-"}
            </td>
          ) : null}

          {/* ****************************CallList************************* */}

          {pagename === "CallList" ||
          pagename === "IncomingCallList" ||
          pagename === "referenceLeadsList" ? (
            item?.firstName ? (
              <td className="text-break">
                {`${item?.firstName} ${item?.lastName}`}
              </td>
            ) : (
              <td>-</td>
            )
          ) : null}

          {pagename === "IncomingCallList" ? (
            item?.call_date || item?.call_time ? (
              <td className="text-break">
                {moment(item?.call_date).format("DD-MM-YYYY")} {item?.call_time}
              </td>
            ) : (
              <td>-</td>
            )
          ) : null}

          {pagename === "IncomingCallList" ? (
            item?.call_transfer_status ? (
              <td className="text-break">{item?.call_transfer_status}</td>
            ) : (
              <td>-</td>
            )
          ) : null}

          {pagename === "CallList" || pagename === "courseAnalysis" ? (
            item?.callBackDate ? (
              <td className="text-break">
                {item.callBackDate
                  ? moment(item.callBackDate)
                      .utc()
                      .format("DD-MM-YYYY hh:mm:ss a")
                  : ""}
              </td>
            ) : (
              <td>-</td>
            )
          ) : null}

          {pagename === "courseAnalysis" ? (
            item?.counsellorfirstName || item?.counsellorlastName ? (
              <td className="text-break">
                {item?.counsellorfirstName} {item?.counsellorlastName}
              </td>
            ) : (
              <td>-</td>
            )
          ) : null}

          {pagename === "CallList" || pagename === "IncomingCallList" ? (
            <td>
              <button
                className={`btn me-1 ${
                  item?.call_transfer_status === "Connected"
                    ? "btn-success"
                    : "btn-danger"
                }`}
                onClick={() => handleEdit(item, "call")}
              >
                Call Now
              </button>
              <button
                className={`btn btn-secondary`}
                onClick={() => handleEdit(item, "view")}
              >
                View
              </button>
            </td>
          ) : null}

          {pagename === "AllCallList" ? (
            item?.studentFirstName ? (
              <td className="text-break">
                {`${item?.studentFirstName ? item?.studentFirstName : ""} ${
                  item?.studentLastName ? item?.studentLastName : ""
                }`}
              </td>
            ) : (
              <td>-</td>
            )
          ) : null}

          {pagename === "AllCallList" ? (
            item?.call_direction ? (
              <td className="text-break">{item?.call_direction}</td>
            ) : (
              <td>-</td>
            )
          ) : null}

          {pagename === "AllCallList" ? (
            item?.call_duration ? (
              <td className="text-break">{item?.call_duration}</td>
            ) : (
              <td>-</td>
            )
          ) : null}

          {pagename === "AllCallList" ? (
            item?.call_date ? (
              <td className="text-break">
                {item.call_date
                  ? moment(item.call_date).utc().format("DD-MM-YYYY hh:mm:ss a")
                  : ""}
              </td>
            ) : (
              <td>-</td>
            )
          ) : null}

          {pagename === "AllCallList" ? (
            item?.call_status ? (
              <td className="text-break">
                {item?.call_status === "Answered"
                  ? "Answered"
                  : item?.call_status === "NotAnswered"
                  ? "Not Answered"
                  : "Missed"}
              </td>
            ) : (
              <td>-</td>
            )
          ) : null}

          {pagename === "AllCallList" ? (
            <td>
              <button
                className={`btn me-1 ${
                  item?.call_status === "Answered"
                    ? "btn-success"
                    : "btn-danger"
                }`}
                onClick={() => handleEdit(item, "call")}
              >
                Call Now
              </button>
              <button
                className={`btn btn-primary`}
                onClick={() => handleEdit(item, "view")}
              >
                View
              </button>
            </td>
          ) : null}
          {/* ***********************************Couponcode**************************************** */}
          {pagename === "CouponCode" ? (
            item?.name ? (
              <td className="text-break">{item?.name}</td>
            ) : (
              <td>-</td>
            )
          ) : null}

          {pagename === "CouponCode" ? (
            item?.examType ? (
              <td className="text-break">{item?.examType}</td>
            ) : (
              <td>-</td>
            )
          ) : null}

          {pagename === "CouponCode" ? (
            item?.subExamType ? (
              <td className="text-break">{item?.subExamType}</td>
            ) : (
              <td>-</td>
            )
          ) : null}

          {pagename === "CouponCode" ? (
            item?.examStream ? (
              <td className="text-break">{item?.examStream}</td>
            ) : (
              <td>-</td>
            )
          ) : null}

          {pagename === "CouponCode" ? (
            item?.mainCategory ? (
              <td className="text-break">{item?.mainCategory}</td>
            ) : (
              <td>-</td>
            )
          ) : null}

          {pagename === "CouponCode" ? (
            item?.subCategory ? (
              <td className="text-break">{item?.subCategory}</td>
            ) : (
              <td>-</td>
            )
          ) : null}

          {pagename === "CouponCode" ? (
            item?.status ? (
              <td className="text-break">
                {item?.status === 0
                  ? "InActive"
                  : item?.status === 1
                  ? "Active"
                  : item?.status === 2
                  ? "DeActivate"
                  : ""}
              </td>
            ) : (
              <td>-</td>
            )
          ) : null}
          {pagename === "leadRecord" || pagename === "leadSearch" ? (
            item?.StudentName ? (
              <td className="text-break">{item?.StudentName}</td>
            ) : (
              <td>-</td>
            )
          ) : null}
          {pagename === "leadRecord" || pagename === "leadSearch" ? (
            item?.status ? (
              <td className="text-break">{item?.status}</td>
            ) : (
              <td>-</td>
            )
          ) : null}
          {pagename === "leadRecord" || pagename === "leadSearch" ? (
            item?.Counsellorname ? (
              <td className="text-break">{item?.Counsellorname}</td>
            ) : (
              <td>-</td>
            )
          ) : null}
          {pagename === "leadRecord" ? (
            item?.examTypeName ? (
              <td className="text-break">{item?.examTypeName}</td>
            ) : (
              <td>-</td>
            )
          ) : null}
          {pagename === "leadRecord" ? (
            item?.basecourseName ? (
              <td className="text-break">{item?.basecourseName}</td>
            ) : (
              <td>-</td>
            )
          ) : null}
          {/* ************************************Enrolled Analysis********************************************** */}
          {/* { pagename === "EnrolledAnalysis" ? (
            item?.contact ? (
              <td className="text-break">{item?.contact}</td>
            ) : (
              <td>-</td>
            )
          ) : null} */}
          {pagename === "EnrolledAnalysis" ? (
            item?.state ? (
              <td className="text-break">{item?.state}</td>
            ) : (
              <td>-</td>
            )
          ) : null}
          {pagename === "EnrolledAnalysis" ? (
            item?.examTypeName ? (
              <td className="text-break">{item?.examTypeName}</td>
            ) : (
              <td>-</td>
            )
          ) : null}
          {pagename === "EnrolledAnalysis" ? (
            item?.basecourseName ? (
              <td className="text-break">{item?.basecourseName}</td>
            ) : (
              <td>-</td>
            )
          ) : null}
          {pagename === "EnrolledAnalysis" ? (
            item?.courseType ? (
              <td className="text-break">{item?.courseType}</td>
            ) : (
              <td>-</td>
            )
          ) : null}
          {pagename === "EnrolledAnalysis" ? (
            item?.studyMaterial ? (
              <td className="text-break">{item?.studyMaterial}</td>
            ) : (
              <td>-</td>
            )
          ) : null}
          {pagename === "EnrolledAnalysis" ? (
            item?.source ? (
              <td className="text-break">{item?.source}</td>
            ) : (
              <td>-</td>
            )
          ) : null}
          {pagename === "EnrolledAnalysis" ? (
            item?.counsellorName ? (
              <td className="text-break">{item?.counsellorName}</td>
            ) : (
              <td>-</td>
            )
          ) : null}
          {pagename === "EnrolledAnalysis" ? (
            item?.ammountPaid ? (
              <td className="text-break">{item?.ammountPaid}</td>
            ) : (
              <td>-</td>
            )
          ) : null}
          {pagename === "EnrolledAnalysis" ? (
            item?.admissionDate ? (
              <td className="text-break">
                {" "}
                {item.admissionDate
                  ? moment(item.admissionDate).utc().format("DD-MM-YYYY  ")
                  : ""}
              </td>
            ) : (
              <td>-</td>
            )
          ) : null}
          {/* {pagename === "EnrolledAnalysis" ? (
            item?.registerDate ? (
              <td className="text-break">
                {" "}
                {item.registerDate
                  ? moment(item.registerDate)
                      .utc()
                      .format("DD-MM-YYYY  ")
                  : ""}
              </td>
            ) : (
              <td>-</td>
            )
          ) : null} */}
          {/********************************LeadGenerationOverview******************************************* */}

          {pagename === "LeadGenerationOverview" ? (
            item?.date ? (
              <td className="text-break">{item?.date}</td>
            ) : (
              <td>-</td>
            )
          ) : null}
          {pagename === "LeadGenerationOverview" ? (
            item?.LeadCount ? (
              <td
                className="text-break cursor"
                onClick={() => OnClickHandle(item)}
              >
                <span></span> {item?.LeadCount}
              </td>
            ) : (
              <td>-</td>
            )
          ) : null}
          {pagename === "LeadGenerationOverview" ? (
            item?.AssignedLead ? (
              <td className="text-break cursor">
                <span onClick={() => OnClickHandle(item)}></span>{" "}
                {item?.AssignedLead}
              </td>
            ) : (
              <td>-</td>
            )
          ) : null}
          {pagename === "LeadGenerationOverview" ? (
            item?.UnassignedLead ? (
              <td className="text-break cursor">{item?.UnassignedLead}</td>
            ) : (
              <td>-</td>
            )
          ) : null}
          {/* *******************************************Attendance******************************************************** */}

          {pagename === "attendance" ? (
            // item?.status ? (
            <td className="text-break cursor">
              <input
                id={item._id}
                // className="check"
                type="checkbox"
                checked={item.isSelected}
              />
            </td>
          ) : // ) : (
          //   <td>-</td>
          // )
          null}

          {pagename === "attendance" || pagename === "attendanceList" ? (
            item?.name ? (
              <td className="text-break cursor">{item?.name}</td>
            ) : (
              <td>-</td>
            )
          ) : null}
          {pagename === "attendance" || pagename === "attendanceList" ? (
            item?.LoginTime ? (
              <td className="text-break">
                {item.LoginTime
                  ? moment(item.LoginTime).utc().format("LTS")
                  : ""}
              </td>
            ) : (
              <td>-</td>
            )
          ) : null}
          {pagename === "attendance" || pagename === "attendanceList" ? (
            item?.LogoutTime ? (
              <td className="text-break">
                {item.LogoutTime
                  ? moment(item.LogoutTime).utc().format("LTS")
                  : ""}
              </td>
            ) : (
              <td>-</td>
            )
          ) : null}

          {pagename === "attendanceList" ? (
            <td>
              {item?.status === "1" ? (
                <span className="fw-bold text-success">Present</span>
              ) : item?.status === "0" ? (
                <span className="fw-bold text-danger">Absent</span>
              ) : (
                "-"
              )}
            </td>
          ) : null}

          {/* **********************Monthly Report************************ */}
          {pagename === "MonthlyReport" ? (
            item?.Date ? (
              <td className="text-break cursor">
                {item.Date
                  ? moment(item.Date).utc().format("DD-MM-YYYY  ")
                  : ""}
              </td>
            ) : (
              <td>-</td>
            )
          ) : null}
          {pagename === "MonthlyReport" ? (
            item?.name ? (
              <td className="text-break cursor">{item?.name}</td>
            ) : (
              <td>-</td>
            )
          ) : null}
          {pagename === "MonthlyReport" ? (
            item?.status ? (
              <td className="text-break fw-bold">
                {item?.status === "0" ? (
                  <span className="fw-bold text-danger">Absent</span>
                ) : item?.status === "1" ? (
                  <span className="fw-bold text-success">Present</span>
                ) : (
                  "-"
                )}
              </td>
            ) : (
              <td>-</td>
            )
          ) : null}
          {pagename === "MonthlyReport" ? (
            item?.LogoutTime ? (
              <td className="text-break">
                {item.LogoutTime
                  ? moment(item.LogoutTime).utc().format("LTS")
                  : ""}
              </td>
            ) : (
              <td>-</td>
            )
          ) : null}
          {/* *******************************TLcollegeList********************************* */}

          {pagename === "TLcollegeList" || pagename === "collegeList" ? (
            item?.collegeName ? (
              <td className="text-break text-uppercase ">
                {item?.collegeName}
              </td>
            ) : (
              <td>-</td>
            )
          ) : null}
          {pagename === "TLcollegeList" || pagename === "collegeList" ? (
            item?.totalDepartment ? (
              <td className="text-break  ">{item?.totalDepartment}</td>
            ) : (
              <td>-</td>
            )
          ) : null}
          {pagename === "TLcollegeList" || pagename === "collegeList" ? (
            item?.addedDepartmemt ? (
              <td className="text-break  ">{item?.addedDepartmemt}</td>
            ) : (
              <td>-</td>
            )
          ) : null}
          {pagename === "TLcollegeList" || pagename === "collegeList" ? (
            item?.remainingDepartmemt ? (
              <td className="text-break  ">{item?.remainingDepartmemt}</td>
            ) : (
              <td>-</td>
            )
          ) : null}
          {/* **************************************UpcomingWebinar********************************************** */}
          {pagename === "UpcomingWebinar" ||
          pagename === "BDEUpcomingWebinar" ||
          pagename === "completeWebinar" ||
          pagename === "completeWebinarTL" ||
          pagename === "cancelledWebinarTL" ||
          pagename === "cancelledWebinar" ? (
            item?.webinarAt ? (
              <td>
                {item.webinarAt
                  ? moment(item.webinarAt).format("DD-MM-YYYY")
                  : ""}
              </td>
            ) : (
              <td>-</td>
            )
          ) : null}
          {pagename === "UpcomingWebinar" ||
          pagename === "BDEUpcomingWebinar" ||
          pagename === "completeWebinar" ||
          pagename === "completeWebinarTL" ||
          pagename === "cancelledWebinarTL" ||
          pagename === "cancelledWebinar" ? (
            item?.webinarAt ? (
              <td>
                {item.webinarAt
                  ? moment(item.webinarAt).format("hh:mm:ss a")
                  : ""}
              </td>
            ) : (
              <td>-</td>
            )
          ) : null}
          {pagename === "UpcomingWebinar" ||
          pagename === "BDEUpcomingWebinar" ||
          pagename === "completeWebinar" ||
          pagename === "completeWebinarTL" ||
          pagename === "cancelledWebinar" ||
          pagename === "cancelledWebinarTL" ||
          pagename === "PendingWebinarTL" ||
          pagename === "PendingWebinar" ? (
            item?.coordinatorName ? (
              <td className="text-break  ">{item?.coordinatorName}</td>
            ) : (
              <td>-</td>
            )
          ) : null}
          {pagename === "UpcomingWebinar" ||
          pagename === "BDEUpcomingWebinar" ||
          pagename === "completeWebinar" ||
          pagename === "completeWebinarTL" ||
          pagename === "cancelledWebinarTL" ||
          pagename === "cancelledWebinar" ? (
            item?.coordinatorPhone ? (
              <td className="text-break  ">{item?.coordinatorPhone}</td>
            ) : (
              <td>-</td>
            )
          ) : null}
          {pagename === "PendingWebinar" || pagename === "PendingWebinarTL" ? (
            item?.college_data?.collegeName ? (
              <td className="text-break  ">
                {item?.college_data?.collegeName}
              </td>
            ) : (
              <td>-</td>
            )
          ) : null}
          {pagename === "PendingWebinar" || pagename === "PendingWebinarTL" ? (
            item?.state_data?.name ? (
              <td className="text-break  ">{item?.state_data?.name}</td>
            ) : (
              <td>-</td>
            )
          ) : null}
          {pagename === "PendingWebinarTL" ? (
            item?.district_data?.CITY ? (
              <td className="text-break  ">{item?.district_data?.CITY}</td>
            ) : (
              <td>-</td>
            )
          ) : null}
          {pagename === "PendingWebinar" ? (
            item?.city_data?.name ? (
              <td className="text-break  ">{item?.city_data?.name}</td>
            ) : (
              <td>-</td>
            )
          ) : null}
          {pagename === "PendingWebinarTL" ? (
            item?.examType_data?.name ? (
              <td className="text-break  ">{item?.examType_data?.name}</td>
            ) : (
              <td>-</td>
            )
          ) : null}
          {pagename === "PendingWebinar" ? (
            item?.examType_data?.name ? (
              <td className="text-break  ">
                {item?.examType_data?.name} - {item?.exam_data?.name}
              </td>
            ) : (
              <td>-</td>
            )
          ) : null}
          {pagename === "completeWebinarTL" ||
          pagename === "cancelledWebinarTL" ||
          pagename === "PendingWebinarTL" ||
          pagename === "PendingWebinar" ? (
            item?.department_data?.departmentName ? (
              <td className="text-break  ">
                {item?.department_data?.departmentName}
              </td>
            ) : (
              <td>-</td>
            )
          ) : null}

          {pagename === "UpcomingWebinar" ||
          pagename === "BDEUpcomingWebinar" ||
          pagename === "completeWebinar" ||
          pagename === "completeWebinarTL" ||
          pagename === "cancelledWebinar" ||
          pagename === "cancelledWebinarTL" ? (
            item?.examType_data?.name ? (
              <td className="text-break  ">
                {item?.examType_data?.name} - {item?.exam_data?.name}
              </td>
            ) : (
              <td>-</td>
            )
          ) : null}

          {pagename === "UpcomingWebinar" ||
          pagename === "BDEUpcomingWebinar" ||
          pagename === "completeWebinar" ||
          pagename === "completeWebinarTL" ||
          pagename === "cancelledWebinarTL" ||
          pagename === "cancelledWebinar" ? (
            item?.teacher_data?.name ? (
              <td className="text-break  ">{item?.teacher_data?.name}</td>
            ) : (
              <td>-</td>
            )
          ) : null}
          {pagename === "completeWebinarTL" ||
          pagename === "PendingWebinarTL" ||
          pagename === "cancelledWebinarTL" ? (
            item?.bdeAdminUsers_data?.name ? (
              <td className="text-break  ">{item?.bdeAdminUsers_data?.name}</td>
            ) : (
              <td>-</td>
            )
          ) : null}

          {pagename === "UpcomingWebinar" ||
          pagename === "BDEUpcomingWebinar" ||
          pagename === "PendingWebinarTL" ||
          pagename === "PendingWebinar" ? (
            item?.meetingURL ? (
              <td className="text-break">
                <a href={item?.meetingURL} target="_blank" rel="noreferrer">
                  {item?.meetingURL}
                </a>
              </td>
            ) : (
              <td>-</td>
            )
          ) : null}
          {pagename === "UpcomingWebinar" ||
          pagename === "BDEUpcomingWebinar" ||
          pagename === "PendingWebinarTL" ||
          pagename === "PendingWebinar" ? (
            item?.registerURL ? (
              // <td className="text-break  ">{item?.registerURL}</td>
              <td>
                <a href={item?.registerURL} target="_blank" rel="noreferrer">
                  {item?.registerURL}
                </a>
              </td>
            ) : (
              <td>-</td>
            )
          ) : null}
          {pagename === "PendingWebinarTL" || pagename === "PendingWebinar" ? (
            item?.leadcount?.TotalCount ? (
              <td className="text-break  ">{item?.leadcount?.TotalCount}</td>
            ) : (
              <td>-</td>
            )
          ) : null}
          {pagename === "UpcomingWebinar" ||
          pagename === "BDEUpcomingWebinar" ||
          pagename === "completeWebinar" ||
          pagename === "completeWebinarTL" ||
          pagename === "cancelledWebinarTL" ||
          pagename === "cancelledWebinar" ? (
            item?.college_data?.collegeName ? (
              <td className="text-break  ">
                {item?.college_data?.collegeName}
              </td>
            ) : (
              <td>-</td>
            )
          ) : null}

          {pagename === "UpcomingWebinar" ||
          pagename === "BDEUpcomingWebinar" ||
          pagename === "completeWebinar" ||
          pagename === "completeWebinarTL" ||
          pagename === "cancelledWebinarTL" ||
          pagename === "cancelledWebinar" ? (
            item?.state_data?.name ? (
              <td className="text-break  ">{item?.state_data?.name}</td>
            ) : (
              <td>-</td>
            )
          ) : null}
          {pagename === "cancelledWebinarTL" ? (
            item?.district_data ? (
              <td className="text-break  "> {item?.district_data?.CITY}</td>
            ) : (
              <td>-</td>
            )
          ) : null}

          {pagename === "completeWebinarTL" ? (
            item?.district_data ? (
              <td className="text-break  "> {item?.district_data?.CITY}</td>
            ) : (
              <td>-</td>
            )
          ) : null}
          {pagename === "UpcomingWebinar" ||
          pagename === "BDEUpcomingWebinar" ||
          pagename === "completeWebinar" ||
          pagename === "cancelledWebinar" ? (
            item?.city_data?.name ? (
              <td className="text-break  ">{item?.city_data?.name}</td>
            ) : (
              <td>-</td>
            )
          ) : null}
          {pagename === "UpcomingWebinar" ||
          pagename === "BDEUpcomingWebinar" ||
          pagename === "completeWebinar" ||
          pagename === "completeWebinarTL" ||
          pagename === "cancelledWebinarTL" ||
          pagename === "cancelledWebinar" ? (
            item?.leadcount?.TotalCount ? (
              <td className="text-break  ">{item?.leadcount?.TotalCount}</td>
            ) : (
              <td>-</td>
            )
          ) : null}

          {/* ***********************departmentList************ */}
          {pagename === "collegeDepartmentList" ? (
            item?.createdAt ? (
              <td className="text-break  ">
                {item?.createdAt
                  ? moment(item?.createdAt).format("DD-MM-YYYY")
                  : ""}
              </td>
            ) : (
              <td>-</td>
            )
          ) : null}
          {pagename === "collegeDepartmentList" ? (
            item?.bde_collegedetails?.collegeName ? (
              <td className="text-break  ">
                {item?.bde_collegedetails?.collegeName}
              </td>
            ) : (
              <td>-</td>
            )
          ) : null}
          {pagename === "collegeDepartmentList" ? (
            item?.stateName?.name ? (
              <td className="text-break  ">{item?.stateName?.name}</td>
            ) : (
              <td>-</td>
            )
          ) : null}
          {pagename === "collegeDepartmentList" ? (
            item?.cityName?.name ? (
              <td className="text-break  ">{item?.cityName?.name}</td>
            ) : (
              <td>-</td>
            )
          ) : null}

          {pagename === "collegeDepartmentList" ? (
            item?.examType_data?.name ? (
              <td className="text-break  ">{item?.examType_data?.name}</td>
            ) : (
              <td>-</td>
            )
          ) : null}
          {pagename === "collegeDepartmentList" ? (
            item?.departmentName ? (
              <td className="text-break  ">{item?.departmentName}</td>
            ) : (
              <td>-</td>
            )
          ) : null}
          {pagename === "collegeDepartmentList" ? (
            item?.primaryContact ? (
              <td className="text-break  ">
                {item?.primaryContact?.firstName}{" "}
                {item?.primaryContact?.lastName}
              </td>
            ) : (
              <td>-</td>
            )
          ) : null}
          {pagename === "collegeDepartmentList" ? (
            item?.secondaryContact ? (
              <td className="text-break  ">
                {item?.primaryContact?.firstName}{" "}
                {item?.primaryContact?.lastName}
              </td>
            ) : (
              <td>-</td>
            )
          ) : null}
          {pagename === "collegeDepartmentList" ? (
            item?.executiveName?.name ? (
              <td className="text-break  ">{item?.executiveName?.name}</td>
            ) : (
              <td>-</td>
            )
          ) : null}

          {pagename === "attendance" ? (
            <td>
              {/* <span
                onClick={() => handleApprove(item)}
                className="cursor  "
                // className={item?.status === 1 ? "text-danger" : "text-dark"}
                // className= "text-danger"
              >
                {" "}
                {item?.status === 1 ? (
                  <CheckCircleOutlineIcon color="success" />
                ) : (
                  <CheckCircleOutlineIcon />
                )}{" "}
              </span>
              <span
                className="text-Dark fw-bold cursor  "
                onClick={() => handleCancel(item)}
              >
                {" "}
                {item?.status === 0 ? (
                  <HighlightOffIcon color="error" />
                ) : (
                  <HighlightOffIcon />
                )}{" "}
              </span> */}

              <span onClick={() => handleApprove(item)} className="cursor  ">
                {" "}
                {item?.status === "1" ? (
                  <button type="button" className="btn btn-success">
                    Present
                  </button>
                ) : (
                  <button type="button" className="btn btn-secondary">
                    Present
                  </button>
                )}{" "}
              </span>

              <span
                className="text-Dark fw-bold cursor  "
                onClick={() => handleCancel(item)}
              >
                {" "}
                {item?.status === "0" ? (
                  <button type="button" className="btn btn-danger">
                    Absent
                  </button>
                ) : (
                  <button type="button" className="btn btn-secondary">
                    Absent
                  </button>
                )}{" "}
              </span>

              {/* <span
                onClick={() => handleApprove(item)}
                className="cursor  "
              // className={item?.status === 1 ? "text-danger" : "text-dark"}
              // className= "text-danger"
              >
                {item?.status === 2 ?
                  <>
                    <button type="button" className="btn btn-secondary">Present</button>
                    <button type="button" className="btn btn-secondary ms-2">Absent</button>
                  </> : ""}
                {" "}

                {item?.status === 1 ? (
                  // <CheckCircleOutlineIcon color="success" />
                  <button type="button" className="btn btn-success">Present</button>
                ) : (
                  ""
                )}{" "}
              </span>
              <span
                className="text-Dark fw-bold cursor  "
                onClick={() => handleCancel(item)}
              >
                {" "}
                {item?.status === 0 ? (
                  <button type="button" className="btn btn-danger">Absent</button>
                ) : (
                  ""
                )}{" "}
              </span> */}
            </td>
          ) : null}

          {pagename === "notIntrestedBDELead" ||
          pagename === "notIntrestedBDTeamLead" ||
          pagename === "IntrestedBDELead" ||
          pagename === "CallBackBDELead" ||
          pagename === "overdueCallBackBDELead" ||
          pagename === "OverdueInterestedBDELead" ||
          pagename === "notInterestedDepartment" ||
          pagename === "interestedDepartment" ||
          pagename === "callBackDepartment" ||
          pagename === "overdueInterestedDepartment" ? (
            item?.primaryContact ? (
              <td className="text-break  ">
                {item?.primaryContact?.firstName}{" "}
                {item?.primaryContact?.lastName}
              </td>
            ) : (
              <td>-</td>
            )
          ) : null}
          {pagename === "notIntrestedBDELead" ||
          pagename === "notIntrestedBDTeamLead" ||
          pagename === "IntrestedBDELead" ||
          pagename === "CallBackBDELead" ||
          pagename === "overdueCallBackBDELead" ||
          pagename === "OverdueInterestedBDELead" ||
          pagename === "notInterestedDepartment" ||
          pagename === "interestedDepartment" ||
          pagename === "callBackDepartment" ||
          pagename === "overdueInterestedDepartment" ? (
            item?.college_data?.collegeName ? (
              <td className="text-break  ">
                {item?.college_data?.collegeName}
              </td>
            ) : (
              <td>-</td>
            )
          ) : null}

          {pagename === "notIntrestedBDELead" ||
          pagename === "notIntrestedBDTeamLead" ||
          pagename === "IntrestedBDELead" ||
          pagename === "CallBackBDELead" ||
          pagename === "overdueCallBackBDELead" ||
          pagename === "OverdueInterestedBDELead" ||
          pagename === "notInterestedDepartment" ||
          pagename === "interestedDepartment" ||
          pagename === "callBackDepartment" ||
          pagename === "overdueInterestedDepartment" ? (
            item?.examType_data?.name ? (
              <td className="text-break  ">{item?.examType_data?.name}</td>
            ) : (
              <td>-</td>
            )
          ) : null}

          {pagename === "notIntrestedBDELead" ||
          pagename === "notIntrestedBDTeamLead" ||
          pagename === "IntrestedBDELead" ||
          pagename === "CallBackBDELead" ||
          pagename === "overdueCallBackBDELead" ||
          pagename === "OverdueInterestedBDELead" ||
          pagename === "notInterestedDepartment" ||
          pagename === "interestedDepartment" ||
          pagename === "callBackDepartment" ||
          pagename === "overdueInterestedDepartment" ? (
            item?.departmentName ? (
              <td className="text-break  ">{item?.departmentName}</td>
            ) : (
              <td>-</td>
            )
          ) : null}

          {pagename === "notIntrestedBDELead" ||
          pagename === "IntrestedBDELead" ||
          pagename === "CallBackBDELead" ||
          pagename === "overdueCallBackBDELead" ||
          pagename === "OverdueInterestedBDELead" ||
          pagename === "notInterestedDepartment" ? (
            item?.remark ? (
              <td className="text-break  ">{item?.remark}</td>
            ) : (
              <td>-</td>
            )
          ) : null}

          {/* *********************"UpcomingTLWebinar"********************* */}

          {pagename === "UpcomingTLWebinar" ? (
            item?.webinarAt ? (
              <td className="text-break  ">
                {" "}
                {moment(item?.webinarAt).utc().format("DD-MM-YYYY ")}
              </td>
            ) : (
              <td>-</td>
            )
          ) : null}

          {pagename === "UpcomingTLWebinar" ? (
            item?.webinarAt ? (
              <td className="text-break  ">
                {" "}
                {moment(item?.webinarAt).utc().format("LT ")}
              </td>
            ) : (
              <td>-</td>
            )
          ) : null}
          {pagename === "UpcomingTLWebinar" ? (
            item?.coordinatorName ? (
              <td className="text-break  "> {item?.coordinatorName}</td>
            ) : (
              <td>-</td>
            )
          ) : null}
          {pagename === "UpcomingTLWebinar" ? (
            item?.coordinatorPhone ? (
              <td className="text-break  "> {item?.coordinatorPhone}</td>
            ) : (
              <td>-</td>
            )
          ) : null}
          {pagename === "UpcomingTLWebinar" ? (
            item?.department_data ? (
              <>
                <td className="text-break ">
                  {item?.department_data?.departmentName}
                </td>
              </>
            ) : (
              <td>-</td>
            )
          ) : null}

          {pagename === "UpcomingTLWebinar" ? (
            item?.examType_data ? (
              <>
                <td className="text-break ">
                  <div>{item?.examType_data?.name}</div>
                  <div>{item?.exam_data?.name}</div>
                </td>
              </>
            ) : (
              <td>-</td>
            )
          ) : null}

          {pagename === "UpcomingTLWebinar" ? (
            item?.teacher_data ? (
              <td className="text-break  "> {item?.teacher_data?.name}</td>
            ) : (
              <td>-</td>
            )
          ) : null}

          {pagename === "UpcomingTLWebinar" ? (
            item?.meetingURL ? (
              <td className="text-break  ">
                <a href={item?.meetingURL} target="_blank" rel="noreferrer">
                  {item?.meetingURL}
                </a>
              </td>
            ) : (
              <td>-</td>
            )
          ) : null}

          {pagename === "UpcomingTLWebinar" ? (
            item?.registerURL ? (
              <td className="text-break  ">
                <a href={item?.registerURL} target="_blank" rel="noreferrer">
                  {item?.registerURL}
                </a>
              </td>
            ) : (
              <td>-</td>
            )
          ) : null}
          {pagename === "UpcomingTLWebinar" ? (
            item?.bdeAdminUsers_data?.name ? (
              <td className="text-break  ">{item?.bdeAdminUsers_data?.name}</td>
            ) : (
              <td>-</td>
            )
          ) : null}
          {pagename === "UpcomingTLWebinar" ? (
            item?.college_data ? (
              <td className="text-break  ">
                {" "}
                {item?.college_data?.collegeName}
              </td>
            ) : (
              <td>-</td>
            )
          ) : null}

          {pagename === "UpcomingTLWebinar" ? (
            item?.state_data ? (
              <td className="text-break  "> {item?.state_data?.name}</td>
            ) : (
              <td>-</td>
            )
          ) : null}
          {pagename === "UpcomingTLWebinar" ? (
            item?.district_data ? (
              <td className="text-break  "> {item?.district_data?.CITY}</td>
            ) : (
              <td>-</td>
            )
          ) : null}
          {pagename === "UpcomingTLWebinar" ? (
            item?.leadcount ? (
              <td className="text-break  "> {item?.leadcount?.TotalCount}</td>
            ) : (
              <td>-</td>
            )
          ) : null}

          {/* ********************************ManagerRemarkTeamLead************************ */}

          {pagename === "ManagerRemarkTeamLead" ||
          pagename === "ManagerRemarkExecutive" ? (
            item?.departmentName ? (
              <td className="text-break  "> {item?.departmentName}</td>
            ) : (
              <td>-</td>
            )
          ) : null}
          {pagename === "ManagerRemarkTeamLead" ? (
            item?.executiveName ? (
              <td className="text-break  "> {item?.executiveName}</td>
            ) : (
              <td>-</td>
            )
          ) : null}
          {pagename === "ManagerRemarkTeamLead" ||
          pagename === "ManagerRemarkExecutive" ? (
            item?.managerRemark ? (
              <td className="text-break  "> {item?.managerRemark}</td>
            ) : (
              <td>-</td>
            )
          ) : null}

          {/* ***********************ShowResult********************************* */}
          {pagename === "showResult" || pagename === "showResultExport" ? (
            item?.firstName ? (
              <td className="text-break  ">
                {" "}
                {item.firstName} {item?.lastName}
              </td>
            ) : (
              <td>-</td>
            )
          ) : null}
          {pagename === "showResult" || pagename === "showResultExport" ? (
            item?.examTypes ? (
              <td className="text-break  "> {item.examTypes?.name}</td>
            ) : (
              <td>-</td>
            )
          ) : null}
          {pagename === "showResult" || pagename === "showResultExport" ? (
            item?.basecourses ? (
              <td className="text-break  "> {item.basecourses?.name}</td>
            ) : (
              <td>-</td>
            )
          ) : null}
          {pagename === "showResult" || pagename === "showResultExport" ? (
            item?.qualifyFor ? (
              <td className="text-break  "> {item.qualifyFor}</td>
            ) : (
              <td>-</td>
            )
          ) : null}
          {pagename === "showResult" || pagename === "showResultExport" ? (
            item?.qualifyYear ? (
              <td className="text-break  "> {item.qualifyYear}</td>
            ) : (
              <td>-</td>
            )
          ) : null}

          {pagename === "showResult" || pagename === "showResultExport" ? (
            item?.createdAt ? (
              <td className="text-break  ">
                {" "}
                {moment(item?.createdAt).utc().format("DD-MM-YYYY")}
              </td>
            ) : (
              <td>-</td>
            )
          ) : null}
          {pagename === "showResultExport" ? (
            item?.rank ? (
              <td className="text-break  "> {item?.rank}</td>
            ) : (
              <td>-</td>
            )
          ) : null}
          {pagename === "showResultExport" ? (
            item?.rollNumber ? (
              <td className="text-break  "> {item?.rollNumber}</td>
            ) : (
              <td>-</td>
            )
          ) : null}

          {/* ***************************userActivityTracker********************** */}

          {pagename === "userActivityTracker" ||
          pagename === "userActivityTrackerTeamLead" ? (
            item?.studentName ? (
              <td className="text-break  "> {item?.studentName}</td>
            ) : (
              <td>-</td>
            )
          ) : null}
          {pagename === "userActivityTracker" ||
          pagename === "userActivityTrackerTeamLead" ? (
            item?.url ? (
              <td className="text-break  ">
                {" "}
                {item?.url.split("/").join(" -> ")}
              </td>
            ) : (
              <td>-</td>
            )
          ) : null}
          {pagename === "userActivityTracker" ||
          pagename === "userActivityTrackerTeamLead" ? (
            item?.date ? (
              <td className="text-break  ">
                {" "}
                {moment(item?.date).utc().format("LTS")}
              </td>
            ) : (
              <td>-</td>
            )
          ) : null}
          {pagename === "userActivityTracker" ||
          pagename === "userActivityTrackerTeamLead" ? (
            item?.date ? (
              <td className="text-break  ">
                {" "}
                {moment(item?.date).utc().format("DD-MM-YYYY")}
              </td>
            ) : (
              <td>-</td>
            )
          ) : null}

          {pagename === "userActivityTrackerTeamLead" ? (
            item?.counselorName ? (
              <td className="text-break  ">{item?.counselorName}</td>
            ) : (
              <td>-</td>
            )
          ) : null}

          {/* *************************** References Generated List********************** */}

          {pagename === "referenceLeadsList" ? (
            item?.exam ? (
              <td className="text-break  ">
                {" "}
                {item?.exam ? item?.exam : "-"} -
                {item?.examStream ? item?.examStream : "-"}
              </td>
            ) : (
              <td>-</td>
            )
          ) : null}

          {pagename === "referenceLeadsList" ? (
            item?.enrolled === true || item?.enrolled === false ? (
              <td className="text-break  ">{item.enrolled ? "Yes" : "No"}</td>
            ) : (
              <td>-</td>
            )
          ) : null}

          {pagename === "referenceLeadsList" ? (
            item?.revenue ? (
              <td className="text-break  "> {item?.revenue}</td>
            ) : (
              <td>-</td>
            )
          ) : null}

          {pagename === "referenceLeadsList" ? (
            item?.statusName ? (
              <td className="text-break">{item?.statusName}</td>
            ) : (
              <td>-</td>
            )
          ) : null}

          {/* ******************** HR Candidates list*************** */}

          {pagename === "resumeShared" ||
          pagename === "firstRoundInterview" ||
          pagename === "secondRoundInterview" ||
          pagename === "hrRoundInterview" ||
          pagename === "rejected" ||
          pagename === "joiningToday" ||
          pagename === "joiningInWeek" ||
          pagename === "callingDone" ? (
            item?.firstName ? (
              <td className="text-break cursor">{item?.firstName}</td>
            ) : (
              <td>-</td>
            )
          ) : null}

          {pagename === "resumeShared" ||
          pagename === "firstRoundInterview" ||
          pagename === "secondRoundInterview" ||
          pagename === "hrRoundInterview" ||
          pagename === "rejected" ||
          pagename === "joiningToday" ||
          pagename === "joiningInWeek" ||
          pagename === "callingDone" ? (
            item?.lastName ? (
              <td className="text-break cursor">{item?.lastName}</td>
            ) : (
              <td>-</td>
            )
          ) : null}

          {pagename === "candidateStatusRecords" ? (
            item?.name ? (
              <td className="text-break cursor">{item?.name}</td>
            ) : (
              <td>-</td>
            )
          ) : null}

          {pagename === "candidateStatusRecords" ? (
            item?.assignStatus ? (
              <td className="text-break cursor">{item?.assignStatus}</td>
            ) : (
              <td>-</td>
            )
          ) : null}

          {pagename === "resumeShared" ||
          pagename === "firstRoundInterview" ||
          pagename === "secondRoundInterview" ||
          pagename === "hrRoundInterview" ||
          pagename === "rejected" ||
          pagename === "joiningToday" ||
          pagename === "joiningInWeek" ||
          pagename === "callingDone" ? (
            item?.stateName ? (
              <td className="text-break cursor">{item?.stateName}</td>
            ) : (
              <td>-</td>
            )
          ) : null}
          {/* {pagename === "resumeShared" ? (
            item?.resume ? (
              <td className="text-break cursor">
                <a
                  href={`https://media.ifasonline.com/development/HR_Candidates/${item?.resume}`}
                  target="_blank"
                >
                  VIEW RESUME
                </a>
              </td>
            ) : (
              <td>-</td>
            )
          ) : null} */}
          {pagename === "resumeShared" ||
          pagename === "firstRoundInterview" ||
          pagename === "secondRoundInterview" ||
          pagename === "hrRoundInterview" ||
          pagename === "rejected" ||
          pagename === "joiningToday" ||
          pagename === "joiningInWeek" ||
          pagename === "callingDone" ||
          pagename === "candidateStatusRecords" ? (
            item?.departmentName ? (
              <td className="text-break cursor">{item?.departmentName}</td>
            ) : (
              <td>-</td>
            )
          ) : null}
          {pagename === "resumeShared" ||
          pagename === "firstRoundInterview" ||
          pagename === "secondRoundInterview" ||
          pagename === "hrRoundInterview" ||
          pagename === "rejected" ||
          pagename === "joiningToday" ||
          pagename === "joiningInWeek" ||
          pagename === "callingDone" ||
          pagename === "candidateStatusRecords" ? (
            item?.designation ? (
              <td className="text-break cursor">{item?.designation}</td>
            ) : (
              <td>-</td>
            )
          ) : null}

          {pagename === "candidateStatusRecords" ? (
            item?.currentStatus ? (
              <td className="text-break cursor">{item?.currentStatus}</td>
            ) : (
              <td>-</td>
            )
          ) : null}

          {pagename === "resumeShared" ||
          pagename === "firstRoundInterview" ||
          pagename === "secondRoundInterview" ||
          pagename === "hrRoundInterview" ||
          pagename === "rejected" ||
          pagename === "joiningToday" ||
          pagename === "joiningInWeek" ||
          pagename === "callingDone" ||
          pagename === "candidateStatusRecords" ? (
            item?.createdAt ? (
              <td className="text-break cursor">
                {item.createdAt
                  ? moment(item.createdAt).utc().format("DD-MM-YYYY ")
                  : ""}
              </td>
            ) : (
              <td>-</td>
            )
          ) : null}

          {/* ****************************CallList************************* */}

          {pagename === "orderLeadsList" ? (
            item?.userDetails?.firstName ? (
              <td className="text-break cursor">
                {`${item?.userDetails?.firstName} ${item?.userDetails?.lastName}`}
              </td>
            ) : (
              <td>-</td>
            )
          ) : null}

          {pagename === "orderLeadsList" ? (
            item?.courseDetails?.length > 0 ? (
              getCourseArrayList(item?.courseDetails)
            ) : (
              <td>-</td>
            )
          ) : null}

          {pagename === "orderLeadsList" ? (
            item?.netPayableAmount ? (
              <td className="text-break cursor">
                &#8377;{item?.netPayableAmount}
              </td>
            ) : (
              <td>-</td>
            )
          ) : null}

          {pagename === "orderLeadsList" ? (
            item?.couponName ? (
              <td className="text-break cursor">{item?.couponName}</td>
            ) : (
              <td>-</td>
            )
          ) : null}

          {pagename === "orderLeadsList" ? (
            item?.date ? (
              <td className="text-break cursor">
                {item.date ? moment(item.date).utc().format("DD-MM-YYYY ") : ""}
              </td>
            ) : (
              <td>-</td>
            )
          ) : null}

          {/* ******************************************* Sales Director Manager******************************************************** */}
          {pagename === "salesDirectorManager" ||
          pagename === "salesDirectorTeamLead" ? (
            item ? (
              <td className="text-break">
                {" "}
                {pagename === "salesDirectorManager"
                  ? item?.managerName
                  : item.teamLeadName}
              </td>
            ) : (
              <td>-</td>
            )
          ) : null}
          {pagename === "salesDirectorManager" ||
          pagename === "salesDirectorTeamLead" ? (
            item?.targetRevenue ? (
              <td className="text-break"> {item.targetRevenue?.toFixed(2)}</td>
            ) : (
              <td>0</td>
            )
          ) : null}
          {pagename === "salesDirectorManager" ||
          pagename === "salesDirectorTeamLead" ? (
            item?.achievedRevenue ? (
              <td className="text-break">
                {" "}
                {item.achievedRevenue?.toFixed(2)}
              </td>
            ) : (
              <td>0</td>
            )
          ) : null}
          {pagename === "salesDirectorManager" ||
          pagename === "salesDirectorTeamLead" ? (
            item?.deficit ? (
              <td className="text-break"> {item.deficit?.toFixed(2)}</td>
            ) : (
              <td>0</td>
            )
          ) : null}
          {pagename === "salesDirectorManager" ||
          pagename === "salesDirectorTeamLead" ? (
            item?.percent ? (
              <td className="text-break"> {item.percent}%</td>
            ) : (
              <td>0%</td>
            )
          ) : null}

          {pagename === "UpcomingWebinar" ||
          pagename === "BDEUpcomingWebinar" ||
          pagename === "UpcomingTLWebinar" ? (
            <td
              className="text-success fw-bold cursor"
              onClick={() => handleNavigate(item)}
            >
              Status
            </td>
          ) : null}

          {pagename === "completeWebinar" ||
          pagename === "completeWebinarTL" ||
          pagename === "cancelledWebinar" ||
          pagename === "cancelledWebinarTL" ||
          pagename === "PendingWebinarTL" ||
          pagename === "PendingWebinar" ||
          pagename === "notIntrestedBDELead" ||
          pagename === "notIntrestedBDTeamLead" ||
          pagename === "IntrestedBDELead" ||
          pagename === "CallBackBDELead" ||
          pagename === "overdueCallBackBDELead" ||
          pagename === "OverdueInterestedBDELead" ||
          pagename === "TLcollegeList" ||
          pagename === "notInterestedDepartment" ||
          pagename === "interestedDepartment" ||
          pagename === "callBackDepartment" ||
          pagename === "overdueInterestedDepartment" ||
          pagename === "showResult" ? (
            <td
              className="text-success fw-bold cursor"
              onClick={() => handleNavigate(item)}
            >
              Details
            </td>
          ) : null}

          {pagename === "Feedback" ||
          pagename === "BatchFeedbackDataList" ||
          pagename === "collegeList" ||
          pagename === "userActivityTracker" ||
          pagename === "userActivityTrackerTeamLead" ? (
            <td
              className="text-success fw-bold cursor"
              onClick={() => handleNavigate(item)}
            >
              View Details
            </td>
          ) : null}

          {pagename === "Employee" ||
          pagename === "HrEmployee" ||
          pagename === "IncentiveAllocation" ||
          pagename === "IncentiveTargetAllocation" ||
          pagename === "BDEmployee" ||
          pagename === "BdeIncentiveAllocation" ||
          pagename === "zoneList" ||
          pagename === "ManageEmployee" ? (
            <td>
              <span
                className="text-Dark fw-bold me-2 cursor"
                onClick={() => handleEdit(item)}
              >
                <BorderColorIcon />
              </span>
            </td>
          ) : null}

          {pagename === "leadRecord" ? (
            item?.isCounsellor ? (
              <td>
                <span
                  className="text-Dark fw-bold me-2 cursor"
                  onClick={() => handleEdit(item)}
                >
                  <BorderColorIcon />
                </span>
              </td>
            ) : (
              <td>-</td>
            )
          ) : null}
          {pagename === "Employee" ||
          pagename === "HrEmployee" ||
          pagename === "IncentiveAllocation" ||
          pagename === "IncentiveTargetAllocation" ||
          pagename === "BDEmployee" ||
          pagename === "zoneList" ||
          pagename === "BdeIncentiveAllocation" ? (
            <td>
              {handleDelete ? (
                <span
                  className="text-Dark fw-bold cursor"
                  onClick={() => handleDelete(item)}
                >
                  {" "}
                  <DeleteIcon />{" "}
                </span>
              ) : null}
            </td>
          ) : null}

          {pagename === "CounsellingLeads1" ? (
            <td>
              <span
                // type="submit"
                className="text-success me-2 fw-bold cursor"
                onClick={() => handleEdit(item)}
              >
                View Details
              </span>
            </td>
          ) : null}
          {addOnButton ? (
            <span
              // type="submit"
              className="btn btn-primary btn-sm"
              onClick={() => handleAddOnButton(item)}
            >
              {addOnButtonLabel}
            </span>
          ) : null}

          {pagename === "question" ? (
            <td>
              <span
                // type="submit"
                className="btn btn-primary btn-sm rounded-pill"
                onClick={() => handleAddOnButton(item)}
              >
                {addOnButtonLabel}
              </span>
            </td>
          ) : null}

          {/* *********************** Call Expert Support */}

          {pagename === "callExpertSupport" ? (
            item?.fullName ? (
              <td>{item?.fullName}</td>
            ) : (
              <td>-</td>
            )
          ) : null}

          {pagename === "callExpertSupport" ? (
            item?.updatedAt ? (
              <td>{moment(item?.updatedAt).format("DD - MM - YYYY")}</td>
            ) : (
              <td>-</td>
            )
          ) : null}

          {pagename === "callExpertSupport" ? (
            item?.updatedAt ? (
              <td>{moment(item?.updatedAt).format("hh:mm:ss a")}</td>
            ) : (
              <td>-</td>
            )
          ) : null}
          {pagename === "callExpertSupport" ? (
            item?.count ? (
              <td>{item?.count}</td>
            ) : (
              <td>-</td>
            )
          ) : null}

          {pagename === "callExpertSupport" ? (
            item?.Remark ? (
              <td>{item?.Remark}</td>
            ) : (
              <td>-</td>
            )
          ) : null}

          {pagename === "callExpertSupport" ? (
            item?.remarkDate ? (
              <td>{moment(item?.remarkDate).format("DD/MM/YYYY LTS")}</td>
            ) : (
              <td>-</td>
            )
          ) : null}

          {pagename === "callExpertSupport" ? (
            <td
              className="text-success fw-bold cursor"
              onClick={() => handleEdit(item)}
            >
              View Details
            </td>
          ) : null}

          {/* *********************** Live Classs Schedule */}

          {pagename === "liveClassSchedule" ? (
            item?.baseCourseId ? (
              <td>{item?.baseCourseId?.name}</td>
            ) : (
              <td>-</td>
            )
          ) : null}

          {pagename === "liveClassSchedule" ? (
            item?.examTypeId ? (
              <td>{item?.examTypeId?.name}</td>
            ) : (
              <td>-</td>
            )
          ) : null}
          {pagename === "liveClassSchedule" ? (
            item?.subExamTypeId ? (
              <td>{item?.subExamTypeId?.name}</td>
            ) : (
              <td>-</td>
            )
          ) : null}

          {pagename === "courseSupport" ? (
            item?.issueId ? (
              <td>{item?.issueId}</td>
            ) : (
              <td>-</td>
            )
          ) : null}

          {pagename === "courseSupport" ? (
            item?.createdAt ? (
              <td>
                {new Date(item?.createdAt).toString().slice(4).substring(0, 20)}
              </td>
            ) : (
              <td>-</td>
            )
          ) : null}

          {pagename === "liveClassSchedule" || pagename === "courseSupport" ? (
            item?.courseId ? (
              <td>{item?.courseId?.name}</td>
            ) : (
              <td>-</td>
            )
          ) : null}

          {pagename === "liveClassSchedule" ? (
            item?.fromDate ? (
              <td>
                {item?.fromDate}
                {item?.sessionTime ? `, ${item?.sessionTime}` : null}
              </td>
            ) : (
              <td>-</td>
            )
          ) : null}

          {pagename === "liveClassSchedule" ? (
            item?.sessionId ? (
              <td>{item?.sessionId?.name}</td>
            ) : (
              <td>-</td>
            )
          ) : null}

          {pagename === "liveClassSchedule" ? (
            <td>
              {item.status === 1 ? (
                <div className="position-relative statusbtn">
                  <button
                    className="add_Question-btn btn btn btn-outline-danger btn-sm"
                    onClick={() => handleCancelButton(item)}
                  >
                    Cancel
                  </button>
                  <button
                    className="add_Question-btn btn btn btn-outline-primary btn-sm"
                    onClick={() => handleRescheduleButton(item)}
                  >
                    Reschedule
                  </button>
                </div>
              ) : item.status === 2 ? (
                <button
                  className="add_Question-btn btn btn btn-outline-danger btn-sm me-4"
                  onClick={() => handleEndLiveButton(item)}
                >
                  End Live
                </button>
              ) : item.status === 9 ? (
                "Live Cancelled"
              ) : item.status === 3 ? (
                "Completed"
              ) : null}
            </td>
          ) : null}

          {/* ******************    HR Candidate Search    **************** */}
          {pagename === "candidateRecord" ? (
            item?.CandidateName ? (
              <td className="text-break">{item?.CandidateName}</td>
            ) : (
              <td>-</td>
            )
          ) : null}

          {pagename === "candidateRecord" ? (
            item?.status ? (
              <td className="text-break">{item?.status}</td>
            ) : (
              <td>-</td>
            )
          ) : null}

          {pagename === "candidateRecord" ? (
            item?.Counsellorname ? (
              <td className="text-break">{item?.Counsellorname}</td>
            ) : (
              <td>-</td>
            )
          ) : null}

          {pagename === "candidateRecord" ? (
            item?.departmentName ? (
              <td className="text-break">{item?.departmentName}</td>
            ) : (
              <td>-</td>
            )
          ) : null}

          {pagename === "candidateRecord" ? (
            item?.subDepartmentName ? (
              <td className="text-break">{item?.subDepartmentName}</td>
            ) : (
              <td>-</td>
            )
          ) : null}

          {pagename === "candidateRecord" ? (
            item?.designation ? (
              <td className="text-break">{item?.designation}</td>
            ) : (
              <td>-</td>
            )
          ) : null}

          {pagename === "candidateRecord" ? (
            item?.isExective ? (
              <td>
                <span
                  className="text-Dark fw-bold me-2 cursor"
                  onClick={() => handleEdit(item)}
                >
                  <BorderColorIcon />
                </span>
              </td>
            ) : (
              <td>-</td>
            )
          ) : null}

          {pagename === "courseSupport" ? (
            item?.subjectId ? (
              <td>{item?.subjectId?.name}</td>
            ) : (
              <td>-</td>
            )
          ) : null}

          {pagename === "courseSupport" ? (
            item?.topicId ? (
              <td>{item?.topicId?.name}</td>
            ) : (
              <td>-</td>
            )
          ) : null}
          {pagename === "courseSupport" ? (
            item?.userId ? (
              <td>{`${item?.userId?.firstName} ${item?.userId?.lastName}`}</td>
            ) : (
              <td>-</td>
            )
          ) : null}

          {pagename === "technicalSupport" ? (
            item?.issueId ? (
              <>
                <td>{item?.issueId}</td>
                <td>
                  {new Date(item?.createdAt)
                    .toString()
                    .slice(4)
                    .substring(0, 20)}
                </td>
                <td>{`${item?.userId?.firstName} ${item?.userId?.lastName}`}</td>
                <td>{`${item?.userId?.mobileNo}`}</td>
                <td>{item?.courseId?.name}</td>
                <td>{item?.name}</td>
                <td>
                  {item?.technicalSupportType === 1
                    ? "App Related"
                    : "Course Related"}
                </td>
                <td>{item?.issue}</td>
              </>
            ) : (
              <td>-</td>
            )
          ) : null}

          <td>
            {(pagename === "technicalSupport" ||
              pagename === "courseSupport" ||
              pagename === "raiseIssue" ||
              pagename === "raiseTeacherIssue" ||
              pagename === "helpdesk" ||
              pagename === "InfraIssue") &&
              renderStatusButton(item)}
          </td>
        </tr>
      );
    });
  };

  return (
    <div className="container-fluid pe-0 ps-0">
      <div className="table-responsive">
        <table className=" w-100">
          <thead className=" font-12">
            <tr>
              {pagename === "LeadTransfer" ? (
                <th>
                  <span>
                    <input
                      className="check"
                      type="checkbox"
                      checked={isSelectedCheckbox}
                      onChange={(value: any) => {
                        handleAllCheck(value);
                      }}
                    />
                  </span>
                </th>
              ) : null}
              {tableHead?.map(
                (
                  data:
                    | string
                    | number
                    | boolean
                    | ReactElement<any, string | JSXElementConstructor<any>>
                    | Iterable<ReactNode>
                    | ReactPortal
                    | null
                    | undefined,
                  index: Key | null | undefined
                ) => {
                  return <th key={index}>{data}</th>;
                }
              )}
            </tr>
          </thead>
          <tbody className="">
            {tableData?.length > 0 ? (
              renderTableRows()
            ) : (
              <tr>
                <td>No record found.</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default PaginationTable;
