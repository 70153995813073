import React, { useEffect, useState } from "react";
import "../../../App.css";
import PageTitle from "../../../Component/pageTitle";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import LabelField from "../../../Component/labelField";
import Pagination from "@mui/material/Pagination";
import usePagination from "../../../Component/usePagination";
import PaginationTable from "../../../Component/paginationTable";
import PaginationView from "../../../Component/paginationView";
import { getTableDataSlots } from "../../../DATA/dataConstant";
import CustomDropdown from "../../../Component/customDropdown";
import { showErrorSnackbar } from "../../../Redux/Actions/snackbarAction";
import {
  getExamList,
  getExamStreamList,
  getTeacherListAll,
  doubtSolveReview,
} from "../../../Redux/Actions/counsellorPanelAction";

function DoubtSolveReview() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [examName, setExamName] = useState<any>("");
  const [examList, setExamList] = useState<any>([]);
  const [parentCourseVal, setparentCourseVal] = useState<any>("");
  const [parentCourseList, setParentCourseList] = useState<any>([]);
  const [teacherList, setteacherList] = useState<any>([]);
  const [teachername, setteachername] = useState<any>("");
  const [doubtdata, setdoubtdata] = useState<any>([]);

  const [selectedStatus, setSelectedStatus] = useState<any>(1);

  // pagination
  const [count, setCount] = useState<any>(0);
  const [page, setPage] = useState<any>(0);
  const [rowsPerPage, setRowsPerPage] = useState<any>(10);
  const [currentPage, setCurrentPage] = useState<any>(1);
  const totalPages = Math.ceil(count / rowsPerPage);
  const _DATA = usePagination(count, rowsPerPage);

  const handleChange = (e: any, page: any) => {
    onPageChange(page, rowsPerPage, 1);
    setPage(page - 1);
    setCurrentPage(page);
    _DATA.jump(page);
  };

  const handleChangePage = (event: any, newPage: any) => {
    setPage(newPage);
    setCurrentPage(newPage + 1);
  };

  const handleChangeRowsPerPage = (event: any) => {
    const value = event.target.value;
    if (value === "All") {
      setRowsPerPage(-1);
      setPage(0);
      setCurrentPage(1);
      return;
    }
    setRowsPerPage(parseInt(value, 10));
    setPage(0);
    setCurrentPage(1);
    onPageChange(page, value, 2);
  };

  const onPageChange = (page: any, rowsPerPage: any, flag: any) => {
    /************ page change****************** */
    let data: any = {};
    if (flag === 1) {
      data = {
        skip: (page - 1) * rowsPerPage,
        limit: rowsPerPage,
        teacherId: teachername._id,
        status: selectedStatus,
      };
    } else if (flag === 2) {
      data = {
        skip: 0,
        limit: rowsPerPage,
        teacherId: teachername._id,
        status: selectedStatus,
      };
    } else {
      data = {
        skip: 0,
        limit: count,
        teacherId: teachername._id,
        status: selectedStatus,
      };
    }

    if (parentCourseVal) data.baseCourseId = parentCourseVal._id;
    if (examName) data.examTypeId = examName._id;

    dispatch(
      doubtSolveReview(data, (res: any) => {
        setCount(res?.data?.count);
        setdoubtdata(res?.data?.data);
      })
    );
  };

  useEffect(() => {
    dispatch(
      getExamList((res: any) => {
        setExamList(res?.data);
      })
    );
    dispatch(
      getTeacherListAll((res: any) => {
        console.log("rssds", res?.data?.data);
        setteacherList(res?.data?.data);
      })
    );
  }, []);

  const changeExamType = (value: any) => {
    setExamName(value);
    if (value)
      dispatch(
        getExamStreamList(`${value._id}`, (res: any) => {
          setParentCourseList(res);
        })
      );
  };

  const changeParentCourseVal = (value: any) => {
    setparentCourseVal(value);
  };

  const changeTeacherName = async (value: any) => {
    await setteachername(value);

    await setdoubtdata([]);
    await setCount(0);
    await APICall(selectedStatus, value);
  };

  const APICall = (val: any, teacher: any) => {
    const postData: any = {
      skip: 0,
      limit: rowsPerPage,
      status: val,
      teacherId: teacher._id,
    };

    if (parentCourseVal) postData.baseCourseId = parentCourseVal._id;
    if (examName) postData.examTypeId = examName._id;
    if (teacher) {
      dispatch(
        doubtSolveReview(postData, (res: any) => {
          console.log("sdefdsa", res);
          setCount(res?.data?.count);
          setdoubtdata(res?.data?.data);
        })
      );
    }
  };

  const chat = (id: any, userId: any, status: any) => {
    navigate("../Chat", {
      state: { id: id, userId, status, screeName: "DoubtSolveReview" },
    });
  };

  const showData = (e: any, val: any) => {
    setSelectedStatus(val);
    if (teachername) APICall(val, teachername);
    else dispatch(showErrorSnackbar("Teacher is required") as any);
  };

  return (
    <div className="contentBox">
      <PageTitle name={"Doubt Solve Review"} />
      <div className="formWidth col-12">
        <div className="row d-flex ">
          <div className="col-6">
            <div className="d-flex mb-2 align-items-center">
              <LabelField lableName={"Exam Name"} isRequired={true} />
              <CustomDropdown
                setInputText={(value: any) => changeExamType(value)}
                value={examName}
                options={examList}
                lableName="Select Exam Name"
              />
            </div>
          </div>
          <div className="col-6">
            <div className="d-flex mb-2 align-items-center">
              <LabelField lableName={"Exam Stream"} isRequired={true} />
              <CustomDropdown
                setInputText={(value: any) => changeParentCourseVal(value)}
                value={parentCourseVal}
                options={parentCourseList}
                lableName="Select Exam Stream"
              />
            </div>
          </div>
          <div className="col-6">
            <div className="d-flex mb-2 align-items-center ">
              <LabelField lableName={"Teacher Name"} isRequired={true} />
              <CustomDropdown
                setInputText={(value: any) => changeTeacherName(value)}
                value={teachername}
                options={teacherList}
                lableName="Select Teacher Name"
              />
            </div>
          </div>
        </div>
        <div className="d-flex paginationdiv justify-content-end align-items-center">
          <Pagination
            count={totalPages}
            size="large"
            page={currentPage}
            variant="outlined"
            shape="rounded"
            color={"primary"}
            onChange={handleChange}
          />
        </div>
        <PaginationView
          count={count}
          title={"Issues"}
          tableData={doubtdata ? doubtdata : []}
          tabs={["New Issue", "In Progress", "Resolved"]}
          onTabChange={(e: any, index: any) => {
            showData(e, index);
          }}
          selectedIndex={selectedStatus}
          page={page}
          rowsPerPage={rowsPerPage}
          handleChangePage={handleChangePage}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
          rowsPerPageOptions={getTableDataSlots(count)}
        />
        <PaginationTable
          tableData={doubtdata ? doubtdata : []}
          pagename={"courseSupport"}
          rowsPerPage={rowsPerPage}
          page={page}
          tableHead={[
            "S.N",
            "Issue ID",
            "Issue Date",
            "Batch Name",
            "Subject Name",
            "Topic Name",
            "Full Name",
            "Status",
          ]}
          handleAddOnButton={(id: any, user: any, status: any) =>
            chat(id, user, status)
          }
          edit={true}
        />
      </div>
    </div>
  );
}

export default DoubtSolveReview;
