import { useEffect, useState } from "react";
import PageTitle from "../../Component/pageTitle";
import { useDispatch } from "react-redux";
import moment from "moment";
import { useSelector } from "react-redux";
import {
  getUserActivityById,
  makeCalltoLead,
} from "../../Redux/Actions/counsellorPanelAction";
import { useLocation, useNavigate } from "react-router-dom";
import GenaralTable from "../../Component/genaralTable";
import CallIcon from "@mui/icons-material/Call";

export default function UserActivityDetails() {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const {
    loggedInUserId,
    ozonetelAgentId,
    selectedLeadName,
    selectedClickedDate,
  } = useSelector((state: any) => ({
    loggedInUserId: state.Auth.loggedInUserId,
    ozonetelAgentId: state.Auth.ozonetelAgentId,
    selectedLeadName: state.Auth.selectedLeadName,
    selectedClickedDate: state.Auth.selectedClickedDate,
  }));

  const leadMobileNo = location.state?.leadMobileNo;

  const [userActivityDetailsList, setUserActivityDetailsList] = useState(0);

  useEffect(() => {
    dispatch(
      getUserActivityById(
        leadMobileNo,
        (res: any) => {
          setUserActivityDetailsList(res?.data);
        },
        () => {}
      )
    );
  }, []);

  const handleClick = (type: String) => {
    if (type === "call") {
      dispatch(
        makeCalltoLead(
          {
            crmEmployeeId: loggedInUserId,
            customerNumber: leadMobileNo,
            agentId: ozonetelAgentId,
          },
          (res: any) => {
            navigate("/leadDetail", {
              state: {
                pageName: "userActivityDetailsList",
                success: res?.success,
                leadMobileNo,
              },
            });
          },
          () => {}
        )
      );
    }
    if (type === "view") {
      navigate("/leadDetail", {
        state: {
          pageName: "userActivityDetailsList",
          leadMobileNo,
        },
      });
    }
  };

  const renderSearchMode = () => {
    return (
      <div>
        <div className="d-flex">
          <div className="border border-secondary rounded p-1 me-2  align-items-center col-6">
            <div className="d-flex  px-2 py-2">
              <span className="fw-bold me-3 col-3">Student Name : </span>
              <span className="col-12">{selectedLeadName}</span>
            </div>
            <div className="d-flex px-2 ">
              <span className="fw-bold me-3 col-3">Date : </span>
              <span className="col-2">
                {moment(selectedClickedDate).utc().format("DD-MM-YYYY")}
              </span>
            </div>
          </div>

          <div className="ms-5 col-6">
            <button
              type="button"
              className="btn btn-success me-2 font12"
              onClick={() => {
                handleClick("call");
              }}
            >
              {" "}
              <span>
                <CallIcon className="font12" />
              </span>
              <span className="d-none d-xl-inline-block ms-2">Call Now</span>
            </button>
            <button
              type="button"
              className="btn btn-outline-primary me-2 font12"
              onClick={() => {
                handleClick("view");
              }}
            >
              <span className="d-none d-xl-inline-block ms-2">
                View Details
              </span>
            </button>
          </div>
        </div>
        <div className="py-5">{renderListMode()}</div>
      </div>
    );
  };

  const renderListMode = () => {
    return (
      <div>
        <GenaralTable
          tableData={userActivityDetailsList ? userActivityDetailsList : []}
          pagename={"UserActivityDetails"}
          tableHead={["Sr.No.", "Activity Page Name", "Activity Time"]}
        />
      </div>
    );
  };

  return (
    <div className="contentBox ">
      <PageTitle name={"USER ACTIVITY "} />
      <div className="row m-2">
        <div className="col-md-12 formDiv formWidth">{renderSearchMode()}</div>
      </div>
    </div>
  );
}
