import { makeCalltoLead } from "./../Redux/Actions/counsellorPanelAction";
/* eslint-disable import/no-anonymous-default-export */
export default {
  // *************sample**********
  sample: {
    sample: "/v1/admin/academic/add",
  },
  auth: {
    login: "/v1/admin/auth/login",
    ForgotPassword: "/v1/admin/auth/forgot-password",
    UpdatePassword: "/v1/admin/auth/update-password",
    logout: "/v1/admin/auth/logout",
    BDElogin: "/v1/bdeAdmin/auth/login",
    HrLogin: "/v1/hr/admin/auth/login",
    HrLogout: "/v1/hr/admin/auth/logout",
  },
  Leads: {
    uploadImportLeadSheeet: "/v1/admin/leads/upload",
    leadTypeList: "/v1/admin/leads/list",
    importLeads: "/v1/admin/leads/import/",
    assignLeadList: "/v1/admin/leads/leads-list",
    expireLeadList: "/v1/admin/leads/upcoming-expire-list",
    admissionDoneLeadList: "/v1/admin/leads/admission-done-list",
    assignLead: "/v1/admin/leads/assign",
    assignSkipLead: "/v1/admin/leads/skip/assign",
    assignExpireLead: "/v1/admin/leads/assign-expire",
    assignAdmissionDoneLead: "v1/admin/leads/admission-done-assign",
    getSourceTypeList: "/v1/admin/auth/source-type/list",
  },
  manageEmployee: {
    getEmployeeList: "/v1/admin/auth/list-crm-employees",
    getTeamLeadEmployeeListById: "/v1/admin/auth/crmEmployeeListByCounsellorId",
    getManagerEmployeeListById: "/v1/admin/auth/crmEmployeeListByManagerId",
    getEmployeeRoleList: "/v1/admin/auth/role/list",
    getManagerList: "/v1/admin/auth/manager-list/",
    addEmployee: "/v1/admin/auth/add-crm-employee",
    deleteEmployee: "/v1/admin/auth/delete-crm-employee/",
    updateEmployee: "/v1/admin/auth/update-crm-employee/",
    restoreEmployee: "/v1/admin/auth/update-emp-status/",
  },
  exam: {
    getExamList: "/v2/admin/exam-type/list/",
    getSubExam: "/v1/admin/sub-exam/list",
    getExamStreamList: "/v2/admin/parent-course/list/",
    // getSubExam: "/v1/admin/general/sub-exam/list",
    // getTeachersList:"/v1/admin/teachers/list"
    getTeachersList: "/v1/admin/general/teachers/getTeacherlist",
  },
  leadTransfer: {
    getAssignedLeadsList: "/v1/admin/leads/assigned-lead/list",
    getCounsellorList: "/v1/admin/general/counsellor-list/",
    getStatusList: "/v1/admin/auth/lead-status/list",
    getCollegeList: "/v1/admin/leads/assigned-lead/Collegelist",
    getCollegeListForAssign: "/v1/admin/leads/assigned-lead/getleadCollegelist",
    getSubStatusList: "/v1/admin/auth/lead-sub-status/list/",
    transferLead: "/v1/admin/leads/transfer-lead",
  },
  IncentiveAllocation: {
    addIncentive: "/v1/admin/incentiveallocation/addincentiveAllocation",
    incentiveAllocationList:
      "/v1/admin/incentiveallocation/incentiveAllocationList",
    updateIncentive: "/v1/admin/incentiveallocation/update/",
    deleteIncentive: "/v1/admin/incentiveallocation/delete/",
    editIncentiveNewDate:
      "/v1/admin/incentiveallocation/addIncentiveAllocationWithNewDate",
    addIncentiveTarget: "/v1/admin/crmIncentiveTarget/addIncentiveTarget",
    getIncentiveTargetList: "/v1/admin/crmIncentiveTarget/incentiveTargetList",
    deleteIncentiveTarget: "/v1/admin/crmIncentiveTarget/delete/",
    updateIncentiveTarget: "/v1/admin/crmIncentiveTarget/update/",
    copyIncentiveTarget:
      "/v1/admin/crmIncentiveTarget/addIncentiveTargetWithNewDate",
  },
  CounsellorPanel: {
    leadAnalysisList: "/v1/admin/leads/assigned-lead/leadAnalysis",
    leadAnalysisTodayList: "/v1/admin/leads/assigned-lead/leadAnalysis/today",
    leadAnalysisOverdueList:
      "/v1/admin/leads/assigned-lead/leadAnalysis/overdue",
    getDashboardStatusRecords: "/v1/admin/dashboard/leads/status-record/",
    getDashboardRevenueRecords: "/v1/admin/dashboard/leads/getTargetRevenue/",
    getDashboardRecords: "/v1/admin/dashboard/counsellor/record/",
    getDashboardIncomingRecords: "/v1/admin/dashboard/counsellor/record-v2/",
    getDashboardCallRecords: "/v1/admin/dashboard/counsellor/call-record/",
    getStudentDetailsById: "/v1/admin/lead-analysis/get/",
    getUserActivityList: "/v1/admin/lead-analysis/user-activity-list",
    getUserActivityById: "/v1/admin/lead-analysis/get-user-activity/",
    getUpcomingCallById: "/v1/admin/dashboard/leads/upcoming-call/",
    getDashboardTotalRevenue: "/v1/admin/dashboard/leads/total-revenue/",
    getStatesList: "/v1/states",
    // getStatesList: "/v1/admin/general/states",
    getCountryList: "/v1/cities/",
    // getCountryList: "/v1/admin/general/cities/",
    getMainCategoryList: "/v1/admin/main-category/list",
    // getMainCategoryList: "/v1/admin/general/main-category/list",
    // updateLeadDetails: "/v1/admin/lead-analysis/save",
    updateLeadDetails: "/v1/admin/lead-analysis/savenew",
    referNewLead: "/v1/admin/lead-analysis/create-lead",
    getCallList: "/v1/admin/dashboard/leads/upcoming-call-notification/",
    getIncomingCallList: "/v1/admin/dashboard/incoming-call",
    getCallHistory: "/v1/admin/lead-analysis/getCallHistory",
    // makeCalltoLead: "/v1/admin/dashboard/lead-counsellor/make-call",
    makeCalltoLead: "/v1/admin/dashboard/lead-counsellor/make-leadcall",
    getBatchName: "/v1/admin/video-lectures/premier/courselist",
    getStudentLeadScore: "/v1/admin/lead-analysis/student-lead/score/",
    getLeadByMobileNumber: "/v1/admin/leads/getLeadbymobileNo",
    getLeadAnalysisSummary:
      "/v1/admin/leads/assigned-lead/leadAnalysis/summary",
    getLeadPaymentHistory: "/v1/admin/teamAnalysis/order-history",
    getCounsellorLeadAssignedOverview:
      "/v1/admin/leads/getCounsellor-newLeads-Details/",
    getLeadAssignedOverviewAnalysis: "/v1/admin/leads/overall-lead-Details/",
    getUserAnalysisByCID:
      "/v1/admin/teamAnalysis/studentAnalysisByCounsellorId",
    getUserAnalysisDetails: "/v1/admin/teamAnalysis/StudentAnalysisDetail",
    getCousesByUserId: "/v1/admin/teamAnalysis/getCousesByUserId",
    getCounsellorAllLeadsCount: "/v1/admin/dashboard/counsellor-LeadCount/",
    getChatBotLeadDetails: "/v1/admin/chatBot/get-chatBot-LeadDetails/",
    updateChatBotStatus: "/v1/admin/chatBot/update-chatBot-status/",
    addcounsellorChatBotMessages:
      "/v1/admin/chatBot/addcounsellor-chatBot-messages",
    getcounsellorChatBotMessages:
      "/v1/admin/chatBot/getcounsellor-chatBot-messages",
    updatecounselloChatBotReadmessages:
      "/v1/admin/chatBot/updatecounsellor-chatBot-readmessages",
    getCounsellorOrderDetails: "/v1/admin/lead-analysis/get-coupon-wise-orders",
    getAllCallList: "/v1/admin/dashboard/get-studentcall-details",
    getCounsellorTalktime: "/v1/admin/dashboard/get-counsellor-talkTimeList",
  },
  callToExpertSupport: {
    getCallToExpertSupportList: "/v1/admin/technicalSupport/expert-sale-lead",
    getCallToExpertSupportRemarkList:
      "/v1/admin/technicalSupport/getRemarkList/",
    addExpertSupportRemark: "/v1/admin/technicalSupport/add-Remark",
  },
  user: {
    getAllUser: "/v1/admin/registerUser/get-all-users/",
    deleteUser: "/v1/admin/registerUser/user-delete/",
  },
  doubtSolveReview: {
    getTeacherListAll: "/v1/admin/teachers/list",
    doubtSolveReview: "/v1/admin/supports/getSupport/doubtSolveReview",
  },
  liveClassSchedule: {
    getLiveLecturesList: "/v1/admin/video-lectures/live/lectureList/fetch",
    updateLiveLecturesById:
      "/v1/admin/video-lectures/liveLecture/scheduleUpdate/",
  },
  registrationDetails: {
    getSectionTypeList: "/v1/admin/course/get-main-types",
    createNewSubscription: "/v1/admin/registerUser/create/newSubscription",
    getPackageListWithFilter: "/v1/admin/course/packageListWithFilter",
    updateOldSubscription: "/v1/admin/registerUser/user-subscription-update/",
    updateUser: "/v1/admin/registerUser/user-update/",
    updateUserBaseCourse: "/v1/admin/auth/change-basecourse",
  },
  technicalSupport: {
    getAdminSupport: "/v1/admin/supports/get-support",
  },
  Chat: {
    getMessageList: "/v1/admin/supports/list-messages/",
    addSupportMessage: "/v1/admin/supports/add-support-message",
    updateCourseSupportResolve: "/v1/admin/supports/resolve-support/",
    uploadSupportMedia: "/v1/admin/supports/upload-file/",
    getTeacherHelpDeskMessagesList:
      "/v1/admin/teacher-help-desk/list-messages/",
    getTeacherAdminHelpDeskMessagesList:
      "/v1/admin/admin-help-desk/list-messages/",
    addMessage: "/v1/admin/teacher-help-desk/add-message",
    addMessageAdmin: "/v1/admin/admin-help-desk/add-message",
    closeIssue: "/v1/admin/admin-help-desk/close-issue/",
    getRaiseIssueMessageList: "/v1/admin/raiseIssue/admin-message-list/",
    getRaiseIssueTeacherMessageList: "/v1/admin/teachers/issue-message-list/",
    addTeacherIssue: "/v1/admin/teachers/send-issue-message",
    addAdminIssue: "/v1/admin/raiseIssue/admin-send-message",
    closeRaiseIssue: "/v1/admin/raiseIssue/close-issue/",
    uploadTeacherHelpdeskImage: "/v1/admin/teacher-help-desk/upload-file",
    uploadIssueImage: "/v1/admin/raiseIssue/upload-issue-image",
    issueTypeList: "/v1/admin/raiseIssue/list-issue-type",
    issueRemark: "/v1/admin/infra-issue/issue-type/",
    issueDuration: "/v1/admin/infra-issue/issue-duration",
    getCourseSupportList: "/v1/admin/supports/get-support",
  },
  multiDeviceTokenClear: {
    getRegisteredUser: "/v1/admin/registerUser/getUser/",
    startEndLiveStatus: "/v1/admin/supports/end-user-live",
  },
  TargetDashboard: {
    getTargetStatus: "/v1/admin/targetDashboard/getTargetStatus",
    // getIncentiveTargetStatus:
    //   "/v1/admin/targetDashboard/getIncentiveTargetData",
    getIncentiveTargetStatus:
      "/v1/admin/targetDashboard/getIncentiveTargetDetails",
  },
  General: {
    getCronTime: "/v1/admin/general/cron-time",
    updateCronTime: "/v1/admin/general/update/cron-time/",
    getCounsellorCount: "/v1/admin/general/counsellor-count",
    updateCounsellorCount: "/v1/admin/general/update/counsellor-count/",
    getCounsellorCallTime: "/v1/admin/general/call-time",
    updateCounsellorCallTime: "/v1/admin/general/update/call-time/",
    getCounsellorReference: "/v1/admin/general/reference",
    updateCounsellorReference: "/v1/admin/general/update/reference/",
    getCounsellorTotalConnectedCall: "/v1/admin/general/total-connected/call",
    updateCounsellorTotalConnectedCall:
      "/v1/admin/general/update/total-connected/call/",
    getCounsellorUniqueCall: "/v1/admin/general/unique/call",
    updateCounsellorUniqueCall: "/v1/admin/general/update/unique/call/",
    getGlobalSettingLeadScore: "/v1/admin/general/lead-score",
    updateGlobalSettingLeadScore: "/v1/admin/general/update/lead-score/",
    getGlobalSettingIncentives:
      "/v1/admin/incentiveallocation/incentive-allocation",
    updateGlobalSettingIncentives:
      "/v1/admin/incentiveallocation/update/incentive-allocation/",
    getTotalLeadCountByDate: "/v1/admin/leads/getTotalLeadCountByDate",
    getTotalLeadCount: "/v1/admin/leads/getLeadCount",
    getLeadCountExamwise: "/v1/admin/leads/getLeadCountExamWise",
    getLeadCountStatewise: "/v1/admin/leads/getLeadCountStateWise",
    getDailyMeetingForManager:
      "/v1/admin/superManagerDashboard/get-manager-records",
    getLeadOrdersSearch: "/v1/admin/Leads/get-all-users/",
    addLeadInCRM: "/v1/admin/Leads/addLeads/",
    addLeadOrderInCRM: "/v1/admin/order/crm/insert-lead-order",
    searchCoupon: "/v1/admin/teamAnalysis/search-coupon/",
    bindCoupon: "v1/admin/teamAnalysis/bind-coupon",
    checkOrderStatus: "/v1/orders/check-status/",
    assignStateZoneWise: "/v1/admin/zone/add",
    getZonesList: "/v1/admin/zone/fetch",
    getAssignedStatesZoneWise: "/v1/admin/zone/get/",
    updateAssignedStatesZoneWise: "/v1/admin/zone/update/",
    deleteAssignedStatesZoneWise: "/v1/admin/zone/delete/",
    getCronTimeByExam: "/v1/admin/general/update/cron-timing/",
    updateCronTimeByExam: "/v1/admin/general/cron-timing",
    getDidList: "/v1/admin/auth/agentdid/list",
  },
  RevenueAnalysis: {
    getRevenueAdmissionList:
      "/v1/admin/revenue-analysis/get-revenue-admission-list",
    // getRevenueLeadsList: "/v1/admin/revenue-analysis/get-revenue-leads-list",
    getRevenueLeadsList:
      "/v1/admin/revenue-analysis/get-revenue-leads-list-new",
    // getRevenueLeadsAnalysisList:
    //   "/v1/admin/revenue-analysis/get-revenue-between-dates",
    getRevenueLeadsAnalysisList:
      "/v1/admin/revenue-analysis/assigned-leads-revenue-between-dates",
    getExamRevenueList: "/v1/admin/revenue-analysis/get-Exam-revenue",
    getExamRevenueManagerList:
      "/v1/admin/superManagerDashboard/get-Exam-revenue-Manager",
    getCourseSubRevenueList:
      "/v1/admin/revenue-analysis/get-course-subscription-revenue",
    getCourseSubRevenueTLeadList:
      "/v1/admin/revenue-analysis/get-course-subscription-revenue-teamlead/",
    getCourseSubRevenueMangerList:
      "/v1/admin/revenue-analysis/get-course-subscription-revenue-manager/",
    getRevenueLeadsAnalysisStatusByDate:
      "/v1/admin/teamAnalysis/get-students-lead-analysis",
  },
  ManagerDashboard: {
    getTeamLeadList: "/v1/admin/managerDashboard/getcounsellorListByteamLeadId",
    getManagerRevenueList:
      "/v1/admin/superManagerDashboard/getteamLeadDataByManagerId",
    getTargetRevenueUnachieved:
      "/v1/admin/managerDashboard/getTargetRevenueUnachieved",
    getMonthlyTargetRevenueUnachieved:
      "v1/admin/managerDashboard/getMonthlyTargetRevenueUnachieved",
    getManagerTargetRevenueUnachieved:
      "/v1/admin/superManagerDashboard/getTargetRevenueUnachievedforManager",
    getMonthlyManagerTargetRevenueUnachieved:
      "/v1/admin/superManagerDashboard/getMonthlyTargetRevenueUnachievedforManager",
    getTeamLeadListByManager:
      "/v1/admin/managerDashboard/getteamLeadListByManagerId",
    getReferencesTeamLeadList: "/v1/admin/teamAnalysis/reference-analysis",
    getManagerReferencesByTeamLeadId:
      "/v1/admin/superManagerDashboard/getcounsellorAnalysisDetailsByTeamLeadId/",
    // getManagerReferencesTeamList: "/v1/admin/teamAnalysis/CounsellorCountbymanagerId",
    getManagerReferencesTeamList:
      "/v1/admin/superManagerDashboard/referenceAnalysisForManager",
    getEmployeetByManagerId: "/v1/admin/teamAnalysis/employee-list/",
    getCounsellorListByManagerId: "/v1/admin/auth/counsellor/list/",
    getTeamAnalysis: "/v1/admin/teamAnalysis/getLeadAnalysis",
    getTeamOverdueAnalysis: "/v1/admin/teamAnalysis/getOverDueLeadAnalysis",
    getCourseAnalysis: "/v1/admin/teamAnalysis/getCourseAnalysis",
    getCounsellorListById:
      "/v1/admin/managerDashboard/getcounsellorDetailsById",
    getReferenceCounsellorListById:
      "/v1/admin/teamAnalysis/getcounsellorDetailsById-analysis/",
    getReferenceListByCounsellorId:
      "/v1/admin/teamAnalysis/getreferenceGenerated-analysis/",
    getStudentLeadDetails:
      "/v1/admin/teamAnalysis/getLeadAnalysis/studentslead-details",
    getAssignedStudentLeadDetails:
      "/v1/admin/teamAnalysis/get-leadAnalysis/studentslead-details",
    getOverdueStudentLeadDetails:
      "/v1/admin/teamAnalysis/getLeadAnalysis/OverDuestudentslead-details",
    getCouponCodeList: "/v1/admin/managerDashboard/listCouponCode",
    getSellableExamList:
      "/v1/admin/managerDashboard/getsellableExamRevenueListByManagerId",
    getSellableExamListByExamID:
      "/v1/admin/managerDashboard/getsellableExamStreamRevenueListByExamId/",
    getTalkTimeList: "/v1/admin/managerDashboard/talk-time/list/",
    getManagerTalkTimeList:
      "/v1/admin/managerDashboard/talk-time/counsellor-list/",
    getIncomingTalkTimeList: "/v1/admin/managerDashboard/talk-time/list-v2/",
    getSellableExamRevenueList:
      "/v1/admin/managerDashboard/getsellableExamSectionsRevenueList",
    getTargetStatusByLeadId: "/v1/admin/targetDashboard/gettargetForTeamLead",
    getTargetStatusByManager: "/v1/admin/targetDashboard/gettargetForManager",
    getCounsellorDetailsByTeamId:
      "/v1/admin/managerDashboard/getcounsellorDetailsByteamLeadId/",
    addManagerRemark: "/v1/admin/lead-analysis/addReamrk/",
    getTeamLeadRemark: "/v1/admin/lead-analysis/getTeamLeadRemarkList",
    getManagerRemark: "/v1/admin/lead-analysis/getManagerRemarkList",
    getRemarkAnalysis: "/v1/admin/lead-analysis/getTeamLeadRemarkAnalysisList",
    getManagerRemarkAnalysis:
      "/v1/admin/lead-analysis/getManagerRemarkAnalysisList",
    getManagerCallRecord: "/v1/admin/managerDashboard/manager/call-record/",
    getManagerCallRecordByCounsellorId:
      "/v1/admin/managerDashboard/getcounsellorCallDetailsById",
    getTeamLeadDailyTalkTime:
      "/v1/admin/managerDashboard/manager/daily-talktime/",
    getDailyMeetingForByManagerId:
      "/v1/admin/superManagerDashboard/get-manager-records/",
    getTeamLeadAssignedOverview: "/v1/admin/leads/getTl-newLeads-Details/",
    getUserActivityList: "/v1/admin/teamAnalysis/user-activity-list",
    getManagerDailyTalkTime:
      "/v1/admin/managerDashboard/manager/daily-alltalktime/",
    getDailyIVRCallsDetails: "/v1/admin/dashboard/get-IVRcall-details",
    getDailyIVROutCallsDetails: "/v1/admin/dashboard/get-Outgoingcall-details",
    updateNotConnectedCounsellorCalls:
      "/v1/admin/dashboard/assign-notAnsweredCall",
  },

  TeamAnalysis: {
    getEnrolledAnalysis:
      "/v1/admin/teamAnalysis/getLeadAnalysis/enrolled-analysis",
  },

  Attendance: {
    getAttendanceReq: "/v1/admin/attendance/get-attendance-request",
    getAttendanceReqForManager:
      "/v1/admin/attendance//get-attendance-request-ByManagerId",
    getMonthlyReportForManager:
      "/v1/admin/attendance/get-employee-attendanceByManagerId",
    getTLAttendanceReq: "/v1/admin/attendance/get-tl-attendance-request",
    markAttendance: "/v1/admin/attendance/mark-attendance",
    getAttendanceUpdatedList: "/v1/admin/attendance/get-attendance-list",
    getMonthlyReport: "/v1/admin/attendance/get-employee-attendance",
  },

  //  *************************************BDE Panel****************************************************

  BDEAuth: {
    getTLbyManagerID: "/v1/bdeAdmin/auth/team-lead-list",
    getExecutiveByManagerID: "/v1/bdeAdmin/auth/executive-list",
  },
  BDEmanageEmployee: {
    getBdeEmployeeList: "/v1/bdeAdmin/auth/list-employees",
    deleteBdeEmployee: "/v1/bdeAdmin/auth/delete-employee/",
    getBdeManagerList: "/v1/bdeAdmin/auth/manager-list/",
    getBdeEmployeeRoleList: "/v1/bdeAdmin/auth/role-list",
    getState: "/v1/states",
    getCity: "/v1/cities/",
    restoreBdeEmployee: "/v1/bdeAdmin/auth/update-emp-status/",
    addBdeEmployee: "/v1/bdeAdmin/auth/add-employee",
    updateBdeEmployee: "/v1/bdeAdmin/auth/update-emp-details/",
  },
  BDtarget: {
    getCallTime: "/v1/bdeAdmin/general/call-time",
    updateCallTime: "/v1/bdeAdmin/general/update/call-time/",
    getTotalConnectedcall: "/v1/bdeAdmin/general/total-connected/call",
    updateTotalConnectedCall:
      "/v1/bdeAdmin/general/update/total-connected/call/",
    getUniqueCallTime: "/v1/bdeAdmin/general/unique/call",
    updateUniqueCallTime: "/v1/bdeAdmin/general/update/unique/call/",
    getDepartmentCall: "/v1/bdeAdmin/general/department/call",
    updateDepartmentcall: "/v1/bdeAdmin/general/update/department/call/",
    getWebinarCount: "/v1/bdeAdmin/general/webinar-count",
    updateWebinarCount: "/v1/bdeAdmin/general/update/webinar-count/",
  },
  BDEIncentiveAllocation: {
    BdeIncentiveAllocationList:
      "/v1/bdeAdmin/incentiveallocation/BDEincentiveAllocationList",
    addBdeIncentive:
      "/v1/bdeAdmin/incentiveallocation/addBdeincentiveAllocation",
    deleteBdeIncentive: "/v1/bdeAdmin/incentiveallocation/delete/",
    updateBdeIncentive:
      "/v1/bdeAdmin/incentiveallocation/update-incentive-target/",
    editBdeIncentiveNewDate:
      "/v1/bdeAdmin/incentiveallocation/add-incentive-Allocation-With-New-Date",
    getGlobalIncentiveAllocation:
      "/v1/bdeAdmin/incentiveallocation/incentive-allocation",
    updateGlobalIncentiveAllocaton:
      "/v1/bdeAdmin/incentiveallocation/update/incentive-allocation/",
  },
  BDEPanel: {
    getCollegeList: "/v1/bdeAdmin/collegeDetails/get-college-list",
    addCollege: "/v1/bdeAdmin/collegeDetails/addBdecollegeDetails",
    updateCollege: "/v1/bdeAdmin/collegeDetails/update-college-details/",
    addDepartment: "/v1/bdeAdmin/department/addBdeDepartment",
    getCollgeDetails:
      "/v1/bdeAdmin/collegeDetails/getBDECollegeDetailsListById/",
    getDepartmentList: "/v1/bdeAdmin/department/BDEdepartmentList/",
    getLeadStatus: "/v1/bdeAdmin/webinar/lead-status-list",
    getSubStatusbyStatusID: "/v1/bdeAdmin/webinar/lead-substatus-list/",
    getLeadType: "/v1/bdeAdmin/webinar/lead-type-list",
    getDepartmentDetails: "/v1/bdeAdmin/webinar/get-department-details",
    getOverdueDepartmentDetails:
      "/v1/bdeAdmin/webinar/get-overdue-department-details",
    getNotInterstedDepartmentDetails:
      "/v1/bdeAdmin/webinar/get-departmentWise-details",
    getDetailsbyDepartmentID:
      "/v1/bdeAdmin/department/getBDEDepartmentDetailsById/",
    updateDepartmentDetails:
      "/v1/bdeAdmin/department/updateBdeDepartmentDetails/",
    getCollegeDepartmentAdded:
      "/v1/bdeAdmin/webinar/get-college-department-added",
    getBDETargetStatus: "/v1/bdeAdmin/targetDashboard/getBDETargetStatus",
    getVrifyNumber: "/v1/bdeAdmin/department/check/",
    getBDEManagerRemark: "/v1/bdeTeamlead/college/getBDEManagerReamrkList",
  },
  BDEWebinar: {
    WebinarList: "/v1/bdeAdmin/webinar/get-webinar-list",
    uploadMailScreenshot: "/v1/bdeAdmin/webinar/upload-mail-screenshot",
    updateCompletedWebinar: "/v1/bdeAdmin/webinar/update-completed-webinar/",
    updateCancelledWebinar: "/v1/bdeAdmin/webinar/update-cancelled-webinar/",
    updateRescheduleWebinar: "/v1/bdeAdmin/webinar/update-reschedule-webinar/",
    updatePendingWebinar: "/v1/bdeAdmin/webinar/update-pending-webinar/",
    addWebinar: "/v1/bdeAdmin/webinar/add-webinar",
    getCallHistory: "/v1/bdeAdmin/general/getleadCallHistory",
    updateWebinar: "v1/bdeAdmin/webinar/update-webinar-details/",
    getWebinarStudentInfo: "/v1/bdeTeamlead/webinar/get-webinar-student-info/",
    getOverDueCallBackDetails:
      "/v1/bdeAdmin/webinar/get-overdue-callback-details",
  },
  BDEExecutiveDashboard: {
    getLeadDepartmentCount:
      "/v1/bdeAdmin/executiveDashboard/leadAndDepartmentCount",
    getStatusWiseDepartmentCount:
      "/v1/bdeAdmin/executiveDashboard/statusWiseDepartmentCount",
    getStatusWiseDepartmentRecordCount:
      "/v1/bdeAdmin/executiveDashboard/statusWiseDepartmentRecordCount",
    getOverdueDepartmentCount:
      "/v1/bdeAdmin/executiveDashboard/overdueDepartmentCount",
    makeCalltoLeadBde:
      "/v1/bdeAdmin/executiveDashboard/lead-counsellor/make-call",
    getTargetAchieved:
      "/v1/bdeAdmin/executiveDashboard/getLeadTargetAndAchieved",
    getTotalDepartmentAchieved:
      "/v1/bdeAdmin/executiveDashboard/getTotalDepartmentAndAchievedDepartment",
    getCallRecordCount: "/v1/bdeAdmin/executiveDashboard/call-record",
    getWebinarListByDeptID: "/v1/bdeAdmin/webinar/fetch-webinar-list/",
    getCallTimeCount: "/v1/bdeAdmin/executiveDashboard/call-time",
    getWebinarCompleteCount: "/v1/bdeAdmin/executiveDashboard/webinar-count",
    getWebinarCountExecutive: "/v1/bdeAdmin/webinar/get-webinar-Count",
    getOverDueIntrestCount: "/v1/bdeAdmin/webinar/get-overdue-Interested-Count",
    getOverDueCallBackCount: "/v1/bdeAdmin/webinar/get-overdue-callback-Count",
  },
  BDELeadAnalysis: {
    getLeadAnalysisData: "/v1/bdeAdmin/leadAnalysis/getLeadAnalysisData",
    getDepartmentLeadAnalysis:
      "/v1/bdeAdmin/leadAnalysis/get-lead-analysis-departmentWise",
  },
  BDTeamLead: {
    getBDCollegeList: "/v1/bdeTeamlead/college/get-college-list",
    getColgDetailsBycolgID: "/v1/bdeTeamlead/college/get-College-Details/",
    getDepartmentDetailsById: "/v1/bdeTeamlead/college/get-department-list/",
    getDeptDetailsByDeptID: "/v1/bdeTeamlead/college/get-department-details/",
    getCallHistory: "/v1/bdeTeamlead/college/getLeadCallHistoryForTeamLead",
    getBDTeamLeadReamrk: "/v1/bdeTeamlead/college/addManagerRemark/",
  },
  BDTeamLeadWebinar: {
    getLeadDepartmentCount:
      "/v1/bdeTeamlead/dashboard/lead-webinar-department-count",
    getTLWebinarList: "/v1/bdeTeamlead/webinar/get-webinar-list",
    getTLDepartmentList: "/v1/bdeTeamlead/webinar/get-department-list",
    getCallBackTodayAndInterestedTodaylist:
      "/v1/bdeTeamlead/webinar/get-departmentCallBackTodayAndInterestedToday-list",
    getOverdueInterestedDepartmentlist:
      "/v1/bdeTeamlead/webinar/get-OverdueIntersesteddepartment-list",
    getTLOverdueDepartmentList: "/v1/bdeTeamlead/webinar/get-department-list",
    getTLDepartmentAddedList:
      "/v1/bdeTeamlead/webinar/get-college-department-added",
  },
  BDManageTeam: {
    getTeamList: "/v1/bdeTeamlead/manageTeam/get-executive-details",
    updateEmployeeStatus: "/v1/bdeTeamlead/manageTeam/update-employee-status/",
  },
  BDETeamLeadDashboard: {
    getMonthlyWebinarTargetDetails:
      "/v1/bdeTeamlead/dashboard/monthly-webinar-target-details",
    getExecutiveWebinarTargetDetails:
      "/v1/bdeTeamlead/dashboard/executive-webinar-target-details",
    getMonthlyDepTargetDetails:
      "/v1/bdeTeamlead/dashboard/monthly-department-target-details",
    getExecutiveDeptTargetDetails:
      "/v1/bdeTeamlead/dashboard/executive-department-target-details",
    getExecutiveConnectedCallTargetDetails:
      "/v1/bdeTeamlead/dashboard/executive-connected-calls-target-details",
    getExecutiveConnectedCallTargetDetailsById:
      "/v1/bdeTeamlead/leadAnalysis/getexecutiveCallDetails",
    getBdeTalkTimeList: "/v1/bdeTeamlead/dashboard/talk-time-list",
    getAchievedUnachievedLeadtarget:
      "/v1/bdeTeamlead/dashboard/monthly-lead-target-details",
    getBdeTargetStatusTL: "/v1/bdeTeamlead/targetDashboard/get-target-status",
    getExecutiveListTeamLeadTarget:
      "/v1/bdeTeamlead/dashboard/executive-lead-target-details",
    getTalkTimeAnalysisByDate:
      "/v1/bdeTeamlead/dashboard/DateWise-talk-time-list",
  },
  BDETeamLeadAnalysis: {
    getTLLeadAnalysisData: "/v1/bdeTeamlead/leadAnalysis/get-lead-analysis",
    getLeadAnalysisiDetails:
      "/v1/bdeTeamlead/analysis/get-LeadAnalysis-Details",
    getExecutiveWebTargetDetails:
      "/v1/bdeTeamlead/analysis/get-WebinarAnalysis-Details",
    getDeptAnalysisDetails:
      "/v1/bdeTeamlead/analysis/get-DepartmentAnalysis-Details",
  },
  SuperManager: {
    getDailyMeetingForManager:
      "/v1/admin/superManagerDashboard/get-manager-records",
    // getTeamLeadAssignedOverview: "/v1/admin/leads/getTl-newLeads-Details/",
    getSMManagerList: "/v1/admin/superManagerDashboard/get-managerList/",

    getManagerwiseAllocatedRevenue:
      "/v1/admin/superManagerDashboard/getRoleWise-revenueTarget",
    getTeamwiseAllocatedRevenueTargetByManagerId:
      "/v1/admin/superManagerDashboard/getTeamLeadExamWise-revenueTargetByManagerId",
    getAllocatedRevenueTargetByExams:
      "/v1/admin/superManagerDashboard/getCounsellorExamBaseCourseWise-revenueTargetByManagerId",

    getManagerwiseOtherRevenue:
      "/v1/admin/superManagerDashboard/getOtherExam-revenueTargetForManager",
    getTeamwiseOtherRevenueTargetByManagerId:
      "/v1/admin/superManagerDashboard/getCounsellorOtherExam-revenueTargetByManagerId",
    getOtherRevenueTargetByExams:
      "/v1/admin/superManagerDashboard/getCounsellorOtherExamWise-revenueTargetByManagerId",

    getManagerwisePublicationRevenue:
      "/v1/admin/superManagerDashboard/getPublication-revenueForManager",
    getTeamwisePublicationRevenueTargetByManagerId:
      "/v1/admin/superManagerDashboard/getPublication-revenueByManagerId",
    getPublicationRevenueTargetByExams:
      "/v1/admin/superManagerDashboard/getCounsellorPublication-revenueByManagerId",

    geCounsellorRevenueTargetByRole:
      "/v1/admin/superManagerDashboard/getCounsellor-revenueTargetByRoles",

    getAllocatedRevenueTargetExamsByCounsellor:
      "/v1/admin/superManagerDashboard/getCounsellorExamBaseCourseWise-revenueTargetByCounsellorId",
    getOtherRevenueTargetExamsByCounsellor:
      "/v1/admin/superManagerDashboard/getCounsellorOtherExam-revenueByCounsellorId",
    getPublicationRevenueTargetExamsByCounsellor:
      "/v1/admin/superManagerDashboard/getCounsellorPublicationExamWise-revenueByCounsellorId",

    getManagerwiseRevenueTarget:
      "/v1/admin/superManagerDashboard/getExamWise-revenueTargetForManager",

    getTeamwLeadwiseRevenueTarget:
      "/v1/admin/superManagerDashboard/getExamWise-revenueTargetForTeamLead",
    geCounsellorwiseRevenueTargetByTeamId:
      "/v1/admin/superManagerDashboard/getCounsellorExamWise-revenueTargetByTeamLeadId",
    geCounsellorwiseRevenueTarget:
      "/v1/admin/superManagerDashboard/getExamWise-revenueTargetForCounsellor",
    geCounsellorwiseRevenueTargetByManagerId:
      "/v1/admin/superManagerDashboard/getExamWise-revenueTargetForCounsellor-ByManagerId",
    geCounsellorwiseRevenue:
      "/v1/admin/superManagerDashboard/getCounsellorLeadsAdmission-ByCounsellorId",
    /* admin apis */
    getEdutechTransaction:
      "/v1/admin/transactions/getUsersTransactions/edutech",
    getStudyMaterialTransaction:
      "/v2/admin/transactions/edutech-study-material",
    sourceAnalysis: "/v2/admin/admissionCount/source-wise",
    stateWiseAnalysis: "/v2/admin/admissionCount/state-wise",
    getExamWiseAnalysis: "/v2/admin/admissionCount/exam-wise",
    getBatchWiseCount: "/v2/admin/admissionCount/getBatchWiseCount",
    getBatchwiseAdmissionDetails:
      "/v2/admin/admissionCount/get-batchwise-details/",
    getDateWiseCount: "/v2/admin/admissionCount/getDateWiseCount",
    getAssuredRevenue: "/v1/admin/studentsCount/installments",
    getRegisteredUserList: "/v1/admin/registerUser/getUsers",
    getStateStudentCount: "/v1/admin/studentsCount/getCount-statewise",
    getStudentCount: "/v2/admin/studentsCount/getCount",
    getStateLeadCount: "/v1/admin/studentsCount/getCount-statewise-web-app",
    getWebAppLeads: "/v2/admin/studentsCount/getCount-web-app",
    getBranchList: "/v1/branch/list",
    getBatchListFilter: "/v1/admin/batch/list-filter",
    getClassroomRegisteredList:
      "/v1/admin/registerUser/getUsers-registration-details",
    getExpertSupportList: "/v1/auth/sale-lead",
    getLeadCountSource: "/v2/admin/studentsCount/get-datewise-count",
    getReferenceGenerated: "/v2/admin/studentsCount/get-reference-generated",
    salesPerformance: "/v1/cxo/sale-performance",
  },
  SuperManagerDashbord: {
    getRevenue:
      "/v1/admin/superManagerDashboard/getManagerDataBySuperManagerId",
    getTalkTimeData:
      "/v1/admin/superManagerDashboard/talk-time/getTalkTimeListForSuperManager",
  },

  SuperManagerTeamAnalysis: {
    getSMCouresAnalysis:
      "/v1/admin/teamAnalysis/getCourseAnalysisForSuperManager",
    getSMTeamLeadOverview:
      "/v1/admin/superManagerDashboard/get-newLeads-Details/",
    getSMReferenceAnalysis:
      "/v1/admin/superManagerDashboard/referenceAnalysisForSuperManager",
    getSMReferenceAnalysisByID:
      "/v1/admin/superManagerDashboard/referenceAnalysisForManager",
    getSMLeadAnalysis:
      "/v1/admin/superManagerDashboard/getLeadAnalysisForSuperManager",
    getSMEnrolledAnalysis:
      "/v1/admin/superManagerDashboard/enrolled-analysis-ForManager",
  },
  ResultCompilation: {
    getVrifyStudentNumber: "/v1/resultCompilation/verify-student/",
    getCasteCategoryList: "/v1/resultCompilation/caste-catagory-list",
    uploadResultImage: "/v1/resultCompilation/upload-file/",
    addResulCompilation: "/v1/resultCompilation/add",
    getResulCompilation: "/v1/resultCompilation/get",
  },
  HR: {
    getHrStatusList: "/v1/hr/admin/general/lead-status-list",
    getHrSubStatusList: "/v1/hr/admin/general/lead-sub-status/",
    uploadCVPDF: "/v1/hr/executive/candidate/upload-resume/",
    getHrEmployeeRoleList: "/v1/hr/admin/auth/role-list",
    getHrEmployeeList: "/v1/hr/admin/auth/list-employees",
    addHrEmployee: "/v1/hr/admin/auth/add-employee",
    getHrManagerList: "/v1/hr/admin/auth/manager-list",
    updateHrEmployee: "/v1/hr/admin/auth/update-emp-details/",
    deleteHrEmployee: "/v1/hr/admin/auth/delete-employee/",
    getHrEmployeeCompanyList: "/v1/companies",
    getHrEmployeeDepartmentList: "/v1/departments",
    getHrSubDepartment: "/v1/superAdmin/department/fetch-sub-department",
    getHrEmployeeDesignationList: "/v1/designations",
    addHrCandidate: "/v1/hr/executive/candidate/add",
    getHrDashboardStatusRecords: "/v1/hr/executive/dashboard",
    getHrCandidateStatusList: "/v1/hr/executive/candidate/get",
    getHrCandidateDetailsById: "/v1/hr/executive/candidate/get/",
    updateHrCandidateDetails: "/v1/hr/executive/candidate/update/",
    makeCalltoCandidate: "/v1/hr/executive/make-call",
    candidateSearch: "/v1/hr/executive/candidate/get-executive/",
    candidateCallHistory: "/v1/hr/executive/get-candidate-CallHistory",
    getCandidateStatusRecords: "/v1/hr/executive/get-candidatestatus-Records",
    getHrCallDetails: "/v1/hr/executive/get-candidatecall-details",
    getHrTalktime: "/v1/hr/executive/get-allExecutive-talktimelist",
    getHrExecutivesListByManagerId: "/v1/hr/admin/auth/executive-list",
    getHrLeadAnalysis: "/v1/hr/executive/getCandidateAnalysis",
    notimplemnted1: "/v1/hr/executive/getTl-newCandidate-Details/",
    getHrLeadAnalysisDetails:
      "/v1/hr/executive/getCandidateStatusAnalysisDetails",
  },
};
