import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import PageTitle from "../../Component/pageTitle";
import DatePicker from "../../Component/datePicker";
import LabelField from "../../Component/labelField";
import SubmitButton from "../../Component/submitButton";
import moment from "moment";
import { useSelector } from "react-redux";
import { getCounsellorLeadAssignedOverview } from "../../Redux/Actions/counsellorPanelAction";

export default function CounsellorLeadOverview() {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  const { selectedClickedDate } = useSelector((state: any) => ({
    selectedClickedDate: state.Auth.selectedClickedDate,
  }));

  const counsellorData = location.state?.counsellorData;
  const [startDate, SetStartDate] = useState<any>("");
  const [endDate, SetEndDate] = useState<any>("");
  const [leadsDataList, setLeadsDataList] = useState<any>([]);
  useEffect(() => {
    SetStartDate(moment(selectedClickedDate).format("YYYY-MM-DD"));
    SetEndDate(moment(selectedClickedDate).format("YYYY-MM-DD"));
    const data: any = {
      startDate: moment(selectedClickedDate).format("YYYY-MM-DD"),
      endDate: moment(selectedClickedDate).format("YYYY-MM-DD"),
    };
    console.log("qqqqqqqqqqqqqqq", data);
    dispatch(
      getCounsellorLeadAssignedOverview(
        counsellorData?.counsellorId,
        data,
        (res: any) => {
          setLeadsDataList(res);
        },
        () => {}
      )
    );
  }, []);

  const onClickLead = (e: any, data: any, assignedDate: any) => {
    navigate("../leadAssignedDateAnalysis", {
      state: { leadData: data, assignedDate, counsellorId: counsellorData },
    });
  };
  const renderSearchMode = () => {
    return (
      <form className="row d-flex ">
        <div className="col-md-4 d-flex mb-3 ">
          <div className="col-md-3 mt-2">
            <LabelField lableName="Start Date" />
          </div>
          <div className="col-md-9">
            <DatePicker
              value={startDate}
              setInputText={(value: string) => SetStartDate(value)}
              disabled={true}
            />
          </div>
        </div>
        <div className="col-md-4 d-flex mb-3">
          <div className="col-md-3 mt-2">
            <LabelField lableName="End Date" />
          </div>
          <div className="col-md-9">
            <DatePicker
              value={endDate}
              setInputText={(value: string) => SetEndDate(value)}
              disabled={true}
            />
          </div>
        </div>
      </form>
    );
  };

  const renderListMode = () => {
    return (
      <>
        <table className="w-100 ">
          <tr className="">
            <th>S.N</th>
            <th>Date</th>
            <th>Source</th>
          </tr>
          <tbody className=" ">
            {leadsDataList?.map((item: any, index: number) => {
              return (
                <tr>
                  <td>{index + 1}</td>
                  <td>{item?.assignedDate}</td>
                  <td>
                    <table className="w-100">
                      <tr>
                        <th>Total</th>
                        <th>{item?.totalLeadCount}</th>
                      </tr>
                      {item?.data?.map((subItem: any) => {
                        return (
                          <tr>
                            <td>{subItem?.source}</td>
                            <td
                              onClick={(e) =>
                                onClickLead(e, subItem, item?.assignedDate)
                              }
                            >
                              <span className="leaddetailsOverView cursor">
                                {subItem?.count}
                              </span>
                            </td>
                          </tr>
                        );
                      })}
                    </table>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </>
    );
  };

  return (
    <div className="contentBox">
      <PageTitle name={"Total Assigned Leads"} />
      <div className="col-md-12 row">
        <div className=" formDiv formWidth p-5">
          {renderSearchMode()}
          {renderListMode()}
        </div>
      </div>
    </div>
  );
}
