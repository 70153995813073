import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import PageTitle from "../../Component/pageTitle";
import DatePicker from "../../Component/datePicker";
import LabelField from "../../Component/labelField";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import BarGraph from "../../Component/barGraph";
import {
  getCounsellorAllLeadsCount,
  getDashboardCallRecords,
  getDashboardIncomingRecords,
  getDashboardRecords,
  getDashboardRevenueRecords,
  getDashboardTotalRevenue,
} from "../../Redux/Actions/counsellorPanelAction";
import { useSelector } from "react-redux";
import moment from "moment";
import { getHrDashboardStatusRecords } from "../../Redux/Actions/hrPanelAction";
// import CanvasJSChart from "@canvasjs/react-charts";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);
export default function HrDashbord() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [startDate, setStartDate] = useState<any>(
    moment.utc(new Date()).format("YYYY-MM-DD")
  );
  const [endDate, setEndDate] = useState<any>(
    moment.utc(new Date()).format("YYYY-MM-DD")
  );

  const [resumeShared, setResumeShared] = useState<number>(0);
  const [firstRoundInterview, setFirstRoundInterview] = useState<number>(0);
  const [secondRoundInterview, setSecondRoundInterview] = useState<number>(0);
  const [hrRoundInterview, setHrRoundInterview] = useState<number>(0);
  const [rejected, setRejected] = useState<number>(0);
  const [joiningToday, setJoiningToday] = useState<number>(0);
  const [joiningInWeek, setJoiningInWeek] = useState<number>(0);
  const [callingDone, setCallingDone] = useState<number>(0);
  const [onBoarding, setOnBoarding] = useState<number>(0);

  const [xLabel1, setXLabel1] = useState<any>([]);
  const [xLabel2, setXLabel2] = useState<any>([]);
  const [xLabel3, setXLabel3] = useState<any>([]);
  const [Graphdata1, setGraphdata1] = useState<any>([]);
  const [Graphdata2, setGraphdata2] = useState<any>([]);
  const [Graphdata3, setGraphdata3] = useState<any>([]);

  const [xLabel4, setXLabel4] = useState<any>([]);
  const [xLabel5, setXLabel5] = useState<any>([]);
  const [xLabel6, setXLabel6] = useState<any>([]);
  const [Graphdata4, setGraphdata4] = useState<any>([]);
  const [Graphdata5, setGraphdata5] = useState<any>([]);
  const [Graphdata6, setGraphdata6] = useState<any>([]);

  const {
    loggedInUserId,
    loggedInUserManagerId,
    loggedInUserRole,
    loggedInUserName,
  } = useSelector((state: any) => ({
    loggedInUserId: state.Auth.loggedInUserId,
    loggedInUserManagerId: state.Auth.loggedInUserManagerId,
    loggedInUserRole: state.Auth.loggedInUserRole,
    loggedInUserName: state.Auth.loggedInUserName,
  }));

  useEffect(() => {
    onChangeDashboard();
  }, []);

  const onChangeDashboard = () => {
    dispatch(
      getHrDashboardStatusRecords(
        "",
        (res: any) => {
          setResumeShared(res?.data?.RESUME_SHARED);
          setFirstRoundInterview(res?.data?.FIRST_ROUND_INTERVIEW);
          setSecondRoundInterview(res?.data?.SECOND_ROUND_INTERVIEW);
          setHrRoundInterview(res?.data?.HR_ROUND_INTERVIEW);
          setRejected(res?.data?.REJECTED);
          setJoiningToday(res?.data?.JOINING_TODAY);
          setJoiningInWeek(res?.data?.JOINING_IN_WEEK);
          setCallingDone(res?.data?.CALLING_DONE);
          setOnBoarding(res?.data?.ON_BOARDING);
        },
        () => {}
      )
    );
  };

  const onChange = (value: string) => {
    if (value === "resumeShared") {
      navigate("../resumeShared");
    } else if (value === "firstRoundInterview") {
      navigate("../firstRoundInterview");
    } else if (value === "secondRoundInterview") {
      navigate("../secondRoundInterview");
    } else if (value === "hrRoundInterview") {
      navigate("../hrRoundInterview");
    } else if (value === "rejected") {
      navigate("../rejected");
    } else if (value === "joiningToday") {
      navigate("../joiningToday");
    } else if (value === "joiningInWeek") {
      navigate("../joiningInWeek");
    } else if (value === "callingDone") {
      navigate("../callingDone");
    } else if (value === "onBoarding") {
      navigate("../onBoarding");
    }
  };

  const options = {
    animationEnabled: true,
    exportEnabled: false,
    title: {
      text: "Source Wise Analysis",
      fontFamily: "verdana",
    },
    axisY: {
      title: "Revenue",
    },
    axisX: {
      title: "Source",
      interval: 1,
    },
    toolTip: {
      shared: true,
      reversed: true,
    },
    data: [
      {
        // type: "stackedColumn",
        type: "column",
        name: "Actual Revenue",
        indexLabel: "{y}",
        showInLegend: true,
        dataPoints: [{ label: "VIpul", y: 2 }],
      },
      // transactionType?._id === 1
      //   ? {
      //       type: "stackedColumn",
      //       name: "GST Amount",
      //       indexLabel: "{y}",
      //       showInLegend: true,
      //       dataPoints: totalGSTGraph,
      //     }
      //   : {},
    ],
  };

  const renderGraphMode = () => {
    return (
      <div className="p-1 cursor">
        <div className="d-flex mx-2 flex-wrap">
          <div className="col-md-6 p-1">
            <div className="boxshadow  p-2">
              {/* <CanvasJSChart options={options} /> */}
              <BarGraph label={xLabel1} value={Graphdata1} />
              <div className=" d-flex justify-content-center">
                <label className="label me-2">Revenue</label>
              </div>
            </div>
          </div>
          <div className="col-md-6 p-1">
            <div className="boxshadow  p-2">
              <BarGraph label={xLabel2} value={Graphdata2} />
              <div className=" d-flex justify-content-center">
                <label className="label me-2">Calling Time</label>
              </div>
            </div>{" "}
          </div>
          <div className="col-md-6 p-1">
            <div className="boxshadow  p-2">
              <BarGraph label={xLabel3} value={Graphdata3} />
              <div className=" d-flex justify-content-center">
                <label className="label me-2">Reference</label>
              </div>
            </div>
          </div>
          <div className="col-md-6 p-1">
            <div className="boxshadow  p-2">
              <BarGraph label={xLabel4} value={Graphdata4} />
              <div className=" d-flex justify-content-center">
                <label className="label me-2">Total Connected Calls</label>
              </div>
            </div>
          </div>
          <div className="col-md-6 p-1">
            <div className="boxshadow  p-2">
              <BarGraph label={xLabel5} value={Graphdata5} />
              <div className=" d-flex justify-content-center">
                <label className="label me-2">Unique Connected Calls</label>
              </div>
            </div>
          </div>
          <div className="col-md-6 p-1">
            <div className="boxshadow  p-2">
              <BarGraph label={xLabel6} value={Graphdata6} />
              <div className=" d-flex justify-content-center">
                <label className="label me-2">Old Connected Calls</label>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="contentBox">
      <PageTitle name={"HR Dashboard"} loggedInUserRole={loggedInUserRole} />
      <div className="col-md-12 row">
        <div className=" formDiv formWidth">
          <div className="d-flex">
            <div className="col-8">
              <div>
                <h5>
                  HELLO, <br />
                  {loggedInUserName}
                </h5>
              </div>
            </div>
            <div className="col-4">
              <div className="mx-0">
                <ol className="list-group list-group-numbered mt-2 border counsellorDashboardStatus">
                  <li
                    className="list-group-item d-flex justify-content-between align-items-start boxshadow my-1 p-2 cursor border-0"
                    onClick={() => {
                      onChange("resumeShared");
                    }}
                  >
                    <div className="ms-2 me-auto">
                      <div className="fw-bold">Resume Shared</div>
                    </div>
                    <span className="badge text-bg-primary rounded-pill fs-6">
                      {resumeShared}
                    </span>
                  </li>
                  <li
                    className="list-group-item d-flex justify-content-between align-items-start boxshadow my-1 p-2 cursor border-0"
                    onClick={() => {
                      onChange("firstRoundInterview");
                    }}
                  >
                    <div className="ms-2 me-auto">
                      <div className="fw-bold">First Round Interview</div>
                    </div>
                    <span className="badge text-bg-primary rounded-pill fs-6">
                      {firstRoundInterview}
                    </span>
                  </li>
                  <li
                    className="list-group-item d-flex justify-content-between align-items-start boxshadow my-1 p-2 cursor border-0"
                    onClick={() => {
                      onChange("secondRoundInterview");
                    }}
                  >
                    <div className="ms-2 me-auto">
                      <div className="fw-bold">Second Round Interview</div>
                    </div>
                    <span className="badge text-bg-primary rounded-pill fs-6">
                      {secondRoundInterview}
                    </span>
                  </li>
                  <li
                    className="list-group-item d-flex justify-content-between align-items-start boxshadow my-1 p-2 cursor border-0"
                    onClick={() => {
                      onChange("hrRoundInterview");
                    }}
                  >
                    <div className="ms-2 me-auto">
                      <div className="fw-bold">Hr Round Interview</div>
                    </div>
                    <span className="badge text-bg-primary rounded-pill fs-6">
                      {hrRoundInterview}
                    </span>
                  </li>
                  <li
                    className="list-group-item d-flex justify-content-between align-items-start boxshadow my-1 p-2 cursor border-0"
                    onClick={() => {
                      onChange("rejected");
                    }}
                  >
                    <div className="ms-2 me-auto">
                      <div className="fw-bold">Rejected</div>
                    </div>
                    <span className="badge text-bg-primary rounded-pill fs-6">
                      {rejected}
                    </span>
                  </li>
                  <li
                    className="list-group-item d-flex justify-content-between align-items-start boxshadow my-1 p-2 cursor border-0"
                    onClick={() => {
                      onChange("joiningToday");
                    }}
                  >
                    <div className="ms-2 me-auto">
                      <div className="fw-bold">Joining Today</div>
                    </div>
                    <span className="badge text-bg-primary rounded-pill fs-6">
                      {joiningToday}
                    </span>
                  </li>
                  <li
                    className="list-group-item d-flex justify-content-between align-items-start boxshadow my-1 p-2 cursor border-0"
                    onClick={() => {
                      onChange("joiningInWeek");
                    }}
                  >
                    <div className="ms-2 me-auto">
                      <div className="fw-bold">Joining In Week</div>
                      {/* <span className="">
                        {((joiningInWeek / totalLeadCount) * 100).toFixed(2)}%
                      </span> */}
                    </div>
                    <span className="badge text-bg-primary rounded-pill fs-6">
                      {joiningInWeek}
                    </span>
                  </li>
                  <li
                    className="list-group-item d-flex justify-content-between align-items-start boxshadow my-1 p-2 cursor border-0"
                    onClick={() => {
                      onChange("callingDone");
                    }}
                  >
                    <div className="ms-2 me-auto">
                      <div className="fw-bold">Calling Done</div>
                      {/* <span className="">
                        {((callingDone / totalLeadCount) * 100).toFixed(2)}%
                      </span> */}
                    </div>
                    <span className="badge text-bg-primary rounded-pill fs-6">
                      {callingDone}
                    </span>
                  </li>
                  <li
                    className="list-group-item d-flex justify-content-between align-items-start boxshadow my-1 p-2 cursor border-0"
                    onClick={() => {
                      onChange("onBoarding");
                    }}
                  >
                    <div className="ms-2 me-auto">
                      <div className="fw-bold">ON BOARDING</div>
                      {/* <span className="">
                        {((callingDone / totalLeadCount) * 100).toFixed(2)}%
                      </span> */}
                    </div>
                    <span className="badge text-bg-primary rounded-pill fs-6">
                      {onBoarding}
                    </span>
                  </li>
                </ol>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
