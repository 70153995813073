export const HrPanelSidebarData = [
  {
    title: "ALL CALLS DETAILS",
    path: "hrCallDetails",
  },
  {
    title: "DASHBOARD",
    path: "hrDashbord",
  },
  {
    title: "TALKTIME ANALYSIS",
    path: "hrTalktime",
  },
  {
    title: "ADD NEW CANDIDATE",
    path: "addNewCandidateLead",
  },
  {
    title: "LEAD ANALYSIS",
    subNav: [
      {
        title: "Resume Shared",
        path: "resumeShared",
        cName: "sub-nav",
      },
      {
        title: "1st Round Interview",
        path: "firstRoundInterview",
        cName: "sub-nav",
      },
      {
        title: "2nd Round Interview",
        path: "secondRoundInterview",
        cName: "sub-nav",
      },
      {
        title: "Hr Round Interview",
        path: "hrRoundInterview",
        cName: "sub-nav",
      },
      {
        title: "Rejected",
        path: "rejected",
        cName: "sub-nav",
      },
      {
        title: "Joining Today",
        path: "joiningToday",
        cName: "sub-nav",
      },
      {
        title: "Joining In Week",
        path: "joiningInWeek",
        cName: "sub-nav",
      },
      {
        title: "Calling Done",
        path: "callingDone",
        cName: "sub-nav",
      },
    ],
  },
  {
    title: "SEARCH CANDIDATE",
    path: "candidateSearchByMobileNumber",
  },
  {
    title: "CANDIDATE RECORDS",
    path: "candidateStatusRecords",
  },
];
