import React, { useEffect, useState } from "react";
import "../../../App.css";
import PageTitle from "../../../Component/pageTitle";
import LabelField from "../../../Component/labelField";
import InputField from "../../../Component/inputField";
// import NoRecord from "../../Component/noRecord";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import ClearOutlinedIcon from "@mui/icons-material/ClearOutlined";
import CustomDropdown from "../../../Component/customDropdown";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { statusActive } from "../../../DATA/dataConstant";
import GenaralTable from "../../../Component/genaralTable";
import DateTime from "../../../Component/dateTime";
import {
  containsNumber,
  length_Fifty,
  length_Hundred,
  length_One,
  length_Ten,
  validateValueWithoutSpace,
} from "../../../DATA/validators";
import { validateIsNumberOnlyErrMsg } from "../../../DATA/errorMsg";
import { showErrorSnackbar } from "../../../Redux/Actions/snackbarAction";
import ToggleSwitchBtn from "../../../Component/toggleSwitchBtn";
import { useLocation } from "react-router";
import {
  getSectionTypeList,
  getBatchName,
  getExamList,
  getExamStreamList,
  getSubExam,
  createNewSubscription,
  getPackageListWithFilter,
  getRegisteredUser,
  updateOldSubscription,
  updateUser,
  updateUserBaseCourse,
} from "../../../Redux/Actions/counsellorPanelAction";
import SubmitButton from "../../../Component/submitButton";

function RegistrationDetails() {
  const dispatch = useDispatch();
  const currentURLarr = useLocation().pathname.split("/");
  let link = currentURLarr[currentURLarr.length - 2];

  const [phoneNumber, setPhoneNumber] = useState<any>("");

  const [parentCourseVal, setparentCourseVal] = useState<any>("");
  const [examName, setExamName] = useState<any>("");
  const [subExamName, setSubExamName] = useState<any>("");
  const [batchName, setbatchName] = useState<any>("");
  const [packageEndDate, setPackageEndDate] = useState<any>("");
  const [courseActive, setcourseActive] = useState<any>("");
  const [sessionName, setsessionName] = useState<any>("");
  const [smCategory, setSMCategory] = useState<any>("");
  const [packageSectionId, setPackageSectionId] = useState<any>("");
  const [typeId, setTypeId] = useState<any>("");
  const [userSubscriptionId, setUserSubscriptionId] = useState<any>("");

  const [userDetailsList, setUserDetailsList] = useState<any>();
  const [parentCourseList, setParentCourseList] = useState<any>([]);
  const [examList, setExamList] = useState<any>([]);
  const [subExamList, setsubExamList] = useState<any>([]);
  const [batchList, setBatchList] = useState<any>([]);
  const [SessionList, setSessionList] = useState<any>([]);
  const [smCategoryList, setSMCategoryList] = useState<any>([]);
  const [DurationList, setDurationList] = useState<any>([]);
  const [typeList, setTypeList] = useState<any>([]);
  const [arrLS, setArrLS] = useState<any>([]);

  const [resetParentCourse, setResetParentCourse] = useState<any>(false);
  const [resetExam, setResetExam] = useState<any>(false);
  const [resetSubExam, setResetSubExam] = useState<any>(false);
  const [resetBatchName, setResetBatchName] = useState<any>(false);
  const [resetCourseActive, setResetCourseActive] = useState<any>(false);
  const [resetSession, setResetSession] = useState<any>(false);
  const [resetType, setResetType] = useState<any>(false);

  const [showDetails, setShowDetails] = useState<any>(false);
  const [addEditcourse, setAddEditcourse] = useState<any>(false);

  const [disabledEndDate, setDisabledEndDate] = useState<any>(true);
  const [open, setOpen] = useState<any>(false);
  const [isEditMode, setIsEditMode] = useState<any>(false);

  useEffect(() => {
    dispatch(
      getExamList((res: any) => {
        setExamList(res);
      })
    );
  }, []);

  const onChangePhone = (value: any) => {
    if (value !== "") {
      if (!containsNumber(value)) {
        dispatch(showErrorSnackbar("Only Numbers are allowed!") as any);
        return;
      } else if (!validateValueWithoutSpace(value)) {
        dispatch(showErrorSnackbar(validateIsNumberOnlyErrMsg) as any);
        return;
      } else {
        setPhoneNumber(value);
      }
    } else {
      setPhoneNumber(value);
    }
  };
  const phoneValidations = () => {
    return phoneNumber === "" || phoneNumber?.length !== 10;
  };

  const renderUserDetails = () => {
    return (
      <div>
        <p
          className="p-1 mb-2 text-white mb-4"
          style={{ backgroundColor: "#3f51b5" }}
        >
          Personal & Contact
        </p>
        <div className="px-3 text-end">
          <button
            className="btn btn-outline-primary"
            style={{ fontSize: "xx-small" }}
            onClick={editProfile}
          >
            Edit Profile
          </button>
        </div>

        <div className="row d-flex mx-0">
          <div className="col-4 d-flex ps-4">
            <p className="col-4"> First Name</p>
            <p className="col-8">{userDetailsList?.user?.firstName}</p>
          </div>
          <div className=" col-4 d-flex ps-4">
            <p className="col-4"> Last Name</p>
            <p className="col-8">{userDetailsList?.user?.lastName}</p>
          </div>
          <div className="col-4 d-flex ps-4">
            <p className="col-4">Contact</p>
            <p className="col-8">{userDetailsList?.user?.mobileNo}</p>
          </div>
          <div className="col-4 d-flex ps-4">
            <p className="col-4">Email Address</p>
            <p className="col-8">{userDetailsList?.user?.email}</p>
          </div>
          <div className="col-4 d-flex ps-4">
            <p className="col-4">Status</p>
            <p className="col-8">
              {userDetailsList?.user?.status === 0
                ? "Deactive"
                : userDetailsList?.user?.status === 1
                ? "Active"
                : "Deleted"}
            </p>
          </div>

          <div className="col-4 d-flex ps-4">
            <p className="col-4">Active Plan</p>
            <p className="col-8">
              {userDetailsList?.user?.hasActivePlan === true ? "Yes" : "No"}
            </p>
          </div>
          <div className="col-4 d-flex ps-4">
            <p className="col-4">Is Mobile Verify</p>
            <p className="col-8">
              {userDetailsList?.user?.isMobileVerified === true ? "Yes" : "No"}
            </p>
          </div>
          <div className="col-4 d-flex ps-4">
            <p className="col-4">Shipping Address</p>
            <div>
              <p className="mb-1">
                {userDetailsList?.user?.addressData
                  ? userDetailsList?.user?.addressData[0]?.line1 !== undefined
                    ? userDetailsList?.user?.addressData[0]?.line1
                    : "" +
                        " " +
                        userDetailsList?.user?.addressData[0]?.line2 !==
                      undefined
                    ? userDetailsList?.user?.addressData[0]?.line2
                    : ""
                  : null}
              </p>
              <p className="mb-1">
                {userDetailsList?.user?.addressData
                  ? userDetailsList?.user?.addressData[0]?.landmark !==
                    undefined
                    ? userDetailsList?.user?.addressData[0]?.landmark
                    : "" + " " + userDetailsList?.user?.addressData[0]?.city !==
                      undefined
                    ? userDetailsList?.user?.addressData[0]?.city
                    : ""
                  : null}
              </p>
              <p className="mb-1">
                {userDetailsList?.user?.addressData
                  ? userDetailsList?.user?.addressData[0]?.state !== undefined
                    ? userDetailsList?.user?.addressData[0]?.state
                    : "" +
                        " - " +
                        userDetailsList?.user?.addressData[0]?.pincode !==
                      undefined
                    ? userDetailsList?.user?.addressData[0]?.pincode
                    : ""
                  : null}
              </p>
            </div>
          </div>
          {userDetailsList?.user?.username ? (
            <div className="col-4 d-flex ps-4">
              <p className="col-4">Username</p>
              <p className="col-8">{userDetailsList?.user?.username}</p>
            </div>
          ) : (
            ""
          )}
          {userDetailsList?.user?.password ? (
            <div className="col-4 d-flex ps-4">
              <p className="col-4">Password</p>
              <p className="col-8">{userDetailsList?.user?.password}</p>
            </div>
          ) : (
            ""
          )}
          {link === "AdminPanel" || link === "TechnicalSupportPanel" ? (
            userDetailsList?.user?.otp ? (
              <div className="col-4 d-flex ps-4">
                <p className="col-4">OTP</p>
                <p className="col-8">{userDetailsList?.user?.otp}</p>
              </div>
            ) : (
              ""
            )
          ) : null}
        </div>
        <p
          className="p-1 mb-2 text-white mb-4"
          style={{ backgroundColor: "#3f51b5" }}
        >
          <div className="d-flex justify-content-between me-2 align-items-center">
            <span>Course Details</span>
            {/* {userDetailsList?.data?.length === 0 ? (
              <button
                className="btn rounded-pill text-white"
                onClick={() => changeParentCourse()}
              >
                Edit
              </button>
            ) : null} */}
          </div>
        </p>
        {link === "AdminPanel" || link === "TechnicalSupportPanel" ? (
          <div className=" col-6 d-flex ps-4">
            <p className="col-4">Registration Date</p>
            <p className="col-8">
              {moment
                .utc(userDetailsList?.user?.createdAt)
                .format("DD-MM-YYYY")}
            </p>
          </div>
        ) : null}

        <div className="row d-flex mx-0">
          {/* <div className="col-6 d-flex ps-4">
            <p className="col-4">Parent Course</p>
            <p className="col-8">{parentCourseVal?.name}</p>
          </div> */}
          <div className="col-4 d-flex ps-4">
            <p className="col-4">Exam Name</p>
            <p className="col-4">{examName?.name ? examName?.name : "-"}</p>
          </div>
          <div className="col-4 d-flex ps-4">
            <p className="col-4">Parent Course</p>
            <p className="col-8">{parentCourseVal?.name}</p>
          </div>
        </div>
        <div>
          <p
            className="p-1 mb-2 text-white mb-4"
            style={{ backgroundColor: "#3f51b5" }}
          >
            Subscription Details
          </p>
          <GenaralTable
            tableData={userDetailsList?.data ? userDetailsList?.data : []}
            pagename={"registrationDetails"}
            tableHead={[
              "S.N",
              "Exam Name",
              "Sub-Exam Name",
              "Batch Name",
              "Course Type",
              "Course Activation",
              "Activation End Date",
              "Study Material Sub-Category",
            ]}
            edit={false}
            handleEdit={(value: any) => handleEdit(value)}
          />
        </div>
      </div>
    );
  };

  const handleSearch = (e: any) => {
    e.preventDefault();
    setDurationList([]);
    setArrLS([]);
    dispatch(
      getRegisteredUser(
        phoneNumber,
        (res: any) => {
          if (res?.data !== null) {
            setShowDetails(true);
            setAddEditcourse(false);
            setIsEditProfile(false);
            setExamName(res?.data?.user?.examTypeId);
            setparentCourseVal(res?.data?.user?.basecourse);
            setUserDetailsList(res.data);
          } else {
            setShowDetails(false);
          }
        },
        () => {}
      )
    );
  };

  const handleEdit = (value: any) => {
    setIsEditMode(true);
    setAddEditcourse(true);
    setUserSubscriptionId(value?._id);
    setExamName(value?.examData);
    setSubExamName(value?.subExamTypeId);
    setparentCourseVal(value?.baseCourseId);
    dispatch(
      getExamList((res: any) => {
        setExamList(res);
      })
    );
    if (value?.subExamTypeId) {
      dispatch(
        getExamStreamList(
          `${value?.examData?._id}/${value?.subExamTypeId?._id}`,
          (res: any) => {
            setParentCourseList(res);
          }
        )
      );
    } else {
      dispatch(
        getExamStreamList(`${value?.examData?._id}`, (res: any) => {
          setParentCourseList(res);
        })
      );
    }

    dispatch(
      getSectionTypeList((data: any) => {
        setTypeList(data);
        const index = data.findIndex(
          (item: any) => item?.type === value?.coursesData?.sectionType
        );
        setTypeId(data[index]);
      })
    );

    setbatchName(value?.coursesData);

    const data: any = {
      baseCourseId: value?.baseCourseId?._id,
      examTypeId: value?.examData?._id,
    };
    if (value?.subExamTypeId) {
      data["subExamTypeId"] = value?.subExamTypeId?._id;
    }
    dispatch(
      getBatchName(
        data,
        (res: any) => {
          setBatchList(res.data);
        },
        () => {}
      )
    );

    setPackageEndDate(value?.endDate);
    setcourseActive(
      value?.status === 1 ? { name: "Yes", _id: 1 } : { name: "No", _id: 0 }
    );

    setsessionName(value?.sessionId);
    setSessionList(value?.sessions);

    setSMCategory(value?.subCategoryId);
    let SubCategories = value?.subcategoriesData?.filter(
      (item: any) => item?.type === 2
    );

    setSMCategoryList(SubCategories);

    let newArr = JSON.parse(
      JSON.stringify(
        value?.selectedPackage ? value?.selectedPackage?.subscriptionPlan : []
      )
    );
    const n2 = newArr?.map((item: any) => {
      let temp = {};
      if (item?._id === item?.selected) {
        temp = {
          durationId: item?.durationId,
          isRecommended: item?.isRecommended,
          months: item?.months,
          price: item?.price,
          selected: item?.selected,
          _id: item?._id,
          isSelected: true,
        };
        return temp;
      } else {
        temp = {
          durationId: item?.durationId,
          isRecommended: item?.isRecommended,
          months: item?.months,
          price: item?.price,
          selected: item?.selected,
          _id: item?._id,
          isSelected: false,
        };
        return temp;
      }
    });
    setDurationList(n2);
    if (value?.coursesData?.sectionType === 5) {
      let newArr = JSON.parse(
        JSON.stringify(value?.selectedPackage ? value?.selectedPackage : [])
      );
      let newwww = [newArr];
      const n1 = newwww?.map((item) => {
        const n2 = item?.subscriptionPlan?.map((subItem: any) => {
          let temp = {
            durationId: subItem?.durationId,
            isRecommended: subItem?.isRecommended,
            months: subItem?.months,
            price: subItem?.price,
            selected: subItem?.selected,
            _id: subItem?._id,
            isActive: subItem?.isActive ? subItem?.isActive : false,
          };
          return temp;
        });
        return {
          lectureUnitId: item?.lectureUnitId,
          name: item?.name,
          subscriptionPlan: n2,
        };
      });
      setArrLS(n1);
    }
  };

  const changeEndDate = (e: any) => {
    if (e.target.checked === true) {
      setDisabledEndDate(false);
    } else {
      setDisabledEndDate(true);
    }
  };

  const changeDurations = (event: any, index: any) => {
    let newArray = JSON.parse(JSON.stringify(DurationList));
    for (let i = 0; i < newArray?.length; i++) {
      newArray[i].isSelected = false;
    }
    if (event.target.checked) {
      newArray[index].isSelected = true;
    } else {
      newArray[index].isSelected = false;
    }
    setDurationList(newArray);
  };

  const renderEditPackage = () => {
    return (
      <>
        <div className="d-flex mb-2 align-items-center">
          <LabelField lableName={"Study Material Sub Category"} />
          <CustomDropdown
            lableName={"Select Sub Category"}
            setInputText={(value: any) => setSMCategory(value)}
            value={smCategory}
            options={smCategoryList}
            disabled={isEditMode}
            defaultValue={smCategory?._id}
          />
        </div>

        <div className="d-flex mb-2 align-items-center">
          <LabelField lableName={"Subscription Duration"} />
          <table className="">
            <tr>
              <th></th>
              <th>Course Duration</th>
              <th>Course Price</th>
            </tr>
            <tbody>
              {DurationList?.length > 0
                ? DurationList?.map((c: any, index: any) => (
                    <tr key={index}>
                      <td className="text-center">
                        <input
                          id={c?.durationId}
                          className="check"
                          type="checkbox"
                          checked={c?.isSelected}
                          onChange={(event) => {
                            changeDurations(event, index);
                          }}
                        />
                      </td>
                      <td>{c?.months} Months</td>
                      <td className="removelable">
                        <InputField
                          placeholder={"Enter Package Price"}
                          disabled={true}
                          value={c?.price}
                          onChangeInput={() => {}}
                        />
                      </td>
                    </tr>
                  ))
                : null}
            </tbody>
          </table>
        </div>
      </>
    );
  };

  const renderEditLectureSeries = () => {
    return arrLS?.map((item: any, index: any) => {
      const { lectureUnitId, name, subscriptionPlan } = item;
      return (
        <div key={index} className="d-flex mb-2">
          <LabelField lableName={"Add Lecture"} />
          <div className="border p-2 rounded">
            <table className="w-100">
              <tr>
                <th></th>
                <th>Unit Name</th>
                <th>Course Duration</th>
                <th className="text-center">Course Price</th>
              </tr>
              <tbody>
                {subscriptionPlan !== undefined
                  ? subscriptionPlan.map((c: any, indexInside: any) => (
                      <tr key={indexInside}>
                        {indexInside === 0 ? (
                          <td
                            className="text-center"
                            rowSpan={subscriptionPlan.length}
                          >
                            <input
                              id={lectureUnitId}
                              className="check"
                              type="checkbox"
                              checked={lectureUnitId}
                              onChange={(event) => {
                                onCheckLS(event, indexInside, index, "checkLS");
                              }}
                            />
                          </td>
                        ) : null}
                        {indexInside === 0 ? (
                          <td rowSpan={subscriptionPlan.length}>{name}</td>
                        ) : null}
                        <td className="text-center">
                          <span>
                            <input
                              id=""
                              className="check"
                              type="checkbox"
                              name={"subType"}
                              checked={c.isActive}
                              onChange={(event) => {
                                onCheckLS(event, indexInside, index, "check");
                              }}
                            />
                          </span>
                          <span>{c.months} Months</span>
                        </td>
                        <td className="removelabel">
                          <InputField
                            placeholder={"Enter Package Price"}
                            disabled={true}
                            value={c.price}
                            onChangeInput={() => {}}
                          />
                        </td>
                      </tr>
                    ))
                  : null}
              </tbody>
            </table>
          </div>
        </div>
      );
    });
  };

  const handleUpdate = (e: any) => {
    e.preventDefault();
    let newArray;
    if (typeId?.type === 5) {
      newArray = arrLS?.map((item: any) => {
        let temp = item?.subscriptionPlan?.filter(
          (subItem: any) => subItem?.isActive
        );
        return temp[0];
      });
    } else {
      newArray = JSON.parse(JSON.stringify(DurationList));
      let indexes = newArray
        .map((elm: any, idx: any) => (!elm.isSelected ? idx : ""))
        .filter(String);
      for (var i = indexes.length - 1; i >= 0; i--) {
        newArray.splice(indexes[i], 1);
      }
    }
    const newArrr2 = newArray?.map((item: any) => {
      const temp = {
        durationId: item?.durationId,
        months: item?.months,
        _id: item?._id,
        price: item?.price,
        isRecommended: item?.isRecommended,
      };
      return temp;
    });

    const data: any = {
      status: courseActive?._id,
    };
    if (disabledEndDate) {
      if (newArrr2[0]) {
        data["subscriptionPlan"] = newArrr2[0];
      }
    } else {
      if (packageEndDate) {
        data["endDate"] = moment.utc(packageEndDate).format("YYYY-MM-DD");
      }
    }

    if (sessionName) {
      data["sessionId"] = sessionName?._id;
    }

    dispatch(
      updateOldSubscription(data, userSubscriptionId, () => {
        setIsEditMode(false);
        setAddEditcourse(false);
        dispatch(
          getRegisteredUser(
            phoneNumber,
            (res: any) => {
              if (res?.data !== null) {
                setShowDetails(true);
                setAddEditcourse(false);
                setparentCourseVal(res?.data?.user?.basecourse);
                setUserDetailsList(res.data);
              } else {
                setShowDetails(false);
              }
            },
            () => {}
          )
        );
      })
    );
  };

  const renderEditMode = () => {
    return (
      <div>
        <p
          className="p-1 mb-2 text-white mb-4"
          style={{ backgroundColor: "#3f51b5" }}
        >
          Course Details
        </p>
        <div className="row mx-0">
          <div className="col-md-10 col-lg-10 col-xl-8">
            <form onSubmit={handleUpdate}>
              <div className="col-auto">
                <div className="d-flex mb-2 align-items-center">
                  <LabelField lableName={"Exam Name"} />
                  <CustomDropdown
                    lableName={"Exam Name"}
                    setInputText={(value: any) => setExamName(value)}
                    options={examList}
                    // reset={resetExam}
                    disabled={isEditMode}
                    value={examName}
                    defaultValue={examList?.length > 0 ? examName?._id : ""}
                  />
                </div>
                {subExamList?.length > 0 ? (
                  <div className="d-flex mb-2 align-items-center">
                    <LabelField lableName={"Sub Exam Name"} />
                    <CustomDropdown
                      lableName={"Sub Exam Name"}
                      setInputText={(value: any) => setSubExamName(value)}
                      options={subExamList}
                      // reset={resetSubExam}
                      disabled={isEditMode}
                      value={subExamName}
                      defaultValue={
                        subExamList?.length > 0 ? subExamName?._id : ""
                      }
                    />
                  </div>
                ) : null}
                <div className="d-flex mb-2 align-items-center">
                  <LabelField lableName={"Parent Course"} />
                  <CustomDropdown
                    lableName={"Parent Course"}
                    setInputText={(value: any) => setparentCourseVal(value)}
                    options={parentCourseList}
                    // reset={resetParentCourse}
                    disabled={isEditMode}
                    value={parentCourseVal}
                    defaultValue={
                      parentCourseList?.length > 0 ? parentCourseVal?._id : ""
                    }
                  />
                </div>
                <div className="d-flex mb-2 align-items-center">
                  <LabelField lableName={"Package Type"} />
                  <CustomDropdown
                    lableName={"Select Type"}
                    setInputText={(value: any) => {
                      setTypeId(value);
                    }}
                    options={typeList}
                    // reset={resetType}
                    disabled={isEditMode}
                    value={typeId}
                    defaultValue={typeList?.length > 0 ? typeId?._id : ""}
                  />
                </div>
                <div className="d-flex mb-2 align-items-center">
                  <LabelField lableName={"Batch Name"} />
                  <CustomDropdown
                    setInputText={(value: any) => setbatchName(value)}
                    options={batchList}
                    lableName="Select Batch Name"
                    // reset={resetbatchName}
                    disabled={isEditMode}
                    value={batchName}
                    defaultValue={batchList?.length > 0 ? batchName?._id : ""}
                  />
                </div>
                {/* {disabledEndDate ? ( */}
                <div className="d-flex align-items-center">
                  <LabelField lableName={"Package End Date"} />
                  <div className="datepicker">
                    <DateTime
                      setInputText={(value: any) => {
                        setPackageEndDate(value);
                      }}
                      // reset={resetPackageStartDate}
                      value={packageEndDate}
                      defaultValue={packageEndDate}
                      disabled={disabledEndDate}
                    />
                  </div>
                </div>
                {/* ) : null} */}

                <div className="d-flex mb-2 align-items-center">
                  <LabelField lableName={"Course Activation"} />
                  <CustomDropdown
                    lableName={"Course Activation"}
                    setInputText={(value: any) => setcourseActive(value)}
                    value={courseActive}
                    options={statusActive}
                    // reset={resetCourseActive}
                    defaultValue={courseActive?._id}
                  />
                </div>
                {typeId?.type === 1 || typeId?.type === 5 ? (
                  SessionList.length > 0 ? (
                    <div className="d-flex mb-2 align-items-center">
                      <LabelField lableName={"Session"} />
                      <CustomDropdown
                        lableName={"Select Session"}
                        setInputText={(value: any) => setsessionName(value)}
                        value={sessionName}
                        options={SessionList}
                        // reset={resetSession}
                        defaultValue={
                          SessionList?.length > 0 ? sessionName?._id : ""
                        }
                      />
                    </div>
                  ) : null
                ) : null}
                {disabledEndDate
                  ? typeId?.type === 1 || typeId.type === 3
                    ? renderEditPackage()
                    : null
                  : null}

                {disabledEndDate
                  ? typeId?.type === 5
                    ? renderEditLectureSeries()
                    : null
                  : null}

                {/* <div className="d-flex justify-content-center">
                  <SubmitButton className="btn btn-primary" name="Save & Update" />
                </div> */}
                <div className="d-flex justify-content-evenly mt-5">
                  <button type="submit" className="btn btn-primary px-5 cursor">
                    Save and Update
                  </button>
                </div>
              </div>
            </form>
          </div>
          {isEditMode ? (
            <div className="col-md-2 col-lg-2 col-xl-4">
              <input type="checkbox" onChange={changeEndDate} />
              <span className="checkVal">Edit End Date</span>
            </div>
          ) : null}
        </div>
      </div>
    );
  };

  const handleCancelEditMode = () => {
    setIsEditMode(false);
    setAddEditcourse(false);
  };

  const onListChange = (value: any) => {
    setExamName(value);
    setResetExam(false);
    setsubExamList([]);
    setSubExamName("");
    setResetSubExam(true);
    setParentCourseList([]);
    setResetParentCourse(true);
    setResetBatchName(true);
    setResetCourseActive(true);
    setResetSession(true);
    dispatch(
      getSubExam(
        {
          examTypeId: value._id,
        },
        (res: any) => {
          if (res?.data.length > 0) {
            setsubExamList(res.data);
          } else {
            dispatch(
              getExamStreamList(`${value._id}`, (res: any) => {
                setParentCourseList(res);
              })
            );
          }
        }
      )
    );
  };
  const onSubExamChange = (value: any) => {
    setSubExamName(value);
    setResetSubExam(false);
    setParentCourseList([]);
    setResetParentCourse(true);
    setResetBatchName(true);
    setResetCourseActive(true);
    setResetSession(true);
    dispatch(
      getExamStreamList(`${examName._id}/${value._id}`, (res: any) => {
        setParentCourseList(res);
      })
    );
  };

  const changeParentCourseVal = (value: any) => {
    setparentCourseVal(value);
    setResetParentCourse(false);
    setBatchList([]);
    setResetBatchName(true);
    setResetCourseActive(true);
    setResetSession(true);
    dispatch(
      getSectionTypeList((data: any) => {
        setTypeList(data);
      })
    );
  };

  const changeTypeId = (value: any) => {
    setTypeId(value);
    setResetType(false);
    const data: any = {
      sectionType: value?.type,
      baseCourseId: parentCourseVal._id,
      examTypeId: examName?._id,
      // skip: 0,
      // limit: 10,
    };
    if (subExamName) {
      data["subExamTypeId"] = subExamName._id;
    }
    dispatch(
      getPackageListWithFilter(data, (res: any) => {
        setBatchList(res?.data);
      })
    );
  };

  const onBatchChange = (value: any) => {
    let filteredSessions = value?.sessions?.filter(
      (elm: any) => elm?.isSelected
    );
    setSessionList(filteredSessions);
    setSMCategoryList(value?.subCategoriesData);
    if (typeId?.type === 5) {
      const temp = value?.packageSection?.filter(
        (elm: any) => elm?.isLSChecked
      );
      const plan = temp?.map((item: any) => {
        const newDurationList = {
          _id: item?._id,
          lectureUnitId: item?.lectureUnitId,
          isLSChecked: false,
          name: item?.name,
          subscriptionPlan: item?.subscriptionPlan,
        };
        return newDurationList;
      });
      setArrLS(plan);
    }
    setbatchName(value);
    setResetBatchName(false);
    setResetCourseActive(true);
    setResetSession(true);
  };

  const onChangeSMSubCategory = (value: any) => {
    setSMCategory(value);
    const plan = batchName?.packageSection?.map((item: any) => {
      if (item?.subCategoryId === value?._id) {
        setPackageSectionId(item?._id);
        return item?.subscriptionPlan;
      }
    });
    const newPlan = plan?.filter((elm: any) => elm !== undefined);
    setDurationList(newPlan[0]);
  };

  const renderPackage = () => {
    return (
      <>
        <div className="d-flex mb-2 align-items-center">
          <LabelField lableName={"Study Material Sub Category"} />
          <CustomDropdown
            lableName={"Select Sub Category"}
            setInputText={(value: any) => onChangeSMSubCategory(value)}
            value={smCategory}
            options={smCategoryList}
            // reset={resetSession}
          />
        </div>

        <div className="d-flex mb-2 align-items-center">
          <LabelField lableName={"Subscription Duration"} />
          <table className="">
            <tr>
              <th></th>
              <th>Course Duration</th>
              <th>Course Price</th>
            </tr>
            <tbody>
              {DurationList?.length > 0
                ? DurationList?.map((c: any, index: any) => (
                    <tr key={index}>
                      <td className="text-center">
                        <input
                          id={c?.durationId}
                          className="check"
                          type="checkbox"
                          checked={c?.isSelected}
                          onChange={(event) => {
                            changeDurations(event, index);
                          }}
                        />
                      </td>
                      <td>{c?.months} Months</td>
                      <td className="removelable">
                        <InputField
                          placeholder={"Enter Package Price"}
                          disabled={true}
                          value={c?.price}
                          onChangeInput={() => {}}
                        />
                      </td>
                    </tr>
                  ))
                : null}
            </tbody>
          </table>
        </div>
      </>
    );
  };

  const onCheckLS = (event: any, indexInside: any, index: any, type: any) => {
    let newArray = JSON.parse(JSON.stringify(arrLS));
    if (type === "checkLS") {
      if (event.target.checked) {
        newArray[index]["isLSChecked"] = true;
        setArrLS(newArray);
      } else {
        newArray[index]["isLSChecked"] = false;
        setArrLS(newArray);
      }
    }
    if (type === "check") {
      for (let i = 0; i < newArray[index]["subscriptionPlan"]?.length; i++) {
        newArray[index]["subscriptionPlan"][i].isActive = false;
      }
      if (event.target.checked) {
        newArray[index]["subscriptionPlan"][indexInside].isActive = true;
      } else {
        newArray[index]["subscriptionPlan"][indexInside].isActive = false;
      }

      setArrLS(newArray);
    }
  };

  const renderLectureSeries = () => {
    return arrLS?.map((item: any, index: any) => {
      const { lectureUnitId, name, subscriptionPlan } = item;
      return (
        <div key={index} className="d-flex mb-2">
          <LabelField lableName={"Add Lecture"} />
          <div className="border p-2 rounded">
            <table className="w-100">
              <tr>
                <th></th>
                <th>Unit Name</th>
                <th>Course Duration</th>
                <th className="text-center">Course Price</th>
              </tr>
              <tbody>
                {subscriptionPlan !== undefined
                  ? subscriptionPlan.map((c: any, indexInside: any) => (
                      <tr key={indexInside}>
                        {indexInside === 0 ? (
                          <td
                            className="text-center"
                            rowSpan={subscriptionPlan.length}
                          >
                            <input
                              id={lectureUnitId}
                              className="check"
                              type="checkbox"
                              checked={item.isLSChecked}
                              onChange={(event) => {
                                onCheckLS(event, indexInside, index, "checkLS");
                              }}
                            />
                          </td>
                        ) : null}
                        {indexInside === 0 ? (
                          <td rowSpan={subscriptionPlan.length}>{name}</td>
                        ) : null}
                        <td className="text-center">
                          <span>
                            <input
                              id=""
                              className="check"
                              type="checkbox"
                              name={"subType"}
                              checked={c.isActive}
                              onChange={(event) => {
                                onCheckLS(event, indexInside, index, "check");
                              }}
                            />
                          </span>
                          <span>{c.months} Months</span>
                        </td>
                        <td className="removelabel">
                          <InputField
                            placeholder={"Enter Package Price"}
                            disabled={true}
                            value={c.price}
                            onChangeInput={() => {}}
                          />
                        </td>
                      </tr>
                    ))
                  : null}
              </tbody>
            </table>
          </div>
        </div>
      );
    });
  };

  const submitData = (e: any) => {
    e.preventDefault();

    let arrLS3;
    let arr;
    if (typeId?.type === 5) {
      let arrLS1 = JSON.parse(JSON.stringify(arrLS));
      let indexes = arrLS1
        .map((elm: any, idx: any) => (!elm.isLSChecked ? idx : ""))
        .filter(String);
      for (var i = indexes.length - 1; i >= 0; i--) {
        arrLS1.splice(indexes[i], 1);
      }

      arrLS3 = arrLS1.map((item: any) => {
        const temp = item?.subscriptionPlan?.map((subItem: any) => {
          return {
            durationId: subItem?.durationId,
            isRecommended: subItem?.isRecommended,
            months: subItem?.months,
            price: subItem?.price,
            _id: subItem?._id,
            isActive: subItem?.isActive ? subItem?.isActive : false,
          };
        });
        return {
          lectureUnitId: item?.lectureUnitId,
          durationId: temp[0],
          _id: item?._id,
        };
      });
    } else {
      arr = JSON.parse(JSON.stringify(DurationList));
      let indexes = arr
        .map((elm: any, idx: any) => (!elm.isSelected ? idx : ""))
        .filter(String);
      for (var j = indexes.length - 1; j >= 0; j--) {
        arr.splice(indexes[j], 1);
      }
      delete arr[0]?.isSelected;
    }

    const postData: any = {
      userId: userDetailsList?.user?._id,
      parentCourseId: parentCourseVal._id,
      examTypeId: examName._id,
      batchId: batchName._id,
      status: courseActive?._id,
    };
    if (subExamName) {
      postData["subExamTypeId"] = subExamName._id;
    }
    if (sessionName) {
      postData["sessionId"] = sessionName._id;
    }
    if (packageSectionId) {
      postData["packageSectionId"] = packageSectionId;
    }
    if (arr?.length > 0) {
      postData["duration"] = arr[0];
    }
    if (arrLS3?.length > 0) {
      postData["packageSection"] = arrLS3;
    }
    dispatch(
      createNewSubscription(postData, () => {
        setAddEditcourse(false);
        setExamName("");
        setSubExamName("");
        setbatchName("");
        setcourseActive("");
        setsessionName("");
        setDurationList([]);
        dispatch(
          getRegisteredUser(
            phoneNumber,
            (res: any) => {
              if (res?.data !== null) {
                setShowDetails(true);
                setAddEditcourse(false);
                setparentCourseVal(res?.data?.user?.basecourse);
                setUserDetailsList(res.data);
                setDurationList([]);
                setArrLS([]);
              } else {
                setShowDetails(false);
              }
            },
            () => {}
          )
        );
      })
    );
  };

  const validations = () => {
    let isValidate = false;
    isValidate =
      examName === "" ||
      (subExamList?.length > 0 && subExamName === "") ||
      parentCourseVal === "" ||
      batchName === "" ||
      courseActive === "" ||
      validateSessions();
    return isValidate;
  };

  const validateSessions = () => {
    let result = false;
    let type = typeId.type;

    if (SessionList.length > 0) {
      if (type === 1 || type === 5) {
        if (sessionName === "") {
          result = true;
        }
      }
    }
    return result;
  };

  const renderAddMode = () => {
    return (
      <div>
        <p
          className="p-1 mb-2 text-white mb-4"
          style={{ backgroundColor: "#3f51b5" }}
        >
          Course Details
        </p>
        <form onSubmit={submitData}>
          <div className="row mx-0">
            <div className="col-7">
              <div className="col-auto">
                <div className="d-flex mb-2 align-items-center">
                  <LabelField lableName={"Exam Name"} />
                  <CustomDropdown
                    lableName={"Exam Name"}
                    setInputText={(value: any) => onListChange(value)}
                    value={examName}
                    options={examList}
                    reset={resetExam}
                    defaultValue={examName?._id}
                  />
                </div>
                {subExamList?.length > 0 ? (
                  <div className="d-flex mb-2 align-items-center">
                    <LabelField lableName={"Sub Exam Name"} />
                    <CustomDropdown
                      lableName={"Sub Exam Name"}
                      setInputText={(value: any) => onSubExamChange(value)}
                      value={subExamName}
                      options={subExamList}
                      reset={resetSubExam}
                    />
                  </div>
                ) : null}
                <div className="d-flex mb-2 align-items-center">
                  <LabelField lableName={"Parent Course"} />
                  <CustomDropdown
                    lableName={"Select Parent Course"}
                    setInputText={(value: any) => changeParentCourseVal(value)}
                    value={parentCourseVal}
                    options={parentCourseList}
                    reset={resetParentCourse}
                    defaultValue={parentCourseVal?._id}
                  />
                </div>
                <div className="d-flex mb-2 align-items-center">
                  <LabelField lableName={"Package Type"} />
                  <CustomDropdown
                    lableName={"Select Type"}
                    setInputText={(value: any) => {
                      changeTypeId(value);
                    }}
                    value={typeId}
                    options={typeList}
                    reset={resetType}
                    // Isrequired={true}
                  />
                </div>
                <div className="d-flex mb-2 align-items-center">
                  <LabelField lableName={"Batch Name"} />
                  <CustomDropdown
                    setInputText={(value: any) => onBatchChange(value)}
                    value={batchName}
                    options={batchList}
                    lableName="Select Batch Name"
                    reset={resetBatchName}
                  />
                </div>
                <div className="d-flex mb-2 align-items-center">
                  <LabelField lableName={"Course Activation"} />
                  <CustomDropdown
                    lableName={"Course Activation"}
                    setInputText={(value: any) => setcourseActive(value)}
                    value={courseActive}
                    options={statusActive}
                    reset={resetCourseActive}
                  />
                </div>
                {typeId.type === 1 || typeId.type === 5 ? (
                  SessionList.length > 0 ? (
                    <div className="d-flex mb-2 align-items-center">
                      <LabelField lableName={"Session"} />
                      <CustomDropdown
                        lableName={"Select Session"}
                        setInputText={(value: any) => setsessionName(value)}
                        value={sessionName}
                        options={SessionList}
                        reset={resetSession}
                      />
                    </div>
                  ) : null
                ) : null}

                {typeId.type === 1 || typeId.type === 3
                  ? renderPackage()
                  : null}
                {typeId.type === 5 ? renderLectureSeries() : null}
              </div>
            </div>

            <div className="d-flex justify-content-center">
              <div className="col-auto d-flex justify-content-center mt-4">
                <button
                  type="submit"
                  className={`btn mb-3 submitbtn ${
                    validations() ? "btn-secondary" : "btn-primary"
                  }`}
                  disabled={validations()}
                >
                  Save and Proceed
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    );
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleUpdateParent = (e: any) => {
    e.preventDefault();
    const data = {
      userId: userDetailsList?.user?._id,
      baseCourseId: parentCourseVal?._id,
    };
    dispatch(
      updateUserBaseCourse(data, () => {
        dispatch(
          getRegisteredUser(
            phoneNumber,
            (res: any) => {
              if (res?.data !== null) {
                setOpen(false);
                setShowDetails(true);
                setAddEditcourse(false);
                setparentCourseVal(res?.data?.user?.basecourse);
                setUserDetailsList(res.data);
              } else {
                setShowDetails(false);
              }
            },
            () => {}
          )
        );
      })
    );
  };
  const [firstname, setfirstname] = useState<any>("");
  const [lastname, setlastname] = useState<any>("");
  const [mobno, setmobno] = useState<any>("");
  const [email, setemail] = useState<any>("");
  const [isMobileVerified, setisMobileVerified] = useState<any>(false);
  const [uname, setuname] = useState<any>("");
  const [password, setpassword] = useState<any>("");
  const [userstatus, setuserstatus] = useState<any>();
  const userStatusData = [
    {
      _id: 0,
      name: "0",
    },
    {
      _id: 1,
      name: "1",
    },
    {
      _id: 9,
      name: "9",
    },
  ];
  const profileValidation = () => {
    return (
      firstname === "" ||
      lastname === "" ||
      mobno === "" ||
      mobno?.length !== 10 ||
      email === ""
    );
  };
  const [IsEditProfile, setIsEditProfile] = useState<any>(false);
  const handleUpdateProfile = (e: any) => {
    e.preventDefault();
    const PostData = {
      firstName: firstname.toString(),
      lastName: lastname.toString(),
      username: uname?.toString(),
      password: password?.toString(),
      isMobileVerified: isMobileVerified,
      mobileNo: mobno.toString(),
      email: email.toString(),
      status: userstatus,
    };

    dispatch(
      updateUser(PostData, userDetailsList?.user?._id, () => {
        dispatch(
          getRegisteredUser(
            phoneNumber,
            (res: any) => {
              if (res?.data !== null) {
                setShowDetails(true);
                setAddEditcourse(false);
                setIsEditProfile(false);
                setparentCourseVal(res?.data?.user?.basecourse);
                setUserDetailsList(res.data);
              } else {
                setShowDetails(false);
              }
            },
            () => {}
          )
        );
      })
    );
  };

  const editProfile = () => {
    setIsEditProfile(true);
    setfirstname(userDetailsList?.user?.firstName);
    setlastname(userDetailsList?.user?.lastName);
    setmobno(userDetailsList?.user?.mobileNo);
    setemail(userDetailsList?.user?.email);
    setuname(userDetailsList?.user?.username);
    setpassword(userDetailsList?.user?.password);
    setisMobileVerified(userDetailsList?.user?.isMobileVerified);
    setuserstatus(userDetailsList?.user?.status);
  };

  const changeToggle = (event: any) => {
    if (event.target.checked) {
      setisMobileVerified(true);
    } else {
      setisMobileVerified(false);
    }
  };
  const changeStatus = (val: any) => {
    setuserstatus(val?._id);
  };
  const renderEditProfile = () => {
    return (
      <div>
        <p
          className="p-1 mb-2 text-white mb-4"
          style={{ backgroundColor: "#3f51b5" }}
        >
          Edit Profile
        </p>
        <div className="row mx-0">
          <div className="col-7">
            <form onSubmit={handleUpdateProfile}>
              <div className="col-auto">
                <div className="row d-flex justify-content-between pt-2">
                  <div className="col-md-5 mt-2">
                    <LabelField lableName="First Name" isRequired={true} />
                  </div>
                  <div className="col-md-7">
                    <InputField
                      placeholder="Enter First Name"
                      value={firstname}
                      onChangeInput={(value: any) => setfirstname(value)}
                      maxlength={length_Fifty}
                      minlength={length_One}
                    />
                  </div>
                </div>
                <div className="row d-flex justify-content-between pt-2">
                  <div className="col-md-5 mt-2">
                    <LabelField lableName="Last Name" isRequired={true} />
                  </div>
                  <div className="col-md-7">
                    <InputField
                      placeholder="Enter Last Name"
                      value={lastname}
                      onChangeInput={(value: any) => setlastname(value)}
                      maxlength={length_Fifty}
                      minlength={length_One}
                    />
                  </div>
                </div>
                <div className="row d-flex justify-content-between pt-2">
                  <div className="col-md-5 mt-2">
                    <LabelField lableName="Contact No" isRequired={true} />
                  </div>
                  <div className="col-md-7">
                    <InputField
                      placeholder="Enter Contact No"
                      value={mobno}
                      onChangeInput={(value: any) => setmobno(value)}
                      maxlength={length_Ten}
                      minlength={length_Ten}
                      disabled={true}
                    />
                  </div>
                </div>
                <div className="row d-flex justify-content-between pt-2">
                  <div className="col-md-5 mt-2">
                    <LabelField lableName="Email" isRequired={true} />
                  </div>
                  <div className="col-md-7">
                    <InputField
                      placeholder="Enter Email"
                      value={email}
                      onChangeInput={(value: any) => setemail(value)}
                      maxlength={length_Hundred}
                      minlength={length_Ten}
                    />
                  </div>
                </div>
                <div className="row d-flex justify-content-between pt-2">
                  <div className="col-md-5 mt-2">
                    <LabelField lableName="Username" />
                  </div>
                  <div className="col-md-7">
                    <InputField
                      placeholder="Enter Username"
                      value={uname}
                      onChangeInput={(value: any) => setuname(value)}
                      maxlength={length_Fifty}
                    />
                  </div>
                </div>
                <div className="row d-flex justify-content-between pt-2">
                  <div className="col-md-5 mt-2">
                    <LabelField lableName="Password" />
                  </div>
                  <div className="col-md-7">
                    <InputField
                      placeholder={"Enter Password"}
                      value={password}
                      onChangeInput={(value: any) => setpassword(value)}
                      maxlength={length_Fifty}
                    />
                  </div>
                </div>
                <div className="row d-flex justify-content-between pt-2">
                  <div className="col-md-5 mt-2">
                    <LabelField lableName="Is Mobile Verified" />
                  </div>
                  <div className="col-md-7">
                    <ToggleSwitchBtn
                      isActive={isMobileVerified}
                      getToggleval={(value: any) => {
                        changeToggle(value);
                      }}
                    />
                  </div>
                </div>
                <div className="row d-flex justify-content-between pt-2">
                  <div className="col-md-5 mt-2">
                    <LabelField lableName={"Status"} />
                  </div>
                  <div className="col-md-7">
                    <CustomDropdown
                      setInputText={(value: any) => changeStatus(value)}
                      value={userstatus}
                      options={userStatusData}
                      lableName="Select Status"
                      defaultValue={userstatus}
                    />
                  </div>
                </div>

                <div className="d-flex justify-content-evenly mt-5">
                  <button
                    type="submit"
                    className={`btn mb-3 submitbtn ${
                      profileValidation() ? "btn-secondary" : "btn-primary"
                    }`}
                    disabled={profileValidation()}
                  >
                    Save and Update
                  </button>
                </div>
              </div>
            </form>
          </div>
          {isEditMode ? (
            <div className="col-3">
              <input type="checkbox" onChange={changeEndDate} />
              <span className="checkVal">Edit End Date</span>
            </div>
          ) : null}
        </div>
      </div>
    );
  };
  return (
    <div className="contentBox">
      <PageTitle
        name={"Registration show Details"}
        isEditMode={isEditMode}
        onClick={handleCancelEditMode}
      />
      <div className="formDiv">
        <div className="col-12 formWidth">
          <form className="" onSubmit={handleSearch}>
            <div className="d-flex pb-5">
              <div className="row d-flex justify-content-between pt-2">
                <div className="col-md-5 mt-2">
                  <LabelField lableName="Phone Number" isRequired={true} />
                </div>
                <div className="col-md-7">
                  <InputField
                    placeholder="Enter Phone Number"
                    value={phoneNumber}
                    onChangeInput={(value: any) => onChangePhone(value)}
                    maxlength={length_Ten}
                    minlength={length_Ten}
                  />
                </div>
              </div>
              <div className="col-auto d-flex justify-content-center mt-2">
                <div className="col-md-7">
                  <SubmitButton name="Search" validation={phoneValidations()} />
                </div>
              </div>
            </div>
          </form>
          {!addEditcourse && !IsEditProfile
            ? showDetails && Object.keys(userDetailsList?.user).length !== 0
              ? renderUserDetails()
              : "" // <NoRecord />
            : !isEditMode && !IsEditProfile
            ? renderAddMode()
            : IsEditProfile
            ? renderEditProfile()
            : renderEditMode()}
        </div>
      </div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className="model"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className="modeldiv p-3">
            <div className="col mb-4 d-flex justify-content-between ">
              <h6 className="colorPurple fw-bold">Update Parent Course</h6>
              <ClearOutlinedIcon
                className="colorPurple cursor"
                onClick={handleClose}
              />
            </div>
            <div className="row px-5 form-check mx-0">
              <form onSubmit={handleUpdateParent}>
                <div>
                  <div className="d-flex mb-2 align-items-center">
                    <LabelField lableName={"Parent Course"} />
                    <CustomDropdown
                      lableName={"Select Parent Course"}
                      setInputText={(value: any) =>
                        changeParentCourseVal(value)
                      }
                      value={parentCourseVal}
                      options={parentCourseList}
                      reset={resetParentCourse}
                      defaultValue={parentCourseVal?._id}
                    />
                  </div>
                </div>
                <div className="d-flex justify-content-evenly mt-5">
                  <button type="submit" className="btn btn-primary px-5 cursor">
                    Save and Proceed
                  </button>
                </div>
              </form>
            </div>
          </div>
        </Fade>
      </Modal>
    </div>
  );
}

export default RegistrationDetails;
