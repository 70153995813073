import { useEffect, useState } from "react";
import { Document, Page, pdfjs } from "react-pdf";

const PdfViewer: React.FC = () => {
  const link = sessionStorage.getItem("pdfLink");

  const [numPages, setNumPages] = useState<number | null>(null);

  useEffect(() => {
    pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`;
  }, []);

  const onDocumentLoadSuccess = ({ numPages }: { numPages: number }) => {
    setNumPages(numPages);
  };

  return (
    <div className="pdfDiv mx-auto">
      <Document
        file={link || ""}
        onLoadSuccess={onDocumentLoadSuccess}
        onContextMenu={(e: any) => e.preventDefault()}
        className="pdf-container"
      >
        {Array.apply(null, Array(numPages))
          .map((x, i) => i + 1)
          .map((page, j) => (
            <Page key={j} pageNumber={page} />
          ))}
      </Document>
    </div>
  );
};

export default PdfViewer;
