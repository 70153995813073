import "../../App.css";
import React, { Suspense, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Sidebar from "../../Module/sidebar";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";

import Loading from "../../Component/loading";
import { HrPanelSidebarData } from "../../DATA/HrPanelSidebarData";
import { getUpcomingCall } from "../../Redux/Actions/counsellorPanelAction";
import CallPopUP from "../../Component/callPopUP";
import { updateCommonValues } from "../../Redux/Actions/CommonAction";
import { useAppDispatch } from "../../hooks";

import HrCallDetails from "./hrCallDetails";
import HrTalktime from "./hrTalktime";
import HrDashbord from "./hrDashbord";
import ResumeShared from "./resumeShared";
import FirstRoundInterview from "./firstRoundInterview";
import SecondRoundInterview from "./secondRoundInterview";
import HrRoundInterview from "./hrRoundInterview";
import Rejected from "./rejected";
import JoiningToday from "./joiningToday";
import JoiningInWeek from "./joiningInWeek";
import CallingDone from "./callingDone";
import AddNewCandidateLead from "./addNewCandidateLead";
import CandidateSearchByMobileNumber from "./candidateSearchByMobileNumber";
import CandidateStatusRecords from "./candidateStatusRecords";
import { makeCalltoCandidate } from "../../Redux/Actions/hrPanelAction";
import { showSuccessSnackbar } from "../../Redux/Actions/snackbarAction";

function HrPanelDashbord(Props: any) {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const currentURLarr = useLocation().pathname.split("/");
  let pageName = currentURLarr[currentURLarr.length - 1];

  const [upcomingCallPopUp, setUpcomingCallPopUp] = useState<boolean>(false);
  const [upcomingCall, setUpcomingCall] = useState<any>([]);

  const { loggedInUserId, loggedInUserManagerId, loggedInUserRole } =
    useSelector((state: any) => ({
      loggedInUserId: state.Auth.loggedInUserId,
      loggedInUserManagerId: state.Auth.loggedInUserManagerId,
      loggedInUserRole: state.Auth.loggedInUserRole,
    }));

  useEffect(() => {
    let interval = setInterval(async () => {
      getUpcomingCallList();
    }, 120000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  const getUpcomingCallList = () => {
    dispatch(
      getUpcomingCall(
        loggedInUserId,
        (res: any) => {
          if (res?.data?.length > 0) {
            setUpcomingCall(res?.data);
            setUpcomingCallPopUp(true);
          }
        },
        () => {}
      )
    );
  };

  const onHandleClose = () => {
    setUpcomingCallPopUp(false);
  };

  const onHandleCall = (data: any) => {
    setUpcomingCallPopUp(false);
    dispatch(
      makeCalltoCandidate(
        {
          employeeId: loggedInUserId,
          candidateNumber: data?.target?.value,
        },
        (res: any) => {
          dispatch(updateCommonValues("selectedLeadId", data?.target?.id));
          dispatch(updateCommonValues("ozonetelIframe", true));
          dispatch(showSuccessSnackbar(res?.data) as any);
          navigate("/hrLeadDetail", {
            state: {
              pageName,
            },
          });
        },
        () => {}
      )
    );
  };

  return (
    <div className="AppBox">
      <Sidebar data={HrPanelSidebarData} />
      <div className="dashboard">
        {
          <Suspense
            fallback={
              <div className="border p-1">
                <Loading />
              </div>
            }
          >
            <Routes>
              <Route path="/" element={<HrCallDetails />} />
              <Route path="hrCallDetails" element={<HrCallDetails />} />
              <Route path="hrTalktime" element={<HrTalktime />} />
              <Route path="hrDashbord" element={<HrDashbord />} />
              <Route path="resumeShared" element={<ResumeShared />} />
              <Route
                path="firstRoundInterview"
                element={<FirstRoundInterview />}
              />
              <Route
                path="secondRoundInterview"
                element={<SecondRoundInterview />}
              />
              <Route path="hrRoundInterview" element={<HrRoundInterview />} />
              <Route path="rejected" element={<Rejected />} />
              <Route path="joiningToday" element={<JoiningToday />} />
              <Route path="joiningInWeek" element={<JoiningInWeek />} />
              <Route path="callingDone" element={<CallingDone />} />
              <Route
                path="addNewCandidateLead"
                element={<AddNewCandidateLead />}
              />
              <Route
                path="candidateSearchByMobileNumber"
                element={<CandidateSearchByMobileNumber />}
              />
              <Route
                path="candidateStatusRecords"
                element={<CandidateStatusRecords />}
              />
            </Routes>
          </Suspense>
        }
      </div>
      {upcomingCallPopUp ? (
        <CallPopUP
          handleClose={() => {
            onHandleClose();
          }}
          handleCall={(value: any) => {
            onHandleCall(value);
          }}
          title={"Upcoming Call"}
          data={upcomingCall}
        />
      ) : null}
    </div>
  );
}

export default HrPanelDashbord;
