import React, { useEffect, useState } from "react";
import "../../../App.css";
import PageTitle from "../../../Component/pageTitle";
import DatePicker from "../../../Component/datePicker";
import { useDispatch } from "react-redux";
import moment from "moment";
import { showErrorSnackbar } from "../../../Redux/Actions/snackbarAction";
import GenaralTable from "../../../Component/genaralTable";
import GstViewButton from "../../../Component/gstViewButton";
import LabelField from "../../../Component/labelField";
import { useSelector } from "react-redux";
import Modal from "@material-ui/core/Modal";
import Fade from "@material-ui/core/Fade";
import Backdrop from "@material-ui/core/Backdrop";
import ClearOutlinedIcon from "@mui/icons-material/ClearOutlined";
import {
  getBatchName,
  getExamList,
  getExamStreamList,
  getSubExam,
} from "../../../Redux/Actions/counsellorPanelAction";
import { getStudyMaterialTransaction } from "../../../Redux/Actions/superManagerAction";
import { paymentOptions } from "../../../DATA/dataConstant";
import SubmitButton from "../../../Component/submitButton";
import InputField from "../../../Component/inputField";
import CustomDropdown from "../../../Component/customDropdown";

function TransactionEdutechStudyMaterial() {
  const dispatch = useDispatch();
  const { loggedInUserId, loggedInUserRole } = useSelector((state: any) => ({
    loggedInUserId: state.Auth.loggedInUserId,
    loggedInUserRole: state.Auth.loggedInUserRole,
  }));
  const [minDate, setMinDate] = useState("");
  const [startDate, setStartDate] = useState(
    moment.utc(new Date()).format("YYYY-MM-DD")
  );
  const [endDate, setEndDate] = useState(
    moment.utc(new Date()).format("YYYY-MM-DD")
  );
  const [examStream, setExamStream] = useState<any>("");
  const [exam, setExam] = useState<any>("");
  const [subExam, setSubExam] = useState<any>("");
  const [batchName, setBatchName] = useState<any>("");
  const [couponName, setCouponName] = useState("");
  const [transactionStatus, setTransactionStatus] = useState(
    loggedInUserRole === 8 ? 0 : 2
  );
  const [selectedPayment, setSelectedPayment] = useState<any>("");

  const [examStreamList, setExamStreamList] = useState([]);
  const [examList, setExamList] = useState([]);
  const [subExamList, setSubExamList] = useState([]);
  const [batchList, setBatchList] = useState([]);
  const [paymentTypeList, setPaymentTypeList] = useState(paymentOptions);
  const [transactionData, setTransactionData] = useState([]);

  const [open, setOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState<any>({});

  const [totalActualAmount, setTotalActualAmount] = useState(0);
  const [totalGSTAmount, setTotalGSTAmount] = useState(0);
  const [totalShopifyAmount, setTotalShopifyAmount] = useState(0);
  const [totalTransactionAmount, setTotalTransactionAmount] = useState(0);

  useEffect(() => {
    if (loggedInUserId === "65efe2933ae10e3fdfa00ae5") {
      setSelectedPayment({ _id: 3, name: "EDUTECH COUNSELLOR" });
    }
    if (loggedInUserRole === 8) {
      let tempList = paymentTypeList?.filter(
        (item) => item?._id !== 3 && item?._id !== 6
      );
      setPaymentTypeList(tempList);
    }
    setMinDate(moment.utc(new Date()).format("YYYY-MM-DD"));
    dispatch(
      getExamList((res: any) => {
        setExamList(res?.data);
      })
    );
    getTransactionDetails(transactionStatus);
  }, []);

  const changeEndDate = (value: any) => {
    setEndDate(value);
    if (value < startDate) {
      dispatch(
        showErrorSnackbar("End date must be greater than start date") as any
      );
    }
  };

  const onChangeExamStream = (value: any) => {
    setExamStream(value);
    setBatchName("");
    setBatchList([]);
    if (value) {
      dispatch(
        getBatchName(
          {
            baseCourseId: value?._id,
            examTypeId: exam?._id,
          },
          (res: any) => {
            setBatchList(res?.data);
          },
          () => {}
        )
      );
    }
  };
  const onChangeExam = (value: any) => {
    setExam(value);
    setSubExam("");
    setSubExamList([]);
    setExamStream("");
    setExamStreamList([]);
    setBatchName("");
    if (value) {
      dispatch(
        getSubExam(
          {
            examTypeId: value._id,
          },
          (res: any) => {
            if (res?.data?.length > 0) setSubExamList(res?.data);
            else
              dispatch(
                getExamStreamList(`${value?._id}`, (res: any) => {
                  setExamStreamList(res);
                })
              );
          }
        )
      );
    }
  };

  const onChangeSubExam = (value: any) => {
    setSubExam(value);
    setExamStream("");
    setExamStreamList([]);
    setBatchName("");
    if (value) {
      dispatch(
        getExamStreamList(`${exam._id}/${value._id}`, (res: any) => {
          setExamStreamList(res);
        })
      );
    }
  };

  const onChangeBatchName = (value: any) => {
    setBatchName(value);
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    setTransactionData([]);
    setTotalActualAmount(0);
    setTotalGSTAmount(0);
    setTotalShopifyAmount(0);
    setTotalTransactionAmount(0);
    getTransactionDetails(transactionStatus);
  };

  const getTransactionDetails = (val: any) => {
    setTransactionStatus(val);
    setTransactionData([]);
    setTotalActualAmount(0);
    setTotalGSTAmount(0);
    setTotalShopifyAmount(0);
    setTotalTransactionAmount(0);
    let postData: any = {
      startDate: startDate,
      endDate: endDate,
      status: val,
      subCategoryId: "6433e3468fb2f655c0e62dfe", // Complete Study Material id from subcategory
    };
    if (exam) {
      postData["examTypeId"] = exam._id;
    }
    if (subExam) {
      postData["subExamTypeId"] = subExam._id;
    }
    if (examStream) {
      postData["baseCourseId"] = examStream._id;
    }
    if (batchName) {
      postData["batchId"] = batchName?._id;
    }
    if (couponName) {
      postData["couponName"] = couponName;
    }
    if (loggedInUserId === "65efe2933ae10e3fdfa00ae5") {
      postData["paymentType"] = 3;
    } else if (selectedPayment) {
      postData["paymentType"] = selectedPayment?._id;
    }

    dispatch(
      getStudyMaterialTransaction(
        postData,
        (res: any) => {
          filterData(res);
        },
        () => {}
      )
    );
  };

  const filterData = (transactionDataList: any) => {
    let price = 0;
    let actualPrice = 0;
    let GSTPrice = 0;
    let shopifyPrice = 0;
    transactionDataList?.map((item: any) => {
      item?.courses?.map((subItem: any) => {
        // actualPrice = actualPrice + subItem?.actual_Amount || 0;
        // GSTPrice = GSTPrice + subItem?.gst_Amount || 0;
        shopifyPrice = shopifyPrice + subItem?.shopifyAmount || 0;
        price = price + subItem?.taxFreeAmount;
      });
    });
    setTransactionData(transactionDataList);
    setTotalActualAmount(actualPrice);
    setTotalGSTAmount(GSTPrice);
    setTotalShopifyAmount(shopifyPrice);
    setTotalTransactionAmount(price);
  };

  const renderListMode = () => {
    return (
      <div className="mt-2">
        <GenaralTable
          pagename={"transactionEdutechStudyMaterial"}
          tableHead={[
            "S.N",
            "ORDER NO",
            "ORDER DATE",
            "BATCH NAME",
            "SUBSCRIPTION",
            "PUBLICATION AMOUNT",
            "ACTUAL AMOUNT",
            "GST AMOUNT",
            "EDUTECH AMOUNT",
            "TOTAL AMOUNT",
            "COUPON CODE",
            "TOTAL DISCOUNT",
            "",
            "",
          ]}
          tableData={transactionData ? transactionData : []}
          edit={false}
          handleViewMore={(data: any) => {
            setOpen(true);
            setSelectedItem(data);
          }}
        />
      </div>
    );
  };

  const validations = () => {
    let isValidate = false;
    isValidate = startDate === "" || endDate === "";
    return isValidate;
  };

  return (
    <div className="contentBox">
      <PageTitle name={"TRANSACTION EDUTECH STUDY MATERIAL"} />
      <div className="row m-2">
        <div className="col-md-12 formDiv formWidth">
          <form onSubmit={handleSubmit}>
            <div className="d-flex flex-wrap">
              <div className="d-flex align-items-center mb-2 col-4 pe-4">
                <div className="col-md-4">
                  <LabelField lableName={"Start Date"} />
                </div>
                <div className="col-md-8">
                  <DatePicker
                    setInputText={(value: any) => {
                      setStartDate(value);
                    }}
                    value={startDate}
                    maxdate={minDate}
                    Isrequired
                  />
                </div>
              </div>
              <div className="d-flex align-items-center mb-2 col-4 pe-4">
                <div className="col-md-4">
                  <LabelField lableName={"End Date"} />
                </div>
                <div className="col-md-8">
                  <DatePicker
                    setInputText={(value: any) => changeEndDate(value)}
                    value={endDate}
                    maxdate={minDate}
                    Isrequired
                  />
                </div>
              </div>
              <div className="d-flex align-items-center mb-2 col-4 pe-4">
                <div className="col-md-4">
                  <LabelField lableName={"Exam Name"} />
                </div>
                <div className="col-md-8">
                  <CustomDropdown
                    lableName="Select Exam"
                    setInputText={(value: any) => onChangeExam(value)}
                    value={exam}
                    options={examList}
                  />
                </div>
              </div>
              {subExamList?.length > 0 ? (
                <div className="d-flex align-items-center mb-2 col-4 pe-4">
                  <div className="col-md-4">
                    <LabelField lableName={"Sub Exam Name"} />
                  </div>
                  <div className="col-md-8">
                    <CustomDropdown
                      lableName="Select Sub Exam Name"
                      setInputText={(value: any) => onChangeSubExam(value)}
                      value={subExam}
                      options={subExamList}
                    />
                  </div>
                </div>
              ) : null}
              <div className="d-flex align-items-center mb-2 col-4 pe-4">
                <div className="col-md-4">
                  <LabelField lableName={"Exam Stream"} />
                </div>
                <div className="col-md-8">
                  <CustomDropdown
                    lableName="Select Exam Stream"
                    setInputText={(value: any) => onChangeExamStream(value)}
                    value={examStream}
                    options={examStreamList}
                  />
                </div>
              </div>
              <div className="d-flex align-items-center mb-2 col-4 pe-4">
                <div className="col-md-4">
                  <LabelField lableName={"Batch Name"} />
                </div>
                <div className="col-md-8">
                  <CustomDropdown
                    lableName="Select Batch Name"
                    setInputText={(value: any) => onChangeBatchName(value)}
                    value={batchName}
                    options={batchList}
                  />
                </div>
              </div>
              <div className="d-flex align-items-center mb-2 col-4 pe-4">
                <div className="col-md-4">
                  <LabelField lableName={"Payment Type"} />
                </div>
                <div className="col-md-8">
                  <CustomDropdown
                    lableName="Select Payment Type"
                    setInputText={(value: any) => setSelectedPayment(value)}
                    value={selectedPayment}
                    options={paymentTypeList}
                    defaultValue={selectedPayment?._id}
                    disabled={
                      loggedInUserId === "65efe2933ae10e3fdfa00ae5"
                        ? true
                        : false
                    }
                  />
                </div>
              </div>
              <div className="d-flex align-items-center mb-2 col-4 pe-4">
                <div className="col-md-4">
                  <LabelField lableName={"Coupon Code"} />
                </div>
                <div className="col-md-8">
                  <InputField
                    placeholder="Enter First Name"
                    value={couponName}
                    onChangeInput={(value: any) =>
                      setCouponName(value?.toUpperCase())
                    }
                  />
                </div>
              </div>
              <div className="d-flex align-items-center mb-2 col-4 pe-4">
                <SubmitButton name="Search" validation={validations()} />
              </div>
            </div>
          </form>

          <div
            className="d-flex justify-content-between p-2"
            style={{ backgroundColor: "#E8EAF5" }}
          >
            <div>
              {loggedInUserRole !== 8 ? (
                <button
                  type="button"
                  className={`btn rounded-pill font-12 ${
                    transactionStatus === 2 ? "btn-success" : "btn-secondary"
                  }`}
                  onClick={() => getTransactionDetails(2)}
                >
                  Successful
                </button>
              ) : null}
              <button
                type="button"
                className={`btn rounded-pill font-12 ms-1 ${
                  transactionStatus === 0 ? "btn-success" : "btn-secondary"
                }`}
                onClick={() => getTransactionDetails(0)}
              >
                Pending
              </button>
              <button
                type="button"
                className={`btn rounded-pill font-12 ms-1 ${
                  transactionStatus === 9 ? "btn-success" : "btn-secondary"
                }`}
                onClick={() => getTransactionDetails(9)}
              >
                Cancelled
              </button>
            </div>
            {loggedInUserRole !== 8 ? (
              <div>
                <div className="d-flex align-items-center">
                  <h5>
                    Total : {"\u20B9"}
                    {totalTransactionAmount?.toFixed(2)}
                  </h5>
                  <GstViewButton
                    totalActualAmount={totalActualAmount}
                    totalGSTAmount={totalGSTAmount}
                    totalShopifyAmount={totalShopifyAmount}
                    totalPublicationAmount={totalTransactionAmount}
                    isPublication={true}
                    totalTransactionAmount={totalTransactionAmount}
                  />
                </div>
              </div>
            ) : null}
          </div>
          {renderListMode()}
        </div>
      </div>
      {open ? (
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          className="model"
          open={open}
          onClose={() => setOpen(false)}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={open}>
            <div style={{ width: "25%" }} className="bookstockmodeldiv p-3">
              <div className="col mb-4 d-flex justify-content-between ">
                <p className="fw-bold">
                  <span style={{ color: "#3F51B5" }}>User Details</span>
                </p>
                <ClearOutlinedIcon
                  className="colorPurple cursor"
                  onClick={() => {
                    setOpen(false);
                  }}
                />
              </div>
              <div className="form-check">
                <div className="d-flex">
                  <div className="w-100">
                    <LabelField lableName={"Student Name:"} />
                  </div>
                  <div className="w-75">
                    <p>{`${selectedItem?.userDetails?.firstName} ${selectedItem?.userDetails?.lastName}`}</p>
                  </div>
                </div>
                <div className="d-flex">
                  <div className="w-100">
                    <LabelField lableName={"Mobile Number:"} />
                  </div>
                  <div className="w-75">
                    <p>{`${selectedItem?.userDetails?.mobileNo}`}</p>
                  </div>
                </div>
              </div>
            </div>
          </Fade>
        </Modal>
      ) : null}
    </div>
  );
}

export default TransactionEdutechStudyMaterial;
