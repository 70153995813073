import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import PageTitle from "../../Component/pageTitle";
import GenaralTable from "../../Component/genaralTable";
import SalesPanelSatckGraph from "../../Component/salesPanelStackGraph";
import LabelField from "../../Component/labelField";
import DatePicker from "../../Component/datePicker";
import SubmitBtn from "../../Component/submitBtn";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { getManagerReferencesTeamList } from "../../Redux/Actions/managerPanelAction";
import moment from "moment";
import { useSelector } from "react-redux";
import SubmitButton from "../../Component/submitButton";
import SalesPanelGraph from "../../Component/salesPanelGraph";
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export default function SummeryOverview() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [xLabel, setXLabel] = useState<any>([]);
  const [Graphdata1, setGraphdata1] = useState<any>([]);
  const [teamLeadList, setTeamLeadList] = useState<any>([]);
  const [totalCount, SetTotalCount] = useState<any>(0);
  const [startDate, SetStartDate] = useState<any>(
    moment.utc(new Date()).subtract(1, "day").format("YYYY-MM-DD")
  );
  const [endDate, SetEndDate] = useState<any>(
    moment.utc(new Date()).subtract(1, "day").format("YYYY-MM-DD")
  );
  const [linkList, setLinkList] = useState<any>([]);

  const { loggedInUserRole, loggedInUserId } = useSelector((state: any) => ({
    loggedInUserRole: state.Auth.loggedInUserRole,
    loggedInUserId: state.Auth.loggedInUserId,
  }));

  useEffect(() => {
    dispatch(
      getManagerReferencesTeamList(
        {
          startDate: startDate,
          endDate: endDate,
          managerId: loggedInUserId,
        },
        (res: any) => {
          setTeamLeadList(res?.data);
          showGraph(res?.data);
          let tempLinkList: any = [];
          for (let i = 0; i <= res?.data?.length; i++) {
            tempLinkList?.push("../referenceAnalysis");
          }
          setLinkList(tempLinkList);
        },
        () => {}
      )
    );
  }, []);

  const showGraph = (list: any) => {
    let total = 0;
    setXLabel([]);
    setGraphdata1([]);
    let labelList: any = [];
    let graphDataList: any = [];
    list?.map((val: any) => {
      total = total + val?.studentLeadsIds;
      labelList?.push(val?.name);
      graphDataList?.push(val?.studentLeadsIds);
    });
    setXLabel(labelList);
    setGraphdata1(graphDataList);
    SetTotalCount(total);
  };

  const getManagerData = (val: any) => {
    sessionStorage.setItem("managerId", teamLeadList[val?.index]?._id);
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    const postdata = {
      startDate: startDate,
      endDate: endDate,
      managerId: loggedInUserId,
    };
    dispatch(
      getManagerReferencesTeamList(
        postdata,
        (res: any) => {
          setTeamLeadList(res?.data);
          showGraph(res?.data);
        },
        () => {}
      )
    );
  };
  return (
    <div className="contentBox">
      <div className="col-md-12 row">
        <div className=" formDiv formWidth p-5">
          <h3 className="fw-bold text-primary">
            References Generated Analysis
          </h3>
          <form className=" d-flex" onSubmit={handleSubmit}>
            <div className="col-md-3 d-flex me-2 mt-3 ">
              <div className="col-md-4 d-flex flex-row-reverse">
                <LabelField lableName="Start Date:" />
              </div>
              <div className="col-md-8">
                <DatePicker
                  value={startDate}
                  setInputText={(value: string) => SetStartDate(value)}
                  maxdate={moment.utc(new Date()).format("YYYY-MM-DD")}
                />
              </div>
            </div>
            <div className="col-md-3 d-flex me-2 mt-3">
              <div className="col-md-4 d-flex flex-row-reverse">
                <LabelField lableName="End Date:" />
              </div>
              <div className="col-md-8">
                <DatePicker
                  value={endDate}
                  setInputText={(value: string) => SetEndDate(value)}
                  maxdate={moment.utc(new Date()).format("YYYY-MM-DD")}
                />
              </div>
            </div>
            <div className="col-md-4 d-flex justify-content-between mb-3">
              <div className="col-md-6 me-2 mt-3">
                <SubmitButton name={"Search"} />
              </div>
            </div>
          </form>
          <div className="  p-3">
            <h4 className="fw-bold">Overview</h4>
            <span className="fw-bold">Total Count : {totalCount}</span>
            <SalesPanelGraph
              label={xLabel}
              link={linkList}
              value={Graphdata1}
              getExamStreamData={(val: any) => getManagerData(val)}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
