export const validateFullNameErrMsg = "Please enter valid name. ";
export const validateLastNameErrMsg = "Please enter valid Last name. ";
export const validateIsNumberOnlyErrMsg = "Please enter number only";
export const validateEmailAddressErrMsg = "Please enter valid email address";
export const validateIsFileImageTypeErrMsg =
  "File should be type of Image only!";
export const validateIsAlphabetErrMsg = "Please enter alphabet only";
export const validateIsFilePDFTypeErrMsg = "File should be type of PDF only!";
export const validateIsFileVideoTypeErrMsg =
  "File should be type of VIDEO only!";
export const containsStartsWithSpecialCharsOrStartsWithNumberOrWhiteSpaceErrMsg =
  "Cannot start with Special Characters, a number and White Space";
export const validateTenDigitNumberOnlyErrMsg = "Please enter 10 digit number.";
