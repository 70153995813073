import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import PageTitle from "../../Component/pageTitle";
import { useSelector } from "react-redux";
import { getHrLeadAnalysis } from "../../Redux/Actions/hrPanelAction";
export default function LeadAnalysis() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [analysis, setAnalysis] = useState<any>("ALL");
  const [analysisList, setAnalysisList] = useState<any>([]);
  const [totalCount, setTotalCount] = useState<any>("");

  const teamAnalysisPostData = location.state?.postData;
  const pageName = location.state?.pageName;

  const { loggedInUserId } = useSelector((state: any) => ({
    loggedInUserId: state.Auth.loggedInUserId,
  }));

  useEffect(() => {
    dispatch(
      getHrLeadAnalysis(
        {
          managerId: loggedInUserId,
        },
        (res1: any) => {
          let res = {
            record: [
              {
                createdAt: "2025-01-17T05:33:15.846Z",
                firstName: "MAHESH",
                lastName: "RAJPUT",
                executiveId: "6789eb9b5fb07129165f3dba",
                FIRSTROUNDINTERVIEW: {
                  _id: 2,
                  status: "FIRST ROUND INTERVIEW",
                  count: 1,
                  candidateIds: ["678f37ecccec0900e92934ee"],
                },
                REJECTED: {
                  _id: 5,
                  status: "REJECTED",
                  count: 2,
                  candidateIds: [
                    "678f3b40ccec0900e9296c36",
                    "679081544f99dba7f27bfc35",
                  ],
                },
                HRROUNDINTERVIEW: {
                  _id: 4,
                  status: "HR ROUND INTERVIEW",
                  count: 1,
                  candidateIds: ["6791d299c86d5e1e6086e2da"],
                },
              },
              {
                createdAt: "2025-01-14T07:41:06.750Z",
                firstName: "ABC",
                lastName: "ABC",
                executiveId: "67861512ca1deb2af512eeff",
                FIRSTROUNDINTERVIEW: {
                  _id: 2,
                  status: "FIRST ROUND INTERVIEW",
                  count: 2,
                  candidateIds: [
                    "6786288aca1deb2af51510d3",
                    "6789eeb95fb07129165f56a3",
                  ],
                },
              },
              {
                createdAt: "2025-01-17T03:08:35.142Z",
                firstName: "PRATIKSHA",
                lastName: "DHANDE",
                executiveId: "6789c9b35fb071291638baa5",
                FIRSTROUNDINTERVIEW: {
                  _id: 2,
                  status: "FIRST ROUND INTERVIEW",
                  count: 1,
                  candidateIds: ["678dee117da802f0d4888bec"],
                },
                JOININGINWEEK: {
                  _id: 7,
                  status: "JOINING IN WEEK",
                  count: 1,
                  candidateIds: ["678f6bceccec0900e9388914"],
                },
              },
              {
                createdAt: "2025-01-17T03:05:44.967Z",
                firstName: "ANJANA",
                lastName: "PANDEY",
                executiveId: "6789c9085fb071291638ba85",
                RESUMESHARED: {
                  _id: 1,
                  status: "RESUME SHARED",
                  count: 1,
                  candidateIds: ["678a095d5fb0712916902f41"],
                },
                SECONDROUNDINTERVIEW: {
                  _id: 3,
                  status: "SECOND ROUND INTERVIEW",
                  count: 1,
                  candidateIds: ["678f8846e42d45e0b6c46df5"],
                },
                REJECTED: {
                  _id: 5,
                  status: "REJECTED",
                  count: 2,
                  candidateIds: [
                    "678a1eac5fb07129169d03a7",
                    "678a2c965fb0712916bacd66",
                  ],
                },
                FIRSTROUNDINTERVIEW: {
                  _id: 2,
                  status: "FIRST ROUND INTERVIEW",
                  count: 1,
                  candidateIds: ["678b40f3e326970cb05fce05"],
                },
              },
              {
                createdAt: "2025-01-17T03:10:07.230Z",
                firstName: "ANJALI",
                lastName: "TEKALE",
                executiveId: "6789ca0f5fb071291638bb03",
                RESUMESHARED: {
                  _id: 1,
                  status: "RESUME SHARED",
                  count: 3,
                  candidateIds: [
                    "678a233d5fb0712916b0abb0",
                    "678dee407da802f0d4888dde",
                    "678f3a90ccec0900e9295e70",
                  ],
                },
                JOININGINWEEK: {
                  _id: 7,
                  status: "JOINING IN WEEK",
                  count: 1,
                  candidateIds: ["678f9188e42d45e0b6cc9d3d"],
                },
              },
            ],
            totalStatusCounts: {
              FIRSTROUNDINTERVIEW: {
                count: 5,
                candidateIds: [
                  "678f37ecccec0900e92934ee",
                  "6786288aca1deb2af51510d3",
                  "6789eeb95fb07129165f56a3",
                  "678dee117da802f0d4888bec",
                  "678b40f3e326970cb05fce05",
                ],
              },
              REJECTED: {
                count: 4,
                candidateIds: [
                  "678f3b40ccec0900e9296c36",
                  "679081544f99dba7f27bfc35",
                  "678a1eac5fb07129169d03a7",
                  "678a2c965fb0712916bacd66",
                ],
              },
              HRROUNDINTERVIEW: {
                count: 1,
                candidateIds: ["6791d299c86d5e1e6086e2da"],
              },
              JOININGINWEEK: {
                count: 2,
                candidateIds: [
                  "678f6bceccec0900e9388914",
                  "678f9188e42d45e0b6cc9d3d",
                ],
              },
              RESUMESHARED: {
                count: 4,
                candidateIds: [
                  "678a095d5fb0712916902f41",
                  "678a233d5fb0712916b0abb0",
                  "678dee407da802f0d4888dde",
                  "678f3a90ccec0900e9295e70",
                ],
              },
              SECONDROUNDINTERVIEW: {
                count: 1,
                candidateIds: ["678f8846e42d45e0b6c46df5"],
              },
            },
          };
          setAnalysisList(res?.record);
          setTotalCount(res?.totalStatusCounts);
        },
        () => {}
      )
    );
  }, []);

  const onClickLead = (item: any, status: any) => {
    console.log("itemmm", item, status);
    navigate("../hrLeadAnalysisDetails", {
      state: {
        executiveName: `${
          item?.firstName ? `${item?.firstName} ${item?.lastName}` : "Vipul"
        }`,
        candidateData: item,
        pageName: "hrLeadAnalysis",
        analysis,
        status: status,
      },
    });
  };

  const renderListMode = () => {
    return (
      <div className="salesTable table-responsive">
        <table className="w-100 mb-2">
          <thead>
            {analysis === "ALL" ? (
              <tr>
                <th rowSpan={2}>EXECUTIVE NAME</th>
                <th>RESUME SHARED</th>
                <th>FIRST ROUND INTERVIEW</th>
                <th>SECOND ROUND INTERVIEW</th>
                <th>HR ROUND INTERVIEW</th>
                <th>REJECTED</th>
                <th>JOINING TODAY</th>
                <th>JOINING IN WEEK</th>
                <th>CALLING DONE</th>
                <th>ON BOARDING</th>
              </tr>
            ) : null}

            {analysis === "ALL" ? (
              <tr>
                <th
                  className="text-primary cursor"
                  onClick={() => onClickLead([], 1)}
                >
                  {totalCount?.RESUMESHARED?.count}
                </th>
                <th
                  className="text-primary cursor"
                  onClick={() => onClickLead([], 2)}
                >
                  {totalCount?.FIRSTROUNDINTERVIEW?.count}
                </th>
                <th
                  className="text-primary cursor"
                  onClick={() => onClickLead([], 3)}
                >
                  {totalCount?.SECONDROUNDINTERVIEW?.count}
                </th>
                <th
                  className="text-primary cursor"
                  onClick={() => onClickLead([], 4)}
                >
                  {totalCount?.HRROUNDINTERVIEW?.count}
                </th>
                <th
                  className="text-primary cursor"
                  onClick={() => onClickLead([], 5)}
                >
                  {totalCount?.REJECTED?.count}
                </th>
                <th
                  className="text-primary cursor"
                  onClick={() => onClickLead([], 6)}
                >
                  {totalCount?.JOININGTODAY?.count}
                </th>
                <th
                  className="text-primary cursor"
                  onClick={() => onClickLead([], 7)}
                >
                  {totalCount?.JOININGINWEEK?.count}
                </th>
                <th
                  className="text-primary cursor"
                  onClick={() => onClickLead([], 8)}
                >
                  {totalCount?.CALLINGDONE?.count}
                </th>
                <th
                  className="text-primary cursor"
                  onClick={() => onClickLead([], 9)}
                >
                  {totalCount?.ONBOARDING?.count}
                </th>
              </tr>
            ) : null}
          </thead>
          <tbody>
            {analysisList.map((item: any, index: any) => {
              return analysis === "ALL" ? (
                <tr>
                  <td>
                    {item?.firstName ? item?.firstName : ""}{" "}
                    {item?.lastName ? item?.lastName : ""}
                  </td>
                  <td className="cursor" onClick={() => onClickLead(item, 1)}>
                    {item["RESUMESHARED"]?.count
                      ? item["RESUMESHARED"]?.count
                      : "-"}
                  </td>
                  <td className="cursor" onClick={() => onClickLead(item, 2)}>
                    {" "}
                    {item["FIRSTROUNDINTERVIEW"]?.count
                      ? item["FIRSTROUNDINTERVIEW"]?.count
                      : "-"}
                  </td>
                  <td className="cursor" onClick={() => onClickLead(item, 3)}>
                    {" "}
                    {item["SECONDROUNDINTERVIEW"]?.count
                      ? item["SECONDROUNDINTERVIEW"]?.count
                      : "-"}
                  </td>
                  <td className="cursor" onClick={() => onClickLead(item, 4)}>
                    {" "}
                    {item["HRROUNDINTERVIEW"]?.count
                      ? item["HRROUNDINTERVIEW"]?.count
                      : "-"}
                  </td>
                  <td className="cursor" onClick={() => onClickLead(item, 5)}>
                    {item["REJECTED"]?.count ? item["REJECTED"]?.count : "-"}
                  </td>
                  <td className="cursor" onClick={() => onClickLead(item, 6)}>
                    {" "}
                    {item["JOININGTODAY"]?.count
                      ? item["JOININGTODAY"]?.count
                      : "-"}
                  </td>
                  <td className="cursor" onClick={() => onClickLead(item, 7)}>
                    {" "}
                    {item["JOININGINWEEK"]?.count
                      ? item["JOININGINWEEK"]?.count
                      : "-"}
                  </td>
                  <td className="cursor" onClick={() => onClickLead(item, 8)}>
                    {" "}
                    {item["CALLINGDONE"]?.count
                      ? item["CALLINGDONE"]?.count
                      : "-"}
                  </td>

                  <td className="cursor" onClick={() => onClickLead(item, 9)}>
                    {" "}
                    {item["ONBOARDING"]?.count
                      ? item["ONBOARDING"]?.count
                      : "-"}
                  </td>
                </tr>
              ) : null;
            })}
          </tbody>
        </table>
      </div>
    );
  };

  return (
    <div className="contentBox">
      <PageTitle name={"Lead Analysis"} />
      <div className="col-md-12">
        <div className="formDiv p-3">{renderListMode()}</div>
      </div>
    </div>
  );
}
