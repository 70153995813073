import { useEffect, useState } from "react";

import moment from "moment";
import DatePicker from "../../Component/datePicker";
import LabelField from "../../Component/labelField";
import PageTitle from "../../Component/pageTitle";
import SubmitButton from "../../Component/submitButton";
import {
  getAllCallList,
  makeCalltoLead,
} from "../../Redux/Actions/counsellorPanelAction";
import { useSelector } from "react-redux";
import { updateCommonValues } from "../../Redux/Actions/CommonAction";
import { showSuccessSnackbar } from "../../Redux/Actions/snackbarAction";
import { useAppDispatch } from "../../hooks";
import { useNavigate } from "react-router-dom";
import { getCounsellorListByManagerId } from "../../Redux/Actions/managerPanelAction";
import CustomDropdown from "../../Component/customDropdown";

const initialVal: any = {
  allCalls: [],
  answeredCalls: [],
  notAnsweredCalls: [],
};

const initialCallStatus: any = {
  answeredCalls: 0,
  notAnsweredCalls: 0,
};

export default function CounsellorCallDetails() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [startDate, setStartDate] = useState<any>(
    moment.utc(new Date()).format("YYYY-MM-DD")
  );
  const [endDate, setEndDate] = useState<any>(
    moment.utc(new Date()).format("YYYY-MM-DD")
  );
  const [counsellor, setCounsellor] = useState<any>("");

  const [totalIncomingCallStatus, setTotalIncomingCallStatus] =
    useState<any>(initialCallStatus);
  const [totalOutgoingCallStatus, setTotalOutgoingCallStatus] =
    useState<any>(initialCallStatus);

  const [incomingCallStatusList, setIncomingCallStatusList] = useState<any>([]);
  const [outgoingCallStatusList, setOutgoingCallStatusList] = useState<any>([]);

  const [incomingDataArr, setIncomingDataArr] = useState<any>(initialVal);
  const [outgoingDataArr, setOutgoingDataArr] = useState<any>(initialVal);

  const [counsellorList, setCounsellorList] = useState<any>([]);
  const [selectCounsellorList, setSelectCounsellorList] = useState<any>([]);
  const [isViewMode, setIsViewMode] = useState<any>(false);

  const { loggedInUserId, ozonetelAgentId } = useSelector((state: any) => ({
    loggedInUserId: state.Auth.loggedInUserId,
    ozonetelAgentId: state.Auth.ozonetelAgentId,
  }));

  useEffect(() => {
    dispatch(
      getCounsellorListByManagerId(
        loggedInUserId,
        (res: any) => {
          setSelectCounsellorList(res?.data);
        },
        () => {}
      )
    );
    displayCallDetails();
  }, []);

  const displayCallDetails = () => {
    dispatch(
      getAllCallList(
        {
          startDate,
          endDate,
          CounsellorId: counsellor?._id,
        },
        (res: any) => {
          const IncomingCalls: any = callDirectionFilter(res, "Incoming");
          const OutgoingCalls: any = callDirectionFilter(res, "Outgoing");

          const answeredIncomingCalls: any = callStatusFilter(
            IncomingCalls,
            "Answered"
          );
          const notAnsweredIncomingCalls: any = callStatusFilter(
            IncomingCalls,
            "NotAnswered"
          );
          setIncomingDataArr({
            allCalls: IncomingCalls,
            answeredCalls: answeredIncomingCalls,
            notAnsweredCalls: notAnsweredIncomingCalls,
          });

          const answeredOutgoingCalls: any = callStatusFilter(
            OutgoingCalls,
            "Answered"
          );
          const notAnsweredoutgoingCalls: any = callStatusFilter(
            OutgoingCalls,
            "NotAnswered"
          );
          setOutgoingDataArr({
            allCalls: OutgoingCalls,
            answeredCalls: answeredOutgoingCalls,
            notAnsweredCalls: notAnsweredoutgoingCalls,
          });
        },
        () => {}
      )
    );
  };

  const callDirectionFilter = (list: any, type: any) => {
    let filteredList = list?.filter(
      (item: any) => item?.call_direction === type
    );
    return filteredList;
  };

  const callStatusFilter = (list: any, type: any) => {
    let filteredList = list?.filter((item: any) => item?.call_status === type);
    return filteredList;
  };

  const calculateTotals = (items: any) => {
    return items.reduce(
      (acc: any, item: any) => {
        if (item?.call_status === "Answered") {
          acc.answeredCalls += 1 || 0;
        } else if (item?.call_status === "NotAnswered") {
          acc.notAnsweredCalls += 1 || 0;
        }
        return acc;
      },
      {
        answeredCalls: 0,
        notAnsweredCalls: 0,
      }
    );
  };

  const onClickLead = (data: any, callDirection: any, callStatus: any) => {
    if (callDirection === "Incoming") {
      if (callStatus === "allCalls") {
        const allCalls = calculateTotals(data);
        setTotalIncomingCallStatus(allCalls);
        let result = calculateByCounsellor(data);
        setIncomingCallStatusList(result);
      } else if (callStatus === "answeredCalls") {
        const answeredCalls = calculateTotals(data);
        setTotalIncomingCallStatus(answeredCalls);
        let result = calculateByCounsellor(data);
        setIncomingCallStatusList(result);
      } else if (callStatus === "notAnsweredCalls") {
        const notAnsweredCalls = calculateTotals(data);
        setTotalIncomingCallStatus(notAnsweredCalls);
        let result = calculateByCounsellor(data);
        setIncomingCallStatusList(result);
      }
    } else if (callDirection === "Outgoing") {
      if (callStatus === "allCalls") {
        const allCalls = calculateTotals(data);
        setTotalOutgoingCallStatus(allCalls);
        let result = calculateByCounsellor(data);
        setOutgoingCallStatusList(result);
      } else if (callStatus === "answeredCalls") {
        const answeredCalls = calculateTotals(data);
        setTotalOutgoingCallStatus(answeredCalls);
        let result = calculateByCounsellor(data);
        setOutgoingCallStatusList(result);
      } else if (callStatus === "notAnsweredCalls") {
        const notAnsweredCalls = calculateTotals(data);
        setTotalOutgoingCallStatus(notAnsweredCalls);
        let result = calculateByCounsellor(data);
        setOutgoingCallStatusList(result);
      }
    }
  };

  const calculateByCounsellor = (data: any) => {
    const groupedData: any = {};
    data.forEach((item: any) => {
      const counsellorId = item.CounsellorId || 0;
      if (!groupedData[counsellorId]) {
        groupedData[counsellorId] = {
          CounsellorName: item.CounsellorName || "Not Connected to Counsellor",
          CounsellorId: counsellorId,
          Answered: 0,
          NotAnswered: 0,
        };
      }

      if (item.call_status === "Answered") {
        groupedData[counsellorId].Answered += 1;
      } else if (item.call_status === "NotAnswered") {
        groupedData[counsellorId].NotAnswered += 1;
      }
    });

    const result = Object.values(groupedData);
    return result;
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    displayCallDetails();
  };

  const onClickIncoming = (list: any, data: any, callStatus: any) => {
    setIsViewMode(true);
    if (data?.CounsellorId) {
      let tempData = list?.filter((item: any) => {
        if (
          item?.CounsellorId === data?.CounsellorId &&
          item?.call_status === callStatus
        ) {
          return item;
        }
      });
      setCounsellorList(tempData);
    } else {
      let tempData = list?.filter((item: any) => {
        if (!item?.CounsellorId && item?.call_status === callStatus) {
          return item;
        }
      });
      setCounsellorList(tempData);
    }
  };

  const renderIVRListMode = () => {
    return (
      <>
        <hr className="hr"></hr>
        <div className="shadow rounded pb-5">
          <div>
            <div className="mt-2">
              <h4 className="p-2 text-center fw-bold">Incoming Calls</h4>
              <div className="d-flex justify-content-between">
                <div className="col-4">
                  <div className="d-flex justify-content-center p-2 rounded m-2 border shadow cursor">
                    <div
                      className="text-center px-5"
                      onClick={() => {
                        onClickLead(
                          incomingDataArr?.allCalls,
                          "Incoming",
                          "allCalls"
                        );
                      }}
                    >
                      <p className="fw-bold font24">All Calls</p>
                      <p className="fw-bold text-success font24">
                        {incomingDataArr?.allCalls?.length > 0
                          ? incomingDataArr?.allCalls?.length
                          : 0}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-4">
                  <div className="d-flex justify-content-center p-2 rounded m-2 border shadow cursor">
                    <div
                      className="text-center px-5"
                      onClick={() => {
                        onClickLead(
                          incomingDataArr?.answeredCalls,
                          "Incoming",
                          "answeredCalls"
                        );
                      }}
                    >
                      <p className="fw-bold font24">Answered Calls</p>
                      <p className="fw-bold text-success font24">
                        {incomingDataArr?.answeredCalls?.length > 0
                          ? incomingDataArr?.answeredCalls?.length
                          : 0}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-4">
                  <div className="d-flex justify-content-center p-2 rounded m-2 border shadow cursor">
                    <div
                      className="text-center px-5"
                      onClick={() => {
                        onClickLead(
                          incomingDataArr?.notAnsweredCalls,
                          "Incoming",
                          "notAnsweredCalls"
                        );
                      }}
                    >
                      <p className="fw-bold font24">Not Answered Calls</p>
                      <p className="fw-bold text-success font24">
                        {incomingDataArr?.notAnsweredCalls?.length > 0
                          ? incomingDataArr?.notAnsweredCalls?.length
                          : 0}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="mx-0">
              <div className="mt-2 ">
                <div className="px-2">
                  <table className="w-100">
                    <thead>
                      <tr className="border-dark border text-dark">
                        <th
                          className="border-dark border"
                          rowSpan={2}
                          style={{ width: "3rem" }}
                        >
                          S.R.
                        </th>
                        <th
                          className="border-dark border"
                          rowSpan={2}
                          style={{ width: "10rem" }}
                        >
                          COUNSELLOR
                        </th>
                        <th
                          className="border-dark border"
                          style={{ width: "5rem" }}
                        >
                          ANSWERED
                        </th>
                        <th
                          className="border-dark border"
                          style={{ width: "5rem" }}
                        >
                          NOT ANSWERED
                        </th>
                      </tr>
                      <tr className="text-dark">
                        <th className="border-dark border bg-white text-dark">
                          {totalIncomingCallStatus?.answeredCalls}
                        </th>
                        <th className="border-dark border bg-white text-dark">
                          {totalIncomingCallStatus?.notAnsweredCalls}
                        </th>
                      </tr>
                    </thead>
                    <tbody className="border-dark border text-dark">
                      {incomingCallStatusList?.map(
                        (item: any, index: number) => {
                          return (
                            <>
                              <tr>
                                <td className="border-dark border bg-white text-dark">
                                  {index + 1}
                                </td>
                                <td className="border-dark border bg-white text-dark">
                                  {item?.CounsellorName}
                                </td>
                                <td
                                  className="border-dark border bg-white text-dark cursor"
                                  onClick={() => {
                                    onClickIncoming(
                                      incomingDataArr?.answeredCalls,
                                      item,
                                      "Answered"
                                    );
                                  }}
                                >
                                  {item?.Answered}
                                </td>
                                <td
                                  className="border-dark border bg-white text-dark cursor"
                                  onClick={() => {
                                    onClickIncoming(
                                      incomingDataArr?.notAnsweredCalls,
                                      item,
                                      "NotAnswered"
                                    );
                                  }}
                                >
                                  {item?.NotAnswered}
                                </td>
                              </tr>
                            </>
                          );
                        }
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <hr />
          <div>
            <div className="mt-2">
              <h4 className="p-2 text-center fw-bold">Outgoing Calls</h4>
              <div className="d-flex justify-content-between">
                <div className="col-4">
                  <div className="d-flex justify-content-center p-2 rounded m-2 border shadow cursor">
                    <div
                      className="text-center px-5"
                      onClick={() => {
                        onClickLead(
                          outgoingDataArr?.allCalls,
                          "Outgoing",
                          "allCalls"
                        );
                      }}
                    >
                      <p className="fw-bold font24">All Calls</p>
                      <p className="fw-bold text-success font24">
                        {outgoingDataArr?.allCalls?.length > 0
                          ? outgoingDataArr?.allCalls?.length
                          : 0}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-4">
                  <div className="d-flex justify-content-center p-2 rounded m-2 border shadow cursor">
                    <div
                      className="text-center px-5"
                      onClick={() => {
                        onClickLead(
                          outgoingDataArr?.answeredCalls,
                          "Outgoing",
                          "answeredCalls"
                        );
                      }}
                    >
                      <p className="fw-bold font24">Answered Calls</p>
                      <p className="fw-bold text-success font24">
                        {outgoingDataArr?.answeredCalls?.length > 0
                          ? outgoingDataArr?.answeredCalls?.length
                          : 0}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-4">
                  <div className="d-flex justify-content-center p-2 rounded m-2 border shadow cursor">
                    <div
                      className="text-center px-5"
                      onClick={() => {
                        onClickLead(
                          outgoingDataArr?.notAnsweredCalls,
                          "Outgoing",
                          "notAnsweredCalls"
                        );
                      }}
                    >
                      <p className="fw-bold font24">Not Answered Calls</p>
                      <p className="fw-bold text-success font24">
                        {outgoingDataArr?.notAnsweredCalls?.length > 0
                          ? outgoingDataArr?.notAnsweredCalls?.length
                          : 0}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="mx-0">
              <div className="mt-2 ">
                <div className="px-2">
                  <table className="w-100">
                    <thead>
                      <tr className="border-dark border text-dark">
                        <th
                          className="border-dark border"
                          rowSpan={2}
                          style={{ width: "3rem" }}
                        >
                          S.R.
                        </th>
                        <th
                          className="border-dark border"
                          rowSpan={2}
                          style={{ width: "10rem" }}
                        >
                          COUNSELLOR
                        </th>
                        <th
                          className="border-dark border"
                          style={{ width: "5rem" }}
                        >
                          ANSWERED
                        </th>
                        <th
                          className="border-dark border"
                          style={{ width: "5rem" }}
                        >
                          NOT ANSWERED
                        </th>
                      </tr>
                      <tr className="text-dark">
                        <th className="border-dark border bg-white text-dark">
                          {totalOutgoingCallStatus?.answeredCalls}
                        </th>
                        <th className="border-dark border bg-white text-dark">
                          {totalOutgoingCallStatus?.notAnsweredCalls}
                        </th>
                      </tr>
                    </thead>
                    <tbody className="border-dark border text-dark">
                      {outgoingCallStatusList?.map(
                        (item: any, index: number) => {
                          return (
                            <>
                              <tr>
                                <td className="border-dark border bg-white text-dark">
                                  {index + 1}
                                </td>
                                <td className="border-dark border bg-white text-dark">
                                  {item?.CounsellorName}
                                </td>
                                <td
                                  className="border-dark border bg-white text-dark cursor"
                                  onClick={() => {
                                    onClickIncoming(
                                      outgoingDataArr?.answeredCalls,
                                      item,
                                      "Answered"
                                    );
                                  }}
                                >
                                  {item?.Answered}
                                </td>
                                <td
                                  className="border-dark border bg-white text-dark cursor"
                                  onClick={() => {
                                    onClickIncoming(
                                      outgoingDataArr?.notAnsweredCalls,
                                      item,
                                      "NotAnswered"
                                    );
                                  }}
                                >
                                  {item?.NotAnswered}
                                </td>
                              </tr>
                            </>
                          );
                        }
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };

  const onChangeCounsellor = (val: any) => {
    setCounsellor(val);
  };

  const renderSearchMode = () => {
    return (
      <form className="row d-flex" onSubmit={handleSubmit}>
        <div className="col-md-3 d-flex mb-3 ">
          <div className="col-md-3 mt-2">
            <LabelField lableName="Start Date" />
          </div>
          <div className="col-md-9">
            <DatePicker
              value={startDate}
              setInputText={(value: string) => setStartDate(value)}
              disabled={isViewMode}
            />
          </div>
        </div>
        <div className="col-md-3 d-flex mb-3">
          <div className="col-md-3 mt-2">
            <LabelField lableName="End Date" />
          </div>
          <div className="col-md-9">
            <DatePicker
              value={endDate}
              setInputText={(value: string) => setEndDate(value)}
              disabled={isViewMode}
            />
          </div>
        </div>
        <div className="col-md-3 d-flex mb-3">
          <div className="col-md-3 mt-2">
            <LabelField lableName="Counsellor" isRequired={true} />
          </div>
          <div className="col-md-9 ms-3 pe-2">
            <CustomDropdown
              lableName="Select Counsellor"
              setInputText={(value: any) => onChangeCounsellor(value)}
              value={counsellor}
              options={selectCounsellorList}
            />
          </div>
        </div>
        <div className="col-md-3 d-flex justify-content-between mb-3">
          <div className="col-md-6 me-2">
            <SubmitButton name={"Search"} validation={isViewMode} />
          </div>
        </div>
      </form>
    );
  };

  const handleOnClick = (value: any, type: string) => {
    dispatch(updateCommonValues("selectedLeadId", value?.studentLeadId));
    if (type === "view") {
      navigate("../completeLeadDetail", {
        state: {
          pageName: "crmCounsellorCallDetails",
        },
      });
    }
  };

  const renderListMode = () => {
    return (
      <div className="mt-2">
        <table className="w-100">
          <thead>
            <tr className="border-dark border text-dark">
              <th className="border-dark border" style={{ width: "3rem" }}>
                S.R.
              </th>
              <th className="border-dark border" style={{ width: "10rem" }}>
                COUNSELLOR NAME
              </th>
              <th className="border-dark border" style={{ width: "5rem" }}>
                CALLER NAME
              </th>
              <th className="border-dark border" style={{ width: "5rem" }}>
                CALL TYPE
              </th>
              <th className="border-dark border" style={{ width: "10rem" }}>
                CALL DURATION
              </th>
              <th className="border-dark border" style={{ width: "10rem" }}>
                CALL DATE & TIME
              </th>
              <th className="border-dark border" style={{ width: "5rem" }}>
                CALL STATUS
              </th>
              <th className="border-dark border" style={{ width: "10rem" }}>
                Call Recording
              </th>
              <th className="border-dark border" style={{ width: "10rem" }}>
                Actions
              </th>
            </tr>
          </thead>
          <tbody className="border-dark border text-dark">
            {counsellorList?.map((item: any, index: number) => {
              return (
                <>
                  <tr className="cursor">
                    <td className="border-dark border bg-white text-dark">
                      {index + 1}
                    </td>
                    <td className="border-dark border bg-white text-dark">
                      {item?.CounsellorName}
                    </td>
                    <td className="border-dark border bg-white text-dark">
                      {`${item?.studentFirstName} ${item?.studentLastName}`}
                    </td>
                    <td className="border-dark border bg-white text-dark">
                      {item?.call_direction}
                    </td>
                    <td className="border-dark border bg-white text-dark">
                      {item?.call_duration}
                    </td>
                    <td className="border-dark border bg-white text-dark">
                      {item.call_date
                        ? moment(item.call_date)
                            .utc()
                            .format("DD-MM-YYYY hh:mm:ss a")
                        : ""}
                    </td>
                    <td className="border-dark border bg-white text-danger">
                      {item?.call_status}
                    </td>
                    <td className="border-dark border bg-white text-dark">
                      <audio
                        className="ps-0 mb-0"
                        src={item?.call_recording}
                        controls
                      ></audio>
                    </td>
                    <td className="border-dark border bg-white text-dark">
                      {/* <button
                        className={`btn me-1 ${
                          item?.call_status === "Answered"
                            ? "btn-success"
                            : "btn-danger"
                        }`}
                        onClick={() => handleOnClick(item, "call")}
                      >
                        Call Now
                      </button> */}
                      <button
                        className={`btn btn-primary`}
                        onClick={() => handleOnClick(item, "view")}
                      >
                        View
                      </button>
                    </td>
                  </tr>
                </>
              );
            })}
          </tbody>
        </table>
      </div>
    );
  };

  const handleGoback = () => {
    setIsViewMode(false);
  };

  return (
    <div className="contentBox">
      <PageTitle
        name={"All Calls Detail"}
        isGoBack={isViewMode}
        onClick={handleGoback}
      />
      <div className="row">
        <div className="formWidth">
          {renderSearchMode()}
          {!isViewMode ? renderIVRListMode() : renderListMode()}
        </div>
      </div>
    </div>
  );
}
