import { Pagination } from "@mui/material";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import PageTitle from "../../Component/pageTitle";
import PaginationTable from "../../Component/paginationTable";
import PaginationView from "../../Component/paginationView";
import usePagination from "../../Component/usePagination";
import { useSelector } from "react-redux";
import { getTableDataSlots } from "../../DATA/dataConstant";
import { useAppDispatch } from "../../hooks";
import { updateCommonValues } from "../../Redux/Actions/CommonAction";
import { getCandidateStatusRecords } from "../../Redux/Actions/hrPanelAction";
import moment from "moment";
import DatePicker from "../../Component/datePicker";
import LabelField from "../../Component/labelField";
import SubmitButton from "../../Component/submitButton";

export default function CandidateRecords() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const { loggedInUserId, selectedPage, selectedRowsPerPage, sDate, eDate } =
    useSelector((state: any) => ({
      loggedInUserId: state.Auth.loggedInUserId,
      selectedPage: state.Auth.selectedPage,
      selectedRowsPerPage: state.Auth.selectedRowsPerPage,
      sDate: state.Auth.startDate,
      eDate: state.Auth.endDate,
    }));

  const pageName = location.state?.pageName;

  const [startDate, SetStartDate] = useState<any>(
    moment.utc(new Date()).format("YYYY-MM-DD")
  );
  const [endDate, SetEndDate] = useState<any>(
    moment.utc(new Date()).format("YYYY-MM-DD")
  );
  const [leadsList, setLeadsList] = useState<any>([]);

  // pagination
  const [count, setCount] = useState<any>(0);
  const [page, setPage] = useState<any>(0);
  const [rowsPerPage, setRowsPerPage] = useState<any>(10);
  const [currentPage, setCurrentPage] = useState<any>(1);
  const totalPages = Math.ceil(count / rowsPerPage);
  const _DATA = usePagination(count, rowsPerPage);

  useEffect(() => {
    if (pageName !== "hrLeadDetail") {
      dispatch(updateCommonValues("selectedLeadId", ""));
      dispatch(updateCommonValues("selectedPage", ""));
      dispatch(updateCommonValues("selectedRowsPerPage", ""));
    }
    let PostData: any = {
      startDate,
      endDate,
      executiveId: loggedInUserId,
    };
    if (pageName === "hrLeadDetail") {
      setRowsPerPage(selectedRowsPerPage);
      setPage(selectedPage);
      setCurrentPage(selectedPage + 1);
      SetStartDate(sDate);
      SetEndDate(eDate);
      PostData["startDate"] = sDate;
      PostData["endDate"] = eDate;
    }
    setCount(0);
    setLeadsList([]);
    dispatch(
      getCandidateStatusRecords(
        PostData,
        (res: any) => {
          setCount(res?.data?.length);
          setLeadsList(res?.data);
        },
        () => {}
      )
    );
  }, []);

  const handleChange = (e: any, page: number) => {
    onPageChange(page, rowsPerPage, 1);
    setPage(page - 1);
    setCurrentPage(page);
    _DATA.jump(page);
  };

  const onPageChange = (page: number, rowsPerPage: number, flag: number) => {
    /************ page change****************** */
    let data: any = {};
    if (flag === 1) {
      data = {
        skip: (page - 1) * rowsPerPage,
        limit: rowsPerPage,
        status: 1,
      };
    } else if (flag === 2) {
      data = {
        skip: 0,
        limit: rowsPerPage,
        status: 1,
      };
    } else {
      data = {
        skip: 0,
        limit: count,
        status: 1,
      };
    }

    dispatch(
      getCandidateStatusRecords(
        {
          startDate,
          endDate,
          executiveId: loggedInUserId,
        },
        (res: any) => {
          setCount(res?.data?.length);
          setLeadsList(res?.data);
        },
        () => {}
      )
    );
  };

  const handleChangePage = (event: any, newPage: number) => {
    setPage(newPage);
    setCurrentPage(newPage + 1);
  };

  const handleChangeRowsPerPage = (event: { target: { value: any } }) => {
    const value = event.target.value;
    if (value === "All") {
      setRowsPerPage(-1);
      setPage(0);
      setCurrentPage(1);
      return;
    }
    setRowsPerPage(parseInt(value, 10));
    setPage(0);
    setCurrentPage(1);
    onPageChange(page, value, 2);
  };

  const onClickLead = (data: any) => {
    dispatch(updateCommonValues("selectedLeadId", data?.candidateId));
    dispatch(updateCommonValues("selectedPage", page));
    dispatch(updateCommonValues("selectedRowsPerPage", rowsPerPage));
    dispatch(updateCommonValues("startDate", startDate));
    dispatch(updateCommonValues("endDate", endDate));
    navigate("/hrLeadDetail", {
      state: {
        pageName: "candidateStatusRecords",
      },
    });
  };

  const renderListMode = () => {
    return (
      <>
        <div>
          <div className="d-flex paginationdiv justify-content-end align-items-center">
            <Pagination
              count={totalPages}
              size="large"
              page={currentPage}
              variant="outlined"
              shape="rounded"
              color={"primary"}
              onChange={handleChange}
            />
          </div>

          <PaginationView
            count={count}
            tableData={leadsList ? leadsList : []}
            page={page}
            rowsPerPage={rowsPerPage}
            handleChangePage={handleChangePage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
            rowsPerPageOptions={getTableDataSlots(count)}
          />

          <div>
            <PaginationTable
              tableData={leadsList ? leadsList : []}
              pagename={"candidateStatusRecords"}
              rowsPerPage={rowsPerPage}
              page={page}
              tableHead={[
                "S.N",
                "Name",
                "Assigned Status",
                "Department",
                "Designation",
                "Current Status",
                "Candiate Registered Date",
              ]}
              edit={false}
              handleClick={(value: any) => {
                onClickLead(value);
              }}
            />
          </div>
        </div>
      </>
    );
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    let PostData: any = {
      startDate,
      endDate,
      executiveId: loggedInUserId,
    };
    setCount(0);
    setLeadsList([]);
    dispatch(
      getCandidateStatusRecords(
        PostData,
        (res: any) => {
          setCount(res?.data?.length);
          setLeadsList(res?.data);
        },
        () => {}
      )
    );
  };

  return (
    <div className="contentBox">
      <PageTitle name={"Candidate Records"} />
      <div className="row m-2">
        <form className="col-md-12 formDiv formWidth" onSubmit={handleSubmit}>
          <div className="d-flex">
            <div className="col-md-4 d-flex mb-3 ms-0">
              <div className="col-md-3 mt-2">
                <LabelField lableName="Start Date" />
              </div>
              <div className="col-md-8 ms-2 pe-2">
                <DatePicker
                  value={startDate}
                  setInputText={(value: string) => SetStartDate(value)}
                />
              </div>
            </div>
            <div className="col-md-4 d-flex mb-3 ms-0">
              <div className="col-md-3 mt-2">
                <LabelField lableName="End Date" />
              </div>
              <div className="col-md-8 ms-2 pe-2">
                <DatePicker
                  value={endDate}
                  setInputText={(value: string) => SetEndDate(value)}
                />
              </div>
            </div>
            <div className="col-md-4 d-flex mb-3 ms-0">
              <div className="col-md-3">
                <SubmitButton name={"Search"} />
              </div>
            </div>
          </div>
          {renderListMode()}
        </form>
      </div>
    </div>
  );
}
