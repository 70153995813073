import services from "../../API/service";
import endpoint from "../../API/endpoint";
import { showSuccessSnackbar } from "./snackbarAction";
import { showErrorSnackbar } from "./snackbarAction";
import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";
import { SET, UPDATE } from "../Types/leadManagementTypes";
import { updateCommonValues } from "./CommonAction";

export const setParentCourse =
  (key: string, value: any) => (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
    dispatch({
      type: SET,
      payload: {
        [key]: value,
      },
    });
  };

export const modifyParentCourse =
  (key: string, value: any) => (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
    dispatch({
      type: UPDATE,
      payload: {
        [key]: value,
      },
    });
  };

export const getTeamLeadList =
  (params: any, callback: Function, errorCallback: Function): any =>
  async (dispatch: Function, getState: Function) => {
    dispatch(updateCommonValues("loader", true));
    services.postApiCall(
      endpoint.ManagerDashboard.getTeamLeadList,
      params,
      (res: any) => {
        console?.log("dataaaa", res);
        dispatch(updateCommonValues("loader", false));
        if (res?.statusCode === services?.status_code?.success) {
          callback(res);
        } else {
          errorCallback(res);
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error: any) => {
        dispatch(updateCommonValues("loader", false));
        errorCallback(error);
      }
    );
  };

export const getManagerRevenueList =
  (params: any, callback: Function, errorCallback: Function): any =>
  async (dispatch: Function, getState: Function) => {
    dispatch(updateCommonValues("loader", true));
    services.postApiCall(
      endpoint.ManagerDashboard.getManagerRevenueList,
      params,
      (res: any) => {
        dispatch(updateCommonValues("loader", false));
        if (res?.statusCode === services?.status_code?.success) {
          callback(res);
        } else {
          errorCallback(res);
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error: any) => {
        dispatch(updateCommonValues("loader", false));
        errorCallback(error);
      }
    );
  };

export const getTargetRevenueUnachieved =
  (params: any, callback: Function, errorCallback: Function): any =>
  async (dispatch: Function, getState: Function) => {
    dispatch(updateCommonValues("loader", true));
    services.postApiCall(
      endpoint.ManagerDashboard.getTargetRevenueUnachieved,
      params,
      (res: any) => {
        dispatch(updateCommonValues("loader", false));
        if (res?.statusCode === services?.status_code?.success) {
          callback(res);
        } else {
          errorCallback(res);
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error: any) => {
        dispatch(updateCommonValues("loader", false));
        errorCallback(error);
      }
    );
  };

export const getMonthlyTargetRevenueUnachieved =
  (params: any, callback: Function, errorCallback: Function): any =>
  async (dispatch: Function, getState: Function) => {
    dispatch(updateCommonValues("loader", true));
    services.postApiCall(
      endpoint.ManagerDashboard.getMonthlyTargetRevenueUnachieved,
      params,
      (res: any) => {
        dispatch(updateCommonValues("loader", false));
        if (res?.statusCode === services?.status_code?.success) {
          callback(res);
        } else {
          errorCallback(res);
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error: any) => {
        dispatch(updateCommonValues("loader", false));
        errorCallback(error);
      }
    );
  };

export const getManagerTargetRevenueUnachieved =
  (params: any, callback: Function, errorCallback: Function): any =>
  async (dispatch: Function, getState: Function) => {
    dispatch(updateCommonValues("loader", true));
    services.postApiCall(
      endpoint.ManagerDashboard.getManagerTargetRevenueUnachieved,
      params,
      (res: any) => {
        dispatch(updateCommonValues("loader", false));
        if (res?.statusCode === services?.status_code?.success) {
          callback(res);
        } else {
          errorCallback(res);
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error: any) => {
        dispatch(updateCommonValues("loader", false));
        errorCallback(error);
      }
    );
  };

export const getMonthlyManagerTargetRevenueUnachieved =
  (params: any, callback: Function, errorCallback: Function): any =>
  async (dispatch: Function, getState: Function) => {
    dispatch(updateCommonValues("loader", true));
    services.postApiCall(
      endpoint.ManagerDashboard.getMonthlyManagerTargetRevenueUnachieved,
      params,
      (res: any) => {
        dispatch(updateCommonValues("loader", false));
        if (res?.statusCode === services?.status_code?.success) {
          callback(res);
        } else {
          errorCallback(res);
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error: any) => {
        dispatch(updateCommonValues("loader", false));
        errorCallback(error);
      }
    );
  };

export const getTeamLeadListByManager =
  (params: any, callback: Function, errorCallback: Function): any =>
  async (dispatch: Function, getState: Function) => {
    dispatch(updateCommonValues("loader", true));
    services.postApiCall(
      endpoint.ManagerDashboard.getTeamLeadListByManager,
      params,
      (res: any) => {
        dispatch(updateCommonValues("loader", false));
        if (res?.statusCode === services?.status_code?.success) {
          callback(res);
        } else {
          errorCallback(res);
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error: any) => {
        dispatch(updateCommonValues("loader", false));
        errorCallback(error);
      }
    );
  };

export const getReferencesTeamLeadList =
  (params: any, callback: Function, errorCallback: Function): any =>
  async (dispatch: Function, getState: Function) => {
    dispatch(updateCommonValues("loader", true));
    services.postApiCall(
      endpoint.ManagerDashboard.getReferencesTeamLeadList,
      params,
      (res: any) => {
        dispatch(updateCommonValues("loader", false));
        if (res?.statusCode === services?.status_code?.success) {
          callback(res);
        } else {
          errorCallback(res);
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error: any) => {
        dispatch(updateCommonValues("loader", false));
        errorCallback(error);
      }
    );
  };

export const getManagerReferencesByTeamLeadId =
  (params: any, callback: Function, errorCallback: Function): any =>
  async (dispatch: Function, getState: Function) => {
    dispatch(updateCommonValues("loader", true));
    services.getApiCall(
      endpoint.ManagerDashboard.getManagerReferencesByTeamLeadId,
      params,
      (res: any) => {
        dispatch(updateCommonValues("loader", false));
        if (res?.data?.statusCode === services?.status_code?.success) {
          callback(res?.data);
        } else {
          errorCallback(res);
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error: any) => {
        dispatch(updateCommonValues("loader", false));
        errorCallback(error);
      }
    );
  };

export const getManagerReferencesTeamList =
  (params: any, callback: Function, errorCallback: Function): any =>
  async (dispatch: Function, getState: Function) => {
    dispatch(updateCommonValues("loader", true));
    services.postApiCall(
      endpoint.ManagerDashboard.getManagerReferencesTeamList,
      params,
      (res: any) => {
        dispatch(updateCommonValues("loader", false));
        if (res?.statusCode === services?.status_code?.success) {
          callback(res);
        } else {
          errorCallback(res);
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error: any) => {
        dispatch(updateCommonValues("loader", false));
        errorCallback(error);
      }
    );
  };

export const getReferenceListByCounsellorId =
  (id: any, params: any, callback: Function): any =>
  async (dispatch: Function, getState: Function) => {
    dispatch(updateCommonValues("loader", true));
    services.postApiCall(
      endpoint.ManagerDashboard.getReferenceListByCounsellorId + id,
      params,
      (res: any) => {
        dispatch(updateCommonValues("loader", false));
        if (res?.statusCode === services?.status_code?.success) {
          callback(res);
        } else {
          callback([]);
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      () => {
        callback([]);
        dispatch(updateCommonValues("loader", false));
      }
    );
  };

export const getEmployeetByManagerId =
  (params: any, callback: Function, errorCallback: Function): any =>
  async (dispatch: Function, getState: Function) => {
    dispatch(updateCommonValues("loader", true));
    services.getApiCall(
      endpoint.ManagerDashboard.getEmployeetByManagerId,
      params,
      (res: any) => {
        dispatch(updateCommonValues("loader", false));
        if (res?.data?.statusCode === services?.status_code?.success) {
          callback(res?.data);
        } else {
          errorCallback(res);
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error: any) => {
        dispatch(updateCommonValues("loader", false));
        errorCallback(error);
      }
    );
  };

export const getCounsellorListByManagerId =
  (params: any, callback: Function, errorCallback: Function): any =>
  async (dispatch: Function, getState: Function) => {
    dispatch(updateCommonValues("loader", true));
    services.getApiCall(
      endpoint.ManagerDashboard.getCounsellorListByManagerId,
      params,
      (res: any) => {
        dispatch(updateCommonValues("loader", false));
        if (res?.data?.statusCode === services?.status_code?.success) {
          callback(res?.data);
        } else {
          errorCallback(res);
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error: any) => {
        dispatch(updateCommonValues("loader", false));
        errorCallback(error);
      }
    );
  };

export const getTeamAnalysis =
  (params: any, callback: Function, errorCallback: Function): any =>
  async (dispatch: Function, getState: Function) => {
    dispatch(updateCommonValues("loader", true));
    services.postApiCall(
      endpoint.ManagerDashboard.getTeamAnalysis,
      params,
      (res: any) => {
        dispatch(updateCommonValues("loader", false));
        if (res?.statusCode === services?.status_code?.success) {
          callback(res?.data);
        } else {
          errorCallback(res);
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error: any) => {
        dispatch(updateCommonValues("loader", false));
        errorCallback(error);
      }
    );
  };

export const getTeamOverdueAnalysis =
  (params: any, callback: Function, errorCallback: Function): any =>
  async (dispatch: Function, getState: Function) => {
    dispatch(updateCommonValues("loader", true));
    services.postApiCall(
      endpoint.ManagerDashboard.getTeamOverdueAnalysis,
      params,
      (res: any) => {
        dispatch(updateCommonValues("loader", false));
        if (res?.statusCode === services?.status_code?.success) {
          callback(res?.data);
        } else {
          errorCallback(res);
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error: any) => {
        dispatch(updateCommonValues("loader", false));
        errorCallback(error);
      }
    );
  };

// export const getCounsellorListById =
// (params: any, callback: Function, errorCallback: Function): any =>
// async (dispatch: Function, getState: Function) => {
//   dispatch(updateCommonValues("loader", true));
//   services.getApiCall(
//     endpoint.ManagerDashboard.getCounsellorListById,
//     params,
//     (res: any) => {
//       dispatch(updateCommonValues("loader", false));
//       if (res?.data?.statusCode === services?.status_code?.success) {
//         callback(res?.data);
//       } else {
//         errorCallback(res);
//         dispatch(showErrorSnackbar(res?.msg));
//       }
//     },
//     (error: any) => {
//       dispatch(updateCommonValues("loader", false));
//       errorCallback(error);
//     }
//   );
// };

export const getCounsellorListById =
  (params: any, callback: Function, errorCallback: Function): any =>
  async (dispatch: Function, getState: Function) => {
    dispatch(updateCommonValues("loader", true));
    services.postApiCall(
      endpoint.ManagerDashboard.getCounsellorListById,
      params,
      (res: any) => {
        dispatch(updateCommonValues("loader", false));
        if (res?.statusCode === services?.status_code?.success) {
          callback(res?.data);
        } else {
          errorCallback(res);
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error: any) => {
        dispatch(updateCommonValues("loader", false));
        errorCallback(error);
      }
    );
  };

export const getReferenceCounsellorListById =
  (params: any, callback: Function, errorCallback: Function): any =>
  async (dispatch: Function, getState: Function) => {
    dispatch(updateCommonValues("loader", true));
    services.getApiCall(
      endpoint.ManagerDashboard.getReferenceCounsellorListById,
      params,
      (res: any) => {
        dispatch(updateCommonValues("loader", false));
        if (res?.data?.statusCode === services?.status_code?.success) {
          callback(res?.data);
        } else {
          errorCallback(res);
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error: any) => {
        dispatch(updateCommonValues("loader", false));
        errorCallback(error);
      }
    );
  };

export const getCourseAnalysis =
  (params: any, callback: Function, errorCallback: Function): any =>
  async (dispatch: Function, getState: Function) => {
    dispatch(updateCommonValues("loader", true));
    services.postApiCall(
      endpoint.ManagerDashboard.getCourseAnalysis,
      params,
      (res: any) => {
        dispatch(updateCommonValues("loader", false));
        if (res?.statusCode === services?.status_code?.success) {
          callback(res?.data);
        } else {
          errorCallback(res);
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error: any) => {
        dispatch(updateCommonValues("loader", false));
        errorCallback(error);
      }
    );
  };

export const getCouponCodeList =
  (params: any, callback: Function, errorCallback: Function): any =>
  async (dispatch: Function, getState: Function) => {
    dispatch(updateCommonValues("loader", true));
    services.postApiCall(
      endpoint.ManagerDashboard.getCouponCodeList,
      params,
      (res: any) => {
        dispatch(updateCommonValues("loader", false));
        if (res?.statusCode === services?.status_code?.success) {
          callback(res?.data);
        } else {
          errorCallback(res);
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error: any) => {
        dispatch(updateCommonValues("loader", false));
        errorCallback(error);
      }
    );
  };

export const getLeadByMobileNumber =
  (params: any, callback: Function, errorCallback: Function): any =>
  async (dispatch: Function, getState: Function) => {
    dispatch(updateCommonValues("loader", true));
    services.postApiCall(
      endpoint.CounsellorPanel.getLeadByMobileNumber,
      params,
      (res: any) => {
        dispatch(updateCommonValues("loader", false));
        if (res?.statusCode === services?.status_code?.success) {
          callback(res?.data);
        } else {
          errorCallback(res);
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error: any) => {
        dispatch(updateCommonValues("loader", false));
        errorCallback(error);
      }
    );
  };

export const getTeamLeadEmployeeListById =
  (params: any, callback: Function, errorCallback: Function): any =>
  async (dispatch: Function, getState: Function) => {
    dispatch(updateCommonValues("loader", true));
    services.postApiCall(
      endpoint.manageEmployee.getTeamLeadEmployeeListById,
      params,
      (res: any) => {
        dispatch(updateCommonValues("loader", false));
        if (res?.statusCode === services?.status_code?.success) {
          callback(res?.data);
        } else {
          errorCallback(res);
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error: any) => {
        dispatch(updateCommonValues("loader", false));
        errorCallback(error);
      }
    );
  };

export const getManagerEmployeeListById =
  (params: any, callback: Function, errorCallback: Function): any =>
  async (dispatch: Function, getState: Function) => {
    dispatch(updateCommonValues("loader", true));
    services.postApiCall(
      endpoint.manageEmployee.getManagerEmployeeListById,
      params,
      (res: any) => {
        dispatch(updateCommonValues("loader", false));
        if (res?.statusCode === services?.status_code?.success) {
          callback(res?.data);
        } else {
          errorCallback(res);
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error: any) => {
        dispatch(updateCommonValues("loader", false));
        errorCallback(error);
      }
    );
  };

export const getEnrolledAnalysis =
  (params: any, callback: Function, errorCallback: Function): any =>
  async (dispatch: Function, getState: Function) => {
    dispatch(updateCommonValues("loader", true));
    services.postApiCall(
      endpoint.TeamAnalysis.getEnrolledAnalysis,
      params,
      (res: any) => {
        dispatch(updateCommonValues("loader", false));
        if (res?.statusCode === services?.status_code?.success) {
          callback(res?.data);
        } else {
          errorCallback(res);
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error: any) => {
        dispatch(updateCommonValues("loader", false));
        errorCallback(error);
      }
    );
  };

export const getStudentLeadDetails =
  (params: any, callback: Function, errorCallback: Function): any =>
  async (dispatch: Function, getState: Function) => {
    dispatch(updateCommonValues("loader", true));
    services.postApiCall(
      endpoint.ManagerDashboard.getStudentLeadDetails,
      params,
      (res: any) => {
        dispatch(updateCommonValues("loader", false));
        if (res?.statusCode === services?.status_code?.success) {
          callback(res?.data);
        } else {
          errorCallback(res);
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error: any) => {
        dispatch(updateCommonValues("loader", false));
        errorCallback(error);
      }
    );
  };

export const getAssignedStudentLeadDetails =
  (params: any, callback: Function, errorCallback: Function): any =>
  async (dispatch: Function, getState: Function) => {
    dispatch(updateCommonValues("loader", true));
    services.postApiCall(
      endpoint.ManagerDashboard.getAssignedStudentLeadDetails,
      params,
      (res: any) => {
        dispatch(updateCommonValues("loader", false));
        if (res?.statusCode === services?.status_code?.success) {
          callback(res?.data);
        } else {
          errorCallback(res);
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error: any) => {
        dispatch(updateCommonValues("loader", false));
        errorCallback(error);
      }
    );
  };

export const getOverdueStudentLeadDetails =
  (params: any, callback: Function, errorCallback: Function): any =>
  async (dispatch: Function, getState: Function) => {
    dispatch(updateCommonValues("loader", true));
    services.postApiCall(
      endpoint.ManagerDashboard.getOverdueStudentLeadDetails,
      params,
      (res: any) => {
        dispatch(updateCommonValues("loader", false));
        if (res?.statusCode === services?.status_code?.success) {
          callback(res?.data);
        } else {
          errorCallback(res);
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error: any) => {
        dispatch(updateCommonValues("loader", false));
        errorCallback(error);
      }
    );
  };

export const getSellableExamList =
  (params: any, callback: Function, errorCallback: Function): any =>
  async (dispatch: Function, getState: Function) => {
    dispatch(updateCommonValues("loader", true));
    services.postApiCall(
      endpoint.ManagerDashboard.getSellableExamList,
      params,
      (res: any) => {
        dispatch(updateCommonValues("loader", false));
        if (res?.statusCode === services?.status_code?.success) {
          callback(res);
        } else {
          errorCallback(res);
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error: any) => {
        dispatch(updateCommonValues("loader", false));
        errorCallback(error);
      }
    );
  };

export const getSellableExamListByExamID =
  (params: any, callback: Function, errorCallback: Function): any =>
  async (dispatch: Function, getState: Function) => {
    dispatch(updateCommonValues("loader", true));
    services.postApiCall(
      endpoint.ManagerDashboard.getSellableExamListByExamID,
      params,
      (res: any) => {
        dispatch(updateCommonValues("loader", false));
        if (res?.statusCode === services?.status_code?.success) {
          callback(res);
        } else {
          errorCallback(res);
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error: any) => {
        dispatch(updateCommonValues("loader", false));
        errorCallback(error);
      }
    );
  };

export const getTalkTimeList =
  (id: any, params: any, callback: Function, errorCallback: Function): any =>
  async (dispatch: Function, getState: Function) => {
    dispatch(updateCommonValues("loader", true));
    services.postApiCall(
      endpoint.ManagerDashboard.getTalkTimeList + id,
      params,
      (res: any) => {
        dispatch(updateCommonValues("loader", false));
        if (res?.statusCode === services?.status_code?.success) {
          callback(res?.data);
        } else {
          errorCallback(res);
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error: any) => {
        dispatch(updateCommonValues("loader", false));
        errorCallback(error);
      }
    );
  };

export const getIncomingTalkTimeList =
  (id: any, params: any, callback: Function, errorCallback: Function): any =>
  async (dispatch: Function, getState: Function) => {
    dispatch(updateCommonValues("loader", true));
    services.postApiCall(
      endpoint.ManagerDashboard.getIncomingTalkTimeList + id,
      params,
      (res: any) => {
        dispatch(updateCommonValues("loader", false));
        if (res?.statusCode === services?.status_code?.success) {
          callback(res?.data);
        } else {
          errorCallback(res);
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error: any) => {
        dispatch(updateCommonValues("loader", false));
        errorCallback(error);
      }
    );
  };

export const getSellableExamRevenueList =
  (params: any, callback: Function, errorCallback: Function): any =>
  async (dispatch: Function, getState: Function) => {
    dispatch(updateCommonValues("loader", true));
    services.postApiCall(
      endpoint.ManagerDashboard.getSellableExamRevenueList,
      params,
      (res: any) => {
        dispatch(updateCommonValues("loader", false));
        if (res?.statusCode === services?.status_code?.success) {
          callback(res);
        } else {
          errorCallback(res);
          // dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error: any) => {
        dispatch(updateCommonValues("loader", false));
        errorCallback(error);
      }
    );
  };

export const getTargetStatusByLeadId =
  (params: any, callback: Function, errorCallback: Function): any =>
  async (dispatch: Function, getState: Function) => {
    dispatch(updateCommonValues("loader", true));
    services.postApiCall(
      endpoint.ManagerDashboard.getTargetStatusByLeadId,
      params,
      (res: any) => {
        dispatch(updateCommonValues("loader", false));
        if (res?.statusCode === services?.status_code?.success) {
          callback(res);
        } else {
          errorCallback(res);
          // dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error: any) => {
        dispatch(updateCommonValues("loader", false));
        errorCallback(error);
      }
    );
  };

export const getTargetStatusByManager =
  (params: any, callback: Function, errorCallback: Function): any =>
  async (dispatch: Function, getState: Function) => {
    dispatch(updateCommonValues("loader", true));
    services.postApiCall(
      endpoint.ManagerDashboard.getTargetStatusByManager,
      params,
      (res: any) => {
        dispatch(updateCommonValues("loader", false));
        if (res?.statusCode === services?.status_code?.success) {
          callback(res);
        } else {
          errorCallback(res);
          // dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error: any) => {
        dispatch(updateCommonValues("loader", false));
        errorCallback(error);
      }
    );
  };

export const getAttendanceReq =
  (params: any, callback: Function, errorCallback: Function): any =>
  async (dispatch: Function, getState: Function) => {
    dispatch(updateCommonValues("loader", true));
    services.postApiCall(
      endpoint.Attendance.getAttendanceReq,
      params,
      (res: any) => {
        dispatch(updateCommonValues("loader", false));
        if (res?.statusCode === services?.status_code?.success) {
          callback(res);
        } else {
          errorCallback(res);
          // dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error: any) => {
        dispatch(updateCommonValues("loader", false));
        errorCallback(error);
      }
    );
  };

export const getAttendanceReqForManager =
  (params: any, callback: Function, errorCallback: Function): any =>
  async (dispatch: Function, getState: Function) => {
    dispatch(updateCommonValues("loader", true));
    services.postApiCall(
      endpoint.Attendance.getAttendanceReqForManager,
      params,
      (res: any) => {
        dispatch(updateCommonValues("loader", false));
        if (res?.statusCode === services?.status_code?.success) {
          callback(res);
        } else {
          errorCallback(res);
          // dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error: any) => {
        dispatch(updateCommonValues("loader", false));
        errorCallback(error);
      }
    );
  };

export const getMonthlyReportForManager =
  (params: any, callback: Function, errorCallback: Function): any =>
  async (dispatch: Function, getState: Function) => {
    dispatch(updateCommonValues("loader", true));
    services.postApiCall(
      endpoint.Attendance.getMonthlyReportForManager,
      params,
      (res: any) => {
        dispatch(updateCommonValues("loader", false));
        if (res?.statusCode === services?.status_code?.success) {
          callback(res);
        } else {
          errorCallback(res);
          // dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error: any) => {
        dispatch(updateCommonValues("loader", false));
        errorCallback(error);
      }
    );
  };

export const getTLAttendanceReq =
  (params: any, callback: Function, errorCallback: Function): any =>
  async (dispatch: Function, getState: Function) => {
    dispatch(updateCommonValues("loader", true));
    services.postApiCall(
      endpoint.Attendance.getTLAttendanceReq,
      params,
      (res: any) => {
        dispatch(updateCommonValues("loader", false));
        if (res?.statusCode === services?.status_code?.success) {
          callback(res);
        } else {
          errorCallback(res);
          // dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error: any) => {
        dispatch(updateCommonValues("loader", false));
        errorCallback(error);
      }
    );
  };

export const markAttendance =
  (params: any, callback: Function, errorCallback: Function): any =>
  async (dispatch: Function, getState: Function) => {
    dispatch(updateCommonValues("loader", true));
    services.postApiCall(
      endpoint.Attendance.markAttendance,
      params,
      (res: any) => {
        dispatch(updateCommonValues("loader", false));
        if (res?.statusCode === services?.status_code?.success) {
          callback(res);
        } else {
          errorCallback(res);
          // dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error: any) => {
        dispatch(updateCommonValues("loader", false));
        errorCallback(error);
      }
    );
  };

export const getAttendanceUpdatedList =
  (params: any, callback: Function, errorCallback: Function): any =>
  async (dispatch: Function, getState: Function) => {
    dispatch(updateCommonValues("loader", true));
    services.getApiCall(
      endpoint.Attendance.getAttendanceUpdatedList,
      params,
      (res: any) => {
        dispatch(updateCommonValues("loader", false));
        if (res?.data?.statusCode === services?.status_code?.success) {
          callback(res?.data?.data);
        } else {
          errorCallback(res);
        }
      },
      (error: any) => {
        dispatch(updateCommonValues("loader", false));
      }
    );
  };

export const getCounsellorDetailsByTeamId =
  (params: any, callback: Function, errorCallback: Function): any =>
  async (dispatch: Function, getState: Function) => {
    dispatch(updateCommonValues("loader", true));
    services.getApiCall(
      endpoint.ManagerDashboard.getCounsellorDetailsByTeamId,
      params,
      (res: any) => {
        dispatch(updateCommonValues("loader", false));
        if (res?.data?.statusCode === services?.status_code?.success) {
          callback(res?.data?.data);
        } else {
          errorCallback(res);
        }
      },
      (error: any) => {
        dispatch(updateCommonValues("loader", false));
      }
    );
  };
export const getMonthlyReport =
  (params: any, callback: Function, errorCallback: Function): any =>
  async (dispatch: Function, getState: Function) => {
    dispatch(updateCommonValues("loader", true));
    services.postApiCall(
      endpoint.Attendance.getMonthlyReport,
      params,
      (res: any) => {
        dispatch(updateCommonValues("loader", false));
        if (res?.statusCode === services?.status_code?.success) {
          callback(res);
        } else {
          errorCallback(res);
          // dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error: any) => {
        dispatch(updateCommonValues("loader", false));
        errorCallback(error);
      }
    );
  };

export const addManagerRemark =
  (id: any, params: any, callback: Function, errorCallback: Function): any =>
  async (dispatch: Function, getState: Function) => {
    dispatch(updateCommonValues("loader", true));
    services.postApiCall(
      endpoint.ManagerDashboard.addManagerRemark + id,
      params,
      (res: any) => {
        dispatch(updateCommonValues("loader", false));
        if (res?.statusCode === services?.status_code?.success) {
          callback(res?.data);
        } else {
          errorCallback(res);
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error: any) => {
        dispatch(updateCommonValues("loader", false));
        errorCallback(error);
      }
    );
  };

export const getTeamLeadRemark =
  (params: any, callback: Function, errorCallback: Function): any =>
  async (dispatch: Function, getState: Function) => {
    dispatch(updateCommonValues("loader", true));
    services.postApiCall(
      endpoint.ManagerDashboard.getTeamLeadRemark,
      params,
      (res: any) => {
        dispatch(updateCommonValues("loader", false));
        if (res?.statusCode === services?.status_code?.success) {
          callback(res?.data);
        } else {
          errorCallback(res);
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error: any) => {
        dispatch(updateCommonValues("loader", false));
        errorCallback(error);
      }
    );
  };

export const getManagerRemark =
  (params: any, callback: Function, errorCallback: Function): any =>
  async (dispatch: Function, getState: Function) => {
    dispatch(updateCommonValues("loader", true));
    services.postApiCall(
      endpoint.ManagerDashboard.getManagerRemark,
      params,
      (res: any) => {
        dispatch(updateCommonValues("loader", false));
        if (res?.statusCode === services?.status_code?.success) {
          callback(res?.data);
        } else {
          errorCallback(res);
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error: any) => {
        dispatch(updateCommonValues("loader", false));
        errorCallback(error);
      }
    );
  };

export const getRemarkAnalysis =
  (params: any, callback: Function, errorCallback: Function): any =>
  async (dispatch: Function, getState: Function) => {
    dispatch(updateCommonValues("loader", true));
    services.postApiCall(
      endpoint.ManagerDashboard.getRemarkAnalysis,
      params,
      (res: any) => {
        dispatch(updateCommonValues("loader", false));
        if (res?.statusCode === services?.status_code?.success) {
          callback(res?.data);
        } else {
          errorCallback(res);
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error: any) => {
        dispatch(updateCommonValues("loader", false));
        errorCallback(error);
      }
    );
  };

export const getManagerRemarkAnalysis =
  (params: any, callback: Function, errorCallback: Function): any =>
  async (dispatch: Function, getState: Function) => {
    dispatch(updateCommonValues("loader", true));
    services.postApiCall(
      endpoint.ManagerDashboard.getManagerRemarkAnalysis,
      params,
      (res: any) => {
        dispatch(updateCommonValues("loader", false));
        if (res?.statusCode === services?.status_code?.success) {
          callback(res?.data);
        } else {
          errorCallback(res);
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error: any) => {
        dispatch(updateCommonValues("loader", false));
        errorCallback(error);
      }
    );
  };

export const getManagerCallRecord =
  (id: any, params: any, callback: Function, errorCallback: Function): any =>
  async (dispatch: Function, getState: Function) => {
    dispatch(updateCommonValues("loader", true));
    services.postApiCall(
      endpoint.ManagerDashboard.getManagerCallRecord + id,
      params,
      (res: any) => {
        dispatch(updateCommonValues("loader", false));
        if (res?.statusCode === services?.status_code?.success) {
          callback(res);
        } else {
          errorCallback(res);
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error: any) => {
        dispatch(updateCommonValues("loader", false));
        errorCallback(error);
      }
    );
  };

export const getManagerCallRecordByCounsellorId =
  (params: any, callback: Function, errorCallback: Function): any =>
  async (dispatch: Function, getState: Function) => {
    dispatch(updateCommonValues("loader", true));
    services.postApiCall(
      endpoint.ManagerDashboard.getManagerCallRecordByCounsellorId,
      params,
      (res: any) => {
        dispatch(updateCommonValues("loader", false));
        if (res?.statusCode === services?.status_code?.success) {
          callback(res?.data);
        } else {
          errorCallback(res);
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error: any) => {
        dispatch(updateCommonValues("loader", false));
        errorCallback(error);
      }
    );
  };

export const getTeamLeadDailyTalkTime =
  (id: any, params: any, callback: Function, errorCallback: Function): any =>
  async (dispatch: Function, getState: Function) => {
    dispatch(updateCommonValues("loader", true));
    services.postApiCall(
      endpoint.ManagerDashboard.getTeamLeadDailyTalkTime + id,
      params,
      (res: any) => {
        dispatch(updateCommonValues("loader", false));
        if (res?.statusCode === services?.status_code?.success) {
          callback(res.data);
        } else {
          errorCallback(res);
          // dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error: any) => {
        dispatch(updateCommonValues("loader", false));
        errorCallback(error);
      }
    );
  };

export const getDailyMeetingForByManagerId =
  (id: any, params: any, callback: Function, errorCallback: Function): any =>
  async (dispatch: Function, getState: Function) => {
    dispatch(updateCommonValues("loader", true));
    services.postApiCall(
      endpoint.ManagerDashboard.getDailyMeetingForByManagerId + id,
      params,
      (res: any) => {
        dispatch(updateCommonValues("loader", false));
        if (res?.statusCode === services?.status_code?.success) {
          callback(res);
        } else {
          errorCallback(res);
          // dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error: any) => {
        dispatch(updateCommonValues("loader", false));
        errorCallback(error);
      }
    );
  };

export const getTeamLeadAssignedOverview =
  (id: any, params: any, callback: Function, errorCallback: Function): any =>
  async (dispatch: Function, getState: Function) => {
    dispatch(updateCommonValues("loader", true));
    services.postApiCall(
      endpoint.ManagerDashboard.getTeamLeadAssignedOverview + id,
      params,
      (res: any) => {
        dispatch(updateCommonValues("loader", false));
        if (res?.statusCode === services?.status_code?.success) {
          callback(res?.data);
        } else {
          errorCallback(res);
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error: any) => {
        dispatch(updateCommonValues("loader", false));
        errorCallback(error);
      }
    );
  };

export const getUserActivityList =
  (params: any, callback: Function, errorCallback: Function): any =>
  async (dispatch: Function, getState: Function) => {
    dispatch(updateCommonValues("loader", true));
    services.postApiCall(
      endpoint.ManagerDashboard.getUserActivityList,
      params,
      (res: any) => {
        dispatch(updateCommonValues("loader", false));
        if (res?.statusCode === services?.status_code?.success) {
          callback(res?.data);
        } else {
          errorCallback(res);
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error: any) => {
        dispatch(updateCommonValues("loader", false));
        errorCallback(error);
      }
    );
  };

export const getManagerTalkTimeList =
  (id: any, params: any, callback: Function, errorCallback: Function): any =>
  async (dispatch: Function, getState: Function) => {
    dispatch(updateCommonValues("loader", true));
    services.postApiCall(
      endpoint.ManagerDashboard.getManagerTalkTimeList + id,
      params,
      (res: any) => {
        dispatch(updateCommonValues("loader", false));
        if (res?.statusCode === services?.status_code?.success) {
          callback(res?.data);
        } else {
          errorCallback(res);
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error: any) => {
        dispatch(updateCommonValues("loader", false));
        errorCallback(error);
      }
    );
  };

export const getManagerDailyTalkTime =
  (id: any, params: any, callback: Function, errorCallback: Function): any =>
  async (dispatch: Function, getState: Function) => {
    dispatch(updateCommonValues("loader", true));
    services.postApiCall(
      endpoint.ManagerDashboard.getManagerDailyTalkTime + id,
      params,
      (res: any) => {
        dispatch(updateCommonValues("loader", false));
        if (res?.statusCode === services?.status_code?.success) {
          callback(res.data);
        } else {
          errorCallback(res);
          // dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error: any) => {
        dispatch(updateCommonValues("loader", false));
        errorCallback(error);
      }
    );
  };

export const getDailyIVRCallsDetails =
  (params: any, callback: Function, errorCallback: Function): any =>
  async (dispatch: Function, getState: Function) => {
    dispatch(updateCommonValues("loader", true));
    services.postApiCall(
      endpoint.ManagerDashboard.getDailyIVRCallsDetails,
      params,
      (res: any) => {
        dispatch(updateCommonValues("loader", false));
        if (res?.statusCode === services?.status_code?.success) {
          callback(res.data);
        } else {
          errorCallback(res);
          // dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error: any) => {
        dispatch(updateCommonValues("loader", false));
        errorCallback(error);
      }
    );
  };

export const getDailyIVROutCallsDetails =
  (params: any, callback: Function, errorCallback: Function): any =>
  async (dispatch: Function, getState: Function) => {
    dispatch(updateCommonValues("loader", true));
    services.postApiCall(
      endpoint.ManagerDashboard.getDailyIVROutCallsDetails,
      params,
      (res: any) => {
        dispatch(updateCommonValues("loader", false));
        if (res?.statusCode === services?.status_code?.success) {
          callback(res.data);
        } else {
          errorCallback(res);
          // dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error: any) => {
        dispatch(updateCommonValues("loader", false));
        errorCallback(error);
      }
    );
  };

export const updateNotConnectedCounsellorCalls =
  (params: any, callback: Function, errorCallback: Function): any =>
  async (dispatch: Function, getState: Function) => {
    dispatch(updateCommonValues("loader", true));
    services.postApiCall(
      endpoint.ManagerDashboard.updateNotConnectedCounsellorCalls,
      params,
      (res: any) => {
        dispatch(updateCommonValues("loader", false));
        if (res?.statusCode === services?.status_code?.success) {
          callback(res.data);
        } else {
          errorCallback(res);
          // dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error: any) => {
        dispatch(updateCommonValues("loader", false));
        errorCallback(error);
      }
    );
  };
