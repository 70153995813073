import React, { useEffect, useState } from "react";
import PageTitle from "../../../Component/pageTitle";
import { useDispatch } from "react-redux";
import LabelField from "../../../Component/labelField";
import CustomDropdown from "../../../Component/customDropdown";
import {
  getLiveLecturesList,
  updateLiveLecturesById,
} from "../../../Redux/Actions/counsellorPanelAction";
import Pagination from "@mui/material/Pagination";
import usePagination from "../../../Component/usePagination";
import PaginationTable from "../../../Component/paginationTable";
import PaginationView from "../../../Component/paginationView";
import DatePicker from "../../../Component/datePicker";
import moment from "moment";
import Modal from "@material-ui/core/Modal";
import Fade from "@material-ui/core/Fade";
import Backdrop from "@material-ui/core/Backdrop";
import ClearOutlinedIcon from "@mui/icons-material/ClearOutlined";
import DateTime from "../../../Component/dateTime";
import { getTableDataSlots } from "../../../DATA/dataConstant";
import {
  getExamList,
  getSubExam,
  getExamStreamList,
  getBatchName,
} from "../../../Redux/Actions/counsellorPanelAction";

const LiveClassSchedule = () => {
  const dispatch = useDispatch();
  const [parentCourseVal, setparentCourseVal] = useState<any>("");
  const [parentCourseList, setParentCourseList] = useState<any>([]);
  const [liveClassScheduleList, setLiveClassScheduleList] = useState<any>([]);
  const [filteredLiveClassScheduleList, setFilteredLiveClassScheduleList] =
    useState<any>([]);
  const [selectedStatus, setSelectedStatus] = useState<any>(1);
  const [batchList, setBatchList] = useState<any>([]);
  const [batchName, setBatchName] = useState<any>("");
  const [examName, setExamName] = useState<any>("");
  const [subExamName, setSubExamName] = useState<any>("");
  const [examList, setExamList] = useState<any>([]);
  const [subExamList, setSubExamList] = useState<any>([]);
  const [startDate, setStartDate] = useState<any>("");
  const [endDate, setEndDate] = useState<any>("");

  const [open, setOpen] = useState<any>(false);
  const [ID, setID] = useState<any>("");
  const [rescheduleDate, setRescheduleDate] = useState<any>("");
  const [rescheduleTime, setRescheduleTime] = useState<any>("");
  const [fromDate, setFromDate] = useState<any>("");
  const [sessionTime, setSessionTime] = useState<any>("");
  const [status, setStatus] = useState<any>("");

  // pagination
  const [page, setPage] = useState<any>(0);
  const [rowsPerPage, setRowsPerPage] = useState<any>(10);
  const [currentPage, setCurrentPage] = useState<any>(1);
  const totalPages = Math.ceil(
    filteredLiveClassScheduleList?.length / rowsPerPage
  );
  const _DATA = usePagination(filteredLiveClassScheduleList, rowsPerPage);

  const handleChange = (e: any, page: any) => {
    setPage(page - 1);
    setCurrentPage(page);
    _DATA.jump(page);
  };

  useEffect(() => {
    dispatch(
      getExamList((res: any) => {
        setExamList(res?.data);
      })
    );
  }, []);

  useEffect(() => {
    setCurrentPage(1);
  }, [rowsPerPage]);

  const showData = (e: any, val: any) => {
    e.preventDefault();
    setSelectedStatus(val);
    const date = new Date();
    let res;
    if (val === 1) {
      res = liveClassScheduleList.filter(
        (item: any) => item.fromDate === moment.utc(date).format("YYYY-MM-DD")
      );
    } else if (val === 2) {
      res = liveClassScheduleList.filter(
        (item: any) => item.fromDate < moment.utc(date).format("YYYY-MM-DD")
      );
    } else {
      res = liveClassScheduleList.filter(
        (item: any) => item.fromDate > moment.utc(date).format("YYYY-MM-DD")
      );
    }
    setFilteredLiveClassScheduleList(res);
  };

  const changeParentCourseVal = (value: any) => {
    setparentCourseVal(value);
    setBatchList([]);
    setBatchName("");
    dispatch(
      getBatchName(
        {
          baseCourseId: value._id,
          examTypeId: examName._id,
          subExamTypeId: subExamName._id,
        },
        (res: any) => {
          setBatchList(res.data);
        },
        () => {}
      )
    );
  };

  const onListChange = (value: any) => {
    setExamName(value);
    setSubExamList([]);
    setSubExamName("");
    setBatchList([]);
    setBatchName("");
    dispatch(
      getSubExam(
        {
          examTypeId: value._id,
        },
        (res: any) => {
          if (res?.data?.length > 0) setSubExamList(res.data);
          else
            dispatch(
              getExamStreamList(`${value._id}`, (res: any) => {
                setParentCourseList(res);
              })
            );
        }
      )
    );
  };
  const onChangeSubExam = (value: any) => {
    setBatchList([]);
    setBatchName("");
    dispatch(
      getExamStreamList(`${examName._id}/${value._id}`, (res: any) => {
        setParentCourseList(res);
      })
    );
  };
  const handleChangePage = (event: any, newPage: any) => {
    setPage(newPage);
    setCurrentPage(newPage + 1);
  };

  const handleChangeRowsPerPage = (event: any) => {
    const value = event.target.value;
    if (value === "All") {
      setRowsPerPage(-1);
      setPage(0);
      setCurrentPage(1);
      return;
    }
    setRowsPerPage(parseInt(value, 10));
    setPage(0);
    setCurrentPage(1);
  };

  const handleEndLive = (value: any) => {
    const postData = {
      type: 1,
      fromDate: value?.fromDate,
      sessionTime: value?.sessionTime,
      status: 3,
    };

    dispatch(
      updateLiveLecturesById(value?._id, postData, () => {
        fetchLiveLecturesList();
      })
    );
  };
  const handleCancel = (value: any) => {
    const postData = {
      type: 1,
      fromDate: value?.fromDate,
      sessionTime: value?.sessionTime,
      status: 9,
    };
    dispatch(
      updateLiveLecturesById(value?._id, postData, () => {
        fetchLiveLecturesList();
      })
    );
  };

  const handleRescheduleLecture = () => {
    const postData = {
      type: 2,
      fromDate: fromDate,
      sessionTime: sessionTime,
      status: status,
      fromDateNew: rescheduleDate,
      sessionTimeNew: rescheduleTime,
    };
    dispatch(
      updateLiveLecturesById(ID, postData, () => {
        setOpen(false);
        fetchLiveLecturesList();
      })
    );
  };
  const fetchLiveLecturesList = () => {
    const postData = {
      baseCourseId: parentCourseVal?._id,
      courseId: batchName?._id,
      startData: startDate,
      endData: endDate,
    };

    dispatch(
      getLiveLecturesList(postData, (res: any) => {
        const resArr = res.data;
        setLiveClassScheduleList(resArr);
        setFilteredLiveClassScheduleList(resArr);
      })
    );
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    fetchLiveLecturesList();
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChangeRescheduleDateAndTime = (value: any) => {
    const [date1, time1] = value.split("T");
    setRescheduleDate(date1);
    const [hh, mm, ss] = time1.split(":");
    let result = "";
    if (ss) {
      setRescheduleTime(time1);
    } else {
      result = time1.concat("", ":00");
      setRescheduleTime(result);
    }
  };

  const validations = () => {
    let isValidate = false;
    isValidate =
      parentCourseVal === "" ||
      examName === "" ||
      batchName === "" ||
      startDate === "" ||
      endDate === "" ||
      (subExamList?.length > 0 && subExamName === "");
    return isValidate;
  };

  return (
    <div className="contentBox">
      <PageTitle name="LIVE CLASS SCHEDULE" />
      <div className="row m-0">
        <div className="formDiv blank-20px">
          <div className="col-12 formWidth">
            <form onSubmit={handleSubmit}>
              <div className="col-auto">
                <div className="row">
                  <div className="d-flex mb-2 align-items-center col-sm-4">
                    <LabelField lableName={"Exam Name"} />
                    <CustomDropdown
                      setInputText={(value: any) => onListChange(value)}
                      value={examName}
                      options={examList}
                      lableName="Select Exam Name"
                      Isrequired
                    />
                  </div>
                  {subExamList.length > 0 ? (
                    <div className="d-flex mb-2 align-items-center col-sm-4">
                      <LabelField lableName={"Sub Exam Name"} />
                      <CustomDropdown
                        setInputText={(value: any) => onChangeSubExam(value)}
                        value={subExamName}
                        options={subExamList}
                        Isrequired
                        lableName="Select Sub Exam Name"
                      />
                    </div>
                  ) : null}
                  <div className="d-flex mb-2 align-items-center col-sm-4">
                    <LabelField lableName={"Exam Stream"} />
                    <CustomDropdown
                      setInputText={(value: any) =>
                        changeParentCourseVal(value)
                      }
                      value={parentCourseVal}
                      options={parentCourseList}
                      lableName="Select Exam Stream"
                      Isrequired
                    />
                  </div>
                  <div className="d-flex mb-2 align-items-center col-sm-4">
                    <LabelField lableName={"Batch Name"} />
                    <CustomDropdown
                      setInputText={(value: any) => setBatchName(value)}
                      value={batchName}
                      options={batchList}
                      lableName="Select Batch Name"
                      Isrequired
                      // reset={resetBatchName}
                    />
                  </div>
                </div>
                <div className="d-flex align-items-center justify-content-between">
                  <div className="d-flex mb-2 align-items-center">
                    <LabelField lableName={"Start Date"} />
                    <DatePicker
                      setInputText={(value: any) => {
                        setStartDate(value);
                      }}
                      value={startDate}
                      Isrequired={true}
                    />
                  </div>
                  <div className="d-flex mb-2 align-items-center">
                    <LabelField lableName={"End Date"} />
                    <DatePicker
                      setInputText={(value: any) => {
                        setEndDate(value);
                      }}
                      value={endDate}
                      Isrequired={true}
                    />
                  </div>
                  <div className="col-auto d-flex justify-content-center mt-4">
                    <button
                      type="submit"
                      className={`btn mb-3 submitbtn ${
                        validations() ? "btn-secondary" : "btn-primary"
                      }`}
                      disabled={validations()}
                    >
                      Submit
                    </button>
                  </div>
                  {/* </div> */}
                </div>
              </div>
            </form>
          </div>
          <div className="d-flex paginationdiv justify-content-end align-items-center">
            <Pagination
              count={totalPages}
              size="large"
              page={currentPage}
              variant="outlined"
              shape="rounded"
              color={"primary"}
              onChange={handleChange}
            />
          </div>

          <PaginationView
            title={"Live Class Details"}
            tableData={
              filteredLiveClassScheduleList ? filteredLiveClassScheduleList : []
            }
            tabs={["Today's Live", "Old Live", "Upcoming Live"]}
            onTabChange={(e: any, index: any) => showData(e, index)}
            selectedIndex={selectedStatus}
            page={page}
            rowsPerPage={rowsPerPage}
            handleChangePage={handleChangePage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
            rowsPerPageOptions={getTableDataSlots(
              filteredLiveClassScheduleList?.length
            )}
          />

          <PaginationTable
            tableData={
              filteredLiveClassScheduleList ? filteredLiveClassScheduleList : []
            }
            pagename={"liveClassSchedule"}
            rowsPerPage={rowsPerPage}
            page={page}
            tableHead={[
              "S.N",
              "Parent Course",
              "Exam Name",
              "Sub-Exam Name",
              "Batch Name",
              "Date & Time",
              "Session",
              "Status",
            ]}
            edit={true}
            handleCancelButton={(value: any) => handleCancel(value)}
            handleRescheduleButton={(value: any) => {
              setOpen(true);
              setRescheduleDate(value?.fromDate);
              // setRescheduleDate(value?.fromDate.split(" ")[0]);
              // setRescheduleTime("15:27:00")
              setRescheduleTime(value?.sessionTime);
              setFromDate(value?.fromDate);
              setSessionTime(value?.sessionTime);
              setStatus(value?.status);
              setID(value?._id);
            }}
            handleEndLiveButton={(value: any) => handleEndLive(value)}
          />
        </div>
      </div>
      {open ? (
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          className="model"
          open={open}
          onClose={handleClose}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={open}>
            <div className="bookstockmodeldiv p-3">
              <div className="col mb-4 d-flex justify-content-between ">
                <p className="fw-bold">
                  <span style={{ color: "#3F51B5" }}>
                    {" "}
                    Reschedule Upcoming Live
                  </span>
                </p>
                <ClearOutlinedIcon
                  className="colorPurple cursor"
                  onClick={handleClose}
                />
              </div>
              <div className="form-check">
                <div className="d-flex mb-2 align-items-center">
                  <LabelField lableName={"New Start Date"} />
                  <DateTime
                    setInputText={(value: any) =>
                      handleChangeRescheduleDateAndTime(value)
                    }
                    value={`${rescheduleDate}T${rescheduleTime}`}
                    defaultValue={`${rescheduleDate}T${rescheduleTime}`}
                  />
                </div>

                <div className="col-auto d-flex justify-content-center mt-5">
                  <button
                    onClick={() => handleRescheduleLecture()}
                    type="submit"
                    className={"btn mb-3 submitbtn btn-primary"}
                  >
                    Save and Update
                  </button>
                </div>
              </div>
            </div>
          </Fade>
        </Modal>
      ) : null}
    </div>
  );
};
export default LiveClassSchedule;
