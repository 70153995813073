import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import PageTitle from "../../Component/pageTitle";
import InputField from "../../Component/inputField";
import DatePicker from "../../Component/datePicker";
import LabelField from "../../Component/labelField";
import {
  containsNumber,
  length_Fifty,
  length_Ten,
  length_Three,
  length_Two,
  startsWithSpace,
  validateFullName,
  validateValueWithoutSpace,
} from "../../DATA/validators";
import { validateIsNumberOnlyErrMsg } from "../../DATA/errorMsg";
import CustomDropdown from "../../Component/customDropdown";
import Button from "../../Component/button";
import SubmitButton from "../../Component/submitButton";
import moment from "moment";
import ToggleSwitchbtn from "../../Component/toggleSwitchBtn";
import { showErrorSnackbar } from "../../Redux/Actions/snackbarAction";
import {
  getEmployeeRoleList,
  getManagerList,
  getSourceTypeList,
  updateEmployee,
} from "../../Redux/Actions/leadManagementAction";
import { Pagination } from "@mui/material";
import PaginationTable from "../../Component/paginationTable";
import PaginationView from "../../Component/paginationView";
import usePagination from "../../Component/usePagination";
import { getTableDataSlots } from "../../DATA/dataConstant";
import {
  getExamList,
  getExamStreamList,
} from "../../Redux/Actions/counsellorPanelAction";
import {
  getTeamLeadEmployeeListById,
  getEmployeetByManagerId,
  getManagerEmployeeListById,
  getCounsellorListByManagerId,
} from "../../Redux/Actions/managerPanelAction";
import { useSelector } from "react-redux";
import SquareCheckBox from "../../Component/SquareCheckBox";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";

const initialVal: {
  sourceType: string;
  baseCourseId: string;
  examTypeId: string;
  isActive: boolean;
}[] = [
  {
    sourceType: "",
    baseCourseId: "",
    examTypeId: "",
    isActive: false,
  },
];

export default function ActiveCounsellor() {
  const dispatch = useDispatch();
  const [isAddEmployee, setAddEmployee] = useState(false);
  const [isEditMode, setisEditMode] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState(1);
  const [firstName, setFirstname] = useState<any>("");
  const [lastName, setLastname] = useState<any>("");
  const [date, setDate] = useState<any>("");
  const [role, setRole] = useState<any>("");
  const [manager, setManager] = useState<any>("");
  const [currentSalary, setCurrentSalary] = useState<any>("");
  const [userName, setUsername] = useState<any>("");
  const [email, setEmail] = useState<any>("");
  const [password, setPassword] = useState<any>("");
  const [mob, setMob] = useState<any>("");
  const [status, setStatus] = useState(false);
  const [uniqueID, setUniqueId] = useState<any>("");
  const [empId, setEmpId] = useState<any>("");
  const [courseExamTypeInfoArr, setCourseExamTypeInfoArr] =
    useState<any>(initialVal);

  const [employeeList, setEmployeeList] = useState([]);
  const [roleList, setRoleList] = useState([]);
  const [managerList, setManagerList] = useState([]);
  const [sourceList, setSourceList] = useState([]);
  const [examList, setExamList] = useState([]);

  const [resetRole, setResetRole] = useState(false);
  const [resetManager, setResetManager] = useState(false);

  const [counsellor, setCounsellor] = useState<any>();
  const [counsellorList, setCounsellorList] = useState([]);
  const [resetCounsellor, setResetCounsellor] = useState(false);
  const [exam, setExam] = useState<any>("");
  const [resetExam, setResetExam] = useState(false);

  const { managerId } = useSelector((state: any) => ({
    managerId: state.Auth.counsellorId,
  }));

  const { loggedInUserRole } = useSelector((state: any) => ({
    loggedInUserRole: state.Auth.loggedInUserRole,
  }));

  // pagination
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const totalPages = Math.ceil(count / rowsPerPage);
  const _DATA = usePagination(count, rowsPerPage);

  const handleChange = (e: any, page: number) => {
    onPageChange(page, rowsPerPage, 1);
    setPage(page - 1);
    setCurrentPage(page);
    _DATA.jump(page);
  };
  const onPageChange = (page: number, rowsPerPage: number, flag: number) => {
    /************ page change****************** */
    let data = {};
    if (flag === 1) {
      data = {
        skip: (page - 1) * rowsPerPage,
        limit: rowsPerPage,
        status: selectedStatus === 1 ? "active" : "deleted",
        managerId,
      };
    } else if (flag === 2) {
      data = {
        skip: 0,
        limit: rowsPerPage,
        status: selectedStatus === 1 ? "active" : "deleted",
        managerId,
      };
    } else {
      data = {
        skip: 0,
        limit: count,
        status: selectedStatus === 1 ? "active" : "deleted",
        managerId,
      };
    }
    if (loggedInUserRole === 1) {
      dispatch(
        getManagerEmployeeListById(
          data,
          (res: any) => {
            setCount(res?.count);
            setEmployeeList(res?.data);
          },
          () => {}
        )
      );
    } else {
      dispatch(
        getTeamLeadEmployeeListById(
          data,
          (res: any) => {
            setCount(res?.count);
            setEmployeeList(res?.data);
          },
          () => {}
        )
      );
    }
  };

  const handleChangePage = (event: any, newPage: number) => {
    setPage(newPage);
    setCurrentPage(newPage + 1);
  };

  const handleChangeRowsPerPage = (event: { target: { value: any } }) => {
    const value = event.target.value;
    if (value === "All") {
      setRowsPerPage(-1);
      setPage(0);
      setCurrentPage(1);
      return;
    }
    setRowsPerPage(parseInt(value, 10));
    setPage(0);
    setCurrentPage(1);
    onPageChange(page, value, 2);
  };

  useEffect(() => {
    getEmpList("active");
    dispatch(
      getExamList((res: any) => {
        if (res?.data?.length > 0) {
          setExamList(res?.data);
        } else {
          setExamList([]);
        }
      })
    );
  }, []);

  const getEmpList = (data: string) => {
    setCount(0);
    setEmployeeList([]);
    if (loggedInUserRole === 1) {
      dispatch(
        getManagerEmployeeListById(
          {
            skip: 0,
            limit: 100,
            status: data,
            managerId,
          },
          (res: any) => {
            setCount(res?.count);
            setEmployeeList(res?.data);
          },
          () => {}
        )
      );
    } else {
      dispatch(
        getTeamLeadEmployeeListById(
          {
            skip: 0,
            limit: 100,
            status: data,
            managerId,
          },
          (res: any) => {
            setCount(res?.count);
            setEmployeeList(res?.data);
          },
          () => {}
        )
      );
    }

    if (loggedInUserRole === 1) {
      dispatch(
        getCounsellorListByManagerId(
          managerId,
          (res: any) => {
            setCounsellorList(res?.data);
          },
          () => {}
        )
      );
    } else if (loggedInUserRole === 2) {
      dispatch(
        getEmployeetByManagerId(
          managerId,
          (res: any) => {
            setCounsellorList(res?.data);
          },
          () => {}
        )
      );
    }
  };

  const showData = (e: any, val: any) => {
    e.preventDefault();
    setSelectedStatus(val);
    if (val === 1) {
      getEmpList("active");
    } else if (val === 2) {
      getEmpList("deleted");
    }
  };

  const handleEdit = (value: any) => {
    setAddEmployee(true);
    setisEditMode(true);
    setEmpId(value?._id);
    setFirstname(value?.firstName);
    setLastname(value?.lastName);
    setDate(value?.yoj);
    dispatch(
      getEmployeeRoleList((res: any) => {
        setRoleList(res);
        setRole(value?.role);
      })
    );
    dispatch(
      getManagerList(
        value?.role?._id,
        (res: any) => {
          setManagerList(res);
          setManager(value?.managerId);
        },
        () => {}
      )
    );
    // let newArr = value?.courseExamTypeInfoList?.map((item: any) => {
    //   return {
    //     baseCourseId: item?.baseCourseId?._id,
    //     examTypeId: item?.examTypeId?._id,
    //     sourceType: item?.sourceType?._id,
    //     isActive: item?.isActive,
    //   };
    // });
    // setCourseExamTypeInfoArr(newArr);
    const filterItems =
      value?.courseExamTypeInfoList?.filter((item: any) => item?.isActive) ||
      [];
    dispatch(
      getExamList((res: any) => {
        let tempRes = res?.data?.map((item: any) => ({
          _id: item?._id,
          name: item?.name,
          baseCourseId: item?.baseCourseId,
        }));
        if (res?.data?.length > 0) {
          const uniqueIds = Array.from(
            new Map(
              filterItems?.map((item: any) => [
                item.examTypeId._id,
                item.examTypeId,
              ])
            ).values()
          );
          const updatedData = tempRes?.map((subItem: any) => {
            const isSelected = uniqueIds.some(
              (exam: any) => exam._id === subItem._id
            );
            subItem.isSelected = isSelected;

            if (subItem.baseCourseId) {
              subItem.baseCourseId.forEach((course: any) => {
                const isCourseSelected = filterItems.some(
                  (activeExam: any) =>
                    activeExam.baseCourseId._id === course._id &&
                    activeExam.examTypeId._id === subItem._id
                );
                course.isSelected = isCourseSelected;
              });
            }

            return subItem;
          });
          setExamList(updatedData);
          setExam("");
        } else {
          setExamList([]);
        }
      })
    );
    // dispatch(
    //   getExamList((res: any) => {
    //     if (res?.data?.length > 0) {
    //       setExamList(res?.data);
    //     } else {
    //       setExamList([]);
    //     }
    //   })
    // );
    setCurrentSalary(value?.salary);
    setUsername(value?.username);
    setEmail(value?.email);
    setPassword(value?.password);
    setMob(value?.contactNo);
    setStatus(value?.status);
    setUniqueId(value?.uniqueId?.toUpperCase());
    dispatch(
      getSourceTypeList((res: any) => {
        const uniqueIds = Array.from(
          new Map(
            filterItems?.map((item: any) => [
              item.sourceType._id,
              item.sourceType,
            ])
          ).values()
        );
        const updatedData = res.map((item: any) => ({
          ...item,
          isSelected: uniqueIds.some((source: any) => source._id === item._id),
        }));
        setSourceList(updatedData);
      })
    );
  };

  const renderAddMode = () => {
    return (
      <>
        <h2 className="text-primary">Edit Employee</h2>
        <form
          className="col-md-12  shadow-lg py-4 px-3"
          onSubmit={handleUpdate}
        >
          <div className="row d-flex">
            <div className="col-md-4 my-3">
              <InputField
                placeholder="Enter First Name"
                value={firstName}
                onChangeInput={(value: any) => onchangeFirstName(value)}
                maxlength={length_Fifty - 1}
                minlength={length_Three}
                isRequired={true}
                disabled={isEditMode}
              />
            </div>
            <div className="col-md-4  my-3">
              <InputField
                placeholder="Enter Last Name"
                value={lastName}
                onChangeInput={(value: any) => onchangelastName(value)}
                maxlength={length_Fifty - 1}
                minlength={length_Three}
                isRequired={true}
                disabled={isEditMode}
              />
            </div>

            <div className="col-md-4 d-flex my-3">
              <div className="col-5 mt-2">
                <LabelField lableName="Date Of Joining" />
              </div>
              <DatePicker
                labelName={"Date of Joining"}
                value={date}
                setInputText={(value: string) => setDate(value)}
                Isrequired={true}
                defaultValue={date}
                maxdate={moment.utc(new Date()).format("YYYY-MM-DD")}
                disabled={isEditMode}
              />
            </div>
            <div className="col-md-4  my-3">
              <CustomDropdown
                lableName="Select Role"
                setInputText={(value: any) => onChangeRole(value)}
                value={role}
                options={roleList}
                Isrequired={true}
                reset={resetRole}
                defaultValue={role?._id}
                disabled={isEditMode}
              />
            </div>
            <div className="col-md-4 my-3 ">
              <CustomDropdown
                lableName="Select Manager"
                setInputText={(value: any) => onChangeManager(value)}
                value={manager}
                options={managerList}
                Isrequired={true}
                reset={resetManager}
                defaultValue={manager?._id}
                disabled={isEditMode}
              />
            </div>
            {/* <div className="col-md-4  my-3">
              <InputField
                placeholder="Enter Current Salary"
                value={currentSalary}
                onChangeInput={(value: any) => onchangeCurrentSalary(value)}
                maxlength={length_Ten}
                minlength={length_Two}
                isRequired={true}
                disabled={isEditMode}
              />
            </div> */}
            {/* {courseExamTypeInfoArr?.map((item: any, index: number) => {
              const { sourceType, baseCourseId, examTypeId, isActive } = item;
              return (
                <div className="position-relative" key={index}>
                  <div className="d-flex position-relative">
                    <div className="col-md-3 my-1 ">
                      <CustomDropdown
                        lableName="Select Source"
                        setInputText={(value: any) =>
                          onChange(index, value, "source")
                        }
                        value={sourceType}
                        options={sourceList}
                        Isrequired={true}
                        defaultValue={sourceList?.length > 0 ? sourceType : ""}
                        disabled={isEditMode}
                      />
                    </div>
                    <div className="col-md-3 my-1 ">
                      <CustomDropdown
                        lableName="Select Exam"
                        setInputText={(value: any) =>
                          onChange(index, value, "exam")
                        }
                        value={examTypeId}
                        options={examList}
                        Isrequired={true}
                        defaultValue={examList?.length > 0 ? examTypeId : ""}
                        disabled={isEditMode}
                      />
                    </div>
                    <div className="col-md-3 my-1 ">
                      <CustomDropdown
                        lableName="Select Exam Stream"
                        setInputText={(value: any) =>
                          onChange(index, value, "examStream")
                        }
                        value={baseCourseId}
                        options={examStreamList}
                        Isrequired={true}
                        defaultValue={
                          examStreamList?.length > 0 ? baseCourseId : ""
                        }
                        disabled={isEditMode}
                      />
                    </div>
                    <div className="col-md-3 my-3">
                      <ToggleSwitchbtn
                        lableName="Active"
                        getToggleval={(value: any) => {
                          onChange(index, value, "isActive");
                        }}
                        isActive={isActive}
                        disabled={isEditMode}
                      />
                    </div>
                  </div>
                </div>
              );
            })} */}
            {/* {role?._id === 1 || role?._id === 2 || role?._id === 9 ? null : (
              <div className="d-flex justify-content-end">
                <Button name={"Add More"} handleClick={onPressAdd} />
              </div>
            )} */}

            <div className="col-md-4 my-3">
              <InputField
                placeholder="Enter User Name"
                value={userName}
                onChangeInput={(value: any) => onchangeUserName(value)}
                maxlength={length_Ten}
                minlength={length_Two}
                isRequired={true}
                disabled={isEditMode}
              />
            </div>
            <div className="col-md-4 my-3">
              <InputField
                placeholder="Enter Email"
                value={email}
                onChangeInput={(value: any) => onchangeEmail(value)}
                isRequired={true}
                disabled={isEditMode}
              />
            </div>
            <div className="col-md-4 my-3">
              <InputField
                placeholder="Enter Password"
                value={password}
                onChangeInput={(value: any) => onChangePassword(value)}
                disabled={isEditMode}
                isRequired={true}
              />
            </div>
            <div className="col-md-4 my-3">
              <InputField
                placeholder="Enter Mobile Number"
                value={mob}
                onChangeInput={(value: any) => onChangeContact(value)}
                maxlength={length_Ten}
                minlength={length_Ten}
                disabled={isEditMode}
                isRequired={true}
              />
            </div>

            <div className="col-md-4 my-3">
              <ToggleSwitchbtn
                lableName="Status"
                getToggleval={(value: any) => {
                  onChangeStatus(value);
                }}
                isActive={status}
              />
            </div>
            <div className="col-md-4 my-3">
              <InputField
                placeholder="Enter Unique Id"
                value={uniqueID}
                onChangeInput={(value: any) => onchangeUniqueId(value)}
                isRequired={true}
                disabled={isEditMode}
              />
            </div>
            <div className="d-flex mt-2 mb-2 justify-content-between">
              {sourceList?.length > 0 ? (
                <div className="border rounded p-2 mx-1 w-100">
                  <div className="mt-2">
                    <LabelField lableName={"Source"} />
                  </div>
                  <div>
                    {sourceList.map((c: any, index: number) => {
                      return (
                        <div className="ms-3" key={index}>
                          <SquareCheckBox
                            id={c._id}
                            name={"Sub Category"}
                            value={c.name}
                            checked={c.isSelected}
                            onChange={(event: any) => {
                              onSelectSource(event, index);
                            }}
                            labelName={c.name}
                            disabled={true}
                          />
                        </div>
                      );
                    })}
                  </div>
                </div>
              ) : null}
              {examList?.length > 0 ? (
                <div className="border rounded p-2 mx-1 w-100">
                  <div className="mt-2">
                    <LabelField lableName={"Exam"} />
                  </div>
                  <div>
                    {examList.map((c: any, index: number) => {
                      return (
                        <div className="ms-3" key={index}>
                          <SquareCheckBox
                            id={c._id}
                            name={"Sub Category"}
                            value={c.name}
                            checked={c.isSelected}
                            onChange={(event: any) => {
                              onSelectExam(event, index);
                            }}
                            labelName={c.name}
                            disabled={true}
                          />

                          <span
                            className="ms-2 cursor"
                            onClick={() => onChangeExam(c)}
                          >
                            {c?._id === exam?._id ? (
                              <RemoveRedEyeIcon
                                style={{ fontSize: "16px", color: "green" }}
                              />
                            ) : (
                              <RemoveRedEyeIcon style={{ fontSize: "16px" }} />
                            )}
                          </span>
                        </div>
                      );
                    })}
                  </div>
                </div>
              ) : null}
              {exam?.baseCourseId?.length > 0 ? (
                <div className="border rounded p-2 mx-1 w-100">
                  <div className="mt-2">
                    <LabelField lableName={"Exam Stream"} />
                  </div>
                  <div>
                    {exam?.baseCourseId?.map((c: any, index: number) => {
                      return (
                        <div className="ms-3" key={index}>
                          <SquareCheckBox
                            id={c._id}
                            name={"Sub Category"}
                            value={c.name}
                            checked={c.isSelected === true ? true : false}
                            onChange={(event: any) => {
                              onSelectExamStream(event, index, exam);
                            }}
                            labelName={c.name}
                            disabled={true}
                          />
                        </div>
                      );
                    })}
                  </div>
                </div>
              ) : null}
            </div>
          </div>
          <div className="d-flex justify-content-end me-3">
            <div className="me-2">
              <Button name={"Cancel"} handleClick={cancelEditEmployee} />
            </div>
            <SubmitButton name={"Save & Update"} />
          </div>
        </form>
      </>
    );
  };

  const onChangeManager = (val: any) => {
    setManager(val);
    dispatch(
      getEmployeetByManagerId(
        val?._id,
        (res: any) => {
          setCounsellorList(res?.data);
        },
        () => {}
      )
    );
    setCount(0);
    setEmployeeList([]);
    if (loggedInUserRole === 1) {
      dispatch(
        getManagerEmployeeListById(
          {
            skip: 0,
            limit: 100,
            status: selectedStatus === 1 ? "active" : "deleted",
            managerId: val?._id,
          },
          (res: any) => {
            setCount(res?.count);
            setEmployeeList(res?.data);
          },
          () => {}
        )
      );
    } else {
      dispatch(
        getTeamLeadEmployeeListById(
          {
            skip: 0,
            limit: 100,
            status: selectedStatus === 1 ? "active" : "deleted",
            managerId: val?._id,
          },
          (res: any) => {
            setCount(res?.count);
            setEmployeeList(res?.data);
          },
          () => {}
        )
      );
    }
  };
  const onChangeCounsellor = (val: any) => {
    setCounsellor(val);
    setResetCounsellor(false);
    setCount(0);
    setEmployeeList([]);
    let data = {};
    if (loggedInUserRole === 1) {
      data = {
        skip: 0,
        limit: 100,
        status: selectedStatus === 1 ? "active" : "deleted",
        managerId: manager?._id,
        counsellorId: val?._id,
      };
    } else if (loggedInUserRole === 2) {
      data = {
        skip: 0,
        limit: 100,
        status: selectedStatus === 1 ? "active" : "deleted",
        managerId,
        counsellorId: val?._id,
      };
    }
    if (loggedInUserRole === 1) {
      dispatch(
        getManagerEmployeeListById(
          data,
          (res: any) => {
            setCount(res?.count);
            setEmployeeList(res?.data);
          },
          () => {}
        )
      );
    } else {
      dispatch(
        getTeamLeadEmployeeListById(
          data,
          (res: any) => {
            setCount(res?.count);
            setEmployeeList(res?.data);
          },
          () => {}
        )
      );
    }
  };
  const onSearchChangeExam = (val: any) => {
    setExam(val);
    setResetExam(false);
    let data = {};
    if (loggedInUserRole === 1) {
      data = {
        skip: 0,
        limit: 100,
        status: selectedStatus === 1 ? "active" : "deleted",
        managerId: manager?._id,
        counsellorId: counsellor?._id,
        examTypeId: val?._id,
      };
    } else if (loggedInUserRole === 2) {
      data = {
        skip: 0,
        limit: 100,
        status: selectedStatus === 1 ? "active" : "deleted",
        managerId,
        counsellorId: counsellor?._id,
        examTypeId: val?._id,
      };
    }
    if (loggedInUserRole === 1) {
      dispatch(
        getManagerEmployeeListById(
          data,
          (res: any) => {
            setCount(res?.count);
            setEmployeeList(res?.data);
          },
          () => {}
        )
      );
    } else {
      dispatch(
        getTeamLeadEmployeeListById(
          data,
          (res: any) => {
            setCount(res?.count);
            setEmployeeList(res?.data);
          },
          () => {}
        )
      );
    }
  };

  const onchangeFirstName = (val: string) => {
    if (validateFullName(val) && !startsWithSpace(val)) {
      setFirstname(val);
    } else {
      dispatch(showErrorSnackbar("Please Character only") as any);
      return;
    }
  };
  const onchangelastName = (val: string) => {
    if (validateFullName(val) && !startsWithSpace(val)) {
      setLastname(val);
    } else {
      dispatch(showErrorSnackbar("Please Character only") as any);
      return;
    }
  };
  const onChangeRole = (val: any) => {
    setRole(val);
    setResetRole(false);
  };
  const onchangeCurrentSalary = (val: string) => {
    if (val !== "") {
      if (!containsNumber(val)) {
        dispatch(showErrorSnackbar("Please enter number only") as any);
        return;
      } else if (!validateValueWithoutSpace(val)) {
        dispatch(showErrorSnackbar(validateIsNumberOnlyErrMsg) as any);
        return;
      } else {
        setCurrentSalary(val);
      }
    } else {
      setCurrentSalary(val);
    }
  };

  const onChangeExamStream = (val: string) => {};
  const onchangeUserName = (val: string) => {
    setUsername(val);
  };
  const onchangeEmail = (val: string) => {
    setEmail(val);
  };
  const onChangePassword = (val: string) => {
    setPassword(val);
  };
  const onChangeContact = (val: string) => {
    if (val !== "") {
      if (!containsNumber(val)) {
        dispatch(showErrorSnackbar("Please enter number only") as any);
        return;
      } else if (!validateValueWithoutSpace(val)) {
        dispatch(showErrorSnackbar(validateIsNumberOnlyErrMsg) as any);
        return;
      } else {
        setMob(val);
      }
    } else {
      setMob(val);
    }
  };

  const onChangeStatus = (event: any) => {
    if (event.target.checked) {
      setStatus(true);
    } else {
      setStatus(false);
    }
  };

  const onchangeUniqueId = (val: string) => {
    if (validateFullName(val) && !startsWithSpace(val)) {
      setUniqueId(val?.toUpperCase());
    } else {
      dispatch(showErrorSnackbar("Please Character only") as any);
      return;
    }
  };

  const cancelEditEmployee = () => {
    setAddEmployee(false);
    setisEditMode(false);
  };

  const handleUpdate = (e: any) => {
    e.preventDefault();
    let postData = {
      firstName: firstName,
      lastName: lastName,
      yoj: date,
      role: role?._id,
      managerId: manager?._id,
      salary: currentSalary,
      email: email,
      contactNo: mob,
      status: status ? 1 : 0,
      courseExamTypeInfoList: courseExamTypeInfoArr,
    };
    if (postData["managerId"] === "" || postData["managerId"] === undefined) {
      delete postData["managerId"];
    }
    if (
      postData["role"] === 3 ||
      postData["role"] === 4 ||
      postData["role"] === 5 ||
      postData["role"] === 6 ||
      postData["role"] === 7 ||
      postData["role"] === 8
    ) {
      if (
        (postData["courseExamTypeInfoList"][0]["sourceType"] === "" ||
          postData["courseExamTypeInfoList"][0]["sourceType"] === undefined) &&
        (postData["courseExamTypeInfoList"][0]["examTypeId"] === "" ||
          postData["courseExamTypeInfoList"][0]["examTypeId"] === undefined) &&
        (postData["courseExamTypeInfoList"][0]["baseCourseId"] === "" ||
          postData["courseExamTypeInfoList"][0]["baseCourseId"] === undefined)
      ) {
        delete postData["courseExamTypeInfoList"];
      }
    }
    if (
      postData["role"] === 1 ||
      postData["role"] === 2 ||
      postData["role"] === 9
    ) {
      delete postData["courseExamTypeInfoList"];
    }

    dispatch(
      updateEmployee(
        empId,
        postData,
        () => {
          setFirstname("");
          setLastname("");
          setDate("");
          setRole("");
          setResetRole(true);
          setManager("");
          setResetManager(true);
          setCurrentSalary("");
          setUsername("");
          setEmail("");
          setPassword("");
          setMob("");
          setStatus(false);
          setUniqueId("");
          setCourseExamTypeInfoArr(initialVal);
          setAddEmployee(false);
          setisEditMode(false);
          getEmpList("active");
        },
        () => {}
      )
    );
  };

  const renderSearchMode = () => {
    return (
      <div className="d-flex col-12">
        <div className="col-md-4 d-flex mb-3 me-3">
          <div className="col-md-3 mt-2">
            <LabelField lableName="Counsellor" />
          </div>
          <div className="col-md-9 ms-2 pe-2">
            <CustomDropdown
              lableName="Select Counsellor"
              setInputText={(value: any) => onChangeCounsellor(value)}
              value={counsellor}
              options={counsellorList}
              // Isrequired={true}
              reset={resetCounsellor}
            />
          </div>
        </div>
        <div className="col-md-4 d-flex mb-3">
          <div className="col-md-3 mt-2">
            <LabelField lableName="Exam Name" />
          </div>
          <div className="col-md-9 ms-2 pe-2">
            <CustomDropdown
              lableName="Select Exam"
              setInputText={(value: any) => onSearchChangeExam(value)}
              value={exam}
              options={examList}
              // Isrequired={true}
              reset={resetExam}
            />
          </div>
        </div>
      </div>
    );
  };

  const renderListMode = () => {
    return (
      <>
        {employeeList?.length > 0 ? (
          <div className="d-flex paginationdiv justify-content-end align-items-center">
            <Pagination
              count={totalPages}
              size="large"
              page={currentPage}
              variant="outlined"
              shape="rounded"
              color={"primary"}
              onChange={handleChange}
            />
          </div>
        ) : null}

        {/* {count > 0 ? ( */}
        <PaginationView
          pagename={"TeamLeadManageEmployee"}
          count={count}
          tableData={employeeList ? employeeList : []}
          tabs={["Active", "Deleted"]}
          onTabChange={(e: any, index: any) => showData(e, index)}
          selectedIndex={selectedStatus}
          page={page}
          rowsPerPage={rowsPerPage}
          handleChangePage={handleChangePage}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
          rowsPerPageOptions={getTableDataSlots(count)}
        />
        {/* ) : null} */}

        {selectedStatus === 1 ? (
          <PaginationTable
            tableData={employeeList ? employeeList : []}
            pagename={"Employee"}
            rowsPerPage={rowsPerPage}
            page={page}
            tableHead={[
              "S.N",
              "Unique ID",
              "First Name",
              "Last Name",
              "Mobile No.",
              "Employee Type",
              "Team Lead",
              "Manager",
              // "Source - Exam Name - Exam Stream",
              // "Experience (Months)",
              // "Status",
              "Edit",
              "",
            ]}
            edit={true}
            handleEdit={(value: any) => handleEdit(value)}
            // handleDelete={(value: any) => handleDelete(value)}
          />
        ) : (
          <PaginationTable
            tableData={employeeList ? employeeList : []}
            pagename={"Employee"}
            rowsPerPage={rowsPerPage}
            page={page}
            tableHead={[
              "S.N",
              "Unique ID",
              "First Name",
              "Last Name",
              "Mobile No.",
              "Employee Type",
              "Team Lead",
              "Manager",
              // "Source - Exam Name - Exam Stream",
              // "Experience (Months)",
              // "Status",
              "Restore",
              "",
            ]}
            edit={true}
            // handleEdit={(value: any) => handleRestore(value)}
          />
        )}
      </>
    );
  };

  const onSelectSource = (event: any, index: any) => {
    let newArray = JSON.parse(JSON.stringify(sourceList));
    if (event.target.checked) {
      newArray[index]["isSelected"] = true;
      setSourceList(newArray);
    } else {
      newArray[index]["isSelected"] = false;
      setSourceList(newArray);
    }
  };

  const onSelectExam = (event: any, index: any) => {
    let newArray = JSON.parse(JSON.stringify(examList));
    if (event.target.checked) {
      newArray[index]["isSelected"] = true;
      setExamList(newArray);
    } else {
      newArray[index]["isSelected"] = false;
      setExamList(newArray);
    }
  };

  const onChangeExam = (value: any) => {
    setExam(value);
  };

  const onSelectExamStream = (event: any, index: number, exam: any) => {
    // Create a shallow copy of the examList
    let newArray: any = [...examList];

    // Find the exam by its ID
    const examIndex = newArray.findIndex((item: any) => item._id === exam._id);

    if (examIndex !== -1) {
      const updatedExam = { ...newArray[examIndex] };

      if (updatedExam.baseCourseId && updatedExam.baseCourseId[index]) {
        // Update the selected base course item
        updatedExam.baseCourseId[index] = {
          ...updatedExam.baseCourseId[index],
          isSelected: event.target.checked,
        };

        // Check if at least one base course is selected
        const isAnyBaseCourseSelected = updatedExam.baseCourseId.some(
          (item: any) => item?.isSelected
        );

        // Update the `isSelected` flag for the exam based on base courses
        updatedExam.isSelected = isAnyBaseCourseSelected;
      }

      // Update the array with the modified exam object
      newArray[examIndex] = updatedExam;
    }

    // Set the updated array to state
    setExamList(newArray);
  };

  return (
    <div className="contentBox">
      <PageTitle name={"Active Counsellor"} />
      <div className="col-md-12 px-0 mx-0 mt-3">
        <div className="col-md-12 d-flex justify-content-between p-2">
          {!isAddEmployee ? renderSearchMode() : ""}
        </div>
        {!isAddEmployee && !isEditMode ? renderListMode() : renderAddMode()}
      </div>
    </div>
  );
}
