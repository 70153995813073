import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import InputField from "../../Component/inputField";
import LabelField from "../../Component/labelField";
import CustomDropdown from "../../Component/customDropdown";
import {
  getCountryList,
  getStatesList,
} from "../../Redux/Actions/counsellorPanelAction";
import {
  addHrCandidate,
  getHrEmployeeCompanyList,
  getHrEmployeeDepartmentList,
  getHrEmployeeDesignationList,
  getHrStatusList,
  getHrSubDepartment,
  getHrSubStatusList,
  uploadCVPDF,
  uploadPDF,
} from "../../Redux/Actions/hrPanelAction";
import DateTime from "../../Component/dateTime";
import SubmitButton from "../../Component/submitButton";
import {
  containsNumber,
  length_Ten,
  pdf_size_1MB,
  validateIsFilePDFType,
  validateValueWithoutSpace,
} from "../../DATA/validators";
import { showErrorSnackbar } from "../../Redux/Actions/snackbarAction";
import {
  validateIsFilePDFTypeErrMsg,
  validateIsNumberOnlyErrMsg,
} from "../../DATA/errorMsg";
import { getLeadByMobileNumber } from "../../Redux/Actions/managerPanelAction";
import { useSelector } from "react-redux";
import { HrGenderList, HrQualificationList } from "../../DATA/dataConstant";
import moment from "moment";
import ImagePickerAndViewer from "../../Component/imagePickerAndViewer";
import { useAppDispatch } from "../../hooks";
import Radiobtn from "../../Component/radiobtn";
import Button from "../../Component/button";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";

const initialQualification: {
  qualification: string;
  educationYear: string;
}[] = [
  {
    qualification: "",
    educationYear: "",
  },
];

export default function LeadDetail() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [firstName, setFirstName] = useState<any>("");
  const [lastName, setLastName] = useState<any>("");
  const [gender, setGender] = useState<any>("");
  const [contact1, setContact1] = useState<any>("");
  const [contact2, setContact2] = useState<any>("");
  const [email, setEmail] = useState<any>("");
  const [state, setState] = useState<any>("");
  const [city, setCity] = useState<any>("");
  const [address, setAddress] = useState<any>("");

  const [type, setType] = useState(1);
  const [currentCompany, setCurrentCompany] = useState<any>("");
  const [currentCTC, setCurrentCTC] = useState<any>("");
  const [experience, setExperience] = useState<any>("");
  const [selectedCV, setSelectedCV] = useState<any>("");
  const [company, setCompany] = useState<any>("");
  const [department, setDepartment] = useState<any>("");
  const [subDepartment, setSubDepartment] = useState<any>("");
  const [designation, setDesignation] = useState<any>("");
  const [status, setStatus] = useState<any>({ _id: 1, name: "RESUME SHARED" });
  const [subStatus, setSubStatus] = useState<any>({ _id: 1, name: "WAITING" });
  const [remark, setRemark] = useState<any>("");
  const [callBackDate, setCallBackDate] = useState<any>("");

  const [stateList, setStateList] = useState([]);
  const [countryList, setCountryList] = useState([]);
  const [qualificationArr, setQualificationArr] =
    useState<any>(initialQualification);
  const [companyList, setCompanyList] = useState([]);
  const [departmentList, setDepartmentList] = useState([]);
  const [subDepartmentList, setSubDepartmentList] = useState([]);
  const [designationList, setDesignationList] = useState([]);
  const [statusList, setStatusList] = useState([]);
  const [subStatusList, setSubStatusList] = useState([]);

  const [resetStatus, setResetStatus] = useState(false);
  const [resetSubStatus, setResetSubStatus] = useState(false);

  const { loggedInUserRole } = useSelector((state: any) => ({
    loggedInUserRole: state.Auth.loggedInUserRole,
  }));

  useEffect(() => {
    dispatch(
      getHrEmployeeCompanyList(
        (res: any) => {
          setCompanyList(res);
        },
        () => {}
      )
    );
    dispatch(
      getHrEmployeeDepartmentList(
        (res: any) => {
          setDepartmentList(res);
        },
        () => {}
      )
    );
    dispatch(
      getHrEmployeeDesignationList(
        (res: any) => {
          setDesignationList(res);
        },
        () => {}
      )
    );
    dispatch(
      getStatesList((res: any) => {
        if (res?.data?.length > 0) {
          setStateList(res?.data);
        } else {
          setStateList([]);
        }
      })
    );
    dispatch(
      getHrStatusList(
        "",
        (res: any) => {
          let tempRes = res?.filter((item: any) => item?._id === 1);
          setStatusList(tempRes);
          dispatch(
            getHrSubStatusList(
              tempRes[0]?._id,
              (res: any) => {
                let tempSubRes = res?.filter((item: any) => item?._id === 1);
                setSubStatusList(tempSubRes);
              },
              () => {}
            )
          );
        },
        () => {}
      )
    );
  }, []);

  const onChangeContact1 = (val: any) => {
    if (val !== "") {
      if (!containsNumber(val)) {
        dispatch(showErrorSnackbar("Please enter number only") as any);
        return;
      } else if (!validateValueWithoutSpace(val)) {
        dispatch(showErrorSnackbar(validateIsNumberOnlyErrMsg) as any);
        return;
      } else {
        setContact1(val);
      }
    } else {
      setContact1(val);
    }
  };

  const onChangeContact2 = (val: any) => {
    if (val !== "") {
      if (!containsNumber(val)) {
        dispatch(showErrorSnackbar("Please enter number only") as any);
        return;
      } else if (!validateValueWithoutSpace(val)) {
        dispatch(showErrorSnackbar(validateIsNumberOnlyErrMsg) as any);
        return;
      } else {
        setContact2(val);
      }
    } else {
      setContact2(val);
    }
  };

  const onChangeState = (val: any) => {
    setState(val);
    dispatch(
      getCountryList(
        val._id,
        (res: any) => {
          setCountryList(res?.data);
        },
        () => {}
      )
    );
  };

  const onPressAddQualification = () => {
    setQualificationArr([...qualificationArr, initialQualification[0]]);
  };

  const onChangeQualification = (index: number, value: any, key: string) => {
    let newArrr = JSON.parse(JSON.stringify(qualificationArr));
    if (key === "qualification") {
      newArrr[index].qualification = value?.name;
      setQualificationArr(newArrr);
    } else if (key === "educationYear") {
      let year = new Date().getFullYear();
      if (value <= year) {
        if (value !== "") {
          if (!containsNumber(value)) {
            dispatch(showErrorSnackbar("Please enter number only") as any);
            return;
          } else if (!validateValueWithoutSpace(value)) {
            dispatch(showErrorSnackbar(validateIsNumberOnlyErrMsg) as any);
            return;
          } else {
            newArrr[index].educationYear = value;
          }
        } else {
          newArrr[index].educationYear = value;
        }
        setQualificationArr(newArrr);
      } else {
        dispatch(
          showErrorSnackbar(
            `Please Enter Year in between 1900 to ${year}` as any
          )
        );
      }
    }
  };

  const onPressCloseQualification = (index: number) => {
    const newArrr = [...qualificationArr];
    if (index > -1) {
      newArrr.splice(index, 1);
    }
    setQualificationArr(newArrr);
  };

  const onChangeType = (value: any) => {
    setType(value);
    setCurrentCompany("");
    setCurrentCTC("");
    setExperience("");
  };

  const onChangePDFCV = (e: any) => {
    e.preventDefault();
    if (!validateIsFilePDFType(e)) {
      dispatch(showErrorSnackbar(validateIsFilePDFTypeErrMsg));
      return;
    }
    if (e.target.files[0].size > pdf_size_1MB) {
      dispatch(
        showErrorSnackbar("File size should be less than 10 MB!!!") as any
      );
    } else {
      const reader = new FileReader();
      const file = e.target.files[0];
      reader.onloadend = () => {
        setSelectedCV(file);
      };
      reader.readAsDataURL(file);
    }
  };

  const onChangeDepartment = (val: any) => {
    setDepartment(val);
    dispatch(
      getHrSubDepartment(
        val?._id,
        (res: any) => {
          setSubDepartmentList(res);
        },
        () => {}
      )
    );
  };

  const onChangeStatus = (val: any) => {
    setStatus(val);
    setResetStatus(false);
  };

  const onChangeSubStatus = (val: any) => {
    setSubStatus(val);
    setResetSubStatus(false);
  };

  const changeRemark = (val: any) => {
    setRemark(val);
  };

  console?.log("selectedCV", selectedCV);
  const handleSubmit = (e: any) => {
    e.preventDefault();

    if (selectedCV) {
      console?.log("selectedCV", selectedCV);
      const sanitizedFirstName = firstName?.toUpperCase().replace(/\s+/g, "_");
      const sanitizedLastName = lastName?.toUpperCase().replace(/\s+/g, "_");
      const sanitizedDesignation = designation?._id.replace(/\s+/g, "_");
      let fileName =
        sanitizedFirstName +
        "_" +
        sanitizedLastName +
        "_" +
        sanitizedDesignation +
        "_";
      dispatch(
        uploadCVPDF(
          selectedCV,
          fileName,
          (resPdfCV: any) => {
            if (resPdfCV) {
              let postData: any = {
                firstName: firstName?.toUpperCase(),
                lastName: lastName?.toUpperCase(),
                gender: gender?.name,
                mobileNo: contact1,
                altMobileNo: contact2,
                email: email,
                stateId: state?._id,
                stateName: state?.name,
                cityId: city?._id,
                cityName: city?.name,
                address: address,
                qualification: qualificationArr,
                currentCTC: currentCTC,
                currentCompany: currentCompany,
                experience: experience,
                resume: resPdfCV?.file,
                companyId: company?._id,
                companyName: company?.name,
                departmentId: department?._id,
                departmentName: department?.name,
                subDepartmentId: subDepartment?._id,
                subDepartmentName: subDepartment?.name,
                designationId: designation?._id,
                designation: designation?.name,
                comment: remark,
                callBackDate: callBackDate,
              };
              if (
                postData["altMobileNo"] === undefined ||
                postData["altMobileNo"] === "" ||
                postData["altMobileNo"] === null
              ) {
                delete postData["altMobileNo"];
              }
              if (
                postData["address"] === undefined ||
                postData["address"] === "" ||
                postData["address"] === null
              ) {
                delete postData["address"];
              }
              if (type === 1) {
                delete postData["currentCTC"];
                delete postData["currentCompany"];
                delete postData["experience"];
              }
              if (postData["qualification"]?.length > 0) {
                if (
                  (postData["qualification"][0]["qualification"] === "" ||
                    postData["qualification"][0]["qualification"] === null ||
                    postData["qualification"][0]["qualification"] ===
                      undefined) &&
                  (postData["qualification"][0]["educationYear"] === "" ||
                    postData["qualification"][0]["educationYear"] === null ||
                    postData["qualification"][0]["educationYear"] === undefined)
                ) {
                  delete postData["qualification"];
                }
              }
              console?.log("postData", postData);
              dispatch(
                addHrCandidate(
                  postData,
                  () => {
                    resetData();
                  },
                  () => {}
                )
              );
            }
          },
          () => {}
        )
      );
    }

    // if (selectedCV) {
    //   dispatch(
    //     addHrCandidate(
    //       postData,
    //       () => {
    //         resetData();
    //       },
    //       () => {}
    //     )
    //   );
    // }
    // else {
    //   dispatch(
    //     addHrCandidate(
    //       postData,
    //       () => {
    //         resetData();
    //       },
    //       () => {}
    //     )
    //   );
    // }
  };

  const resetData = () => {
    setFirstName("");
    setLastName("");
    setGender("");
    setContact1("");
    setContact2("");
    setEmail("");
    setState("");
    setCity("");
    setAddress("");
    setQualificationArr(initialQualification);
    setCurrentCTC("");
    setCurrentCompany("");
    setExperience("");
    setSelectedCV("");
    setDepartment("");
    setSubDepartment("");
    setDesignation("");
    setStatus("");
    setSubStatus("");
    setRemark("");
    setCallBackDate("");
    navigate("../hrDashbord");
  };

  const validations = () => {
    return (
      firstName === "" ||
      firstName?.length < 3 ||
      lastName === "" ||
      lastName?.length < 3 ||
      contact1 === "" ||
      email === "" ||
      state === "" ||
      city === "" ||
      // address === "" ||
      // educationYear === "" ||
      // qualification?.length < 10 ||
      // currentCTC === "" ||
      // currentCompany === "" ||
      company === "" ||
      department === "" ||
      subDepartment === "" ||
      designation === "" ||
      status === "" ||
      subStatus === "" ||
      remark === "" ||
      callBackDate === ""
    );
  };

  console?.log("callBackDate", callBackDate);

  return (
    <div className="contentBox">
      <div className="col-md-12">
        <div className="row m-0">
          <div className="col-md-12 leaddetails font14">
            <div className="boxshadow mx-4 my-1">
              <div className="p-1 d-flex justify-content-center  ">
                <form
                  onSubmit={handleSubmit}
                  className="detailsdiv col-md-12 row d-flex justify-content-center px-2 "
                >
                  <div className="col-md-6 col-sm-12 p-2">
                    <div className="tabheader p-2">Personal & Contact </div>
                    <div className="row d-flex justify-content-between pt-2 ">
                      <div className="col-md-4 mt-2">
                        <LabelField lableName="First Name" isRequired={true} />
                      </div>
                      <div className="col-md-7">
                        <InputField
                          placeholder="Enter First Name"
                          value={firstName}
                          onChangeInput={(value: any) =>
                            setFirstName(value?.toUpperCase())
                          }
                        />
                      </div>
                    </div>
                    <div className="row d-flex justify-content-between ">
                      <div className="col-md-4 mt-2">
                        <LabelField lableName="Last Name" isRequired={true} />
                      </div>
                      <div className="col-md-7">
                        <InputField
                          placeholder="Enter Last Name"
                          value={lastName}
                          onChangeInput={(value: any) =>
                            setLastName(value?.toUpperCase())
                          }
                        />
                      </div>
                    </div>
                    <div className="row d-flex justify-content-between mt-1">
                      <div className="col-md-4 mt-2">
                        <LabelField lableName="Gender" isRequired={true} />
                      </div>
                      <div className="col-md-7 ps-3">
                        <CustomDropdown
                          lableName="Select Gender"
                          setInputText={(value: any) => setGender(value)}
                          value={gender}
                          options={HrGenderList}
                        />
                      </div>
                    </div>
                    <div className="row d-flex justify-content-between  ">
                      <div className="col-md-4 mt-2">
                        <LabelField lableName="Contact 1" isRequired={true} />
                      </div>
                      <div className="col-md-7">
                        <InputField
                          placeholder="Enter Contact 1"
                          value={contact1}
                          onChangeInput={(value: any) =>
                            onChangeContact1(value)
                          }
                          minlength={length_Ten}
                          maxlength={length_Ten}
                        />
                      </div>
                    </div>
                    <div className="row d-flex justify-content-between  ">
                      <div className="col-md-4 mt-2">
                        <LabelField lableName="Contact 2" />
                      </div>
                      <div className="col-md-7">
                        <InputField
                          placeholder="Enter Contact 2"
                          value={contact2}
                          onChangeInput={(value: any) =>
                            onChangeContact2(value)
                          }
                          minlength={length_Ten}
                          maxlength={length_Ten}
                        />
                      </div>
                    </div>
                    <div className="row d-flex justify-content-between  ">
                      <div className="col-md-4 mt-2">
                        <LabelField lableName="Email" isRequired={true} />
                      </div>
                      <div className="col-md-7">
                        <InputField
                          placeholder="Enter Email"
                          value={email}
                          onChangeInput={(value: any) => setEmail(value)}
                        />
                      </div>
                    </div>
                    <div className="row d-flex justify-content-between mt-1">
                      <div className="col-md-4 mt-2">
                        <LabelField lableName="State" isRequired={true} />
                      </div>
                      <div className="col-md-7 ps-3">
                        <CustomDropdown
                          lableName="Select State"
                          setInputText={(value: any) => onChangeState(value)}
                          value={state}
                          options={stateList}
                          defaultValue={stateList?.length > 0 ? state : ""}
                        />
                      </div>
                    </div>
                    <div className="row d-flex justify-content-between mt-1">
                      <div className="col-md-4 mt-2">
                        <LabelField lableName="City" isRequired={true} />
                      </div>
                      <div className="col-md-7  ps-3">
                        <CustomDropdown
                          lableName="Select City"
                          setInputText={(value: any) => setCity(value)}
                          value={city}
                          options={countryList}
                          defaultValue={countryList?.length > 0 ? city : ""}
                        />
                      </div>
                    </div>
                    <div className="row d-flex justify-content-between mt-1">
                      <div className="col-md-4 mt-2">
                        <LabelField lableName="Address" />
                      </div>
                      <div className="col-md-7 ps-3">
                        <textarea
                          className="form-control"
                          placeholder={"Address"}
                          value={address}
                          onChange={(e) => setAddress(e.target.value)}
                        ></textarea>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 p-2">
                    <div className="tabheader p-2">Employment Details</div>
                    <div className="row d-flex justify-content-between  pt-3">
                      <div className="col-md-4 mt-2">
                        <LabelField lableName="Company" isRequired={true} />
                      </div>
                      <div className="col-md-7">
                        <CustomDropdown
                          lableName="Select Company"
                          setInputText={(value: any) => setCompany(value)}
                          value={company}
                          options={companyList}
                          defaultValue={
                            companyList?.length > 0 ? company?._id : ""
                          }
                        />
                      </div>
                    </div>
                    <div className="row d-flex justify-content-between  pt-3">
                      <div className="col-md-4 mt-2">
                        <LabelField lableName="Department" isRequired={true} />
                      </div>
                      <div className="col-md-7">
                        <CustomDropdown
                          lableName="Select Department"
                          setInputText={(value: any) =>
                            onChangeDepartment(value)
                          }
                          value={department}
                          options={departmentList}
                          defaultValue={
                            departmentList?.length > 0 ? department?._id : ""
                          }
                        />
                      </div>
                    </div>
                    <div className="row d-flex justify-content-between  pt-3">
                      <div className="col-md-4 mt-2">
                        <LabelField
                          lableName="Sub Department"
                          isRequired={true}
                        />
                      </div>
                      <div className="col-md-7">
                        <CustomDropdown
                          lableName="Select Sub Department"
                          setInputText={(value: any) => setSubDepartment(value)}
                          value={subDepartment}
                          options={subDepartmentList}
                          defaultValue={
                            subDepartmentList?.length > 0
                              ? subDepartment?._id
                              : ""
                          }
                        />
                      </div>
                    </div>
                    <div className="row d-flex justify-content-between  pt-3">
                      <div className="col-md-4 mt-2">
                        <LabelField lableName="Designation" isRequired={true} />
                      </div>
                      <div className="col-md-7">
                        <CustomDropdown
                          lableName="Select Designation"
                          setInputText={(value: any) => setDesignation(value)}
                          value={designation}
                          options={designationList}
                          defaultValue={
                            designationList?.length > 0 ? designation?._id : ""
                          }
                        />
                      </div>
                    </div>
                  </div>

                  <div className="col-md-6 p-2">
                    <div className="tabheader p-2">Education & Work</div>
                    <div className="row d-flex justify-content-between mt-1">
                      {qualificationArr?.map((item: any, index: number) => {
                        const { qualification, educationYear } = item;
                        return (
                          <div className="position-relative" key={index}>
                            <div className="position-relative">
                              <div className="row d-flex justify-content-between  pt-3">
                                <div className="col-md-4 mt-2">
                                  <LabelField lableName="Qualification" />
                                </div>
                                <div className="col-md-7 d-flex">
                                  <CustomDropdown
                                    lableName="Select Qualification"
                                    setInputText={(value: any) =>
                                      onChangeQualification(
                                        index,
                                        value,
                                        "qualification"
                                      )
                                    }
                                    value={qualification}
                                    options={HrQualificationList}
                                    defaultValue={
                                      HrQualificationList?.length > 0
                                        ? qualification
                                        : ""
                                    }
                                  />
                                  <span className="d-flex justify-content-end ms-2 removeButton">
                                    {qualificationArr.length === 1 ? null : (
                                      <HighlightOffIcon
                                        className="cursor"
                                        color="primary"
                                        fontSize="small"
                                        onClick={() =>
                                          onPressCloseQualification(index)
                                        }
                                      />
                                    )}
                                  </span>
                                </div>
                              </div>
                              {qualification ? (
                                <div className="row d-flex justify-content-between  ">
                                  <div className="col-md-4 mt-2">
                                    <LabelField
                                      lableName="Education Year"
                                      isRequired={true}
                                    />
                                  </div>
                                  <div className="col-md-7 mx-3 px-4">
                                    <InputField
                                      placeholder="Enter Education Year"
                                      value={educationYear}
                                      onChangeInput={(value: any) =>
                                        onChangeQualification(
                                          index,
                                          value,
                                          "educationYear"
                                        )
                                      }
                                    />
                                  </div>
                                </div>
                              ) : null}
                            </div>
                          </div>
                        );
                      })}
                      <div className="d-flex justify-content-end">
                        <Button
                          name={"Add Qualification"}
                          handleClick={onPressAddQualification}
                        />
                      </div>
                    </div>
                    <div className="row d-flex justify-content-between  ">
                      <div className="col-md-4 mt-2">
                        <LabelField
                          lableName="Candidate Type"
                          isRequired={true}
                        />
                      </div>
                      <div className="col-md-7 mt-2">
                        <span className="me-3 mt-2 ms-3">
                          <input
                            type="radio"
                            value={1}
                            checked={type === 1}
                            onChange={() => onChangeType(1)}
                          />{" "}
                          Fresher
                        </span>
                        <span className="me-3 mt-2">
                          <input
                            type="radio"
                            value={2}
                            checked={type === 2}
                            onChange={() => onChangeType(2)}
                          />{" "}
                          Experienced
                        </span>
                      </div>
                    </div>
                    {type === 2 ? (
                      <>
                        <div className="row d-flex justify-content-between ">
                          <div className="col-md-4 mt-2">
                            <LabelField
                              lableName="Current Company"
                              isRequired={true}
                            />
                          </div>
                          <div className="col-md-7">
                            <InputField
                              placeholder="Enter Current Company"
                              value={currentCompany}
                              onChangeInput={(value: any) =>
                                setCurrentCompany(value)
                              }
                            />
                          </div>
                        </div>
                        <div className="row d-flex justify-content-between ">
                          <div className="col-md-4 mt-2">
                            <LabelField
                              lableName="Current CTC"
                              isRequired={true}
                            />
                          </div>
                          <div className="col-md-7">
                            <InputField
                              placeholder="Enter Current CTC in Lakhs"
                              value={currentCTC}
                              onChangeInput={(value: any) =>
                                setCurrentCTC(value)
                              }
                            />
                          </div>
                        </div>
                        <div className="row d-flex justify-content-between ">
                          <div className="col-md-4 mt-2">
                            <LabelField lableName="Experience" />
                          </div>
                          <div className="col-md-7">
                            <InputField
                              placeholder="Enter Experience in Months"
                              value={experience}
                              onChangeInput={(value: any) =>
                                setExperience(value)
                              }
                            />
                          </div>
                        </div>
                      </>
                    ) : null}

                    <div className="row d-flex justify-content-between ">
                      <div className="col-md-4 mt-2">
                        <LabelField lableName="Upload CV" isRequired={true} />
                      </div>
                      <div className="col-md-7">
                        <ImagePickerAndViewer
                          lableName={"Upload PDF"}
                          value={selectedCV}
                          doctype={"pdf"}
                          removelabel={true}
                          htmlFor={`pdf-upload`}
                          handleChange={(e: any) => onChangePDFCV(e)}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 p-2">
                    <div className="tabheader p-2">Action & Disposition</div>
                    <div className="row d-flex justify-content-between pt-2 ">
                      <div className="col-md-4 mt-2">
                        <LabelField lableName="Status" />
                      </div>
                      <div className="col-md-7">
                        <CustomDropdown
                          lableName="Select Status"
                          setInputText={(value: any) => onChangeStatus(value)}
                          value={status}
                          options={statusList}
                          defaultValue={
                            statusList?.length > 0 ? status?._id : ""
                          }
                          reset={resetStatus}
                          disabled={true}
                        />
                      </div>
                    </div>
                    <div className="row d-flex justify-content-between mt-1">
                      <div className="col-md-4 mt-2">
                        <LabelField lableName="Status Details" />
                      </div>
                      <div className="col-md-7">
                        <CustomDropdown
                          lableName="Select Status Details"
                          setInputText={(value: any) =>
                            onChangeSubStatus(value)
                          }
                          value={subStatus}
                          options={subStatusList}
                          defaultValue={
                            subStatusList?.length > 0 ? subStatus?._id : ""
                          }
                          reset={resetSubStatus}
                          disabled={true}
                        />
                      </div>
                    </div>

                    <div className="row d-flex justify-content-between mt-1 pb-2">
                      <div className="col-md-4 mt-0">
                        <LabelField lableName="Remark" isRequired={true} />
                      </div>
                      <div className="d-flex">
                        <textarea
                          className="form-control"
                          placeholder={"Remark"}
                          value={remark}
                          onChange={(e) => changeRemark(e.target.value)}
                        ></textarea>
                      </div>
                    </div>
                    {status?._id === 1 ? (
                      <div className="row d-flex justify-content-between ">
                        <div className="col-md-4 mt-2">
                          <LabelField
                            lableName="Call Back Date"
                            isRequired={true}
                          />
                        </div>
                        <div className="col-md-7">
                          <DateTime
                            value={callBackDate}
                            setInputText={(value: any) =>
                              setCallBackDate(value)
                            }
                            defaultValue={callBackDate}
                            mindate={moment
                              .utc(new Date())
                              .format("YYYY-MM-DD HH:MM:SS")}
                          />
                        </div>
                      </div>
                    ) : null}

                    <div className=" d-flex justify-content-end mt-2 ">
                      <SubmitButton name={"Save"} validation={validations()} />
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
