import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import PageTitle from "../../Component/pageTitle";
import GenaralTable from "../../Component/genaralTable";
import SalesPanelSatckGraph from "../../Component/salesPanelStackGraph";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import {
  getCounsellorListById,
  getManagerRevenueList,
  getTeamLeadList,
} from "../../Redux/Actions/managerPanelAction";
import moment from "moment";
import LabelField from "../../Component/labelField";
import DatePicker from "../../Component/datePicker";
import { useSelector } from "react-redux";
import SubmitButton from "../../Component/submitButton";
import { updateCommonValues } from "../../Redux/Actions/CommonAction";
import { useAppDispatch } from "../../hooks";
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export default function SummeryOverview() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const counsellorData = location.state?.counsellorId;
  const { loggedInUserRole, crmEmployeeId, sDate, eDate } = useSelector(
    (state: any) => ({
      loggedInUserRole: state.Auth.loggedInUserRole,
      crmEmployeeId: state.Auth.counsellorId,
      sDate: state.Auth.startDate,
      eDate: state.Auth.endDate,
    })
  );

  const [totalRevenue, SetTotalRevenue] = useState<any>("");
  const [xLabel, setXLabel] = useState<any>([]);
  const [Graphdata1, setGraphdata1] = useState<any>([]);
  const [teamLeadList, setTeamLeadList] = useState<any>([]);
  const [counsellorByIdList, setCounsellorByIdList] = useState<any>([]);
  const [startDate, SetStartDate] = useState<any>(sDate);
  const [endDate, SetEndDate] = useState<any>(eDate);

  console?.log("counsellorData", counsellorData);

  useEffect(() => {
    if (loggedInUserRole === 1) {
      let data = {
        startDate,
        endDate,
        managerId: counsellorData,
      };
      dispatch(
        getTeamLeadList(
          data,
          (res: any) => {
            setTeamLeadList(res?.data);
            showGraph(res?.data);
          },
          () => {}
        )
      );
    } else if (loggedInUserRole === 2) {
      let data = {
        startDate,
        endDate,
        managerId: crmEmployeeId,
      };
      dispatch(
        getTeamLeadList(
          data,
          (res: any) => {
            setTeamLeadList(res?.data);
            showGraph(res?.data);
          },
          () => {}
        )
      );
    }
  }, []);

  const onChange = () => {
    if (loggedInUserRole === 1) {
      dispatch(
        getManagerRevenueList(
          {
            startDate,
            endDate,
          },
          (res: any) => {
            setTeamLeadList(res?.data);
            showGraph(res?.data);
          },
          () => {}
        )
      );
    } else if (loggedInUserRole === 2) {
      dispatch(
        getTeamLeadList(
          {
            startDate,
            endDate,
            managerId: crmEmployeeId,
          },
          (res: any) => {
            setTeamLeadList(res?.data);
            showGraph(res?.data);
          },
          () => {}
        )
      );
    }
  };

  const showGraph = (list: any) => {
    console?.log("list", list);
    let total = 0;
    while (xLabel.length) {
      xLabel.pop();
      Graphdata1?.pop();
    }
    let labelList: any = [];
    let graphDataList: any = [];
    if (loggedInUserRole === 1) {
      list?.map((val: any) => {
        total = total + val?.revenue;
        labelList?.push(val?.name);
        graphDataList?.push(val?.revenue);
      });
    } else if (loggedInUserRole === 2) {
      list?.map((val: any) => {
        total = total + val?.revenue;
        labelList?.push(val?._id?.name);
        graphDataList?.push(val?.revenue);
      });
    }
    SetTotalRevenue(total?.toFixed(2));
    setXLabel(labelList);
    setGraphdata1(graphDataList);
  };

  const handleEdit = (value: any) => {
    let tempData: any = {};
    tempData["_id"] = value?.studentLeadsId;
    dispatch(updateCommonValues("selectedLeadId", tempData?._id));
    navigate("../completeLeadDetail", {
      state: {
        pageName: "summaryOverview",
      },
    });
  };

  const renderListMode = () => {
    return (
      <div>
        <GenaralTable
          tableData={counsellorByIdList ? counsellorByIdList : []}
          pagename={"SummeryOverview"}
          tableHead={[
            "Sr.No.",
            "First Name",
            "Last Name",
            "Exam",
            "Exam Stream",
            "Source",
            "Revenue",
          ]}
          handleEdit={(value: any) => handleEdit(value)}
        />
      </div>
    );
  };

  const getManagerData = (val: any) => {
    if (loggedInUserRole === 1) {
      const data = {
        startDate,
        endDate,
        counsellorId: teamLeadList[val?.index]?._id,
      };
      dispatch(
        getCounsellorListById(
          data,
          (res: any) => {
            setCounsellorByIdList(res);
          },
          () => {}
        )
      );
    } else if (loggedInUserRole === 2) {
      const data = {
        startDate,
        endDate,
        counsellorId: teamLeadList[val?.index]?._id?._id,
      };
      dispatch(
        getCounsellorListById(
          data,
          (res: any) => {
            setCounsellorByIdList(res);
          },
          () => {}
        )
      );
    }
  };

  const onChangeStartDate = (val: any) => {
    SetStartDate(val);
    dispatch(updateCommonValues("startDate", val));
  };

  const onChangeEndDate = (val: any) => {
    SetEndDate(val);
    dispatch(updateCommonValues("endDate", val));
  };

  return (
    <div className="contentBox">
      <PageTitle name={"Revenue Analysis Overview"} />
      <div className="col-md-12 row">
        <div className="formDiv formWidth p-2">
          <div className="d-flex px-5  mx-5">
            <div className="col-md-3 d-flex me-2 mt-3 ">
              <div className="col-md-4 d-flex flex-row-reverse">
                <LabelField lableName="Start Date :" />
              </div>
              <div className="col-md-8">
                <DatePicker
                  value={startDate}
                  setInputText={(value: string) => onChangeStartDate(value)}
                  maxdate={moment.utc(new Date()).format("YYYY-MM-DD")}
                />
              </div>
            </div>
            <div className="col-md-3 d-flex me-2  mt-3">
              <div className="col-md-4 d-flex flex-row-reverse">
                <LabelField lableName="End Date :" />
              </div>
              <div className="col-md-8">
                <DatePicker
                  value={endDate}
                  setInputText={(value: string) => onChangeEndDate(value)}
                  maxdate={moment.utc(new Date()).format("YYYY-MM-DD")}
                />
              </div>
            </div>
            <div className=" col-md-3 text-center mb-2  mt-2 me-4 ">
              <SubmitButton name={"Submit"} handleClick={onChange} />
            </div>
          </div>
          <div className="d-flex justify-content-between mx-3 mt-3 boxshadow">
            <div className="col-md-12 p-3">
              <h4 className="fw-bold">Overview </h4>
              <span className="fw-bold">Total Revenue : {totalRevenue}</span>
              <SalesPanelSatckGraph
                label={xLabel}
                value={Graphdata1}
                getManagerData={(val: any) => getManagerData(val)}
              />
            </div>
          </div>
          <div className="my-3 mx-3">{renderListMode()}</div>
        </div>
      </div>
    </div>
  );
}
