class AuthReducerModal {
  loader = false;
  accountType = "";
  authToken = "";
  counsellorId = "";
  managerId = "";
  loggedInUserId = "";
  loggedInUserManagerId = "";
  loggedInUserName = "";
  loggedInUserRole = 0;
  selectedLeadId = "";
  selectedLeadName = "";
  selectedClickedDate = "";
  selectedManagerId = "";
  selectedCounsellorId = "";
  selectedExamTypeId = "";
  selectedSubExamTypeId = "";
  selectedBaseCourseId = "";
  selectedMainCategoryId = "";
  selectedSubCategoryId = "";
  selectedStatus = "";
  selectedSubStatus = "";
  selectedPage = "";
  selectedRowsPerPage = "";
  startDate = "";
  endDate = "";
  CollegeName = "";
  CollegeContactNo = "";
  CollegeEmail = "";
  CollegeState = "";
  CollegeDistric = "";
  executiveID = "";
  ozonetelIframe = false;
  ozonetelURL = "";
  ozonetelApiKey = "";
  ozonetelAccountId = "";
  ozonetelAgentId = "";
  ozonetelPin = "";
  ozonetelAgentSIP = "";
  ozonetelContactNo = "";
}

class LeadManagementModal {}

class SnackbarReducerModal {
  successSnackbarOpen = false;
  errorSnackbarOpen = false;
  infoSnackbarOpen = false;
  message = "";
}

export { AuthReducerModal, LeadManagementModal, SnackbarReducerModal };
