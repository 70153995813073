import React, { useState } from "react";
import "../../../App.css";
import PageTitle from "../../../Component/pageTitle";
import InputField from "../../../Component/inputField";
// import NoRecord from "../../Component/noRecord";
import { useDispatch } from "react-redux";
import {
  getRegisteredUser,
  startEndLiveStatus,
} from "../../../Redux/Actions/counsellorPanelAction";
import moment from "moment";
import { showErrorSnackbar } from "../../../Redux/Actions/snackbarAction";
import {
  containsNumber,
  length_Ten,
  validateValueWithoutSpace,
} from "../../../DATA/validators";
import {
  validateIsNumberOnlyErrMsg,
  validateTenDigitNumberOnlyErrMsg,
} from "../../../DATA/errorMsg";
import LabelField from "../../../Component/labelField";
import SubmitButton from "../../../Component/submitButton";

function MultiDeviceTokenClear() {
  const dispatch = useDispatch();
  const [details, setdetails] = useState<any>(false);

  const [userdetails, setuserdetails] = useState<any>();
  const [liveStatus, setLiveStatus] = useState<any>();

  const [phoneNumber, setPhoneNumber] = useState<any>("");

  const onChangePhone = (value: any) => {
    if (value !== "") {
      if (!containsNumber(value)) {
        dispatch(showErrorSnackbar("Only Numbers are allowed!") as any);
        return;
      } else if (!validateValueWithoutSpace(value)) {
        dispatch(showErrorSnackbar(validateIsNumberOnlyErrMsg) as any);
        return;
      } else {
        setPhoneNumber(value);
      }
    } else {
      setPhoneNumber(value);
    }
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();

    if (phoneNumber.length > 0 && phoneNumber.length !== 10) {
      dispatch(showErrorSnackbar(validateTenDigitNumberOnlyErrMsg) as any);
    }
    dispatch(
      getRegisteredUser(
        phoneNumber,
        (res: any) => {
          console.log("redsxc", res);
          setuserdetails(res.data);
          setLiveStatus(res.data.liveStatus);
          setdetails(true);
        },
        () => {}
      )
    );
  };

  const ClearloginToken = () => {
    dispatch(
      startEndLiveStatus(
        {
          mobileNo: userdetails?.user?.mobileNo,
        },
        (res: any) => {
          console.log("resss0", res);
          setLiveStatus(res?.data?.isLive);
        }
      )
    );
  };

  const validations = () => {
    return phoneNumber === "" || phoneNumber.length !== 10;
  };

  return (
    <div className="contentBox">
      <PageTitle name={"Multi Device Token Clear"} />
      <div className="row m-0">
        <div className="formDiv blank-20px">
          <form className="col-12 formWidth" onSubmit={handleSubmit}>
            <div className="d-flex pb-5">
              <div className="row d-flex justify-content-between pt-2">
                <div className="col-md-5 mt-2">
                  <LabelField lableName="Phone Number" isRequired={true} />
                </div>
                <div className="col-md-7">
                  <InputField
                    placeholder="Enter Phone Number"
                    value={phoneNumber}
                    onChangeInput={(value: any) => onChangePhone(value)}
                    maxlength={length_Ten}
                    minlength={length_Ten}
                  />
                </div>
              </div>

              <div className="d-flex justify-content-center mt-2">
                <div className="col-md-7">
                  <SubmitButton name="Search" validation={validations()} />
                </div>
              </div>
            </div>
          </form>

          <div>
            {details === true ? (
              <div>
                <div
                  className="d-flex w-100 justify-content-between"
                  style={{ backgroundColor: "#3f51b5" }}
                >
                  <p className="p-1  text-white mb-0">Personnel & Contact</p>

                  {liveStatus ? (
                    <p className="p-1 text-white mb-0 me-2">Login Status: 1</p>
                  ) : (
                    <p className="p-1 text-white mb-0 me-2">Login Status: 0</p>
                  )}
                </div>

                <div className=" row d-flex ">
                  <div className="col-6 d-flex ps-4">
                    <p className="col-3"> First Name</p>
                    <p className="col-3">{userdetails?.user?.firstName}</p>
                  </div>
                  <div className=" col-6 d-flex ps-4">
                    <p className="col-3"> Last Name</p>
                    <p className="col-3">{userdetails?.user?.lastName}</p>
                  </div>
                  <div className="col-6 d-flex ps-4">
                    <p className="col-3">Contact</p>
                    <p className="col-3">{userdetails?.user?.mobileNo}</p>
                  </div>
                  <div className="col-6 d-flex ps-4">
                    <p className="col-3">Email Address</p>
                    <p className="col-3">{userdetails?.user?.email}</p>
                  </div>
                </div>

                {/* ***********************course details************************ */}

                <p
                  className="p-1 mb-2 text-white mb-4"
                  style={{ backgroundColor: "#3f51b5" }}
                >
                  Course Details
                </p>

                <div className=" row d-flex ">
                  <div className="col-6 d-flex ps-4">
                    <p className="col-3">Parent Course</p>
                    <p className="col-3">
                      {userdetails?.user?.basecourse?.name}
                    </p>
                  </div>
                  <div className=" col-6 d-flex ps-4">
                    <p className="col-3">Registration Date</p>
                    <p className="col-9">
                      {moment
                        .utc(userdetails?.user?.createdAt)
                        .format("DD-MM-YYYY")}
                    </p>
                  </div>
                  <div className="col-6 d-flex ps-4">
                    <p className="col-3">Exam Name</p>
                    <p className="col-3">
                      {userdetails?.data[0]?.examData?.name}
                    </p>
                  </div>
                  <div className=" col-6 d-flex ps-4">
                    <p className="col-3">Batch Name</p>
                    <p className="col-3">
                      {userdetails?.data[0]?.coursesData?.name}
                    </p>
                  </div>
                </div>

                <div className="col-auto d-flex justify-content-center mt-4">
                  <button
                    className={"btn mb-3 submitbtn btn-primary"}
                    onClick={ClearloginToken}
                  >
                    Clear Login Token
                  </button>
                </div>
              </div>
            ) : (
              "" // <NoRecord />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default MultiDeviceTokenClear;
